import React from 'react'
import styled from '@emotion/styled'
import { BadgeNew } from '../Common/Badge/BadgeNew'

const CommonCardImageContainer = styled.div({}, ({ size, borderRadius, hoverEffect, ismobile }) => {

  const swipe45 = hoverEffect==='swipe45' ? {
    '&:hover div::before': {
      '-webkit-transform': 'scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,150%,0)',
      transform: 'scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,150%,0)'
    }}
    : {}

  const baseStyle = {
    ...swipe45,
    position: 'relative',
    overflow: 'hidden',
    width : size.pc.width,
    height: size.pc.height,
    boxShadow: 'none',
    borderRadius: borderRadius
  }

  const mobileStyle = {
    width : size.mobile.width,
    height: size.mobile.height,    
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const CommonCardImageEffect = styled.div`
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.5);
    content: '';
    -webkit-transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-120%,0);
    transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-120%,0);
  }
`

const CommonCardImageBody = styled.img({}, ({ hoverEffect, ismobile }) => {

  const enlarge = hoverEffect==='enlarge' ? {
    transform: 'scale(1.1)',
    transition: 'all 0.5s ease'
  } : {}
  const baseStyle = {
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
    position: 'relative',
  }
  
  const mobileStyle = {
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : {
    ...baseStyle,
    '&:hover': enlarge
  }
})

export function CommonCardImage(props) {
  const { imageUrl, iconnew=false, size, hoverEffect, ismobile, borderRadius } = props
  
  // hovereffect 
  // 1. enlarge
  // 2. swipe45
  // 3. playbutton

  const iconNewElement = iconnew ? (
    <BadgeNew ismobile={ismobile} size={'list'}></BadgeNew>
  ) : null

  return (
    <CommonCardImageContainer size={size} borderRadius={borderRadius} hoverEffect={hoverEffect} ismobile={ismobile}>
      <CommonCardImageBody src={imageUrl} hoverEffect={hoverEffect} ismobile={ismobile}>
      </CommonCardImageBody>
      { iconNewElement }
      <CommonCardImageEffect hoverEffect={hoverEffect}></CommonCardImageEffect>
    </CommonCardImageContainer>    
  )
}
