import { blue, gray, orange } from '~/react/Foundation/Color'

export const InputSizeStyle = {
  large: { height: '44px', padding: '8px 12px', radius: '6px', iconsize: 16, gap: '12px' },
  medium: { height: '34px', padding: '8px 10px', radius: '6px', iconsize: 16, gap: '10px' },
  small: { height: '32px', padding: '8px', radius: '4px', iconsize: 16, gap: '8px' }
}

export const InputValidStatusColorStyle = {
  normal: gray[50],
  active: gray[70],
  complete: gray[50],
  error: orange[50],
  confirm: blue[50]
}
