/** HTTP 요청 상태와 관련한 상태를 저장하는 Store */

export const state = () => ({
  /** 토큰 갱신이 진행중인가 */
  isRefreshingToken: false
})

export const mutations = {
  SET_IS_REFRESHING(state, isRefreshingToken) {
    state.isRefreshingToken = isRefreshingToken
  }
}

export const actions = {
  setIsRefreshingToken({ commit }, isRefreshingToken) {
    commit('SET_IS_REFRESHING', isRefreshingToken)
  }
}

export const getters = {
  getIsRefreshingToken: (state) => {
    return state.isRefreshingToken
  }
}
