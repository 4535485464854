import React, { useState } from 'react'
import styled from '@emotion/styled'
import { BadgeNew } from '../../Badge/BadgeNew'
import { CardEmptyImage } from '~/react/Common/Card/CardEmptyImage'
import { gray } from '~/react/Foundation/Color'
import { ImageResizeTag } from '~/react/common'

const CardUpdateImageContainer = styled.div({}, ({ ismobile, size }) => {
  let width = ''
  let height = ''
  let minWidth = '164px'
  if (ismobile) {
    switch(size){
      case 'medium': {
        minWidth = '224px'
        break;
      }
      case 'large': {
        minWidth = '344px'
        break;
      }
      default:
        width = '100%';
        break;
    }
  } else {
    width ='206px'
    height = '116px'
  }
  const baseStyle = {
    width,
    height,
    overflow: 'hidden',
    borderRadius: '4px'
  }

  const mobileStyle = {
    width,
    minWidth,
    aspectRatio: '16 / 9',
    overflow: 'hidden',
    border: `1px solid ${gray[50]}`,
    flexShrink: 0,
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const CardUpdateImageBody = styled.img({
  }, ({ ismobile }) => {

  const baseStyle = {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    position: 'relative',
  }
  return baseStyle
})

export function CardUpdateImage(props) {
  const { imageUrl, ismobile, iconnew, ...extraProps } = props

  const imgProps = ImageResizeTag({
    imageUrl: imageUrl,
  })

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <CardUpdateImageContainer ismobile={ismobile} {...extraProps}>
      {imageError ? (
        <CardEmptyImage />
      ) : (
        <CardUpdateImageBody {...imgProps} ismobile={ismobile} className="card-update-image-scale" onError={handleImageError}>
        </CardUpdateImageBody>
      )}
      { iconnew && <BadgeNew ismobile={ismobile} size={'list'}></BadgeNew> }
    </CardUpdateImageContainer>
  )
}
