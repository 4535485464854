// eslint-disable-next-line no-unused-vars
import { rest } from 'msw'
// import { RESPONSE_IN_DELIVERY_PROGRESS, RESPONSE_CONTAINS_REFUND } from '~/mocks/data'

// eslint-disable-next-line no-unused-vars
const BASE_URL = `${process.env.WEB_URL}/_n`

export const handlers = [
  rest.post(`${BASE_URL}/order/v1/my/shopping/invoice`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        resCd: '1000',
        resMsg: 'success',
        resData: {
          totCnt: 1,
          invoiceList: [
            {
              invNo: 'FEDEX0001',
              logisNm: 'FEDEX',
              logisCd: 'FEDEX',
              trackingUrl: 'https://www.fedex.com/fedextrack/no-results-found?trknbr=FEDEX0001',
              makestarTrackingUrl: null,
              status: 'S',
              goodsflowCd: null,
              statusName: null,
              odrNo: 'PC0DBDBQ',
              odrSubNo: 'PC0DBDBQ001',
              statusCheck: 'S'
            }
          ],
          pageSize: 5
        }
      })
    )
  }),
  rest.post(`${BASE_URL}/order/v1/my/project/invoice`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        resCd: '1000',
        resMsg: 'success',
        resData: {
          totCnt: 1,
          invoiceList: [
            {
              invNo: 'FEDEX0001',
              logisNm: 'JDL',
              logisCd: 'JDL',
              trackingUrl: 'https://www.fedex.com/fedextrack/no-results-found?trknbr=FEDEX0001',
              makestarTrackingUrl: null,
              status: 'R',
              goodsflowCd: null,
              statusName: null,
              odrNo: 'PC0DBDBQ',
              odrSubNo: 'PC0DBDBQ001',
              statusCheck: 'R'
            }
          ],
          pageSize: 5
        }
      })
    )
  })
]
