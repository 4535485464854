import React, { useEffect, useState } from 'react'

/**@typedef Props
 * @param {string} tooltip 툴팁 텍스트
 * @param {number} position 툴팁 위치
 * @param {'left' | 'right'} align 툴팁 좌우 정렬
 */

/**
 * ToolTip Component
 * @param {Props} props
 * @returns {JSX.Element}
 */
export default function(props) {
  const { tooltip = 'tooltip!', position, align = 'left' } = props

  const css = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    position: 'absolute',
    width: 'fit-content',
    background: 'rgba(51, 51, 51, 0.9)',
    borderRadius: '4px',
    color: '#FFFFFF',
    zIndex: 1000,
    whiteSpace: 'pre-line',
    fontSize: '13px',
    fontWeight: '700',
    lineHeight: '28px',
    textAlign: 'left',
    maxWidth: '80%'
  }

  const [innerPosition, setInnerPosition] = useState({
    X: 0,
    Y: 0
  })

  useEffect(() => {
    setInnerPosition(position)
  }, [position])

  return (
    <div
      style={{ ...css, display: innerPosition.Y > 0 ? '' : 'none', [align]: innerPosition.X, top: innerPosition.Y }}>
      {tooltip}
    </div>
  )
}
