import React from 'react'
import styled from '@emotion/styled'
import { ProjectCardImage } from './ProjectCardImage'
import { ProjectCardTimer } from './ProjectCardTimer'
import { ProjectCardPrice } from './ProjectCardPrice'
import { ProjectCardStatusText } from './ProjectCardStatusText'
import { ProjectCardProgressBar } from './ProjectCardProgressBar'
import { CardTitleDescInfo } from './CardTitleDescInfo'

const ProjectCardContainer = styled.div({}, ({ direction, ismobile }) => {
  
  const cardSize = {}

  if( direction === 'column') {
    if (ismobile) {
      cardSize.width = '162px'
      cardSize.height = '197px'
    } else {
      cardSize.width = '284px'
      cardSize.height = '326px'
    }
  } else { // row
    if (ismobile) {
      cardSize.width = '162px' // TODO
      cardSize.height = '197px' // TODO
    } else {
      cardSize.width = '610px'
      cardSize.height = '162px'
    }
  }

  
  const baseStyle = {
    ...cardSize,
    display: 'flex',
    flexDirection: direction,
    cursor: 'pointer',
    // .project-card-container
    boxShadow: 'none',
    transition: 'box-shadow 0.5s',
    // project-link
    position: 'relative',
    
    // display: 'block',
    
    // 추가
    // marginLeft: '8px',
    // marginRight: '8px',
  }
  const mobileStyle = {
    // project-link
    
    // 추가
    padLeft: '8px',
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ProjectCardImageWrapper = styled.div({}, ({ ismobile }) => {
  
  const baseStyle = {
  }
  
  const mobileStyle = {
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ProjectCardInfoWrapper = styled.div({}, ({ direction, ismobile }) => {
  
  const margin = {}

  if( direction === 'column') {
    margin.marginLeft = '0px'
  } else { // row
    margin.marginLeft = '4px'
  }
  
  const baseStyle = {
    ...margin,
    display: 'flex',
    flexDirection: 'column',
    width :'288px',
    height: '162px'
    
  }
  
  const mobileStyle = {
    width :'164px', 
    height: '81px'
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ProjectCardInfoHeader = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    height: '120px'
  }
  const mobileStyle = {
    height: '70px'
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle  
})

const ProjectCardInfoFooter = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    height: '40px'
  }
  const mobileStyle = {
    height: '11px'
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle  
})

const ProjectCardStatusWrapper = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    width: '286px'
  }
  const mobileStyle = {
    width : '164px'
  }
  
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function ProjectCard(props) {
  const { item, title, description, imageurl, iconnew, projectlabel, cardcontext, price, direction, clickcallback, ismobile, locale } = props
  
  let validDirection = 'column'
  if( direction === undefined) {
    validDirection = 'column'
  } else {
    validDirection = direction
  }

  return (
    <ProjectCardContainer onClick={()=>clickcallback(item.url)} direction={validDirection} ismobile={ismobile}>
      <ProjectCardImageWrapper>
        <ProjectCardImage imageUrl={imageurl} iconnew={iconnew} ismobile={ismobile}>
        </ProjectCardImage>
      </ProjectCardImageWrapper>
      <ProjectCardInfoWrapper direction={validDirection} ismobile={ismobile}>
        <ProjectCardInfoHeader ismobile={ismobile}>
          <ProjectCardTimer 
            // className = {'project-timer-' + item.idx}
            projectLabel={projectlabel}
            color={cardcontext.timerColor}
            ismobile={ismobile}>
          </ProjectCardTimer>
          <CardTitleDescInfo title={title} description={description} ismobile={ismobile}>
          </CardTitleDescInfo>
        </ProjectCardInfoHeader>
        <ProjectCardInfoFooter ismobile={ismobile}>
          <ProjectCardStatusWrapper ismobile={ismobile}>
            <ProjectCardPrice price={price} ismobile={ismobile}>
            </ProjectCardPrice>
            <ProjectCardStatusText 
              statusText={cardcontext.statusText}
              color={cardcontext.statusColor}
              ismobile={ismobile}
              locale={locale}>
            </ProjectCardStatusText>
          </ProjectCardStatusWrapper>
          <ProjectCardProgressBar color={cardcontext.progreeBarColor} value={cardcontext.progreeBarValue} height={4} ismobile={ismobile}>
          </ProjectCardProgressBar>
        </ProjectCardInfoFooter>
      </ProjectCardInfoWrapper>      
    </ProjectCardContainer>
  )
}

