import React from 'react'
import styled from '@emotion/styled'
import { CommonCardImage } from '../commonCard/CommonCardImage'

const CandidateCardContainer = styled.div({}, ({ ismobile, status }) => {
  const cardSize = {}
  if (ismobile) {
    cardSize.width = '100%'
    cardSize.height = 'auto'
  } else {
    cardSize.width = '100%'
    cardSize.height = 'auto' 
  }
  const baseStyle = {
    ...cardSize,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    position: 'relative',
    borderRadius: '8px',
    overflow: 'hidden'
  }

  if (status === 'disabled' || status === 'selected' || status === 'unselected') {
    baseStyle.cursor = 'unset'
  } else {
    baseStyle.cursor = 'pointer'
  }

  const mobileStyle = {
    marginBottom: '32px'
  }
  const checkedStyle = status === 'checked' || status === 'selected'
  ? {
    border: '1px solid #E7351E'
  } : {
    border: '1px solid #FFFFFF'
  }
  return ismobile ? {
    ...baseStyle, ...checkedStyle,  ...mobileStyle
  } : {
    ...baseStyle, ...checkedStyle
  }
})

const ImageWrapper = styled.div({}, ({ ismobile }) => {
  const baseStyle = {}
  const mobileStyle = {}  
  return ismobile ? { ...baseStyle, ...mobileStyle } 
  : baseStyle
})

const InfoWrapper = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'auto',
    padding: '8px 16px 20px 16px',
  }
  const mobileStyle = {
    padding: '8px 12px 12px 12px',
  }  

  return ismobile ? { ...baseStyle, ...mobileStyle } 
  : baseStyle
})

const InfoElement = styled.div({}, ({ }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }
})

function TotalVotesPercentWrapper(props) {
  const {status, totalpercent, ismobile} = props
  
  const TotalVotesPercent = styled.div({}, ({ ismobile }) => {
    const baseStyle = {
      textAlign: 'left',
    }
    const mobileStyle = {
    } 
    return ismobile ? { ...baseStyle, ...mobileStyle } 
    : baseStyle
  })

  const TotalVotesPercentNumber = styled.span({}, ({ status, ismobile }) => {
    const baseStyle = {
      fontWeight: '700',
      fontSize: '18px',
      lineHeight: '26px',
      color: '#E8351D',
    }
    if (status === 'disabled') {
      baseStyle.color = '#CCCCCC'
    }

    const mobileStyle = {
      lineHeight: '22px',
    } 
    return ismobile ? { ...baseStyle, ...mobileStyle } 
    : baseStyle
  })
  const TotalVotesPercentUnit = styled.span({}, ({ status, ismobile }) => {
    const baseStyle = {
      fontWeight: '700',
      fontSize: '12px',
      lineHeight: '20px',
      color: '#E8351D'
    }
    if (status === 'disabled') {
      baseStyle.color = '#CCCCCC'
    }
    const mobileStyle = {
      lineHeight: '18px',
    } 
    return ismobile ? { ...baseStyle, ...mobileStyle } 
    : baseStyle
  })

  if (totalpercent === null) {
    return (
      <TotalVotesPercent ismobile={ismobile}>
      </TotalVotesPercent>
      )
  } else {
    return (
    <TotalVotesPercent ismobile={ismobile}>
      <TotalVotesPercentNumber status={status} ismobile={ismobile}>{totalpercent}</TotalVotesPercentNumber>
      <TotalVotesPercentUnit status={status} ismobile={ismobile}>{'%'}</TotalVotesPercentUnit>
    </TotalVotesPercent>
    )
  }
}

function TotalVotes(props) {
  const {children, css = {}, status, totalpercent, totalvotes, totalvotestext, ismobile} = props

  const TotalVotesWrapper = styled.div({}, ({ ismobile }) => {
    const baseStyle = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: '26px',
      marginBottom: '4px'
    }
    const mobileStyle = {
      height: '22px',
      marginBottom: '6px'
    }  
    return ismobile ? { ...baseStyle, ...mobileStyle } 
    : baseStyle
  })
  if (totalpercent === null && totalvotes === null) {
    return ''
  } else {
    return (
      <TotalVotesWrapper css={css} ismobile={ismobile}>
        <TotalVotesPercentWrapper status={status} totalpercent={totalpercent} ismobile={ismobile}/>
        <TotalVotesNumberWrapper status={status} totalvotes={totalvotes} totalvotestext={totalvotestext} ismobile={ismobile}/>
      </TotalVotesWrapper>)
  }
}

function TotalVotesNumberWrapper(props) {
  const {children, css = {}, status, totalvotes, totalvotestext, ismobile} = props
  const TotalVotesNumber = styled.span({}, ({ status, ismobile }) => {
    const baseStyle = {
      textAlign: 'right',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '22px',
      color: '#888888',
      paddingTop: '4px'
    }
    if (status === 'disabled') {
      baseStyle.color = '#CCCCCC'
    }
    const mobileStyle = {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '20px',
      paddingTop: '0px'
    }
    return ismobile ? { ...baseStyle, ...mobileStyle } 
    : baseStyle
  })

  if (totalvotes === null) {
    return ''
  } else {
    const totalvotescontent = totalvotes.toLocaleString() + totalvotestext
    return (
    <TotalVotesNumber status={status} ismobile={ismobile}> {totalvotescontent} </TotalVotesNumber>
    )
  }
}

const TitleLinkWrapper = styled.div({}, ({ ismobile}) => {
  const baseStyle = {
    minHeight: '92px'
  }
  const mobileStyle = {
    minHeight: '66px'
  }  
  return ismobile ? { ...baseStyle, ...mobileStyle } 
  : baseStyle
})
const TitleWrapper = styled.div({}, ({ status, ismobile }) => {
  const baseStyle = {
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#333333',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    overflowY: 'hidden',
    marginBottom: '4px'
  }
  if (status === 'disabled') {
    baseStyle.color = '#CCCCCC'
  }
  const mobileStyle = {
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '6px'
  }  

  return ismobile ? { ...baseStyle, ...mobileStyle } 
  : baseStyle
})

const LinkWrapper = styled.a({}, ({ ismobile }) => {
  const baseStyle = {
    textDecoration: 'none !important',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '18px',
    color: '#3BA0F7',
    textDecoration: 'underline'
  }
  const mobileStyle = {
    fontSize: '12px',
    lineHeight: '16px',
  }  
  return ismobile ? { ...baseStyle, ...mobileStyle } 
  : baseStyle
})

const CountWrapper = styled.div({}, ({}) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  }
})
const CheckboxRounded = styled.div({}, ({ismobile, status}) => {

  const baseStyle = {
    width: '32px',
    height: '32px',
    background: 'url(/image/svg-icon/check-on.svg)',
    backgroundSize: '32px',
    marginRight: '8px'
  }
  if (status === 'checked') {
    baseStyle.background = 'url(/image/svg-icon/check-on.svg)'
  } else if (status === 'unchecked') {
    baseStyle.background = 'url(/image/svg-icon/check-off.svg)'
  } else if (status === 'disabled') {
    baseStyle.background = 'url(/image/svg-icon/check-disabled.svg)'
  } else {
    return {}
  }

  const mobileStyle = {
    width: '22px',
    height: '22px',
    backgroundSize: '22px',
    marginRight: '6px'
  }  
  return ismobile ? { ...baseStyle, ...mobileStyle } 
  : baseStyle
})

const MyVotesCount = styled.div({}, ({ismobile, status}) => {
  const colorStyle = {}
  if (status === 'checked' || status === 'selected') {
    colorStyle.color = '#E8351D'
  } else if (status === 'unchecked' || status === 'unselected') {
    colorStyle.color = '#AAAAAA'
  } else if (status === 'disabled') {
    colorStyle.color = '#CCCCCC'
  }

  const baseStyle = {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    padding: '5px 0px'
  }
  const mobileStyle = {
    lineHeight: '16px',
  }  
  return ismobile ? { ...colorStyle, ...baseStyle, ...mobileStyle } 
  : {...colorStyle, ...baseStyle}
})

export function CandidateCard(props) {
  const { imageurl, name, status, myvotes, myvotestext, linkurl, linktext, totalvotes, totalvotestext, totalpercent, clickcallback, clickcallbackparam, ismobile } = props
  const size = {
    pc: {
      width: '100%',
      height: '100%'
    }, 
    mobile:{
      width: '100%',
      height: '100%'
    }
  }
  const radius = '0%'
  const myvotescontent = myvotestext + myvotes
  let onclickfunction
  if (status === 'disabled' || status === 'selected' || status === 'unselected') {
    onclickfunction = () => {}
  } else {
    onclickfunction = () => clickcallback(clickcallbackparam)
  }

  return (
    <CandidateCardContainer status={status} onClick={onclickfunction} ismobile={ismobile}>
      <div style={{width:'100%', paddingBottom: '100%', overflow: 'hidden', position: 'relative'}}>
        <div style={{position: 'absolute'}}>
          <ImageWrapper>
            <CommonCardImage imageUrl={imageurl} size={size} borderRadius={radius} ismobile={ismobile}>
            </CommonCardImage>
          </ImageWrapper>
        </div>
      </div>
      <InfoWrapper ismobile={ismobile}>
        <InfoElement>
          <TotalVotes status={status} totalpercent={totalpercent} totalvotes={totalvotes} totalvotestext={totalvotestext} ismobile={ismobile}>
          </TotalVotes>
          <TitleLinkWrapper ismobile={ismobile}>
            <TitleWrapper status={status} ismobile={ismobile}> {name} </TitleWrapper>
            <LinkWrapper href={linkurl} onClick={(event)=>{event.stopPropagation()}}> {linktext} </LinkWrapper>
          </TitleLinkWrapper>
        </InfoElement>
        <InfoElement>
          <CountWrapper>
            <CheckboxRounded status={status} ismobile={ismobile}></CheckboxRounded>
            <MyVotesCount ismobile={ismobile} status={status}>{myvotescontent}</MyVotesCount>
          </CountWrapper>
        </InfoElement>
      </InfoWrapper>
    </CandidateCardContainer>
  )
}

