import React from 'react'
import styled from '@emotion/styled'

const LogImg = styled.img({
  marginTop: '80px',
  cursor: 'pointer',
  width: '213px',
  height: '32px'
})
export function Logo({ callback }) {
  return (
    <LogImg src={'/image/logo.svg'}
      alt='MakeStar Logo'
      onClick={callback}/>
  )
}
