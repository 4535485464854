import React from 'react'
import styled from '@emotion/styled'

const CardTitleDescContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '12px'
  }
  const mobileStyle = {
    marginTop: '10px'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const CardTitle = styled.div({}, ({ ismobile }) => {

  const baseStyle = {
    // .project-title
    position: 'relative',
    //display: block;
    // minHeight: '18px',
    // maxHeight: '38px',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#333333 !important',
    // margin: '0 8px',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    overflowY: 'hidden',
  }
  const mobileStyle = {
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: '500',
    whiteSpace: 'pre',
    textOverflow: 'ellipsis'
    // minHeight: '19px',
    // maxHheight: '36px'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const CardDescription = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    // marginLeft: "8px",
    // marginRight: "8px",

    // .project_description
    position: 'relative',
    //display: block;
    // minHeight: '16px',
    // maxHeight: '32px',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '19px !important',
    color: '#999999',
    marginTop: '4px',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    overflowY: 'hidden',
  }
  const mobileStyle = {
    fontSize: '12px',
    lineHeight: '17px',
    fontWeight: '400',
    marginTop: '0px'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function CircledCardTitleDescInfo(props) {
  const { title, description, ismobile } = props
  // const descCaption = ismobile ? '' : description
  return (
    <CardTitleDescContainer ismobile={ismobile}>
      <CardTitle ismobile={ismobile}> {title}
      </CardTitle>
      <CardDescription ismobile={ismobile}> {description}
      </CardDescription>
    </CardTitleDescContainer>

  )
}
