import React from 'react'
import styled from '@emotion/styled'

import { BadgeNew } from '../Common/Badge/BadgeNew'

const ShopCardImageContainer = styled.div({}, ({ size, ismobile }) => {
  const baseStyle = {
    // .card-item
    width : size.pc.width,
    height: size.pc.height,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    // .relative-content 332
    boxShadow: 'none',
    transition: 'box-shadow 0.5s',

    // .image-hover-zoom 347
    overflow: 'hidden',

    // .card-thumbnail
    position: 'relative',
    flexShrink: '0',
    overflow: 'hidden',
    minHeight: '288px',
    maxHeight: '288px',
    borderRadius: '4px 4px 0 0',
    border: '0.5px solid #ccc'
  }

  const mobileStyle = {
    width : size.mobile.width,
    height: size.mobile.height,   
    minHeight: '160px',
    borderBottom: 'solid 1px #cccccc',
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ShopCardImageBody = styled.img({
  '&:hover': {
      transform: 'scale(1.1)'
  }
}, ({ ismobile }) => {
  const baseStyle = {
    position: 'absolute',
    height: '100%',
    top: '0',
    left: '0',
    display: 'flex',
    width: '100%',
    transition: 'all 0.5s ease'
  }
  const mobileStyle = {
    objectFit: 'cover',
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function ShopCardImage(props) {
  const { imageUrl, iconNew, size, ismobile } = props
  const iconNewElement = iconNew ? (
    <BadgeNew ismobile={ismobile} size={'list'}></BadgeNew>
  ) : null

  return (
    <ShopCardImageContainer size={size} ismobile={ismobile}>
      <ShopCardImageBody src={imageUrl}></ShopCardImageBody>
      { iconNewElement }
    </ShopCardImageContainer>
  )
}
