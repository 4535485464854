import React from 'react'
import styled from '@emotion/styled'


const ButtonContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    width: 'auto',
    minWidth: '49px',
    display: 'flex',
    justifyContent: 'center',
  }
  
  const mobileStyle = {
    width: 'auto',
    height: '40px',
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ButtonDisabledDiv = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    cursor: 'unset',
    width: '100%',
    height: '100%',
    borderRadius: '40px',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center',
    padding: '9px 20px',
    backgroundColor: 'DEDEDE',
    border: '1px solid #CCCCCC',
    color: '#333333',
    opacity: '0.3'
  }
  
  const mobileStyle = {
    fontSize: '16px',
    lineHeight: '16px',    
    padding: '12px 16px',
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle,
  }
  : baseStyle
})

const ButtonDiv = styled.div({
  '&:hover' : {
    outline: '1px solid #B92713',
    border: '1px solid #B92713',
    color: '#B92713',
    width: 'calc(100%-3px)',
    height: 'calc(100%-3px)'
  }
}, ({ selected }) => {
  const baseStyle = {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    borderRadius: '40px',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center',
    padding: '9px 20px',
  }
  
  const selectedStyle = selected ? {
    outline: '1px solid #E8351D',
    border: '1px solid #E8351D',    
    color: '#E8351D'
  } 
  : {
    border: '1px solid #CCCCCC',
    color: '#333333'
  }

  return {
    ...baseStyle,
    ...selectedStyle
  }
})

const ButtonDivMobile = styled.div({
}, ({ selected }) => {
  const mobileStyle = {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    borderRadius: '40px',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '16px',
    textAlign: 'center',
    padding: '12px 16px',
  }

  const selectedStyle = selected ? {
    // outline: '1px solid #333333', // ���ĸ����� outline�� radius�� ������� �ʴ� ��Ŷ ��ü ���׷� �ּ�ó��
    border: '1px solid #E8351D',    
    color: '#E8351D'
  } 
  : {
    border: '1px solid #CCCCCC',
    color: '#333333'
  }

  return {
    ...mobileStyle,
    ...selectedStyle
  }
})


export function ButtonIndex(props) {
  const { caption, selected, disabled, clickcallback, clickcallbackparam, ismobile } = props

  const buttonDiv = ismobile? (
    <ButtonDivMobile onClick={() => clickcallback(clickcallbackparam)} selected={selected}>
      { caption }
    </ButtonDivMobile>
  ) : (
    <ButtonDiv onClick={() => clickcallback(clickcallbackparam)} selected={selected}>
      { caption }
    </ButtonDiv>
  )

  return disabled? (
    <ButtonContainer ismobile={ismobile}>
      <ButtonDisabledDiv ismobile={ismobile} >
        { caption }
      </ButtonDisabledDiv>
    </ButtonContainer>
  ) : (
    <ButtonContainer ismobile={ismobile}>
      { buttonDiv }
      {/* <ButtonDiv onClick={disabled? {} : () => clickcallback(clickcallbackparam)} selected={selected}>
        { caption }
      </ButtonDiv> */}
    </ButtonContainer>
  )
}
