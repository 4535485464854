export default function(request) {
  return {
    pollList(data) {
      const url = '/board/v1/poll'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    pollDetail(params, Idx) {
      const url = '/board/v1/poll/' + Idx

      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params
      }
      return request.$get(url, config)
    },
    pollDetailContent(Idx) {
      const url = '/board/v1/poll/' + Idx + '/detail'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    pollSearchList() {
      const url = '/board/v1/poll/search'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    updateVote(id, data) {
      const url = '/board/v1/poll/' + id + '/vote'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    }

  }
}

