import React from 'react'
import styled from '@emotion/styled'
import { gray, red } from '../Color'

export const IconViewGrid = styled.div({}, ({color=gray[90], iconsize='16px'}) => {
  const baseStyle = {
    backgroundSize: '100%',
    backgroundColor: color,
    mask: 'url(/image/designsystem/view_grid.svg) no-repeat center',
    '-webkit-mask': 'url(/image/designsystem/view_grid.svg) no-repeat center',
    '-webkit-mask-size': 'auto',
    cursor: 'pointer'
  }
  return baseStyle
})

export const IconViewList = styled.div({}, ({color=gray[90], iconsize='16px'} ) => {
  const baseStyle = {
    backgroundSize: '100%',
    backgroundColor: color,
    mask: 'url(/image/designsystem/view_list.svg) no-repeat center',
    '-webkit-mask': 'url(/image/designsystem/view_list.svg) no-repeat center',
    '-webkit-mask-size': iconsize + ' ' + iconsize,
    cursor: 'pointer'
  }
  return baseStyle
})


export const IconRestore = styled.div({
  background: 'url(/image/designsystem/restore.svg) no-repeat',
  backgroundSize: '70%',
  cursor: 'pointer'
})

export const IconFilter = styled.div({
  background: 'url(/image/designsystem/filter.svg) no-repeat',
  backgroundSize: '100%',
  cursor: 'pointer'
})

export const IconEqualizer = styled.div({
  background: 'url(/image/designsystem/equalizer.svg) no-repeat',
  backgroundSize: '100%',
  cursor: 'pointer'
})

export const IconAlarm = styled.div({
  background: 'url(/image/designsystem/alarm.svg) no-repeat',
  backgroundSize: '100%',
  cursor: 'pointer'
})

export const IconAlarmWBadge = styled.div({
  background: 'url(/image/designsystem/alarm_wbadge.svg) no-repeat',
  backgroundSize: '100%',
  cursor: 'pointer'
})
export const IconCart = styled.div({
  background: 'url(/image/designsystem/cart.svg) no-repeat',
  backgroundSize: '100%',
  cursor: 'pointer'
})
export const IconMenu = styled.div({
  background: 'url(/image/designsystem/menu.svg) no-repeat',
  backgroundSize: '100%',
  cursor: 'pointer'
})
export const IconMenuWBadge = styled.div({
  background: 'url(/image/designsystem/menu_wbadge.svg) no-repeat',
  backgroundSize: '100%',
  cursor: 'pointer'
})

export const IconSearch = styled.div({
  background: 'url(/image/designsystem/search.svg) no-repeat',
  backgroundSize: '100%',
  cursor: 'pointer'
})

const IconContainer = styled.div({}, ({ size })=> {
  return {
    width: size+'px', height: size+'px',
    display: 'flex', justifyContent: 'center', alignItems: 'center'
  }
})

export function Icon(props) {
  const { type, color=gray[90], hovercolor='', size=28, ...extraProps } = props

  let IconBody = null
  if (color !== 'original') {
    IconBody = styled.div({}, ({ type, color, hovercolor})=> {
      return {
        width: '100%',
        height: '100%',
        backgroundColor: color,
        mask: 'url(/image/designsystem/' + type + '.svg) no-repeat center',
        '-webkit-mask': 'url(/image/designsystem/' + type + '.svg) no-repeat center',
        '-webkit-mask-size': '100%',
        cursor: 'pointer',
        ...(hovercolor !== '' && {'&:hover': {
          backgroundColor: hovercolor
        }})
      }
    })
  } else {
    IconBody = styled.div({}, ({ type, color, hovercolor})=> {
      return {
        width: '100%',
        height: '100%',
        background: 'url(/image/designsystem/' + type + '.svg) no-repeat center',
        cursor: 'pointer',
        ...(hovercolor !== '' && {'&:hover': {
          backgroundColor: hovercolor
        }})
      }
    })
  }

  return (
    <IconContainer type={type} color={color} size={size} {...extraProps}>
      <IconBody type={type} color={color} size={size} hovercolor={hovercolor}>
      </IconBody>
    </IconContainer>
  )
}



