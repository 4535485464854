import Vue from 'vue'
import { serialize } from '~/utils/common'
import { log } from '~/communicator/utils/logging'

async function checkLogin(context) {
  Vue.prototype.hiddenToast = true
  try {
    const isLoggedIn = context.app.store.getters.loginInfo.loggedInUser
    if (!isLoggedIn) {
      const res = await context.app.$makeStarApi.user.loginUserProfile()

      if (res.resCd === '1000') {
        if (res.resData.loggedInUser) {
          await context.store.dispatch('modules/loginuserinfo/setLoginInfo', res.resData)
          // Vue.prototype.hiddenToast = false
          return true
        }
        return false
      }
    }
    return true
  } catch (e) {
    log('error', e)
  } finally {
    Vue.prototype.hiddenToast = false
  }

  return false
}

function checkRedirect(context) {
  const regex = /\/projects\/.*\/updates\/o\/.*/g
  if (regex.test(context.route.path)) {
    let path = ''
    const split = context.route.path.split('/')

    path += '/' + split[1] + '/'
    path += split[2] + '/'
    path += 'update/detail?'
    path += 'orderNo=' + split[5]

    context.redirect(path)
    return
  }
  if (context.route.name === 'projects-id') {
    if (context.route.path.lastIndexOf('/') === context.route.path.length - 1) {
      context.redirect(context.route.path + 'story' + serialize(context.route.query))
      return
    } else {
      context.redirect(context.route.path + '/story' + serialize(context.route.query))
      return
    }
  }

  if (context.route.path === '/projects') {
    context.redirect(context.route.path + '/list/artist/all' + serialize(context.route.query))
    return
  }

  if (context.route.path === '/noticeList') {
    context.route.meta[0].auth = false
    return
  }
  const regexArtists = /\/artists\/\d+/g
  if (regexArtists.test(context.route.path) && context.route.path.split('/').length < 4) {
    context.redirect(context.route.path + '/all')
    return
  }
}

/**
 * 현재 Url을 기준으로 layout 타입 상태값을 갱신해줍니다.
 * @param context
 */
function checkLayoutTypeStatus(context) {
  const routePath = context.route.path
  const isEmptyLayoutPage = routePath.startsWith('/v1') ||
    routePath.endsWith('/payment2') ||
    routePath.endsWith('/paymentStep3') ||
    routePath.endsWith('/paymentRefundStep2') ||
    routePath.startsWith('/popup/') ||
    routePath.endsWith('/leaveres')
  const isAccountPage = routePath.startsWith('/result') ||
    routePath.startsWith('/signup') ||
    routePath.startsWith('/social') ||
    routePath.startsWith('/login') ||
    routePath.startsWith('/password-retrieval') ||
    routePath.startsWith('/about/')
  const isSimpleLayoutPage = routePath.startsWith('/search') ||
    routePath.endsWith('/payment') ||
    routePath.endsWith('/paymentstep2') ||
    routePath.endsWith('/paymentRefundStep')
  if (isEmptyLayoutPage) {
    context.store.dispatch('modules/layout/setLayoutType', 'empty')
  } else if (isAccountPage || isSimpleLayoutPage) {
    context.store.dispatch('modules/layout/setLayoutType', 'simple')
  } else {
    context.store.dispatch('modules/layout/setLayoutType', 'default')
  }

  const isB2BLayoutPage = routePath.includes('/B2B')
  if (isB2BLayoutPage) {
    context.store.dispatch('modules/layout/setB2B', true)
  } else {
    context.store.dispatch('modules/layout/setB2B', false)
  }
}

export default async function(context) {
  checkRedirect(context)
  checkLayoutTypeStatus(context)

  if (context?.route?.query) {
    if (context.route.query.lang) {
      context.app.i18n.setLocale(context.route.query.lang)
    }
  }

  if (context?.route?.meta?.[0]?.auth) {
    const isSucess = await checkLogin(context)

    if (!isSucess) {
      await context.store.dispatch('modules/loginuserinfo/setLoginInfo', {})
      await context.redirect('/login?redirectUrl=' + context.route.path + '?' + serialize(context.route.query))
    }
  }
}
