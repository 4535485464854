
import { mapGetters } from 'vuex'
import { applyReactInVue } from 'vuereact-combined'
import { isEmpty, isNotEmpty } from '~/utils/common'
import { welcomeToMakestar } from '~/static/welcomeMessage'
import codeConst from '~/utils/codeConst'
import { GNB } from '~/react'

const POPOVER_TYPE = {
  HOME: 'HOME',
  PAYLIST: 'PAYLIST',
  SHOPPING_CART: 'SHOPPING_CART',
  WISHLIST: 'WISHLIST',
  PROFILE: 'PROFILE',
  INQUERY: 'INQUERY',
  LOGOUT: 'LOGOUT'
}

export default {
  name: 'MainHeader',
  directives: {},
  components: {
    RGNB: applyReactInVue(GNB)
  },
  filters: {},
  props: {
    sticky: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      logoRoutePath: '/',
      menuList: [],
      subMenuList: [
        { i18n: 'header.submenu_0', routePath: '/payment/orders/project' },
        { i18n: 'header.submenu_1', routePath: '/shop/cart' },
        { i18n: 'header.submenu_4', routePath: '/projects/wishlist' },
        { i18n: 'header.submenu_3', routePath: '/userqna' }
      ],
      selectableLang: [
        { i18n: 'common.ko', key: 'ko' },
        { i18n: 'common.en', key: 'en' },
        { i18n: 'common.zh', key: 'zh' },
        { i18n: 'common.ja', key: 'ja' },
        { i18n: 'common.es', key: 'es' }
      ],
      popOver: {
        isShow: false,
        list: [
          { title: this.$t('header.submenu_0'), code: POPOVER_TYPE.PAYLIST },
          { title: this.$t('header.submenu_1'), code: POPOVER_TYPE.SHOPPING_CART },
          { title: this.$t('header.submenu_4'), code: POPOVER_TYPE.WISHLIST },
          { title: this.$t('header.submenu_2'), code: POPOVER_TYPE.PROFILE },
          { title: this.$t('header.submenu_3'), code: POPOVER_TYPE.INQUERY },
          { title: this.$t('header.logout'), code: POPOVER_TYPE.LOGOUT }
        ]
      },
      popOverNoti: {
        isShow: false,
        i18n: {
          notice: this.$t('header.notice'),
          allReadStatus: this.$t('header.allReadStatus'),
          more: this.$t('shop.more')
        }
      },
      name: '',
      notiList: [],
      notilistAmount: 5,
      notiPageIndex: 1,
      specialPollURL: '/contents/polls/detail/212'
    }
  },
  computed: {
    ...mapGetters([
      'device',
      'loginInfo',
      'cartAmount',
      'showHamburgerMenu',
      'b2b',
      'isPC'
    ]),
    isMobile() {
      return this.$store.getters.device === 'MOBILE'
    },
    isLogin() {
      return this.loginInfo.loggedInUser
    },
    userRole() {
      return this.loginInfo.role
    }
  },
  mounted() {
  },
  created() {
    // this.isPeaktimeMenu = await getData('peaktime_menu')
    // this.isPeaktimeMenuToLiveVote = await getData('peaktime_menu_livevote')
    // if (this.isPeaktimeMenuToLiveVote) {
    //   this.specialPollURL = '/contents/polls/detail/213'
    // }
    const { SHOPPING_MENU } = codeConst

    // eslint-disable-next-line nuxt/no-globals-in-created
    const isApp = window && window.flutter_inappwebview
    this.menuList = isApp
      ? [
        { i18n: 'header.home', routeName: 'Home', searchKey: '/', routePath: '/', isShowMobileMenu: false },
        { i18n: 'header.mainMenu_0', routeName: 'Project', searchKey: '/projects/list', routePath: '/projects/list/pre_order', isShowMobileMenu: true },
        { i18n: 'header.mainMenu_1', routeName: 'Shop', searchKey: '/shop/list/SHOPPINGLIST', routePath: SHOPPING_MENU[2].URL, isShowMobileMenu: true },
        { i18n: 'header.mainMenu_2', routeName: 'Artist', searchKey: '/artists', routePath: '/artists', isShowMobileMenu: true },
        { i18n: 'header.mainMenu_8', routeName: 'PocaAlbum', searchKey: '/poca', routePath: '/poca', isShowMobileMenu: false }
      ]
      : [
        { i18n: 'header.home', routeName: 'Home', searchKey: '/', routePath: '/', isShowMobileMenu: false },
        { i18n: 'header.mainMenu_0', routeName: 'Project', searchKey: '/projects/list', routePath: '/projects/list/pre_order', isShowMobileMenu: true },
        { i18n: 'header.mainMenu_1', routeName: 'Shop', searchKey: '/shop/list/SHOPPINGLIST', routePath: SHOPPING_MENU[2].URL, isShowMobileMenu: true },
        { i18n: 'header.mainMenu_2', routeName: 'Artist', searchKey: '/artists', routePath: '/artists', isShowMobileMenu: true },
        { i18n: 'header.vote', routeName: 'Vote', searchKey: '/vote', routePath: '/vote', isShowMobileMenu: true },
        { i18n: 'header.mainMenu_8', routeName: 'PocaAlbum', searchKey: '/poca', routePath: '/poca', isShowMobileMenu: false }
      ]

    this.getNotiAndCartTotal()
    this.welcomeToMakestar()
  },
  methods: {
    openBurgerMenu() {
      this.$store.dispatch('modules/layout/setHamburgerMenuViewStatus', true)
    },
    closeBurgerMenu() {
      this.$store.dispatch('modules/layout/setHamburgerMenuViewStatus', false)
    },
    getActiveRoute() {
      const activeRoute = this.menuList.find((menu) => {
        if (menu.routeName === 'Project' || menu.routeName === 'Shop') return this.$route.fullPath.includes(menu.searchKey)
        return this.$route.fullPath.includes(menu.routePath.replace(/\/$/, ''))
      })
      const isB2B = this.$route.fullPath.includes('B2B')
      if (activeRoute) {
        if (activeRoute.routeName !== 'SpecialPoll') {
          return activeRoute
        }
      } else if (isB2B) {
        return this.menuList.find(menu => menu.routeName === 'Shop')
      }
      return undefined
    },
    isEmpty,
    isNotEmpty,
    welcomeToMakestar,
    handleBack() {
      this.popOverNoti.isShow = false
    },
    async pageMoveByRoutePath(routePath) {
      if (routePath) {
        if (routePath.split('/')[3] === 'SHOPPINGLIST' && this.loginInfo.role === 'B') {
          routePath = codeConst.SHOPPING_MENU[1].URL
        }
        await this.routePush({
          path: routePath
        }, () => {
        })
      }
    },
    handleClickOutSidePopOver(evt) {
      this.popOver.isShow = false
      this.popOverNoti.isShow = false
    },
    handleLogout() {
      this.logoutAndClearData()
    },
    handleSelect(code) {
      if (code === POPOVER_TYPE.PAYLIST) {
        this.routePush('/payment/orders/project')
        return
      }

      if (code === POPOVER_TYPE.SHOPPING_CART) {
        this.routePush('/shop/cart')
        return
      }
      if (code === POPOVER_TYPE.WISHLIST) {
        this.routePush('/projects/wishlist')
        return
      }
      if (code === POPOVER_TYPE.PROFILE) {
        this.routePush('/user/profile')
        return
      }

      if (code === POPOVER_TYPE.INQUERY) {
        this.routePush('/userqna')
        return
      }

      if (code === POPOVER_TYPE.LOGOUT) {
        this.handleLogout()
      }
    },
    async getNotiAndCartTotal() {
      if (!this.isLogin) return
      await Promise.allSettled([
        await this.$makeStarApi.user.selectNoti(),
        await this.$makeStarApi.shopping.getShoppingTotal()
      ]).then((allRes) => {
        if (allRes[0] && allRes[0].status && allRes[0].status === 'fulfilled' && allRes[0].value) {
          this.notiList = allRes[0].value.resData.map((notiItem) => {
            return {
              ...notiItem,
              toAfterDateText: `${this.getToAfterDateText({ date: notiItem.regDate })} ${this.$t('header.posted')}`
            }
          })
        }
        if (allRes[1] && allRes[1].status && allRes[1].status === 'fulfilled' && allRes[1].value) {
          if (allRes[1].value.resCd === '1000') {
            this.$store.dispatch('modules/user/setCartAmount', allRes[1].value.resData)
          }
        }
      })
    },
    getToAfterDateText(regDate) {
      return this.$options.filters.toAfterDate(regDate)
    },
    handleOpenNoti() {
      this.popOverNoti.isShow = !this.popOverNoti.isShow

      if (this.popOverNoti.isShow) {
        this.getNotiAndCartTotal()
      }
    },
    async handleClickNotiItem(item) {
      try {
        const res = await this.$makeStarApi.user.updateNoti(item.idx)

        if (res.resCd === '1000') {
          item.checkYn = 'Y'
          this.$forceUpdate()
        }

        this.$nextTick(() => {
          if (isNotEmpty(item.url)) {
            window.location.href = item.url
          }
        })
      } catch {

      }
    },
    checkNotiCount() {
      if (isEmpty(this.notiList)) {
        return false
      }

      for (let i = 0; i < this.notiList.length; i++) {
        const item = this.notiList[i]

        if (item.checkYn === 'N') {
          return true
        }
      }

      return false
    },
    async handleReadAllNoti() {
      try {
        await this.$makeStarApi.user.readAllNoti()

        for (let i = 0; i < this.notiList.length; i++) {
          const item = this.notiList[i]

          item.checkYn = 'Y'
        }

        this.$forceUpdate()
      } catch {

      }
    },
    handleSelectLang(key, changedAt) {
      // console.log(this.loginInfo)
      this.$i18n.setLocale(key)
      const query = this.$route.query
      query.lang = key
      if (changedAt) {
        query.lang_at = changedAt
      }
      const resolve = this.$router.resolve({
        path: this.$route.path,
        query
      })
      window.location.href = resolve.href
    },
    getLocale() {
      if (this.$i18n.locale === 'ko') {
        return this.$t('common.ko')
      } else if (this.$i18n.locale === 'en') {
        return this.$t('common.en')
      } else if (this.$i18n.locale === 'zh') {
        return this.$t('common.zh')
      } else if (this.$i18n.locale === 'ja') {
        return this.$t('common.ja')
      } else if (this.$i18n.locale === 'es') {
        return this.$t('common.es')
      }
    },
    handleGoLogin(e) {
      e.stopPropagation()
      this.routePush('/login?redirectUrl=' + this.$route.path)
    },
    handleGoLoginProfileLogin(e) {
      e.stopPropagation()
      console.log('this.$route.path', '/login?route=profile_login&redirectUrl=' + this.$route.path)
      this.routePush('/login?route=profile_login&redirectUrl=' + this.$route.path)
    },
    handleGoLoginProfileSignup() {
      this.routePush('/login/?route=profile_signup&redirectUrl=' + this.$route.path)
    },
    handleGoSignup() {
      this.routePush('/signup/send-email?redirectUrl=' + this.$route.path)
    },
    onClickMoreNotiList() {
      this.notilistAmount = 10 * this.notiPageIndex
    }
  }
}
