import React from'react'
import styled from '@emotion/styled'
import { red } from '../../Foundation/Color'
import { IconCart } from "../../Foundation/Icon";

export function GNBCartIcon(props) {
  const { cartnumber, islogin, menuclickcallback, ismobile } = props;

  const CartIconContainer = styled.div({}, ({ ismobile }) => {
    const baseStyle = {
      position: "relative",
      width: "28px",
      height: "28px",
    };
    const mobileStyle = {
      width: "20px",
      height: "20px",
    };
    return ismobile ? { ...baseStyle, ...mobileStyle } : baseStyle;
  });

  const CartIconWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
  `;

  const CartNumberWrapper = styled.div({}, ({ ismobile }) => {
    const baseStyle = {
      position: "absolute",
      right: "1px",
      top: "-4px",
      fontSize: "9px",
      color: red[50],
    };
    const mobileStyle = {};
    return ismobile ? { ...baseStyle, ...mobileStyle } : baseStyle;
  });

  return islogin ? (
    <CartIconContainer
      onClick={() => menuclickcallback("/shop/cart")}
      ismobile={ismobile}
    >
      <CartIconWrapper>
        <IconCart style={{ width: "28px", height: "28px" }} />
      </CartIconWrapper>
      <CartNumberWrapper>{cartnumber > 0 && cartnumber }</CartNumberWrapper>
    </CartIconContainer>
  ) : (
    <CartIconContainer
      onClick={() => menuclickcallback("/login?redirectUrl=/shop/cart")}
      ismobile={ismobile}
    >
      <CartIconWrapper>
        <IconCart style={{ width: "28px", height: "28px" }} />
      </CartIconWrapper>
    </CartIconContainer>
  );
}