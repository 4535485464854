import React from 'react'
import styled from '@emotion/styled'
import { gray, white } from '../../Foundation/Color'
import { C2Medium } from '../../Foundation/Typography'

const BadgeTextC2Medium = styled(C2Medium)`
  line-height: 1;
`

const BadgeTextContainer = styled.div({}, ({ ismobile }) => {

  const baseStyle = {
    position: 'relative',
    color: gray[80],
    padding: '0px 4px',

    fontWeight: '700',
    height: '18px',

    width: 'fit-content',
    textAlign: 'center',
    backgroundColor: white,
    border: '1px solid ' + gray[50],
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
  }

  const mobileStyle = {
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function BadgeText(props) {
  const { text='', ismobile } = props
  if (!text) return null
  return (
    <BadgeTextContainer ismobile={ismobile}>
      <BadgeTextC2Medium ismobile={ismobile}>
        { text }
      </BadgeTextC2Medium>
    </BadgeTextContainer>
  )
}
