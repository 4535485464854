import React from 'react'
import styled from '@emotion/styled'
import { CardArtistCircledImage } from './CardArtistCircledImage'
import { CardArtistTitleDescInfo } from './CardArtistTitleDescInfo'


const CardArtistCircledContainer = styled.div({}, ({ ismobile }) => {

  const cardSize = {}

  if (ismobile) {
    cardSize.width = '104px'
    cardSize.height = '157px'
  } else {
    cardSize.width = '176px'
    cardSize.height = '235px'
  }

  const baseStyle = {
    ...cardSize,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px 0',
    cursor: 'pointer',
    alignItems: 'center',
  }
  const mobileStyle = {
  }

  return ismobile? { ...baseStyle, ...mobileStyle }
  : baseStyle
})

export function CardArtist(props) {
  const { title, description, imageurl, clickcallback, clickcallbackparam, ismobile } = props

  return (
    <CardArtistCircledContainer onClick={()=>clickcallback(clickcallbackparam)} ismobile={ismobile}>
      <CardArtistCircledImage imageUrl={imageurl} ismobile={ismobile}>
      </CardArtistCircledImage>
      <CardArtistTitleDescInfo title={title} description={description} ismobile={ismobile}>
      </CardArtistTitleDescInfo>
    </CardArtistCircledContainer>
  )
}

