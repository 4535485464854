import React, { useEffect, useState } from 'react'
import { SelectBox } from './SelectBox'

export function Country({ choiceplz, countrylist, id, name, onchange, value = '' }) {
  const [innerValue, setInnerValue] = useState('')
  const attr = {
    id,
    name,
    onchange: (index) => {
      if (onchange) onchange(countrylist[index].cntryCd)
    },
    list: countrylist,
    value: innerValue,
    valueKey: 'cntryCd',
    displayKey: 'cntryI18n'
  }

  useEffect(() => {
    setInnerValue(value)
  }, [value])
  return (
    <SelectBox {...attr}/>
  )
}
