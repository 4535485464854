import React from 'react'
import styled from '@emotion/styled'
import { gray } from '~/react/Foundation/Color'

const EmptyImage = styled.div`
  width : 100%;
  height: 100%;
  background-color: ${gray[50]};
  position: relative
`;

export function CardEmptyImage() {
  return (
    <EmptyImage />
  )
}
