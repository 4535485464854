
export default function(request) {
  return {
    fetchSearchPestsList(param) {
      const url = '/api/actchart'
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        params: param
      }

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async(resolve, reject) => {
        try {
          const result = await request.get(url, config)
          resolve(result)
        } catch (error) {
          resolve({
            data: []
          })
        }
      })
    }
  }
}

