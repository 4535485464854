import React from 'react'
import styled from '@emotion/styled'
import { red, green, gray } from '../../../Foundation/Color'
import { B1Bold, B2Bold, C1Bold } from '../../../Foundation/Typography'

const CardProjectStatusTextBody = styled.div({}, ({ ismobile, color  }) => {

  const baseStyle = {
    display: 'block',
    textAlign: 'right',
    opacity: '0.8'
  }

  switch (color) {
    case 'green': baseStyle.color = green[50]; break
    case 'red': baseStyle.color = red[50]; break
    case 'gray': baseStyle.color = gray[70]; break
    case 'black': baseStyle.color = gray[80]; break
  }

  const mobileStyle = {
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle

})

/**
 * @typedef CardProjectStatusTextProps
 * @property {boolean} ismobile
 * @property {string} locale
 * @property {string} statusText
 * @property {'green' | 'red' | 'gray' | 'black'} color
 * @property {'large' | 'medium' | 'small'}}size
 */

/**
 *
 * @param {CardProjectStatusTextProps} props
 * @returns
 */

export function CardProjectStatusText(props) {
  const { ismobile, locale, statusText, color, size } = props
  const statusTextComponent = (() => {
    if (ismobile){
      switch (size) {
        case 'small': return <C1Bold ismobile>{statusText}</C1Bold>
        case 'large': return <B1Bold ismobile>{statusText}</B1Bold>
        case 'medium':
        default:
          return <B2Bold ismobile>{statusText}</B2Bold>
      }

    } else {
      switch (size) {
        case 'large': return <B1Bold>{statusText}</B1Bold>
        case 'medium':
        default:
          return <B2Bold>{statusText}</B2Bold>
      }
    }
  })
  return (
    <CardProjectStatusTextBody color={color} ismobile={ismobile} locale={locale}>
      {statusTextComponent()}
    </CardProjectStatusTextBody>
  )
}
