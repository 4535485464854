/** MSA 서버로 직접 요청하는 경우 Base URL 사용 */
const baseUrl = process.env.API_SERVER_URL

export default function(request) {
  return {
    // joinMembershipCheck() {
    //   const url = '/user/v1/email/check'
    //   const config = {
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   }
    //   return request.$post(url, config)
    // },
    joinMembershipEmail(e) {
      console.log(e)
      const params = {
        email: e
      }
      const url = '/user/v1/email/send'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, params, config)
    },
    joinMembershipEmailauth(data) {
      const url = baseUrl + '/user/v1/signup'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    // 비밀번호 찾기
    passwdFind(e) {
      console.log(e)
      const params = {
        email: e
      }
      const url = '/user/v1/passwd/find'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, params, config)
    },
    // 회원가입
    joinMembershipSignUp(data) {
      const url = '/user/v1/signup'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    // 닉네임 중복 확인
    joinMembershipNicknameCheck(data) {
      const url = '/user/v1/name/check'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    // 사용자 로그인 테스트
    normalUserLogin(data) {
      const url = '/user/v2/auth/normalUserLogin'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },

    // 비밀번호 찾기
    joinMembershipFindPassword(data) {
      const url = baseUrl + '/user/v1/passwd/find'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    // 소셜 회원가입
    socialSignup(data) {
      const url = '/user/v1/social/signup'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    // 국가목록
    countriesList() {
      const url = baseUrl + '/user/v1/countries'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    // 로그인 한 유저 프로필
    loginUserProfile() {
      const url = baseUrl + '/user/v1/profile'

      const headers = {
        'Content-Type': 'application/json'
      }

      const config = {
        headers
      }

      return request.$get(url, config)
    },
    // 로그인 한 유저 소셜 프로필
    loginUserSocalProfile(tokenKey) {
      const url = '/user/v1/profile/?token=' + tokenKey

      const headers = {
        'Content-Type': 'application/json'
      }
      console.log('usertokenKey', tokenKey)
      const config = {
        headers
      }
      return request.$get(url, config)
    },
    // 유저 프로필 수정
    updateProfile(data) {
      const url = '/user/v1/profile/update'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    // 유저 비밀번호 변경
    changePasswd(data) {
      const url = '/user/v1/profile/passwd'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$put(url, data, config)
    },
    // 유저 이메일 변경
    changeEmail(data) {
      const url = '/user/v1/profile/email'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$put(url, data, config)
    },
    // 유저 탈퇴
    userLeave(data) {
      const url = '/user/v2/auth/withdrawal-terms'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    // 로그아웃
    userLogout() {
      const url = '/user/v2/auth/logout'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, {}, config)
    },
    selectNoti() {
      const url = baseUrl + '/user/v1/noti'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    updateNoti(notiIdx) {
      const url = baseUrl + '/user/v1/noti/read/' + notiIdx
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    readAllNoti() {
      const url = baseUrl + '/user/v1/noti/read/all'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    // 공지사항 리스트
    selectSiteNoticeList(data) {
      const url = baseUrl + '/user/v1/site/noticelist'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    selectSiteNotice() {
      const url = baseUrl + '/user/v1/site/notice'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    countConvertedEmail(token) {
      const url = '/user/v1/social/check/email/' + token
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    // 사용자 필수 정보 입력
    insertUserInfo(data) {
      const url = '/user/v1/userInfo'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    updateUserOption(data) {
      const url = 'user/v1/userOptionUpdate'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },

    /**
     * 닉네임 중복체크
     * @param data
     * @returns {Promise<any>}
     */
    nicknameDoubleCheck(data) {
      const url = '/user/v1/name/check-nickname'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },

    loginInfoCheck(data) {
      const url = '/user/v2/auth/login-info-check'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    refreshToken() {
      const url = 'user/v2/auth/token-refresh'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, {}, config)
    },
    termsSignup(data) {
      const url = 'user/v2/signup/terms'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },

    /**
     * 토큰으로 메이크스타 로그인
     * @returns {Promise<any>}
     */
    tokenLogin() {
      const url = `user/v2/auth/token-login`
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, {}, config)
    }
  }
}
