import React from 'react'
import styled from '@emotion/styled'
import { B2Medium, C1Medium } from '../../Foundation/Typography'
import { gray } from '../../Foundation/Color'

const CardDescriptionContainer = styled.div({}, () => {
  return {
    minHeight: '16px',
    color: gray[70],
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    overflowY: 'hidden',
  }
})

/**
 * @typedef CardDescriptionProps
 * @property {string} description
 * @property {'large' | 'medium' | 'small'} size 카드 사이즈
 * @property {boolean} ismobile
 */

/**
 * @param {CardDescriptionProps} props
 * @returns {JSX.Element}
 */
export function CardDescription({ description, size, ismobile }) {
  return (
    <CardDescriptionContainer>
      { size === 'large' ?
        <B2Medium ismobile={ismobile}>{description}</B2Medium> :
        <C1Medium>{description}</C1Medium>
      }
    </CardDescriptionContainer>
  )
}
