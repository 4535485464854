import React, { useCallback } from'react'
import { B2Regular, B2Medium, B1Medium, H8Medium } from '../../Foundation/Typography'
import { red } from '~/react/Foundation/Color'
/**
 * @typedef HeaderTitleCountProps
 * @property {'xlarge' | 'larege' | 'medium' | 'small'} size 헤더 크기
 * @property {boolean} ismobile PC / Mobile 구분
 * @property {number} [count] 타이틀 오른쪽에 표시됨
 */

/**
 * @param {HeaderTitleCountProps} props
 */
export function HeaderTitleCount(props) {
  const { size, ismobile, locale, count } = props
  const getCountTextComponent = useCallback(() => {
    switch(size){
      case 'small':
        return (
          <B2Regular ismobile={ismobile}>({count})</B2Regular>
        )
      case 'medium':
        return (
          <B2Medium ismobile={ismobile}>({count})</B2Medium>
        )
      case 'large':
        return (
          <B1Medium ismobile={ismobile}>({count})</B1Medium>
        )
      case 'xlarge':
        return (
          <H8Medium ismobile={ismobile}>({count})</H8Medium>
        )
    }
  }, [count, size])
  return (
    <div style={{color: red[50]}}>
      {getCountTextComponent()}
    </div>
  )
}