import React from 'react'
import styled from '@emotion/styled'

const ProjectCardPriceBody = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    position: 'relative',
    flex : '1',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '23px !important',
    color: '#333333',
    textAlign: 'left',
    padding: '0 0 0 8px'
  }
  const mobileStyle = {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '16px !important'
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function ProjectCardPrice(props) {
  const { price, ismobile } = props
  return (
    <ProjectCardPriceBody ismobile={ismobile}>
      {price}
    </ProjectCardPriceBody>
  )
}
