

import { applyReactInVue } from 'vuereact-combined'
import { MainWebsiteLayout } from '~/react/Common/Layout/MainWebsiteLayout'

const RMainWebsiteLayout = applyReactInVue(MainWebsiteLayout)

export default {
  name: 'ErrorLayout',
  components: {
    RMainWebsiteLayout
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      }
    }
  },
  methods: {
    /**
     * key에 맞게 다국어 변경
     * @param {'ko' | 'en' | 'zh' | 'ja' | 'es'} key
     */
    onChangeLanguage(key) {
      const query = Object.assign({}, this.$route.query)
      query.lang = key

      const { href } = this.$router.resolve({ query })
      window.location.href = href
    }
  }
}
