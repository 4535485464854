export default function(request) {
  return {
    setSession(data) {
      const url = '/system/v1/set/session'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    getSession(data) {
      const url = '/system/v1/get/session'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    }
    // UpdateMaxSize(request_id) {
    //   const url = '/system/v1/update/max/size'
    //   const config = {
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   }
    //   const data = {
    //     request_id
    //   }
    //   return request.$post(url, data, config)
    // }
  }
}
