import dayjs from 'dayjs'

const nameReducer = (state, action) => {
  switch (action.type) {
    case 'entered': {
      return {
        value: action.value,
        isValid: action.value.trim().length > 1
      }
    }
    case 'blurred': {
      return {
        value: state.value,
        isValid: state.value.trim().length > 1
      }
    }
    default:
      return state
  }
}

const nicknameCheckReducer = (state, action) => {
  switch (action.type) {
    case 'entered': {
      return {
        value: action.value,
        isNickCheckAvailable: action.value.trim().length > 2 && action.value.trim().length < 13,
        isValid: false
      }
    }
    case 'blurred': {
      return {
        ...state
      }
    }
    case 'serverChecked': {
      return {
        ...state,
        isValid: action.isValid
      }
    }
    default:
      return state
  }
}

const birthReducer = (state, action) => {
  switch (action.type) {
    case 'updated': {
      const hasNonNumericValue = !/^\d+$/.test(action.value)
      if (hasNonNumericValue) {
        return {
          ...state,
          value: action.value.replace(/\D/g, '')
        }
      }
      const first8Digits = action.value.slice(0, 8)
      const formattedValue = dayjs(first8Digits, 'YYYYMMDD').format('YYYY-MM-DD')
      return {
        ...state,
        value: first8Digits,
        formattedValue,
        isValid: first8Digits.trim().length === 8
      }
    }
    case 'blurred':
      return {
        ...state,
        value: state.formattedValue
      }
    case 'focused':
      if (state.value.includes('-')) {
        return {
          ...state,
          value: dayjs(state.value, 'YYYY-MM-DD').format('YYYYMMDD')
        }
      }
      return state
    default:
      return state
  }
}

export { nameReducer, nicknameCheckReducer, birthReducer }
