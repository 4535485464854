import React from'react'
import styled from '@emotion/styled'
import { Desktop, Mobile } from '../../Foundation/Spacing'

const HeaderWrapper = styled.div({}, ({ismobile}) => {
  const desktopStyle = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '72px',
    boxSizing: 'border-box'
  }
  const mobileStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: ' center',
    width: '100%',
    height: '84px',
    boxSizing: 'border-box'
  }
  return ismobile? mobileStyle : desktopStyle
})

/**
 * @typedef GNBContainerProps
 * @property {React.ReactNode} children
 * @property {boolean} ismobile
 */

/**
 *
 * @param {GNBContainerProps} props
 * @returns
 */
export function GNBContainer(props) {
  const { children, ismobile } = props
  return ismobile ?
    <Mobile fullwidth={true}>
      <HeaderWrapper ismobile={ismobile}>
        { children }
      </HeaderWrapper>
    </Mobile>
    :
    <Desktop>
      <HeaderWrapper ismobile={ismobile}>
        { children }
      </HeaderWrapper>
    </Desktop>

}