import Vue from 'vue'
import actchart from '~/api/actChart'

/**
 * MakeStar Api
 * @param context
 * @param inject
 */
export default (context, inject) => {
  const actChartAxios = context.$axios.create()

  context.$toast = Vue.prototype.$toast

  actChartAxios.setBaseURL(process.env.CHART_API_URL)
  actChartAxios.defaults.timeout = 10000
  // actChartAxios.defaults.withCredentials = true
  // actChartAxios.defaults.origin = true
  /**
   * Request Intercepter
   */
  actChartAxios.onRequest((config) => {
    // Example
    // config.headers.Authorization = 'Bearer Token'

    // CORS 에러 해결
    // config.proxy = false
    // console.log('요청 설정: ', config)
  })
  actChartAxios.onRequestError((e) => {
    // console.log('요청 에러: ', error)
  })

  /**
   * Response Intercepter
   */
  // actChartAxios.onResponse((res) => {
  //
  //   return res
  // })

  // actChartAxios.onResponseError((error) => {
  //   console.log('에러: ', error)
  // })

  // 커스텀 Axios 전역으로 등록
  inject('actchartApi', actchart(actChartAxios))
}
//
// export default function({ $axios, $toast }) {
//   console.log('$axios', $axios)
//   console.log('$toast', Vue.prototype.$toast)
//
//
// }
