import React from 'react'
import styled from '@emotion/styled'

const ShopCardProductNameCtgrContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
  }
  const mobileStyle = {
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ShopCardProductNameBody = styled.div({}, ({ ismobile }) => {
  const baseStyle = {

    position: 'relative',

    // .cut-text
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: '1.3',
    whiteSpace: 'unset',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',

    // fs-15
    fontSize: '15px',
    // color-black
    color: '#2d2d2d',
    // fw-500
    fontWeight: '500'
  }
  const mobileStyle = {
    // fs-13
    fontSize: '13px',
    marginTop: '4px',
    marginBottom: '4px'
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ShopCardProductCtgrBody = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    position: 'relative',
    paddingTop: '3px',

    // .cut-text
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: '1.3',
    whiteSpace: 'unset',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',

    // fs-13
    fontSize: '13px',
    // color-gray
    color: '#9b9b9b',
    // fw-500
    fontWeight: '500',
    // mb-4
    marginBottom: '16px'
  }
  const mobileStyle = {
    paddingTop: '0px',
    // fs-11
    fontSize: '11px',
    // mb-1
    marginBottom: '0px'

  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function ShopCardProductNameCtgr(props) {
  const { pdNm, ctgrNm, ismobile } = props
  
  return (
    <ShopCardProductNameCtgrContainer ismobile={ismobile}>
      <ShopCardProductNameBody ismobile={ismobile}>
        {pdNm}
      </ShopCardProductNameBody>
      <ShopCardProductCtgrBody ismobile={ismobile}>
        {ctgrNm}
      </ShopCardProductCtgrBody>
    </ShopCardProductNameCtgrContainer>
  )
}
