import React from 'react'
import styled from '@emotion/styled'
import { CommonCardImage } from '../commonCard/CommonCardImage'
import { CardTitleInfo } from '../commonCard/CardTitleInfo'

const ArtistInfoCardContainer = styled.div({}, ({ ismobile }) => {
  
  const cardSize = {}

  if (ismobile) {
    cardSize.width = 'auto'
    cardSize.height = 'auto' // image 127 + title 29
  } else {
    cardSize.width = '264px'
    cardSize.height = 'auto'// '631px' // image 454 + title 177
  }
    
  const baseStyle = {
    ...cardSize,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    position: 'relative',
    border: '1px solid #DEDEDE',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF'
  }
  const mobileStyle = {
    border: 'unset',
    borderRadius: 'unset'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ImageWrapper = styled.div({}, ({ ismobile }) => {

  const baseStyle = {
  }
  
  const mobileStyle = {
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const InfoWrapper = styled.div({}, ({ ismobile }) => {

  const baseStyle = {
    width :'264px',
    // height: '137px',
    height: 'auto',
    padding: '20px'
  }
  
  const mobileStyle = {
    width :'100%',
    height: '80px',
    padding: '24px 20px'
  }  
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const BrandName = styled.h3({}, ({ ismobile }) => {
  const baseStyle = {
    width: '224px',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '32px',
    // margin: '15px 0px'
  }
  
  const mobileStyle = {
    fontWeight: '800',
    margin: '0 0 16px 0px'
  }
  
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const Description = styled.p({}, ({ ismobile }) => {
  const baseStyle = {
    width :'224px',
    fontSize: '13px',
    lineHeight: '20px',
    fontWeight: '400',
    marginBottom: '10px',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word'
  }
  
  const mobileStyle = {
    width :'100%',
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '0px'
  }
  
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})


export function ArtistInfoCard(props) {
  const { imageurl, brandname, description, ismobile } = props
  const size = {
    pc: {
      width: '263px',
      height: 'auto',// '177px'
    }, 
    mobile:{
      width: '100%',
      height: 'auto'
    }
  }

  return (
    <ArtistInfoCardContainer ismobile={ismobile}>
      <ImageWrapper>
        <CommonCardImage imageUrl={imageurl} size={size} hoverEffect={'enlarge'} borderRadius={'0'} ismobile={ismobile}>
        </CommonCardImage>
      </ImageWrapper>
      <InfoWrapper ismobile={ismobile}>
        <BrandName ismobile={ismobile}> { brandname }</BrandName>
        {/* <Description ismobile={ismobile}> { description }</Description> */}
      </InfoWrapper>      
    </ArtistInfoCardContainer>
  )
}

