import React from 'react'
import styled from '@emotion/styled'

const StyledContainer = styled.div({}, ({ size, direction, ismobile }) => {
  let padding = '8px 8px 12px';
  if (ismobile) {
    padding = '8px 6px';
  } else {
    if (size === 'large') padding = '8px';
    if (size === 'medium' && direction === 'horizontal') padding = '12px';
  }
  const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width :'100%',
    height: '100%',
    padding,
    gap: '12px',
    boxSizing: 'border-box'
  }

  const mobileStyle = {
    maxHeight : size === 'large' ? '157px' : '120px', // 고정 높이
    gap: size === 'large' ? '8px' : '6px'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

/**
 * @typedef CardProjectInfoContainerProps
 * @property {boolean} ismobile
 * @property {'large' | 'medium' | 'small'} size 카드 사이즈
 * @property {'horizontal' | 'vertical'} direction 카드 방향
 * @property {React.ReactNode} children
 */

/**
 *
 * @param {CardProjectInfoContainerProps} param0
 * @returns
 */

export function CardProjectInfoContainer ({ismobile, size, direction, children}) {
  return (
    <StyledContainer size={size} direction={direction} ismobile={ismobile}>
      {children}
    </StyledContainer>
  )
}