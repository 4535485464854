import React from 'react'
import styled from '@emotion/styled'
import { B2Bold, B2Medium, B2Regular, C1Medium } from '../../../Foundation/Typography'
import { gray } from '../../../Foundation/Color'
import { Icon } from '~/react/Foundation/Icon'

const CardTitleDescContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0',
  }
  const mobileStyle = {
    padding: '8px 6px',
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const CardTitle = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    position: 'relative',
    maxHeight: '36px',
    color: gray[90],
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    overflow: 'hidden',
  }
  const mobileStyle = {
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const CardDescription = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'relative',
    color: gray[80],
    paddingTop: '12px',
    gap: '2px 0',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    overflowY: 'hidden',
  }
  const mobileStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '-webkit-line-clamp': '1',
    paddingTop: '4px',
    gap: '0px 8px'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const IconWithTextWrap = styled.div({
  display: 'flex',
  gap: '2px',
  alignItems: 'center',
  color: gray[70]
}, () => {})

const UpdateAndComment = styled.div({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  paddingTop: '8px'
}, () => {})

/**
 * @typedef CardUpdateInfoProps
 * @property {string} title 제목
 * @property {string} description
 * @property {string} timefromnowtext 업데이트 날짜와 현재의 시간 차이 텍스트
 * @property {number} commentcount 댓글 수 - 12.6 요청사항으로 우선 노출 제외
 */
/**
 *
 * @param {CardUpdateInfoProps} props
 * @returns
 */

export function CardUpdateInfo(props) {
  const { title, description, timefromnowtext, commentcount, ismobile } = props
  return (
    <CardTitleDescContainer ismobile={ismobile}>
      <CardTitle ismobile={ismobile}>
        <B2Medium ismobile={ismobile}>{title} </B2Medium>
      </CardTitle>
      <CardDescription ismobile={ismobile}>
        { ismobile ?
          <React.Fragment>
            <B2Regular ismobile={ismobile}>{description} </B2Regular>
          </React.Fragment> :
          <React.Fragment>
            <B2Medium ismobile={ismobile}>{description} </B2Medium>
            <B2Medium ismobile={ismobile}>{timefromnowtext} </B2Medium>
          </React.Fragment>
        }
      </CardDescription>
      { ismobile &&
        <UpdateAndComment>
          <IconWithTextWrap>
            <Icon type="clock" color={gray[70]} size={14} />
            <C1Medium ismobile={ismobile}>{timefromnowtext} </C1Medium>
          </IconWithTextWrap>
        </UpdateAndComment>
      }
    </CardTitleDescContainer>
  )
}
