import React from 'react'
import styled from '@emotion/styled'
import { H7Heading } from '../../Foundation/Typography'
import { red, gray, black } from '../../Foundation/Color'


const ButtonContainer = styled.div({}, ({ color, type, disabled, ismobile }) => {
  const colorStyle = {}
  if (color === 'red50') {
    colorStyle.backgroundColor = red[50]
  } else if (color === 'black') {
    colorStyle.backgroundColor = black
  } else if (color === 'gray50') {
    colorStyle.backgroundColor = gray[50]
  }
  const widthStyle = {}
  if (type === 'fill-width') {
    widthStyle.width = '100%'
    widthStyle.borderRadius = '0px'
  } else if (type === 'normal') {
    widthStyle.width = '335px'
    widthStyle.borderRadius = '4px'
  }
  const baseStyle = {
    cursor: 'pointer',
    height: '56px'
  }
  if (disabled)
  {
    colorStyle.backgroundColor = '#CCCCCC'
    baseStyle.cursor = 'unset'
  }
  const mobileStyle = {
  }

  return ismobile ? { ...colorStyle, ...baseStyle, ...mobileStyle, ...widthStyle }
  : {...colorStyle, ...baseStyle, ...widthStyle}
})

const ButtonDiv = styled.div({}, ({ color, ismobile }) => {
  const baseStyle = {
    // fontWeight: '500',
    // fontSize: '16px',
    // lineHeight: '16px',
    color: '#fff',
    textAlign: 'center',
    padding: '13px 14px'
  }

  const mobileStyle = {
  }

  return ismobile ? { ...baseStyle, ...mobileStyle }
  : baseStyle
})

export function ButtonFullWidth(props) {
  const { text, color, type, disabled, clickcallback, clickcallbackparam } = props
  const clickfunction = disabled ? ()=>{} : () => clickcallback(...clickcallbackparam)

  return (
  <ButtonContainer color={color} type={type} disabled={disabled} onClick={clickfunction} ismobile={true}>
      <ButtonDiv color={color} ismobile={true} >
        <H7Heading weight={'medium'} ismobile={true}>
          { text }
        </H7Heading>
      </ButtonDiv>
    </ButtonContainer>
  )
}
