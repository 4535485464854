import React from "react";
import styled from "@emotion/styled";
import { gray } from '~/react/Foundation/Color';
import { PopOverContainer } from './PopOverContainer'
import { B1Regular } from "~/react/Foundation/Typography";

const POPOVER_TYPE = {
  PAYLIST: 'PAYLIST',
  SHOPPING_CART: 'SHOPPING_CART',
  WISHLIST: 'WISHLIST',
  PROFILE: 'PROFILE',
  INQUERY: 'INQUERY',
  LOGOUT: 'LOGOUT'
}

const PopOverListItem = styled.div({}, () => {
  const baseStyle = {
    padding: '12px',
    width: '100%',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: gray[20]
    }
  }
  return baseStyle
})

/**
 * @typedef GNBPopOverProps
 * @property {boolean} isshow popover display 여부
 * @property {{code: keyof POPOVER_TYPE, title: string}[]} list 팝업리스트
 * @property {(listCode: keyof POPOVER_TYPE) => void} handleselect 서브메뉴 클릭시 핸들러
 */

/**
 *
 * @param {GNBPopOverProps} props
 * @returns
 */
export function GNBPopOver ({isshow, list, handleselect}) {
  const makePopupMenuList = list.map((item, index) => (
    <PopOverListItem key={`${index}-${item.title}`} onMouseDown={() => handleselect(item.code)}>
      <B1Regular>{item.title}</B1Regular>
    </PopOverListItem>
  ))
  return (
    <PopOverContainer isShow={isshow} width="174px" right="10%">
      {makePopupMenuList}
    </PopOverContainer>
  )
}