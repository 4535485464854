
export const uniqueId = () => '_' + Math.random().toString(36).substr(2, 9)

export const ImageResizeTag = (props) => {
    const { width, height, imageUrl, lazy = true, innerClass, alt } = props
    const tagProps = {}
    tagProps['alt'] = alt
    tagProps['class'] = innerClass
    tagProps['loading'] = 'lazy'
    if (!lazy) {
        delete tagProps['loading']
    }
    if (imageUrl) {
        const changeUrl = new URL(imageUrl)
        const urlParams = {}
        if (imageUrl !== undefined) {
            const extension = imageUrl.match(/\/?(.*)\.(.*)/)[2].toLowerCase()
            if (extension !== 'webp') {
            urlParams['f'] = 'webp'
            }
        }
        // if (width) {
        //     urlParams['w'] = width
        // }
        if (height) {
            urlParams['h'] = height
        }
        urlParams['q'] = 85
        for (const key in urlParams) {
            changeUrl.searchParams.set(key, urlParams[key])
        }
        tagProps['src'] = changeUrl.href
        return tagProps
        // return <img { ...tagProps }/>
    }
}
