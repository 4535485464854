
import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment'
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart'

import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'

import { faBell } from '@fortawesome/free-regular-svg-icons/faBell'
import { faBellSlash } from '@fortawesome/free-regular-svg-icons/faBellSlash'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons/faHeart'

const fasList = [
  faExclamationCircle,
  faComment,
  faTag,
  faChevronUp,
  faChevronDown,
  faTimesCircle,
  faInfoCircle,
  faCheck,
  faHeart
]

const fabList = [
  faGoogle
]

const farList = [
  faBell,
  faBellSlash,
  farHeart
]
// config.autoAddCss = false
library.add(...fasList, ...fabList, ...farList)

// Register the component globally
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
