export default function(request) {
  return {
    /**
     * 인증서버 이메일 로그인
     * @param data
     * @returns {Promise<{ code: string, message: string, result: string, refresh_token: string, access_token: string}>}
     */
    signInWithEmail(data) {
      const url = '/auth/apis/user/registration/signin_with_email'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },

    /**
     * 인증서버 (임시) 회원가입
     * @param {{ email: string, password: string }} data
     * @returns {Promise<{ code: string, message: string, result: string}>}
     */
    signUpWithEmail(data) {
      const url = '/auth/apis/user/registration/signup_with_email'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },

    /**
     * 소셜 로그인 계정 회원가입
     * @param {{ social_type: number, social_id: string, email: string, is_agreed_marketing: boolean }} data
     * @returns {Promise<{ code: string, message: string, result: string, refresh_token: string, access_token: string}>}
     */
    signUpWithSocial(data) {
      const url = '/auth/apis/user/registration/social_login_or_join'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },

    /**
     * 소셜 로그인 계정 인증코드 발송
     * @param {{ social_type: number, social_id: string, email: string, is_agreed_marketing: boolean }} data
     * @returns {Promise<{ code: string, message: string, result: string }>}
     */
    socialJoin(data) {
      const url = '/auth/apis/user/registration/social_join'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    /**
     * 소셜 로그인 계정 인증코드 발송
     * @param {{ social_type: number, social_id: string, email: string, is_agreed_marketing: boolean }} data
     * @returns {Promise<{ code: string, message: string, result: string }>}
     */
    socialVerifyEmail(data) {
      const url = '/auth/apis/user/registration/social_verify_email'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    verifyEmail(data) {
      const url = '/auth/apis/user/registration/verify_email'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$put(url, data, config)
    },
    reSendCertificationMail(data) {
      const url = '/auth/apis/user/registration/send_verification_code'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    }
  }
}
