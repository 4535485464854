import React from 'react'
import styled from '@emotion/styled';
const SectionBottomLine = styled.div({
  width: '100%'
},({ color = '#202020', top = 10 }) => ({
  borderBottom: `1px solid ${color}`,
  marginTop: `${top}px`
}));

export function BottomLine(props) {
  return <SectionBottomLine {...props}></SectionBottomLine>;
}
