import React from 'react'
import styled from '@emotion/styled'
import { ContentTitle } from '../../react'

const InnerContentLayout = styled.div({
  display: 'flex',
  padding: '30px 0 30px 0'
}, ({ ismobile }) => {
  return {
    flexDirection: ismobile ? 'column' : ''
  }
})
const Label = styled.div({
  width: '242px'
})
const Content = styled.div({
  flex: 1
}, ({ ismobile, type }) => {
  return {
    marginLeft: style.Content.marginLeft[style.device(ismobile)],
    marginTop: style.Content.marginTop[style.device(ismobile)]
  }
})

const style = {
  device(ismobile) {
    return ismobile ? 'MOBILE' : 'PC'
  },
  Content: {
    marginLeft: {
      MOBILE: '0',
      PC: '12px'
    },
    marginTop: {
      MOBILE: '24px',
      PC: '0'
    }
  }
}

export function ContentLayout(props) {
  const { item, index, children, ismobile } = props
  const { title, required } = item

  return (
    <InnerContentLayout key={`${title}-${index}`} ismobile={ismobile}>
      <Label>
        <ContentTitle required={required}>{title}</ContentTitle>
      </Label>
      <Content ismobile={ismobile} type={item.type}>
        { children }
      </Content>
    </InnerContentLayout>)
}
