import React, { ReactNode, MouseEvent, CSSProperties, useRef, useState, useCallback } from 'react'
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  max-width: ${(props) => props.maxWidth && props.maxWidth};
`;

/**
 * 마우스로 좌우스크롤할 수 있는 스크롤 컴포넌트입니다.
 * @param {ReactNode} children
 * @param {number} maxWidth 최대 너비
 * @param {CSSProperties} style 그 외 추가적으로 적용하고 싶은 스타일
 * @returns {JSX.Element}
 */
export function DraggableScroller({ children, maxWidth, style }) {
  const containerRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [totalX, setTotalX] = useState(0);

  // addEventListener, removeEventListener 가 동일한 함수 인스턴스를 참조하도록 메모이제이션 추가
  const preventClick = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  /**
   * @param {MouseEvent} e
   */
  const onDragStart = (e) => {
    e.preventDefault()
    setIsDragging(true);
    const x = e.clientX
    setStartX(x)
    if (containerRef.current && 'scrollLeft' in containerRef.current) {
      setTotalX(x + containerRef.current.scrollLeft);
    }
  };

  /**
   * @param {MouseEvent} e
   */
  const onDragEnd = (e) => {
    if (!isDragging) return;

    preventClick(e)
    setIsDragging(false);

    const endX = e.clientX;
    const childNodes = [...(containerRef.current?.childNodes || [])]
    const diff = Math.abs(startX - endX);

    if (diff > 20) {
      childNodes.forEach((child) => {
        child.addEventListener("click", preventClick);
      })
    } else {
      childNodes.forEach((child) => {
        child.removeEventListener("click", preventClick);
      })
    }
  };

  /**
   * @param {MouseEvent} e
   */
  const onDragMove = (e) => {
    if (!isDragging) return;
    preventClick(e)

    const scrollLeft = totalX - e.clientX;
    if (containerRef.current && 'scrollLeft' in containerRef.current) {
      // 스크롤 발생
      containerRef.current.scrollLeft = scrollLeft;
    }
  };

  return (
    <>
      <Container
        maxWidth={maxWidth}
        style={style}
        onMouseDown={onDragStart}
        onMouseMove={onDragMove}
        onMouseUp={onDragEnd}
        onMouseLeave={onDragEnd}
        ref={containerRef}
      >
        {children}
      </Container>
    </>
  );
}
