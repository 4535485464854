import React from 'react'
import styled from '@emotion/styled'
import { red, green, gray, black, white } from '../../Foundation/Color'
import { C2Bold } from '../../Foundation/Typography'

const BadgeColorMap = {
  black: gray[90],
  gray: gray[60],
  green: green[50],
  red: red[40]
}

const BadgeC2Bold = styled(C2Bold)`
  line-height: 1;
`

const BadgeDdayBody = styled.div({}, ({ color, round, ismobile }) => {

  const baseStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: white,
    padding: round ? '4px 6px' : '4px',
    minWidth: '22px',

    height: '18px',
    width: 'fit-content',
    textAlign: 'center',
    boxSizing: 'border-box',
    ... (round && {borderRadius: '18px'}),
    backgroundColor: BadgeColorMap[color] ?? red[40]
  }

  const mobileStyle = {
    height: '16px',
  }

  return ismobile
  ? {
    ...baseStyle,
      ...mobileStyle,
  }
  : baseStyle
})

/**
 * @typedef BadgeDdayProps
 * @property {'black' | 'gray' | 'green' | 'red'} color 뱃지 색상
 * @property {string} projectLabel 뱃지에 들어갈 텍스트
 * @property {boolean} round 라운드 여부
 * @property {boolean} ismobile 모바일 여부
 */

/**
 * @param {BadgeDdayProps} props
 * @returns {JSX.Element}
 */
export function BadgeDday(props) {
  const { color, projectLabel, round, ismobile } = props
  return (
     <BadgeDdayBody
        round={round}
        color={color}
        ismobile={ismobile}
      >
       <BadgeC2Bold>
         { projectLabel }
       </BadgeC2Bold>
     </BadgeDdayBody>
  )
}
