import React from 'react'
import styled from '@emotion/styled'

import { Desktop } from '../../Foundation/Spacing'
import { Mobile } from '../../Foundation/Spacing'
import { LnbHeader } from './LnbHeader'
import { gray } from '../../Foundation/Color'

function LNBContainer(props) {
  const { children, ismobile } = props
  return ismobile ?
      <Mobile> { children } </Mobile>
    : <Desktop> { children } </Desktop>
}

const LayoutContainer = styled.div({}, ({ismobile}) => {
  const baseStyle = {
    margin: '24px 0',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
  const mobileStyle = {
    marginBottom: '16px',
    maxHeight: '128px',
  }
  return ismobile? { ...baseStyle, ...mobileStyle }
  : baseStyle
})

const CategoryContainer = styled.div({}, ({ismobile}) => {
  const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  }
  const mobileStyle = {
    width: '100%',
    gap: '16px',
  }
  return ismobile? { ...baseStyle, ...mobileStyle }
  : baseStyle
})

const Divider = styled.div({
  width: '100%',
  height: '1px',
  backgroundColor: gray[20]
})

/**
 * @typedef LnbProps
 * @property {Category[]} majorcategorylist
 * @property {() => void} majorclickcallback
 * @property {string} majorselectedcategory
 * @property {Category[]} subcategorylist (TODO: PC 제거하면서 같이 제거 예정)
 * @property {() => void} subclickcallback (TODO: PC 제거하면서 같이 제거 예정)
 * @property {string} subselectedcategory (TODO: PC 제거하면서 같이 제거 예정)
 * @property {boolean} ismobile PC, mobile 여부
 */

/**
 *
 * @param {LnbProps} props
 * @returns
 */
export function Lnb(props) {
  const { majorcategorylist=[], majorclickcallback, majorselectedcategory,
          subcategorylist=[], subclickcallback, subselectedcategory,
          ismobile,
          children
        } = props;

  return (
    <React.Fragment>
      <LNBContainer ismobile={ismobile}>
        <LayoutContainer ismobile={ismobile}>
        {ismobile ? (
          <CategoryContainer ismobile={ismobile}>
            <LnbHeader
            ctgrlist={majorcategorylist} clickcallback={majorclickcallback} selectedCateogry={majorselectedcategory}
            ctgrtype={'major'} ismobile={ismobile}/>
            {children}
          </CategoryContainer>
      ) : (
          <React.Fragment>
            <CategoryContainer>
              <LnbHeader
              ctgrlist={majorcategorylist} clickcallback={majorclickcallback} selectedCateogry={majorselectedcategory}
              ctgrtype={'major'} delimiter={false} ismobile={ismobile}/>
              <LnbHeader
                ctgrlist={subcategorylist} clickcallback={subclickcallback} selectedCateogry={subselectedcategory}
                ctgrtype={'sub'} delimiter={true} ismobile={ismobile}/>
            </CategoryContainer>
            {children}
          </React.Fragment>
        )}
        </LayoutContainer>
      </LNBContainer>
      { ismobile && <Divider></Divider>}
    </React.Fragment>
  )
}
