import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import { BadgeDday } from '../../Badge/BadgeDday'
import { CardProjectImage } from './CardProjectImage'
import { CardProjectContainer } from './CardProjectContainer'
import { CardProjectInfoContainer } from './CardProjectInfoContainer'
import { CardProjectPrice } from './CardProjectPrice'
import { CardProjectStatusText } from './CardProjectStatusText'
import { CardProjectTitleAndDesc } from './CardProjectTitleAndDesc'
import { ProgressBar } from '../../ProgressBar/ProgressBar'
import codeConst from '/utils/codeConst'

const CardProjectInfoTop = styled.div({}, () => {
  const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  }
  return baseStyle
})
const CardProjectInfoBottom = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: ismobile ? '2px': '4px'
  }
  return baseStyle
})

const CardProjectStatusWrapper = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
  const mobileStyle = {
    width : '100%'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

/**
 * @typedef CardProjectProps
 * @property {} item 프로젝트 아이템
 * @property {string} title 프로젝트 제목
 * @property {string} description 프로젝트 설명
 * @property {boolean} isshowdescription 설명 표시 여부
 * @property {string} imageurl 이미지 url
 * @property {{timerColor: string, statusText: string, statusColor: string, progressBarValue:number, progressBarColor: string }} cardcontext 카드의 색상. 뱃지, 프로그레스바, 프로그레스바 위 상태 텍스트에 사용된다.
 * @property {string} projectlabel 뱃지에 들어갈 텍스트
 * @property {number} price 가격이 있는 이벤트일 경우
 * @property {string} viewmode
 * @property {(url:string) => void} clickcallback 프로젝트 클릭 후 콜백동작
 * @property {boolean} ismobile PC/Mobile
 * @property {string} locale 국가코드
 * @property {'samll' | 'medium' | 'large'} size 카드 사이즈
 */
/**
 *
 * @param {CardProjectProps} props
 * @returns
 */
import { applyVueInReact } from 'vuereact-combined'
import VRouterLink from '../../../../components/VRouterLink'
const VVRouterLink = applyVueInReact(VRouterLink)
export function CardProject(props) {
  const { url, title='', description='', isshowdescription=true, imageurl, iconnew, projectlabel, cardcontext, price, viewmode = codeConst.VIEW_MODE.GRID, clickcallback, ismobile, locale, size } = props
  const validDescription = ismobile && viewmode === codeConst.VIEW_MODE.GRID ? null : description
  const getCardSize = useCallback(() => {
    if (size) return size;
    if (ismobile && viewmode === codeConst.VIEW_MODE.LIST) return 'large';
    return ismobile ? 'small' : 'medium';
  }, [viewmode, ismobile, size])

  const getCardDirection = useCallback(() => {
    if(ismobile){
      return 'vertical'
    } else {
      switch(viewmode){
        case codeConst.VIEW_MODE.LIST: return 'horizontal';
        case codeConst.VIEW_MODE.GRID:
        default: return 'vertical';
      }
    }
  }, [viewmode, ismobile])

  return (
    <CardProjectContainer size={getCardSize()} direction={getCardDirection()} ismobile={ismobile}>
      <VVRouterLink url={`/projects/${url}/story`} >
        <CardProjectImage imageUrl={imageurl} iconnew={iconnew} size={getCardSize()} direction={getCardDirection()} ismobile={ismobile}>
        </CardProjectImage>
        <CardProjectInfoContainer size={getCardSize()} direction={getCardDirection()} ismobile={ismobile}>
          <CardProjectInfoTop>
            <BadgeDday
              projectLabel={projectlabel}
              color={cardcontext.timerColor}
              ismobile={ismobile}>
            </BadgeDday>
            <CardProjectTitleAndDesc ismobile={ismobile} size={getCardSize()} title={title} validDescription={validDescription} isshowdescription={isshowdescription}/>
          </CardProjectInfoTop>
          <CardProjectInfoBottom ismobile={ismobile}>
            <CardProjectStatusWrapper ismobile={ismobile}>
              {price && <CardProjectPrice price={price} size={getCardSize()} ismobile={ismobile} />}
              <div style={{marginLeft: 'auto'}}>
                <CardProjectStatusText
                  statusText={cardcontext.statusText}
                  color={cardcontext.statusColor}
                  ismobile={ismobile}
                  locale={locale}
                  size={getCardSize()}
                />
              </div>
            </CardProjectStatusWrapper>
            <ProgressBar color={cardcontext.progressBarColor} currentValue={cardcontext.progressBarValue} height={4} ismobile={ismobile}>
            </ProgressBar>
          </CardProjectInfoBottom>
        </CardProjectInfoContainer>
      </VVRouterLink>
    </CardProjectContainer>
  )
}

