import React from "react";
import styled from "@emotion/styled";
import { white } from '../../Foundation/Color';

const Container = styled.div({}, ({ isShow, isMobile, width, height, top, right }) => {
  const pcStyle = {
    position: 'absolute',
    top: top ?? '100%',
    right: right ?? '15%',
    display: isShow ? 'flex' : 'none',
    flexDirection: 'column',
    width: width ?? '368px',
    height: height ?? 'auto',
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.25)',
    backgroundColor: white,
    borderRadius: '4px',
    padding: '8px 0',
    '&:after': {
      position: 'absolute',
      top: '-8px',
      right: '10px',
      content: "''",
      display: 'block',
      borderBottom: `10px solid ${white}`,
      borderRight: `7px solid transparent`,
      borderLeft: `7px solid transparent`,
      zIndex: 1,
    },
  }

  const mobileStyle = {
    position: 'absolute',
    top: 0,
    display: isShow ? 'flex' : 'none',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    backgroundColor: white,
    zIndex: 10
  }
  return isMobile ? mobileStyle : pcStyle
})

/**
 * @typedef PopOverContainerProps
 * @property {boolean} isShow 노출조건
 * @property {boolean} isMobile PC / Mobile 여부
 * @property {string} [width] css에 들어갈 width
 * @property {string} [height] css에 들어갈 height
 * @property {string} [top] css에 들어갈 컨테이너 출력 top 값
 * @property {string} [right] css에 들어갈 컨테이너 출력 right 값
 */
/**
 * @description Popover의 기본 컨테이너
 * @param {PopOverContainerProps} param0
 * @returns
 */

export function PopOverContainer({ isShow, isMobile, width, height, top, right, children, ...extraProps}) {

  return (
    <Container isShow={isShow} isMobile={isMobile} width={width} height={height} top={top} right={right} {...extraProps}>
      {children}
    </Container>
  )
}