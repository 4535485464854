import styled from '@emotion/styled';
import { B1_STYLES, B2_STYLES } from './styles';

/** 
 * Body 1 (B1) 
 * Font-weight: 700
 * Font-size: PC, Mobile: 16px
*/
export const B1Bold = styled.div({}, ({ ismobile }) => {
  return ismobile ? B1_STYLES.mobile.bold : B1_STYLES.pc.bold
});

/** 
 * Body 1 (B1) 
 * Font-weight: 500
 * Font-size: PC, Mobile: 16px
*/
export const B1Medium = styled.div({}, ({ ismobile }) => {
  return ismobile ? B1_STYLES.mobile.medium : B1_STYLES.pc.medium
});

/** 
 * Body 1 (B1) 
 * Font-weight: 400
 * Font-size: PC, Mobile: 16px
*/
export const B1Regular = styled.div({}, ({ ismobile }) => {
  return ismobile ? B1_STYLES.mobile.regular : B1_STYLES.pc.regular
});

/** 
 * Body 2 (B2) 
 * Font-weight: 700
 * Font-size: PC, Mobile: 14px
*/
export const B2Bold = styled.div({}, ({ ismobile }) => {
  return ismobile ? B2_STYLES.mobile.bold : B2_STYLES.pc.bold
});
/** 
 * Body 2 (B2) 
 * Font-weight: 500
 * Font-size: PC, Mobile: 14px
*/
export const B2Medium = styled.div({}, ({ ismobile }) => {
  return ismobile ? B2_STYLES.mobile.medium : B2_STYLES.pc.medium
});
/** 
 * Body 2 (B2) 
 * Font-weight: 400
 * Font-size: PC, Mobile: 14px
*/
export const B2Regular = styled.div({}, ({ ismobile }) => {
  return ismobile ? B2_STYLES.mobile.regular : B2_STYLES.pc.regular
});

/** @deprecated B1Bold, B1Medium, B1Regular 중 하나 사용  */
export const B1Body = styled.div({}, ({weight = 'bold', ismobile = false }) => {
  const baseStyle = {
    all: 'unset',
    fontSize: '16px',
    lineHeight: '22px'
  }
  const weightStyle = weight === 'bold' ? {
    fontWeight: '700'
  } : weight === 'medium' ? {
    fontWeight: '500'
  } : {
    fontWeight: '400'
  }

  const mobileStyle = {
    fontSize: '16px',
    lineHeight: '22px'
  }
  return ismobile ? {
    ...baseStyle, ...weightStyle, ...mobileStyle
  } : { 
    ...baseStyle, ...weightStyle
  }
})

/** @deprecated B2Bold, B2Medium, B2Regular 중 하나 사용  */
export const B2Body = styled.div({}, ({weight = 'regular', ismobile = false }) => {
  const baseStyle = {
    all: 'unset',
    fontSize: '14px',
    lineHeight: '18px'
  }
  const weightStyle = weight === 'bold' ? {
    fontWeight: '700'
  } : weight === 'medium' ? {
    fontWeight: '500'
  } : {
    fontWeight: '400'
  }

  const mobileStyle = {
    fontSize: '14px',
    lineHeight: '18px'
  }
  return ismobile ? {
    ...baseStyle, ...weightStyle, ...mobileStyle
  } : { 
    ...baseStyle, ...weightStyle
  }
})
