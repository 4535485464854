import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  IconSearch,
  IconMenu, Icon
} from '~/react/Foundation/Icon'
import { GNBContainer } from "./GNBContainer";
import { GNBProfile } from "./GNBProfile";
import { GNBMenuList } from "./GNBMenuList";
import { GNBCartIcon } from "./GNBCartIcon";
import { GNBNotiIcon } from './GNBNotiIcon'
import { GNBPopOver } from "./GNBPopOver";
import { GNBNotiPopOver } from "./GNBNotiPopOver";
import { GNBFullSizeMenu } from './GNBFullSizeMenu'
import { GNBFullSizeMenuFooter } from './GNBFullSizeMenuFooter'
import { white } from "~/react/Foundation/Color";
import codeConst from '~/utils/codeConst'

const { WEB_CONTENT_CONTAINER_SIZE, DEFAULT_MOBILE_LAYOUT_SIZE, DEFAULT_MOBILE_B2BLAYOUT_SIZE } = codeConst

const GNBDesktopContainer = styled.div({}, ({}) => {
  return {
    display: "flex",
    alignItems: "center",
    width: '100%',
    height: '100%'
  };
});
const GNBMenuStatusWrapperDesktop = styled.div({}, ({}) => {
  return {
    display: "flex",
    marginLeft: "auto",
    gap: "0 8px",
    alignItems: "center"
  };
});
const GNBMenuWrapperMobile = styled.div({}, ({}) => {
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };
});
const GNBTopWrapperMobile = styled.div({}, ({}) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 15px 0 16px",
    height: "52px",
  };
});

const GNBBottomWrapperMobile = styled.div({}, ({}) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    height: "32px",
  };
});

const MakestarLogo = styled.img({}, ({ ismobile }) => {
  const desktopStyle = {
    display: "inline-block",
    width: "160px",
    height: "24px",
    marginRight: "40px",
    cursor: "pointer",
  };
  const mobileStyle = {
    display: "inline-block",
    width: "120px",
    height: "18px",
    cursor: "pointer",
  };
  return ismobile ? mobileStyle : desktopStyle;
});

const GNBMobileFullSizeMenuContainer = styled.div({}, (props) => {
  const { b2b } = props
  return {
    position: 'fixed',
    top: 0,
    // left: 0,
    // right: 0,
    width: '100%',
    maxWidth: b2b ? DEFAULT_MOBILE_B2BLAYOUT_SIZE : DEFAULT_MOBILE_LAYOUT_SIZE,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: white,
    zIndex: 10
  }
})

/**
 *
 * @param {GNBProps} props
 * @returns
 */

export function GNB(props) {
  const {
    route,
    routeBack,
    menulist,
    activemenu,
    menuclickcallback,
    logininfo,
    alarm,
    cartnumber,
    handlelogin,
    i18n,
    ismobile,
    locale,
    fetchnoti,
    notilist,
    notilistamount,
    onclickmorenotilist,
    handlereadallnoti,
    handleclicknotiitem,
    notii18ntext,
    submenulist,
    popovermenulist,
    handleselectsubmenu,
    selectedlang,
    selectablelanglist,
    handlechangelang,
    handlelogout,
    handlegologinprofilelogin,
    handlegologinprofilesignup,
    isHamburgerMenuOpen,
    openHamburgerMenu,
    closeHamburgerMenu,
    b2b
  } = props;

  const [isLogin, setIsLogin] = useState(false)
  const [isB2BRole, setIsB2BRole] = useState(false)
  const [popOverOpenState, setPopOverOpenState] = useState(false)
  const [popOverNotiOpenState, setPopOverNotiOpenState] = useState(false)

  useEffect(() => {
    setIsLogin(logininfo.loggedInUser)
    setIsB2BRole(logininfo.role === 'B')
  }, [logininfo])

  useEffect(() => {
    if (popOverNotiOpenState) fetchnoti();
  }, [popOverNotiOpenState])

  const openPopOver = useCallback(() => {
    setPopOverOpenState(!popOverOpenState)
  }, [popOverOpenState])

  const openPopOverNoti = useCallback(() => {
    setPopOverNotiOpenState(!popOverNotiOpenState)
  }, [popOverNotiOpenState])

  const handlePopOverOutsideClick = () => {
    setPopOverOpenState(false)
    setPopOverNotiOpenState(false)
  }

  const openMobileFullsizeMenu = useCallback(() => {
    // 스크롤 이벤트로 GNB가 없어지는 것때문에 스크롤 잠금
    document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    openHamburgerMenu()
  }, [])

  const closeMobileFullsizeMenu = useCallback(() => {
    document.getElementsByTagName('body')[0].style.removeProperty('overflow')
    closeHamburgerMenu()
  }, [])

  const mobileMenuClickCallback = useCallback((route) => {
    closeMobileFullsizeMenu();
    menuclickcallback(route)
  }, [menuclickcallback])

  const back = () => {
    routeBack && routeBack()
  }

  return (
    <GNBContainer ismobile={ismobile}>
        {ismobile ? (
          <React.Fragment>
            <GNBTopWrapperMobile>
              <GNBMenuWrapperMobile>
                {route && (route.path !== "/") ? (
                  <Icon type={'navigation-arrow-left'} size={24} onClick={() => back()} />
                ) : (
                  <MakestarLogo ismobile={ismobile} onClick={() => menuclickcallback("/")} src="/image/logo.svg" />
                )}
              </GNBMenuWrapperMobile>
              <div style={{ display: "flex", flexDirection: "row", gap: "0 12px" }}>
                <GNBMenuWrapperMobile>
                  <IconSearch
                    style={{ width: "28px", height: "28px" }}
                    onClick={() => menuclickcallback("/search")}
                  />
                </GNBMenuWrapperMobile>
                <GNBMenuWrapperMobile>
                  <GNBCartIcon
                    cartnumber={cartnumber}
                    islogin={isLogin}
                    menuclickcallback={menuclickcallback}
                    isbmoile={ismobile}
                  ></GNBCartIcon>
                </GNBMenuWrapperMobile>
                <GNBMenuWrapperMobile>
                  <IconMenu
                    style={{ width: "28px", height: "28px" }}
                    onClick={openMobileFullsizeMenu}
                  />
                </GNBMenuWrapperMobile>
              </div>
            </GNBTopWrapperMobile>
            <GNBBottomWrapperMobile>
              <GNBMenuList
                menulist={menulist}
                isb2brole={isB2BRole}
                ismobile={ismobile}
                activemenu={activemenu}
                menuclickcallback={menuclickcallback}
                i18n={i18n}
              />
            </GNBBottomWrapperMobile>
            {
              isHamburgerMenuOpen &&
              <GNBMobileFullSizeMenuContainer b2b={b2b}>
                <GNBFullSizeMenu
                  ismobile={ismobile}
                  alarm={alarm}
                  userinfo={logininfo}
                  handlelogin={handlelogin}
                  menulist={menulist}
                  submenulist={submenulist}
                  i18n={i18n}
                  i18nlogintext={i18n('account.login')}
                  i18nguidemessagetext={i18n('account.guideMessage')}
                  isb2brole={isB2BRole}
                  menuclickcallback={mobileMenuClickCallback}
                  handleMenuClose={closeMobileFullsizeMenu}
                  handleopennoti={openPopOverNoti}
                  handlegologinprofilelogin={handlegologinprofilelogin}
                  handlegologinprofilesignup={handlegologinprofilesignup}
                  />
                  <GNBFullSizeMenuFooter
                    style={{marginTop: 'auto'}}
                    ismobile={ismobile}
                    islogin={isLogin}
                    handlelogout={handlelogout}
                    handleMenuClose={closeMobileFullsizeMenu}
                    i18n={i18n}
                    logouttext={i18n('header.logout')}
                    selectedlang={selectedlang}
                    selectablelanglist={selectablelanglist}
                    handlechangelang={handlechangelang}
                  />
              </GNBMobileFullSizeMenuContainer>
            }
              </React.Fragment>
        ) : (
          <GNBDesktopContainer>
            <MakestarLogo ismobile={ismobile} onClick={() => menuclickcallback("/")} src="/image/logo.svg" />
            <GNBMenuList
              menulist={menulist}
              isb2brole={isB2BRole}
              ismobile={ismobile}
              activemenu={activemenu}
              menuclickcallback={menuclickcallback}
              i18n={i18n}
            />
            <GNBMenuStatusWrapperDesktop>
              <GNBProfile
                islogin={isLogin}
                profileimage={logininfo.profileImageUrl}
                nickname={logininfo.name}
                handleclickprofile={openPopOver}
                handlelogin={handlelogin}
                blurHandler={handlePopOverOutsideClick}
                i18n={i18n}
              />
              { isLogin &&
                <GNBNotiIcon
                  alarm={alarm}
                  onClick={openPopOverNoti}
                  ismobile={ismobile}
                  onBlur={handlePopOverOutsideClick}
                />
              }
              <IconSearch
                style={{ width: "28px", height: "28px" }}
                onClick={() => menuclickcallback("/search")}
              />
              <GNBCartIcon
                cartnumber={cartnumber}
                islogin={isLogin}
                menuclickcallback={menuclickcallback}
                isbmoile={ismobile}
              ></GNBCartIcon>
            </GNBMenuStatusWrapperDesktop>
          </GNBDesktopContainer>
        )}

      <GNBPopOver
        isshow={popOverOpenState}
        list={popovermenulist}
        handleselect={handleselectsubmenu}
      ></GNBPopOver>

      <GNBNotiPopOver
        isshow={popOverNotiOpenState}
        ismobile={ismobile}
        notiheadtext={notii18ntext.notice}
        allreadstatustext={notii18ntext.allReadStatus}
        morenotitext={notii18ntext.more}
        handlereadallnoti={handlereadallnoti}
        handleclicknotiitem={handleclicknotiitem}
        onclickmorenotilist={onclickmorenotilist}
        list={notilist}
        notilistamount={notilistamount}
        handleCloseMobile={handlePopOverOutsideClick}
      ></GNBNotiPopOver>
    </GNBContainer>
  );
}

/**
 * @typedef UserInfo
 * @property {boolean} loggedInUser 로그인 상태
 * @property {string} role 계정 role 타입
 * @property {string} profileImageUrl 프로필 이미지 url
 * @property {string} name 유저 이름
 * @property {string} email 유저 이메일
 */

/**
 * @typedef Menu
 * @property {string} i18n 다국어 키
 * @property {string} routeName 라우팅 이름
 * @property {string} searchKey 라우팅 key
 * @property {string} routePath 라우팅 패스
 * @property {boolean} isShowMobileMenu 모바일메뉴 표시 여부
 */

/**
 * @typedef NotiItem
 * @property {'Y' | 'N'} checkYn 알림의 읽음 / 안읽음 여부
 * @property {number} idx 알림메세지 인덱스번호
 * @property {string} image 알림메세지 썸네일 이미지
 * @property {string} notiType 알림메세지 타입
 * @property {string} regDate
 * @property {string} [toAfterDateText] regDate로부터 몇일전인지 표시하는 텍스트
 * @property {string} text 알림메세지 내용
 * @property {string} title 알림메세지 제목
 * @property {string} url 알림 연결 링크
 * @property {number} userIdx 해당 알림 아이템과 연결된 Useridx
 */

/**
 * @typedef GNBProps
 * @property {object} route VueRouter Object
 * @property {() => void} routeBack
 * @property {Menu[]} menulist
 * @property {Menu | undefined} activemenu
 * @property {(routePath: string) => void} menuclickcallback
 * @property {UserInfo} logininfo 로그인한 유저 정보
 * @property {boolean} alarm 알람 메세지 유무
 * @property {number} cartnumber 장바구니 숫자
 * @property {() => void} handlelogin
 * @property {() => void} handleopenmobilemenu 모바일 햄버거 메뉴 트리거
 * @property {(text: string) => string} i18n 다국어 메서드
 * @property {boolean} ismobile PC / mobile flag
 * @property {string} locale 언어모드
 * @property {() => void} fetchnoti
 * @property {NotiItem[]} notilist 알림 리스트
 * @property {number} notilistamount
 * @property {() => void} onclickmorenotilist
 * @property {() => void} handlereadallnoti
 * @property {(item:NotiItem) => void} handleclicknotiitem
 * @property {{[key: string]: string}} notii18ntext 알람창 내부 타이틀들에 필요한 i18n 텍스트들
 * @property {{title: string, code: string}[]} popovermenulist 프로필 팝오버 서브메뉴
 * @property {{i18n: string, routePath: string}[]} submenulist 모바일 GNB 서브메뉴
 * @property {(code:string) => void} handleselectsubmenu 프로필 팝오버 서브메뉴 클릭콜백
 * @property {string} selectedlang 현재 선택된 언어 코드
 * @property {{i18n: string, key: string}[]} selectablelanglist 선택가능한 언어 리스트
 * @property {(langKey: string) => void} handlechangelang 언어 변경 callback
 * @property {(path: string) => void} handlelogout 로그아웃핸들러
 * @property {() => void} handlegologinprofilelogin mobile fullsize 메뉴일 때, 프로필 버튼 클릭시 로그인
 * @property {() => void} handlegologinprofilesignup mobile fullsize 메뉴일 때, 가이드 텍스트 버튼 클릭시 로그인 또는 회원가입
 */
