import { labelPrice, formatPrice, calculationSalePercent } from './project/index'
import codeConst from './codeConst'
import moment from 'moment'
// import S3 from 'aws-sdk/clients/s3'

export function isEmpty(value) {
  if (value === '' ||
    value === null ||
    value === undefined ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && value.constructor === Object && Object.keys(value).length <= 0)) {
    return true
  } else {
    return false
  }
}

export function isNotEmpty(value) {
  return !isEmpty(value)
}

export function clone(object) {
  return JSON.parse(JSON.stringify(object))
}

export function getKeyArray(Obj) {
  // 오브젝트 안의 오브젝트(+배열), 함수 타입은 무시
  const returnArray = []
  const keys = Object.keys(Obj)

  keys.forEach(function(key) {
    if (typeof (Obj[key]) !== 'object' && typeof (Obj[key]) !== 'function' && Obj[key] !== '') {
      returnArray.push(key)
    }
  })

  return returnArray
}

export function labelCurrency() {
  if (this.$i18n.locale === codeConst.LANGUAGE.KO) {
    return 'KRW'
  } else if (this.$i18n.locale === codeConst.LANGUAGE.EN) {
    return 'USD'
  } else if (this.$i18n.locale === codeConst.LANGUAGE.ZH) {
    return 'CNY'
  } else if (this.$i18n.locale === codeConst.LANGUAGE.JA) {
    return 'JPY'
  } else if (this.$i18n.locale === codeConst.LANGUAGE.ES) {
    return 'EUR'
  }
  return ''
}
// Vue2React
export function labelStatus(self, item, locale) {
  if (isEmpty(item)) {
    return ''
  }

  if (item.status === codeConst.STATUS['30'] || item.status === codeConst.STATUS['40']) {
    return 'COMING SOON'
  }

  if (item.status === codeConst.STATUS['10']) {
    return self.$t('project.projectStatusText')
  }

  if (item.projectType === codeConst.PROJECT_PROJECT_TYPE.FUNDING) {
    if (locale === codeConst.LANGUAGE.ZH) {
      return self.$t('project.groupBuying')
    }
    return ''
  }

  if (isEmpty(item.fundingSubtype)) {
    return self.$t('project.preOrder')
  }

  return codeConst.FUNDING_SUB_TYPE(self)[item.fundingSubtype].NM
}

// Vue2React
export function labelPriceNow(item, locale) {
  if (isEmpty(item)) {
    return ''
  }
  if (item.status === codeConst.STATUS['30'] || item.status === codeConst.STATUS['40']) {
    return ''
  }
  if (item.projectType === codeConst.PROJECT_PROJECT_TYPE.FUNDING) {
    if (locale === codeConst.LANGUAGE.ZH) {
      return ''
    }
    let currencyInfo = item.currencyInfo

    if (isEmpty(item.currencyInfo)) {
      currencyInfo = labelCurrencyByLocale(locale)
    }
    return formatPrice(currencyInfo, labelPrice(item.amount, locale))
  }

  if (isEmpty(item.fundingSubtype)) {
    return ''
  }
  return ''
}
// Vue2React
export function getTimerColor(item) {
  let colorTimer = 'gray'
  if (item.status === codeConst.STATUS['20'] || item.status === codeConst.STATUS['30']) {
    if (item.status === codeConst.STATUS['30']) {
      colorTimer = 'gray'
    } else if (item.projectType === codeConst.PROJECT_PROJECT_TYPE.FUNDING) {
      colorTimer = 'red'
    } else {
      colorTimer = 'green'
    }
  } else if (item.status === codeConst.STATUS['40']) {
    colorTimer = 'black'
  }
  return colorTimer
}

export function labelCurrencyByLocale(locale) {
  if (locale === codeConst.LANGUAGE.KO) {
    return 'KRW'
  } else if (locale === codeConst.LANGUAGE.EN) {
    return 'USD'
  } else if (locale === codeConst.LANGUAGE.ZH) {
    return 'CNY'
  } else if (locale === codeConst.LANGUAGE.JA) {
    return 'JPY'
  } else if (locale === codeConst.LANGUAGE.ES) {
    return 'EUR'
  }
  return ''
}

export function projectCardContext(self, item, locale) {
  const timerColor = getTimerColor(item)
  let statusText = ''
  let statusColor = ''
  let progressBarColor = ''
  let progressBarValue = item.percent
  if (item.status === codeConst.STATUS['30']) {
    statusText = labelStatus(self, item, locale)
    statusColor = 'gray'
    progressBarColor = 'gray'
  } else if (item.status === codeConst.STATUS['40']) {
    statusText = labelStatus(self, item, locale)
    statusColor = 'black'
    progressBarColor = 'black'
  } else if (item.status === codeConst.STATUS['10']) {
    statusText = labelStatus(self, item, locale)
    statusColor = 'gray'
    progressBarColor = 'gray'
    progressBarValue = 100
  } else if (item.projectType === codeConst.PROJECT_PROJECT_TYPE.FUNDING) {
    statusText = Math.ceil(item.percent) + '%'
    statusColor = 'red'
    progressBarColor = 'red'
  } else {
    statusText = labelStatus(self, item, locale)
    statusColor = 'green'
    progressBarColor = 'green'
    progressBarValue = 100
  }

  return {
    timerColor,
    statusText,
    statusColor,
    progressBarColor,
    progressBarValue
  }
}

export function labelCurrency3(paymentType, PAYMENT_TYPE, local) {
  if (isEmpty(paymentType) || isEmpty(PAYMENT_TYPE)) {
    return ''
  }

  if (
    paymentType === PAYMENT_TYPE.LOCAL_CREDIT_CARD.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_MONEY_TRANSFER.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_CELLULAR_PHONE.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_CULTURAL_GIFT.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_KAKAOPAY.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_PAYCO.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_MONEY_DEPOSIT.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_NAVERPAY.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_NAVERPAY_POINT.CD ||
    (paymentType === PAYMENT_TYPE.DIRECT_MONEY_TRANSFER.CD && local === 'ko') ||
    (paymentType === PAYMENT_TYPE.FREE.CD && local === 'ko') ||
    paymentType === PAYMENT_TYPE.LOCAL_TOSS_VIRTUAL_ACCOUNT.CD
  ) {
    return 'KRW'
  } else if (
    paymentType === PAYMENT_TYPE.GLOBAL_ECONTEXT.CD ||
    (paymentType === PAYMENT_TYPE.GLOBAL_CREDIT_CARD.CD && local === 'ja') ||
    (paymentType === PAYMENT_TYPE.DIRECT_MONEY_TRANSFER.CD && local === 'ja') ||
    (paymentType === PAYMENT_TYPE.FREE.CD && local === 'ja')
  ) {
    return 'JPY'
  } else if (
    paymentType === PAYMENT_TYPE.GLOBAL_ALIPAY.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_WECHAT.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_ALIPAY_ICB.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_ALIPAY_ICB_GB.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_WECHAT_ICB.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_MOLPAY.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_PAYPAL.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_CREDIT_CARD.CD ||
    paymentType === PAYMENT_TYPE.DIRECT_MONEY_TRANSFER.CD ||
    paymentType === PAYMENT_TYPE.FREE.CD ||
    paymentType === PAYMENT_TYPE.TOSS_PAYPAL.CD
  ) {
    return 'USD'
  }
}

export function getLocaleByPaymentType(paymentType, PAYMENT_TYPE, local) {
  if (
    paymentType === PAYMENT_TYPE.LOCAL_CREDIT_CARD.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_MONEY_TRANSFER.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_CELLULAR_PHONE.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_CULTURAL_GIFT.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_KAKAOPAY.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_PAYCO.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_MONEY_DEPOSIT.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_NAVERPAY.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_NAVERPAY_POINT.CD ||
    (paymentType === PAYMENT_TYPE.DIRECT_MONEY_TRANSFER.CD && local === 'ko') ||
    (paymentType === PAYMENT_TYPE.FREE.CD && local === 'ko') ||
    paymentType === PAYMENT_TYPE.LOCAL_TOSS_VIRTUAL_ACCOUNT.CD
  ) {
    return 'ko'
  } else if (
    paymentType === PAYMENT_TYPE.GLOBAL_ECONTEXT.CD ||
    (paymentType === PAYMENT_TYPE.GLOBAL_CREDIT_CARD.CD && local === 'ja') ||
    (paymentType === PAYMENT_TYPE.DIRECT_MONEY_TRANSFER.CD && local === 'ja') ||
    (paymentType === PAYMENT_TYPE.FREE.CD && local === 'ja')
  ) {
    return 'ja'
  } else if (
    paymentType === PAYMENT_TYPE.GLOBAL_ALIPAY.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_WECHAT.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_ALIPAY_ICB.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_ALIPAY_ICB_GB.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_WECHAT_ICB.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_MOLPAY.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_PAYPAL.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_CREDIT_CARD.CD ||
    paymentType === PAYMENT_TYPE.DIRECT_MONEY_TRANSFER.CD ||
    paymentType === PAYMENT_TYPE.FREE.CD ||
    paymentType === PAYMENT_TYPE.TOSS_PAYPAL.CD
  ) {
    return 'en'
  }
}

export function labelTelNo(countryList, cntryCd, telNo) {
  if (isEmpty(countryList)) {
    return ''
  }

  const country = countryList.filter((item) => {
    return item.cntryCd === cntryCd
  })[0]

  if (isEmpty(country)) {
    return telNo
  }

  let prefix = country.telNoCd[0]

  if (country.telNoCd.length >= 2) {
    prefix = '+1'
  }

  if (cntryCd === 'AS' ||
    cntryCd === 'VG' ||
    cntryCd === 'AI' ||
    cntryCd === 'BM' ||
    cntryCd === 'KY' ||
    cntryCd === 'MS' ||
    cntryCd === 'TC' ||
    cntryCd === 'GU' ||
    cntryCd === 'MP' ||
    cntryCd === 'VI'

  ) {
    prefix = '+1'
  }

  let resultTelNo = telNo

  const telNoSplit = resultTelNo.split(' ')

  if (telNoSplit && telNoSplit[0] && telNoSplit[0].charAt(0) === '+') {
    resultTelNo = resultTelNo.substring(telNoSplit[0].length, resultTelNo.length)
  }

  return prefix + ' ' + resultTelNo
}

export function qnaTypeFilter(cd) {
  if (cd === '10') {
    return this.$t('qna.qnaType.service')
  } else if (cd === '20') {
    return this.$t('qna.qnaType.project')
  } else if (cd === '30') {
    return this.$t('qna.qnaType.payment')
  } else if (cd === '40') {
    return this.$t('qna.qnaType.delivery')
  } else if (cd === '50') {
    return this.$t('qna.qnaType.deliveryUpdate')
  } else if (cd === '60') {
    return this.$t('qna.qnaType.rewordUpgrade')
  } else if (cd === '70') {
    return this.$t('qna.qnaType.poll')
  } else if (cd === '80') {
    return this.$t('qna.qnaType.groupPurchase')
  } else {
    return ''
  }
}

export function statusTypeFilter(cd) {
  if (cd === '10') {
    return this.$t('qna.statusType.confirm')
  } else if (cd === '20') {
    return this.$t('qna.statusType.mngConfirm')
  } else if (cd === '30') {
    return this.$t('qna.statusType.answerComplete')
  } else if (cd === '50') {
    return this.$t('qna.statusType.refundComplete')
  } else if (cd === '70') {
    return this.$t('qna.statusType.qnaComplete')
  } else {
    return ''
  }
}

export function serialize(obj) {
  const str = '?' + Object.keys(obj).reduce(function(a, k) {
    a.push(k + '=' + encodeURIComponent(obj[k]))
    return a
  }, []).join('&')
  return str
}

export function isContainSpecialChar(value) {
  const pattern = /[~!@#$%^&*()_\-+={\[}\]|\\:;"'<>/]/g
  return pattern.test(value)
}

export function isContainEmoticon(value) {
  const pattern = /[\u{1F300}-\u{1F5FF}\u{1F900}-\u{1F9FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E6}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F004}\u{1F0CF}\u{1F170}-\u{1F171}\u{1F17E}-\u{1F17F}\u{1F18E}\u{3030}\u{2B50}\u{2B55}\u{2934}-\u{2935}\u{2B05}-\u{2B07}\u{2B1B}-\u{2B1C}\u{3297}\u{3299}\u{303D}\u{00A9}\u{00AE}\u{2122}\u{23F3}\u{24C2}\u{23E9}-\u{23EF}\u{25B6}\u{23F8}-\u{23FA}]/gu
  return pattern.test(value)
}

export function getRemainDate(self, endDate) {
  if (self === null) {
    return ''
  }
  const date_m = moment.utc(endDate).local()

  let ms = date_m.diff(moment())
  let d = moment.duration(ms)

  const day = d.asDays()
  const hours = d.asHours()
  const minute = d.asMinutes()

  if (Math.floor(day) > 0) {
    const date_m_day = date_m.startOf('day')
    const cur_m_day = moment().startOf('day')
    ms = date_m_day.diff(cur_m_day)
    d = moment.duration(ms)

    return 'D-' + Math.floor(d.asDays())
  } else if (Math.floor(hours) > 0) {
    return Math.floor(hours) + self.$t('project.hours')
  } else if (Math.floor(minute) > 0) {
    return Math.floor(minute) + self.$t('project.minute')
  } else {
    return 1 + self.$t('project.minute')
  }
}

export function getRemainDateREACT(self, endDate) {
  if (self === null) {
    return ''
  }
  const date_m = moment.utc(endDate).local()

  let ms = date_m.diff(moment())
  let d = moment.duration(ms)

  const day = d.asDays()
  const hours = d.asHours()
  const minute = d.asMinutes()

  if (Math.floor(day) > 0) {
    const date_m_day = date_m.startOf('day')
    const cur_m_day = moment().startOf('day')
    ms = date_m_day.diff(cur_m_day)
    d = moment.duration(ms)

    return 'D-' + Math.floor(d.asDays())
  } else if (Math.floor(hours) > 0) {
    return Math.floor(hours) + '시간'
  } else if (Math.floor(minute) > 0) {
    return Math.floor(minute) + '분'
  } else {
    return 1 + '분'
  }
}

export function getShoppingFormattedPrice(item, projectCardType) { // vut2react
  const formattedPrice = {}
  if (projectCardType === 'SHOPPING') {
    if (item.saleStat === 'S' && item.stkCnt > 0) {
      formattedPrice.salePrice = formatPrice(item.langCrcy, item.salePrice)
      if (item.dcYn === 'Y') {
        formattedPrice.dcRatio = ''
        if (item.dcDiv === 'P') {
          formattedPrice.dcRatio = calculationSalePercent(item.price, item.dcFee)
        } else {
          formattedPrice.dcRatio = Math.floor(item.dcRatio)
        }
        formattedPrice.dcRatio = formattedPrice.dcRatio + '%'
      }
    } else { // 품절된 상품입니다.
      formattedPrice.salePrice = this.$i18n.t('shop.soldOut')
    }
  }
  return formattedPrice
}

export function isValidEmail(value) {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
  return regex.test(value)
}

export function formatCurrency(currency) {
  if (currency === 'JPY') {
    return '円'
  }

  return currency
}

export function getGtmLoginInfo(loginInfo) {
  let joinDate = null
  if (loginInfo.activatedDate && loginInfo.activatedDate.length > 0) {
    const joinDateStr = loginInfo.activatedDate.replace(
      /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
      '$4:$5:$6 $2/$3/$1'
    )

    joinDate = new Date(joinDateStr.replace(/-/g, '/'))
    joinDate.setHours(joinDate.getHours() + 9)
    joinDate.toISOString().replace('T', ' ').replace(/\..*/, '')
  }

  const now = new Date()
  let userAge = null
  if (loginInfo.birthday && loginInfo.birthday.length > 0) {
    userAge = now.getFullYear() - parseInt(loginInfo.birthday.substring(0, 4))
  }

  const info = {
    age: userAge,
    user_id: loginInfo.idx,
    gender: '',
    country: loginInfo.countryNameKo,
    language: loginInfo.locale,
    join_date: joinDate
  }

  if (loginInfo.gender === 'M') {
    info.gender = '남'
  } else if (loginInfo.gender === 'F') {
    info.gender = '여'
  } else {
    info.gender = '기타'
  }

  return info
}

export function dlvyStatFilter(cd) {
  if (cd === 'R') {
    return this.$t('delivery.status_preparing')
  } else if (cd === 'SS') {
    return this.$t('delivery.status_start')
  } else if (cd === 'S') {
    return this.$t('delivery.status_progress')
  } else if (cd === 'E') {
    return this.$t('delivery.status_complete')
  } else if (cd === 'RE') {
    return this.$t('delivery.status_sendBack')
  } else if (cd === 'C') {
    return this.$t('delivery.status_cancel')
  } else if (cd === 'I') {
    return this.$t('delivery.status_error')
  } else {
    return ''
  }
}

/**
 * 뒤로가기 막기
 * 결제 완료 페이지에서 뒤로 가기 실행하는 경우 중복결제요청 가능
 * @param window
 */
export function blockHistoryGoBack(window) {
  window.history.pushState(null, null, window.location.href)
  window.onpopstate = function(event) {
    window.history.go(1)
  }
  window.onpagehide = function(event) {
    if (event.persisted || (window.performance.getEntriesByType('navigation') &&
        window.performance.getEntriesByType('navigation')[0] &&
        window.performance.getEntriesByType('navigation')[0].entryType === 'back_forward')) {
      window.history.go(1)
    }
  }
  window.onpageshow = function(event) {
    if (event.persisted || (window.performance.getEntriesByType('navigation') &&
      window.performance.getEntriesByType('navigation')[0] &&
      window.performance.getEntriesByType('navigation')[0].entryType === 'back_forward')) {
      window.history.go(1)
    }
  }
}

/**
 * 뒤로가기 막기 해제
 * 결제 완료 페이지에서 뒤로 가기 실행하는 경우 중복결제요청 가능
 * @param window
 */
export function blockHistoryGoBackRollback(window) {
  window.onpopstate = null
  window.onpagehide = null
  window.onpageshow = null
}

/**
 * 해당 엘리먼트의 화면 절대좌표( 좌상단 )를 반환
 * @param {HTMLElement} el
 * @return {Object} r {x: number, y: number}
 */
export function getAbsoluteXY(el) {
  const r = { x: 0, y: 0 }
  if (el) {
    r.x = el.offsetLeft
    r.y = el.offsetTop

    if (el.offsetParent) {
      const parentpos = getAbsoluteXY(el.offsetParent)
      r.x += parentpos.x
      r.y += parentpos.y
    }
  }
  r.cx = el.offsetWidth
  r.cy = el.offsetHeight
  return r
}

const KB_MARKER = 1024
const MB_MARKER = Math.pow(KB_MARKER, 2)
const GB_MARKER = Math.pow(KB_MARKER, 3)
const TB_MARKER = Math.pow(KB_MARKER, 4)

/**
 * @param {number} bytes
 * @param {number | undefined} fractionalDigits
 * @returns {string}
 */
export function formatBytes(bytes, fractionalDigits = 0, targetUnit = '') {
  switch (targetUnit) {
    case 'B':
      return `${bytes.toFixed(fractionalDigits)}B`
    case 'KB':
      return `${(bytes / KB_MARKER).toFixed(fractionalDigits)}KB`
    case 'MB':
      return `${(bytes / MB_MARKER).toFixed(fractionalDigits)}MB`
    case 'GB':
      return `${(bytes / GB_MARKER).toFixed(fractionalDigits)}GB`
    case 'TB':
      return `${(bytes / TB_MARKER).toFixed(fractionalDigits)}TB`
    default:
      if (bytes < KB_MARKER) {
        return `${bytes}B`
      } else if (bytes < MB_MARKER) {
        return `${(bytes / KB_MARKER).toFixed(fractionalDigits)}KB`
      } else if (bytes < GB_MARKER) {
        return `${(bytes / MB_MARKER).toFixed(fractionalDigits)}MB`
      } else if (bytes < TB_MARKER) {
        return `${(bytes / GB_MARKER).toFixed(fractionalDigits)}GB`
      } else {
        return `${(bytes / TB_MARKER).toFixed(fractionalDigits)}TB`
      }
  }
}

export function isDeliveryMessageTargetCountry(countryCode) {
  return ['KR', 'JP', 'CN', 'US'].includes(countryCode)
}
