import { getLogStat } from './env'
import dayjs from 'dayjs'

export function defaultLogging(req, onOff) {
  const logStat = getLogStat()
  if (req && onOff) {
    if (['DETAIL', 'SIMPLE'].includes(logStat)) {
      // console.log('Request Time : ', moment().format('YYYY-MM-DD HH:mm:ss'))
      // console.log('Controller Path : ', req.route.path)
      // console.log('Controller Referer : ', req.headers.referer)
      // if (req?.headers['s-original-t']) { console.log('Client Past Session Token: ', req.headers['s-original-t']) }
      // if (req.session.token) { console.log('Client Session Token: ', req.session.token) }
    }
    if (['DETAIL'].includes(logStat)) {
      // if (req.params) { console.log('Controller Params: ', req.params) }
      // if (req.query) { console.log('Controller Query: ', req.query) }
      // if (req.body) { console.log('Controller Body: ', req.body) }
      // if (req.file) { console.log('Controller File: ', req.file) }
    }
  }
}

const Level = {
  ERROR: 'error',
  WARN: 'warn',
  INFO: 'info',
  DEBUG: 'debug'
}
Object.freeze(Level)

const AllowedLevels = {
  dev: [Level.ERROR, Level.WARN, Level.INFO, Level.DEBUG],
  prestage: [Level.ERROR, Level.WARN, Level.INFO],
  stag: [Level.ERROR, Level.WARN],
  prod: [Level.ERROR]
}

const logFormattedMessage = (level, message) => {
  const timestamp = dayjs().toISOString()
  const logFunctions = {
    [Level.ERROR]: console.error,
    [Level.WARN]: console.warn,
    [Level.INFO]: console.info,
    [Level.DEBUG]: console.debug
  }
  const logFunction = logFunctions[level] || console.log
  const levelKey = convertValueToLevelKey(level)
  logFunction(`${timestamp} ${levelKey} ${message} \n-----------------`)
}

const convertValueToLevelKey = (value) => {
  for (const key in Level) {
    if (Level[key] && Level[key] === value) {
      return key
    }
  }
  return null
}

export const log = (level, message) => {
  const env = process.env.NODE_ENV
  if (AllowedLevels[env]?.includes(level)) {
    logFormattedMessage(level, message)
  }
}
