import React from 'react';
import styled from '@emotion/styled';

const ButtonStyle = styled.img`
  width: 170px;
  cursor: pointer;
`;

/**
 * 앱스토어/플레이스토어 이동 버튼
 * @param {'iOS' | 'Android' | 'ChinaAPK' } type
 * @param {string} url
 * @param props
 * @returns {JSX.Element}
 */
export function AppStoreButton({ type = 'iOS', url = '', ...props }) {

  const imageSrc = () => {
    if (type === 'iOS') {
      return '/image/button_appstore.svg'
    }
    if (type === 'Android') {
      return '/image/button_googleplay.svg'
    }
    if (type === 'ChinaAPK') {
      return '/image/button_apkdownload.svg'
    }
  };

  const openLink = () => {
    window.open(url)
  };

  return (
    <>
      <ButtonStyle src={imageSrc()} onClick={openLink} {...props} />
    </>
  )
}
