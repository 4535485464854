import React from 'react'
import styled from '@emotion/styled'

const CardTitleContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
  }
  const style = {
  }
  return style
})

const CardTitle = styled.div({}, ({ ismobile }) => {

  const baseStyle = {
    marginTop: "8px",
    display: 'flex',
    justifyContent: 'center',

    position: 'relative',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px !important',
    color: '#333333 !important',

    textOverflow: 'ellipsis',
    display: '-webkit-box',
    wordWrap: 'break-word',
    overflowY: 'hidden',
  }
  const mobileStyle = {
    fontSize: '13px',
    lineHeight: '17px !important',
    minHeight: '19px',
    maxHheight: '36px'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function CardTitleInfo(props) {
  const { title, ismobile } = props
  return (
    <CardTitleContainer ismobile={ismobile}>
      <CardTitle ismobile={ismobile}> {title}
      </CardTitle>
    </CardTitleContainer>

  )
}
