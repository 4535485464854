import React from 'react'
import { Icon } from '~/react/Foundation/Icon'
import { InputSizeStyle, InputValidStatusColorStyle } from './styles'

const STATUS_ICON_MAP = {
  error: 'caution',
  confirm: 'check',
}
/**
 * @typedef InputPostIconProps
 * @property {'normal' | 'active' | 'complete' | 'error' | 'confirm' } status 상태값
 * @property {'small' | 'medium' | 'large'} size 인풋 사이즈
 */
/**
 *
 * @param {InputPostIconProps} param0
 * @returns
 */
export function InputPostIcon ({ status, size, ... props}) {

  return (
    <Icon type={STATUS_ICON_MAP[status] ?? 'cancel'} size={InputSizeStyle[size].iconsize} color={InputValidStatusColorStyle[status]} {...props}></Icon>
  )
}