import React from 'react'
import styled from '@emotion/styled'

// * 포토카드 컴포넌트 스타일 적용

const B2BAccessOnlyContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    margin: '80px 0 170px',
    width: '600px',
    height: 'auto',
    textAlign: 'center',
    background: '#FFFFFF',
    paddingTop: '40px',
    paddingBottom: '40px',
  }

  const mobileStyle = {
    margin: '36px 0 175px',
    width: '335px',
    paddingTop: '32px',
    paddingBottom: '32px',
  }

  return ismobile ? { ...baseStyle, ...mobileStyle } 
  : baseStyle
})

const B2BAOTitle = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    padding: '0 80px',
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '32px',
    color: '#333333',
    wordBreak: 'break-word'
  }

  const mobileStyle = {
    padding: '0 12px',
    width: '271px',
    margin: '0px 20px',
    width: '295px', // 271px design
    fontSize: '22px',
    lineHeight: '28px',
  }

  return ismobile ? { ...baseStyle, ...mobileStyle } 
  : baseStyle
})
const B2BAODesctiprion = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    margin: '16px 80px 0px 80px',
    width: '440px',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#333333',
    // marginTop: '16px',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap'
  }

  const mobileStyle = {
    margin: '16px 20px 0px 20px',
    width: '295px',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px'
  }

  return ismobile ? { ...baseStyle, ...mobileStyle } 
  : baseStyle
})

const B2BAOButtonPartener = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    cursor: 'pointer',
    borderRadius: '4px',
    border: '1px solid #E52E15',
    background: '#E52E15',
    width: '300px',
    height: '40px',
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '40px',
    textAlign: 'center',
    color: '#FFFFFF',
    wordBreak: 'keep-all',
    whiteSpace: 'pre',
    margin: '40px 150px 0px 150px',
  }

  const mobileStyle = {
    margin: '40px 32px 0px 32px',
    width: '271px',
  }

  return ismobile ? { ...baseStyle, ...mobileStyle } 
  : baseStyle
})

const B2BAOButtonB2BLogin = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    cursor: 'pointer',
    borderRadius: '4px',
    border: '1px solid #CCCCCC',
    background: '#FFFFFF',
    width: '300px',
    height: '40px',
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '40px',
    textAlign: 'center',
    color: '#333333',
    wordBreak: 'keep-all',
    whiteSpace: 'pre',
    margin: '16px 150px 0px 150px',
  }

  const mobileStyle = {
    margin: '16px 32px 0px 32px',
    width: '271px',
  }

  return ismobile ? { ...baseStyle, ...mobileStyle } 
  : baseStyle
})

export function B2BAccessOnly(props) {
  const { textset, clicktopartnership, clicktob2blogin, ismobile } = props
  const { title, description, buttonpartner, buttonb2blogin } = textset

  return (
    <div style={{ display: 'flex', justifyContent: 'center', background: '#F6F6F6' }}>

      <B2BAccessOnlyContainer ismobile={ismobile}>
        <B2BAOTitle ismobile={ismobile}>
          { title }
        </B2BAOTitle>
        <B2BAODesctiprion dangerouslySetInnerHTML={ { __html: description } } ismobile={ismobile}>
        </B2BAODesctiprion>
        <B2BAOButtonPartener onClick={clicktopartnership} ismobile={ismobile}>
          { buttonpartner }
        </B2BAOButtonPartener>
        <B2BAOButtonB2BLogin onClick={clicktob2blogin} ismobile={ismobile}>
          { buttonb2blogin }
      </B2BAOButtonB2BLogin>
      </B2BAccessOnlyContainer>
    </div>
  )
}
