
import React from 'react'
import styled from '@emotion/styled'
import { gray, } from '../../Foundation/Color'
import { Icon } from '../../Foundation/Icon'

const CloseButton = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;
export function CloseIconButton({ width = 24, height = 24, handler }) {
  return (
  <CloseButton width={width} height={height} onClick={() => handler()}>
    <Icon type={'close'} color={gray[90]} size={width}/>
  </CloseButton>

  )
}