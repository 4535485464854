import React from 'react'
import styled from '@emotion/styled'

const CardTitleInfoContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    width: '240px',
    display: 'flex',
    flexDirection: 'column'
  }
  const mobileStyle = {
    width: 'auto',
  }

  return ismobile ? { ...baseStyle, ...mobileStyle } 
  : baseStyle
})

const CardTitlePrimary = styled.div({}, ({ ismobile }) => {

  const baseStyle = {
    
    width: 'auto',
    position: 'relative',
    fontWeight: '400',
    fontSize: '14px',
    textAlign: 'left',
    lineHeight: '20.27px !important',
    color: '#333333 !important',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    wordWrap: 'break-word',
    overflow: 'hidden',
  }
  const mobileStyle = {
    // width: '251px'
    width: 'auto'
  }

  return ismobile ? { ...baseStyle, ...mobileStyle } 
  : baseStyle
})

const CardTitleSecondary = styled.div({}, ({ ismobile }) => {
  const style = {
    width: 'auto',
    position: 'relative',
    fontWeight: '400',
    fontSize: '13px',
    textAlign: 'left',
    lineHeight: '18.82px !important',
    color: '#999999 !important',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    wordWrap: 'break-word',
    overflow: 'hidden'
  }
  return style
})

export function ArtistCardSpreadInfo(props) {
  const { titleprimary, titlesecondary, ismobile } = props
  return (
    <CardTitleInfoContainer ismobile={ismobile}>
      <CardTitlePrimary ismobile={ismobile}> {titleprimary}
      </CardTitlePrimary>
      <CardTitleSecondary ismobile={ismobile}> {titlesecondary}
      </CardTitleSecondary>
    </CardTitleInfoContainer>

  )
}
