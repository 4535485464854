
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { TheMask } from 'vue-the-mask'

export default {
  components: {
    VuePhoneNumberInput,
    TheMask
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    noCountrySelector: {
      type: Boolean,
      default: false
    },
    defaultCountryCode: {
      type: String,
      default: ''
    },
    translations: {
      type: Object,
      default() {
        return {}
      }
    },
    update: {
      type: Function,
      default: () => {}
    },
    style: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      innerValue: ''
    }
  },
  watch: {
    value(n, o) {
      if (n !== o) {
        this.innerValue = n
      }
    },
    innerValue(newValue, oldValue) {
      this.$emit('input', newValue)
    }
  },
  created() {
    this.innerValue = this.value
  },
  mounted() {
  },
  methods: {
    innerUpdateEvent(event) {
      if (this.update !== undefined) {
        this.update(event)
      }
    }
  }
}

