import React from 'react'
import styled from '@emotion/styled'
import { cyan, white } from '../../Foundation/Color'
import { C2Bold } from '../../Foundation/Typography'

const BadgePromotionC2Bold = styled(C2Bold)`
  height: 18px;
  line-height: 18px;
  padding: 0 4px;
  background-color: ${cyan[50]};
  color: ${white};
  border-radius: 2px;
  box-sizing: border-box;
  display: table-cell;
`

export function BadgePromotion() {
  return (
      <BadgePromotionC2Bold>
        MAKESTAR's Choice
      </BadgePromotionC2Bold>
  )
}
