import React from 'react'
import styled from '@emotion/styled'
import { ShopCardImage } from './ShopCardImage'
import { ShopCardRecomBadge } from './ShopCardRecomBadge'
import { ShopCardProductNameCtgr } from './ShopCardProductNameCtgr'
import { ShopCardPrice } from './ShopCardPrice'
import { CardShopBadge } from '~/react/Common/Card/Shop/CardShopBadge'

// card-item relative-content cursor
const ShopCardContainer = styled.div({}, ({ ismobile }) => {
  const mobileStyle = {
    cursor: 'pointer',
    width: '200px',
    minHeight: '316px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // space-between'
  }

  return mobileStyle

})

const ShopCardImageWrapper = styled.div({}, ({ title, description, ismobile }) => {
  const mobileStyle = {
    width: '200px',
    height: '200px'
  }
  return mobileStyle

})

const ShopCardInfoWrapper = styled.div({}, ({ item, ismobile }) => {
  const baseStyle = {
    // .item-info
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'flex-start',
    padding: '8px 5px 5px 8px !important'
  }

  const mobileStyle = {
    // .item-info-mobile
    minHeight: '116px',
    padding: '8px 5px 15px 5px !important'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function ShopCard15xMobile(props) {
  const { imageurl, iconnew, recomyn, pdnm, pdcd, ctgrnm, formattedprice, badgelist, clickcallback, ismobile } = props

  const imageSize = {
    pc:{
      width :'0px',
      height: '0px'
    },
    mobile:{
      width :'200px',
      height: '200px'
    }
  }

  return (
  <ShopCardContainer onClick={() => clickcallback(pdcd) } ismobile={true}>
    <ShopCardImageWrapper>
      <ShopCardImage imageUrl={imageurl} iconNew={iconnew} size={imageSize} ismobile={true}>
      </ShopCardImage>
    </ShopCardImageWrapper>
    <ShopCardInfoWrapper ismobile={true}>
      <ShopCardRecomBadge recomYn={recomyn} ismobile={true}>
      </ShopCardRecomBadge>
      <ShopCardProductNameCtgr pdNm={pdnm} ctgrNm={ctgrnm} ismobile={true}>
      </ShopCardProductNameCtgr>
      <ShopCardPrice
        ismobile={true}
        formattedPrice={formattedprice}>
      </ShopCardPrice>
      <CardShopBadge badgeList={badgelist} />
    </ShopCardInfoWrapper>
  </ShopCardContainer>
  )
}


