import React from 'react'
import styled from '@emotion/styled'


const ShopCardRecomBadgeContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    lineHeight: '18px'
  }
  const mobileStyle = {
    lineHeight: '0px'
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ShopCardRecomBadgeBody = styled.img({}, ({ ismobile }) => {
  const baseStyle = {
    marginBottom: '4px'
  }
  const mobileStyle = {
    marginBottom: '0px'
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function ShopCardRecomBadge(props) {
  const { recomYn, ismobile } = props

  if( recomYn === true ) {
    return (
      <ShopCardRecomBadgeContainer>
        <ShopCardRecomBadgeBody src={"/image/svg-icon/badge_mobile_md.svg"} ismobile={ismobile}>
        </ShopCardRecomBadgeBody>
      </ShopCardRecomBadgeContainer>
    )
  }
  else {
    return (null)
  }  
}
