
import moment from 'moment'

export default {
  name: 'SiteNoticeDialog',
  components: { },
  props: {

    dialog: {
      type: Object,
      require: true
    },
    index: {
      type: Number,
      require: true
    },
    dontShow: {
      type: Boolean,
      require: false,
      default: true
    }

  },
  data() {
    return {
      show: true,
      check: false
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters.device === 'MOBILE'
    }
  },
  mounted() {
    document.documentElement.style.overflow = 'hidden'
    document.body.style.overflow = 'hidden'
  },
  beforeDestroy() {
    document.documentElement.style.overflow = ''
    document.body.style.overflow = ''
  },
  methods: {
    handleCloseDialog(name) {
      const className = name.target.className
      if (className.includes('closeDialog' + this.index)) {
        this.show = false

        setTimeout(() => {
          this.$emit('handleCloseDialog')
        }, 200)
      }
    },
    handleConfirm(cookieId) {
      console.log('cookieId', cookieId)
      if (this.check) {
        window.localStorage.setItem(cookieId, moment().format('YYYY-MM-DD HH:mm:ss'))
      }

      this.show = false
      setTimeout(() => {
        this.$emit('handleCloseDialog')
      }, 200)
    },
    handleSvgClick() {
      this.show = false

      setTimeout(() => {
        this.$emit('handleCloseDialog')
      }, 200)
    }
  }

}
