import React from 'react';
import { CardArtist } from '~/react';
import { DraggableScroller } from '~/react/Common/Scroll/TouchDraggableScroller'

const style = {
  gap: '4px'
}
export function ArtistListHorizontalScroll(props) {
  const { list = [], goToArtistDetail, locale } = props

  const renderCard = (item) => {
    return (
      <CardArtist
        class="cursor"
        key={item.brandIdx}
        title={locale === 'ko' ? item.brandKoreanName : item.brandEnglishName}
        description={locale === 'ko' ? item.brandKoreanName : item.brandEnglishName}
        imageurl={item.brandImage}
        clickcallback={goToArtistDetail}
        clickcallbackparam={item.brandIdx}
        ismobile={true}
      />
    )
  }

  return (
    <>
      <DraggableScroller style={style}>
        {list.map(item => (
          renderCard(item)
        ))}
      </DraggableScroller>
    </>
  )
}
