import React from 'react'
import styled from '@emotion/styled'
import { B1Medium, B2Medium } from '../../Foundation/Typography'
import { gray } from '../../Foundation/Color'

const CardTitleContainer = styled.div({}, ({ size }) => {
  const numberofline = size === 'small' ? '3' : '2'
  return {
    color: gray[90],
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': numberofline,
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    overflowY: 'hidden',
  }
})

/**
 * @typedef CardTitleProps
 * @property {string} title
 * @property {boolean} ismobile
 * @property {'large' | 'medium' | 'small'} size 카드 사이즈
 */

/**

 * @param {CardTitleProps} props
 * @returns {JSX.Element}
 */
export function CardTitle({ title , ismobile = true, size }) {
  return (
    <CardTitleContainer size={size}>
      { size === 'large' ? <B1Medium ismobile={ismobile}>{title}</B1Medium> : <B2Medium ismobile={ismobile}>{title}</B2Medium>}
    </CardTitleContainer>
  )
}
