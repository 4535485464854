import React from 'react'
import styled from '@emotion/styled';
// import Image from 'next/image';

const MainLayout = styled.div({
  display: 'flex',
  marginTop: '30px',
});
const ContentLayout = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 6,
}, (props) => {
  const style = {
    marginLeft: '50px'
  }
  return props['ismobile'] ? {} : style
});
const Thumbnail = styled.div({
  position: 'relative',
  flex: 4,
});
const TwoRow = styled.div({
  display: 'flex',
});

export function ProjectInfoLayout(props) {
  const {
    thunmbnail = 'https://cfw-makestar-staging.s3.ap-northeast-2.amazonaws.com/file/project/IMG_1694.jpg?w=250',
    projectTitle,
    projectProductTitle,
    quantity,
    amount,
    ismobile
  } = props;

  return (
    <MainLayout>
      {
        ismobile ? '' : <Thumbnail> <img style={{ maxWidth: '250px' }} src={thunmbnail} ></img> </Thumbnail>
      }
      <ContentLayout ismobile={ismobile}>
        {projectTitle}
        {projectProductTitle}
        <TwoRow>
          {quantity}
          {amount}
        </TwoRow>
      </ContentLayout>
    </MainLayout>
  );
}
