import React from'react'
import styled from '@emotion/styled'
import { B2Regular, B1Regular } from '~/react/Foundation/Typography'
import { gray } from '~/react/Foundation/Color'

const DescriptionWrapper = styled.div({}, ({ textalign }) => {
  return {
    paddingTop : '2px',
    display: 'flex',
    color: gray[70],
    wordBreak: 'keep-all',
    justifyContent: textalign === 'center' ? 'center' : 'flex-start',
    textAlign: textalign === 'center' ? 'center' : 'left'
  }
})

/**
 * @typedef HeaderDescriptionProps
 * @property {string} children 내부에 들어갈 텍스트
 * @property {'larege' | 'medium' | 'small'} size 헤더 크기
 * @property {boolean} ismobile PC / Mobile 구분
 * @property {'left' | 'center'} [textalign] 타이틀과 디스크립션 정렬 기준. 기본 left
 */

/**
 *
 * @param {HeaderDescriptionProps} props
 * @returns
 */

export function HeaderDescription(props) {
  const { size, ismobile, children, locale, textalign } = props
  const getTypo = () => {
    if (ismobile) {
      if (size === 'large') {
        return (
          <B1Regular ismobile={ismobile}>{children}</B1Regular>
        )
      } else {
        return (
          <B2Regular ismobile={ismobile}>{children}</B2Regular>
        )
      }
    } else {
      if (size === 'small') {
        return (
          <B2Regular>{children}</B2Regular>
        )
      } else {
        return (
          <B1Regular>{children}</B1Regular>
        )
      }
    }
  }

  return (
    <DescriptionWrapper textalign={textalign}>
      {getTypo()}
    </DescriptionWrapper>
  )
}