import React from 'react';
import { CardShop } from '~/react'
import i18nContext from '../i18nContext'
import { DraggableScroller } from '~/react/Common/Scroll/TouchDraggableScroller'

const style = {
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '16px ',
  overflowY: 'hidden',
  scrollBarWidth: 'none',
  whiteSpace: 'wrap',
  overflow: 'auto',
}

export function ShopListHorizontalScroll(props) {
  const {
    list=[],
    gotoshopdetail,
    size,
    $i18n,
  } = props

  const renderCard = (item) => {
    return (
      <div style={{minWidth: '224px'}}>
        <CardShop
          key={item.pdCd}
          imageurl={item.fileUrl}
          iconnew={item.iconNew === 'new'}
          recomyn={item.recomYn === 'Y'}
          pdnm={item.pdNm}
          pdcd={item.pdCd}
          ctgrnm={item.ctgrNm}
          dcRatio={item.dcRatio}
          price={item.price}
          salePrice={item.salePrice}
          dcFee={item.dcFee}
          currency={item.langCrcy}
          dcDiv={item.dcDiv}
          isDiscount={item.dcYn === 'Y'}
          badgelist={item.badgeList}
          clickcallback={gotoshopdetail}
          size={size}
          ismobile={true}
          saleStatus={item.saleStat}
        />
      </div>
    )
  }

  return (
    <>
      <i18nContext.Provider value={$i18n}>
        <DraggableScroller style={style}>
          {list.map(item => (
            renderCard(item)
          ))}
        </DraggableScroller>
      </i18nContext.Provider>
    </>
  )
}
