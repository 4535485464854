import styled from '@emotion/styled'
import { gray } from '~/react/Foundation/Color'

export const MakestarLogo = styled.div({}, ({ismobile}) => {
  const baseStyle = {
    backgroundColor: gray[70],
    mask: 'url(/image/MAKESTAR_logo.svg) no-repeat center',
    '-webkit-mask': 'url(/image/MAKESTAR_logo.svg) no-repeat center',
    '-webkit-mask-size': '160px',
    cursor: 'pointer',

    height: '24px',
    width: '160px',
    objectFit: 'contain'
  }
  const mobileStyle = {
    '-webkit-mask-size': '125px',
    width: '125px',
    height: '18px'
  }
  return ismobile ? { ...baseStyle, ...mobileStyle } : baseStyle
})
