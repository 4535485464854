import React from'react'
import styled from '@emotion/styled'

const CategoryHeaderBMContainer = styled.div({}, ({}) => {
  const mobileStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    height: '32px'
  }
  return mobileStyle
})

const CategoryHeaderBMWrapper = styled.div({}, ({}) => {
  const mobileStyle = {
    flex: '1 1 auto',
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '32px',
    padding: '6px auto'
  }
  return mobileStyle  
})

export function CategoryHeaderButtonMobile(props) {
  const { ctgrlist, underctgr, clickcallback } = props
  if (ctgrlist.length < 1) {
    return null
  } else {
    const categoryList = ctgrlist.map(ctgr=>
      ctgr.code === underctgr ? 
        <CategoryHeaderBMWrapper onClick={()=>clickcallback(ctgr.code)} style={{color: '#E8351D', backgroundColor: '#F2F2F2'}}>
          {ctgr.displayName}
        </CategoryHeaderBMWrapper>
      :
      <CategoryHeaderBMWrapper onClick={()=>clickcallback(ctgr.code)}>
        {ctgr.displayName}
      </CategoryHeaderBMWrapper>
      )

    return (
      <CategoryHeaderBMContainer>
        {categoryList}
      </CategoryHeaderBMContainer>
    )
  }
}