import React, { useCallback } from 'react'
import { ButtonSizeStyle, ButtonTypeColor, ButtonDisabledTypeColor, TextButtonColor } from './styles'
import { ButtonCaption } from './ButtonCaption'
import { ButtonContainer } from './ButtonContainer'
import { Icon } from '~/react/Foundation/Icon'
import { gray, white } from '~/react/Foundation/Color'


/**
 * @typedef ButtonProps
 * @property {'filled' | 'outlined' | 'text'} typeName 버튼 타입.
 * @property {'large' | 'medium' | 'small'} size 버튼 사이즈
 * @property {'white' | 'black' | 'red'} color  버튼 컬러
 * @property {string} [preIconType] 버튼 캡션 앞 아이콘 타입. 없을 경우 표시하지 않음
 * @property {string} [postIconType] 버튼 캡션 앞 아이콘 타입. 없을 경우 표시하지 않음
 * @property {string} caption 버튼 안에 들어가는 텍스트
 * @property {boolean} [isDisabled] 활성화 여부
 * @property {string} [width]  width 조정이 필요할 경우 사용. 기본 fit-content
 * @property {{ isCustomPadding?: boolean, paddingLeft?: string, paddingRight?: string }} [options] 좌우 시각적 패딩이 필요할 경우 사용할 옵션
 */
/**
 *
 * @param {ButtonProps} props
 * @returns
 */
export function Button ({ typeName, size, color, preIconType, postIconType, caption, isDisabled = false, width, options, ...props }){
  const getColorPerType = useCallback(() => {
    if (typeName === 'outlined') {
      const styles = isDisabled ? ButtonDisabledTypeColor : ButtonTypeColor
      return {
        borderColor: styles[typeName][color]?.borderColor ?? gray[50],
        color: styles[typeName][color]?.textColor ?? gray[90],
        backgroundColor: white
      }
    }
    if (typeName === 'filled') {
      const styles = isDisabled ? ButtonDisabledTypeColor : ButtonTypeColor
      return {
        borderColor: 'none',
        color: styles[typeName][color]?.textColor ?? white,
        backgroundColor: styles[typeName][color]?.backgroundColor ?? gray[90]
      }
    } else {
      // text타입이거나 타입이름이 없을 때 디폴트 스타일
      const textButtonStyle = {
        borderColor: 'none',
        color: TextButtonColor.default.textColor,
        backgroundColor: white
      }
      const disabledTextButtonStyle = {
        borderColor: 'none',
        color: TextButtonColor.disabled.textColor,
        backgroundColor: TextButtonColor.disabled.backgroundColor
      }
      return isDisabled ? disabledTextButtonStyle : textButtonStyle
    }
  }, [typeName, color, isDisabled])

  return (
    <ButtonContainer type="button" typeName={typeName} size={size} options={options} colorStyle={getColorPerType()} width={width} isDisabled={isDisabled} disabled={isDisabled} {...props}>
      { preIconType && preIconType.length > 0 && <Icon type={preIconType} size={ButtonSizeStyle[size].iconsize} color={getColorPerType().color}></Icon> }
      {caption.length > 0 && <ButtonCaption size={size} type={typeName} isDisabled={isDisabled}>{caption}</ButtonCaption>}
      { postIconType && postIconType.length > 0 && <Icon type={postIconType} size={ButtonSizeStyle[size].iconsize} color={getColorPerType().color}></Icon> }
    </ButtonContainer>
  )
}
