// 데이터 랩핑이 절대적으로 필요로함.
import React from 'react'
import styled from '@emotion/styled'
import { Logo } from './logo'
const Flex = styled.div({
  display: 'flex'
})
const ColumnLayout = styled(Flex)({
  flexDirection: 'column',
  justifyContent: 'center',
  border: '1px solid #f6f6f6'
})
const CenterFlex = styled(Flex)({
  alignItems: 'center',
  justifyContent: 'center'
})
const CenterFlexMargin = styled(CenterFlex)({
},
({ marginTop, marginLeft, marginRight, marginBottom }) => ({
  marginTop: marginTop ? `${marginTop}px` : 0,
  marginLeft: marginLeft ? `${marginLeft}px` : 0,
  marginRight: marginRight ? `${marginRight}px` : 0,
  marginBottom: marginBottom ? `${marginBottom}px` : 0
}))

const ColumnFlex = styled(Flex)({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '80px',
  height: '98.5px',
  display: 'flex',
  position: 'relative'
},
({ active }) => ({
  opacity: active ? '1' : '50%'
}))
const BorderFlex = styled(ColumnFlex)({
  top: '10px',
  borderTop: '1px solid #acacac'
})
const Title = styled.div({
  display: 'flex',
  width: '100%',
  height: '38.5px',
  alignItems: 'baseline',
  justifyContent: 'center',
  fontSize: '15px',
  fontWeight: 700,
  color: '#232323'
})

const ImageDiv = styled.img({
  position: 'absolute',
  bottom: 0
})
const PageTitleBox = styled(CenterFlex)({
  width: '100%',
  position: 'relative',
  fontSize: '29px',
  fontWeight: 700,
  '&:before': {
    width: '100%',
    height: '1px',
    position: 'absolute',
    top: '50%',
    background: '#9d9d9d',
    display: 'block',
    content: '""',
    clear: 'both',
    left: '0'
  }
})
const PageTitle = styled.span({
  background: '#f6f6f6',
  padding: '0 20px 0 20px',
  position: 'relative',
  display: 'inline-block'
})

export function ProjectHeader({ title = '결제', items = [], description, ismobile }) {
  const displayItems = ismobile ? [items.find(i => i.active === true)] : items
  return (
    <ColumnLayout>
      {ismobile
        ? []
        : [
          <CenterFlex>
            <Logo></Logo>
          </CenterFlex>,
          <CenterFlexMargin marginTop={20}>
            <PageTitleBox><PageTitle>{title}</PageTitle></PageTitleBox>
          </CenterFlexMargin>
        ]}
      <CenterFlexMargin marginTop={40}>
        {displayItems.map((i, index) => (
          <CenterFlex>
            <ColumnFlex active={i.active}>
              <Title>{i.title}</Title><ImageDiv src={i.imageUrl} alt={i.title} />
            </ColumnFlex>
            {displayItems.length > 1 && index < displayItems.length - 1 ? <BorderFlex></BorderFlex> : ''}
          </CenterFlex>
        ))}
      </CenterFlexMargin>
      <CenterFlex>
        <div className='fs-14 color-black9' style={{ marginTop: '40px' }}>
          { description }
        </div>
      </CenterFlex>
    </ColumnLayout>
  )
}
