import React from 'react'
import styled from '@emotion/styled'

const ProjectCardTimerContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    marginLeft : '8px',
    marginTop : '2px'
  }
  const mobileStyle = {
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ProjectCardTimerBody = styled.div({}, ({ color, ismobile }) => {

  const baseStyle = {
    position: 'relative',
    display: 'block',
    fontSize: '11px',
    fontWeight: '700',
    lineHeight: '12px',
    color: '#FFFFFF',
    padding: '5px 4px',
    minWidth: '22px',
  
    width: 'fit-content',
    textAlign: 'center',
    marginTop: '8px',
    marginBottom: '8px'
  }

  const mobileStyle = {
    fontSize: '10px',
    lineHeight: '8px !important',
    marginBottom: '4px'
  }


  switch(color) {
    case 'black': baseStyle.backgroundColor = '#444444 !important'; break
    case 'gray': baseStyle.backgroundColor = '#999999 !important'; break
    case 'blue': baseStyle.backgroundColor = '#00A49D !important'; break
    case 'red': baseStyle.backgroundColor = '#E8351D !important'; break
    default : baseStyle.backgroundColor = '#999999 !important';
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
    
})

export function ProjectCardTimer(props) {
  // const { title, required, index, children, essential, ismobile } = props
  const { color, projectLabel, ismobile } = props
  return (
    <ProjectCardTimerContainer ismobile={ismobile}>
     <ProjectCardTimerBody 
      color={color}
      ismobile={ismobile}
      >
      { projectLabel }
     </ProjectCardTimerBody>
    </ProjectCardTimerContainer>
  )
}
