import moment from 'moment'

import 'moment/locale/en-ca'
import 'moment/locale/ko'
import 'moment/locale/es'
import 'moment/locale/ja'
import 'moment/locale/zh-cn'

moment.locale('en-ca')

export default (ctx, inject) => {
  ctx.$moment = moment
  inject('moment', moment)
}
