import React from 'react'
import styled from '@emotion/styled'
import { Checkbox } from './Checkbox'
import { B1Regular, B2Regular, C1Regular } from '~/react/Foundation/Typography'
import { gray } from '~/react/Foundation/Color'

const TextWrap = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`
const LabelWrap = styled.div({}, ({ isDisabled, labelColor }) => {
  return {
    color: isDisabled ? gray[50] : labelColor
  }
})

const DescriptionWrap = styled.div({}, ({ isDisabled, descriptionColor }) => {
  return {
    color: isDisabled ? gray[40] : descriptionColor
  }
})


/**
 * @typedef CheckboxWLabelProps
 * @property {'small' | 'medium'} [size] 사이즈
 * @property {string} label 체크박스 라벨
 * @property {string} value 체크박스가 가질 value 값
 * @property {string} [labelColor] 체크박스 라벨 컬러
 * @property {string} [description] 라벨 옆 부가 설명
 * @property {string} [descriptionColor] 체크박스 라벨 컬러
 * @property {boolean} [isChecked] 체크상태
 * @property {(value:string) => void} [onChange] change 이벤트
 * @property {boolean} [isDisabled] 활성화 상태
 */
/**
 * @param {CheckboxWLabelProps} param0
 * @returns
 */
export function CheckboxWLabel ({ size = 'small', value, label, labelColor=gray[90], descriptionColor=gray[70], description='', isChecked, isDisabled, onChange=()=>{} }){
  const getLabelTypo = () => {
    return size === 'small' ? <B2Regular>{label}</B2Regular> : <B1Regular>{label}</B1Regular>
  }
  const getDescriptionTypo = () => {
    return size === 'small' ? <C1Regular>{description}</C1Regular> : <B2Regular>{description}</B2Regular>
  }
  return (
    <Checkbox size={size} isChecked={isChecked} isDisabled={isDisabled} value={value} onChange={onChange}>
      <TextWrap>
        <LabelWrap isDisabled={isDisabled} labelColor={labelColor}>
          {getLabelTypo()}
        </LabelWrap>
        {description.length > 0 &&
          (<DescriptionWrap isDisabled={isDisabled} descriptionColor={descriptionColor}>
            {getDescriptionTypo()}
          </DescriptionWrap>)
        }
      </TextWrap>
    </Checkbox>
  )
}
