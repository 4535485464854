import React from 'react'
import styled from '@emotion/styled'
import { ShopCardImage } from './ShopCardImage'
import { ShopCardRecomBadge } from './ShopCardRecomBadge'
import { ShopCardProductNameCtgr } from './ShopCardProductNameCtgr'
import { ShopCardPrice } from './ShopCardPrice'
import { CardShopBadge } from '~/react/Common/Card/Shop/CardShopBadge'

const ShopCardContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    cursor: 'pointer',
    width: '288px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }

  const mobileStyle = {
    width: '161.5px',
    minHeight: '256px'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle

})

const ShopCardImageWrapper = styled.div({}, ({ title, description, ismobile }) => {
  const baseStyle = {
  }

  const mobileStyle = {
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ShopCardInfoWrapper = styled.div({}, ({ item, ismobile }) => {
  const baseStyle = {
    // .item-info
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'flex-start',
    padding: '8px 5px 5px 8px !important'
  }

  const mobileStyle = {
    // .item-info-mobile
    minHeight: '126px',
    padding: '8px 5px 15px 5px !important'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function ShopCard(props) {
  const { imageurl, iconnew, recomyn, pdnm, pdcd, ctgrnm, formattedprice, badgelist, clickcallback, ismobile } = props
  const imageSize = {
    pc:{
      width :'288px',
      height: '288px'
    },
    mobile:{
      width :'162px',
      height: '162px'
    }
  }

  return (
  <ShopCardContainer onClick={() => clickcallback(pdcd) } ismobile={ismobile}>
      <ShopCardImageWrapper>
      </ShopCardImageWrapper>
      <ShopCardImage imageUrl={imageurl} iconNew={iconnew} size={imageSize} ismobile={ismobile}>
        </ShopCardImage>
      <ShopCardInfoWrapper ismobile={ismobile}>
        <ShopCardRecomBadge recomYn={recomyn} ismobile={ismobile}>
        </ShopCardRecomBadge>
        <ShopCardProductNameCtgr pdNm={pdnm} ctgrNm={ctgrnm} ismobile={ismobile}>
        </ShopCardProductNameCtgr>
        <ShopCardPrice
          ismobile={ismobile}
          formattedPrice={formattedprice}>
        </ShopCardPrice>
        <CardShopBadge badgeList={badgelist}/>
      </ShopCardInfoWrapper>
    </ShopCardContainer>
  )
}


