import React from 'react'
import styled from '@emotion/styled'
import { gray } from '~/react/Foundation/Color'
import { Icon } from '~/react/Foundation/Icon'
import InfoIcon from '~/react/infoIcon'
import dateUtil from '~/utils/dateUtil'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DueInfoContainer = styled.div`
  padding: 16px;
  background-color: #F8F8F8;
  border: 1px solid ${gray[40]};
  border-radius: 4px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${gray[90]};
`;

const Timeline = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: ${gray[90]};
  padding-left: 20px;
`;

const DescriptionBase = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${gray[80]};
  font-weight: 400;
`;

const DescriptionBold = styled(DescriptionBase)`
  font-weight: 700;
`;

const DueTitleFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;
`;

const DueDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const NoticeSectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const NoticeTitle = styled.div`
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: ${gray[80]};
`;

const NoticeTitleFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 2px;
`;

const NoticeItem = styled.li`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  padding-left: 6px;
  list-style: none;
  color: ${gray[80]};
`;

const TooltipFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

function DueDate({ label, dueDate, locale, tooltip = null }) {
  const localeFormats = {
    ko: 'YYYY.MM.DD HH:mm',
    en: 'DD.MM.YYYY HH:mm',
    zh: 'YYYY.MM.DD HH:mm',
    ja: 'YYYY.MM.DD HH:mm',
    es: 'DD.MM.YYYY HH:mm'
  };
  const formattedDate = dateUtil.formatDate({
    date: dueDate,
    format: localeFormats[locale]
  })
  return (
    <div>
      <TooltipFlex>
        <div>
          <DueTitleFlex>
            <Icon type={'clock'} size={14} color={'#E8351D'}></Icon>
            <Title>{label}</Title>
          </DueTitleFlex>
          <Timeline>{formattedDate} KST</Timeline>
        </div>
        {tooltip && (
          <InfoIcon tooltip={tooltip} align={'right'} size={20} />
        )}
      </TooltipFlex>
    </div>
  )
}

/**
 * @typedef Props
 * @property {Date} exclusiveEventDueDate 단독 특전 기한
 * @property {Date} initialEventDueDate 초도 특전 기한
 * @property {Date} preOrderDueDate 선주문 마감 기한
 * @property {boolean} hasPreorderGuide 예약 판매 안내 여부
 * @property {boolean} hasInitialEventGuide 초도 특전 안내 여부
 * @property {boolean} hasMinimumOrderQuantityGuide 최소 주문 수량 안내 여부
 * @property {VueI18n} $i18n i18n
 */

/**
 * ShopDetailDescriptionInfo
 * @param {Props} props
 * @returns {JSX.Element}
 */
export function ShopDetailDescriptionInfo({
  exclusiveEventDueDate,
  initialEventDueDate,
  preOrderDueDate,
  hasPreorderGuide,
  hasInitialEventGuide,
  hasMinimumOrderQuantityGuide,
  $i18n
}) {
  const hasDueDateSection = exclusiveEventDueDate || initialEventDueDate || preOrderDueDate
  const hasNoticeSection = hasPreorderGuide || hasInitialEventGuide || hasMinimumOrderQuantityGuide

  return (
    <Container>
      {(hasDueDateSection || initialEventDueDate || preOrderDueDate) && (
        <DueInfoContainer>
          {hasDueDateSection && (
            <DueDateContainer>
              {exclusiveEventDueDate && (
                <DueDate
                  label={$i18n.t('shop.pobDeadline')}
                  dueDate={exclusiveEventDueDate.toString()}
                  locale={$i18n.locale} />
              )}
              {initialEventDueDate && (
                <DueDate
                  label={$i18n.t('shop.firstPressDeadline')}
                  dueDate={initialEventDueDate.toString()}
                  locale={$i18n.locale}
                  tooltip={$i18n.t('shop.firstPressGuideText')} />
              )}
              {preOrderDueDate && (
                <DueDate
                  label={$i18n.t('shop.preOrderDeadline')}
                  dueDate={preOrderDueDate.toString()}
                  locale={$i18n.locale}
                  tooltip={$i18n.t('shop.preOrderDeadlineDescription')} />
              )}
            </DueDateContainer>
          )}
        </DueInfoContainer>
      )}
      {hasNoticeSection && (
        <NoticeSectionContainer>
          <NoticeTitleFlex>
            <Icon type={'check'} size={12}/>
            <NoticeTitle>{$i18n.t('shop.noticeBeforePurchase')}</NoticeTitle>
          </NoticeTitleFlex>
          {hasPreorderGuide && (
            <NoticeItem>
              - {$i18n.t('shop.preOrderDescription')}
            </NoticeItem>
          )}
          {hasInitialEventGuide && (
            <NoticeItem>
              - {$i18n.t('shop.firstPressGuideText')}
            </NoticeItem>
          )}
          {hasMinimumOrderQuantityGuide && (
            <NoticeItem>
              - {$i18n.t('shop.minimumOrderQuantityGuide')}
            </NoticeItem>
          )}
        </NoticeSectionContainer>
      )}
    </Container>
  )
}
