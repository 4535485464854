import React from 'react';
import styled from '@emotion/styled'
import { Icon } from '~/react/Foundation/Icon'
import { gray } from '~/react/Foundation/Color'

const Container = styled.div`
  padding: 0 8px 16px 8px;
  display: flex;
  gap: 8px;
  align-content: stretch;
  background-color: white;
  height: 80px;
  border-top: 1px solid ${gray[40]};
`;

const TabButton = styled.button`
  padding: 16px 0;
  width: 100%;
  display: flex;
  & > div {
    margin: auto;
  }
`;

export function NavigationBar({ forward, back, home, refresh }) {
  const renderTabIcon = (type) => {
    return <Icon type={type} size={32} color={gray[70]} />
  }

  return (
    <Container>
      <TabButton onClick={back}>
        {renderTabIcon('arrow_left_small')}
      </TabButton>
      <TabButton onClick={forward}>
        {renderTabIcon('arrow_right_small')}
      </TabButton>
      <TabButton onClick={home}>
        {renderTabIcon('home')}
      </TabButton>
      <TabButton onClick={refresh}>
        {renderTabIcon('refresh')}
      </TabButton>
    </Container>
  )
}
