import React from 'react'
import styled from '@emotion/styled'
import { gray } from '../../../Foundation/Color'
import { Chips } from '../../Chips/Chips'
import { B2Bold } from '../../../Foundation/Typography'

export function FilterButtonsWithLabel(props) {
  const {list = [], title, selectedKey, handleselect} = props
  const LabelTitle = styled.div({
    color: gray[70]
  })
  const ButtonsContainer = styled.div({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  })
  const ButtonList = list?.map(item=>
      <div key={`ButtonList-${item.key}`} style={{margin: '8px 8px 8px 0'}}>
        <Chips caption={item.value} clickcallback={handleselect} value={item.key} selected={ selectedKey === item.key} iconName={item.iconName ?? ''} disabled={false} ismobile />
      </div>
    )

  return (
    <div>
      <LabelTitle>
        <B2Bold ismobile> {title} </B2Bold>
      </LabelTitle>
      <ButtonsContainer>
        {ButtonList}
      </ButtonsContainer>
    </div>
  )
}