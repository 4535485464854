import React from'react'
import styled from '@emotion/styled'

import { B1Medium } from '../../Foundation/Typography'
import { white } from '../../Foundation/Color'
import { Avatar } from '~/react'

const ProfileContainer = styled.button({}, ({ismobile}) => {
  const baseStyle = {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '180px',
    width: 'auto',
    marginRight: '10px',
    padding: 0,
    border: 'none',
    boxSizing: 'border-box',
    backgroundColor: white,
  }
  const mobileStyle = {
  }
  return ismobile? { ...baseStyle, ...mobileStyle }
  : baseStyle
})

const Nickname = styled(B1Medium)({}, ({ismobile}) => {
  const baseStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textOverflow: 'ellipsis',

    lineHeight: '32px',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    overflowY: 'hidden',
  }
  const mobileStyle = {
  }
  return ismobile? { ...baseStyle, ...mobileStyle }
  : baseStyle
})

/**
 * @typedef GNBProfileProps
 * @property {boolean} islogin 로그인 여부
 * @property {string} profileimage
 * @property {string} nickname
 * @property {(text:string) => string} i18n 다국어 메서드
 * @property {() => void} handlelogin
 * @property {() => void} handleclickprofile 이미지 클릭시 callback
 * @property {boolean} ismobile
 * @property {string} locale
 * @property {number} [width] 프로필 사이즈
 * @property {() => void} blurHandler 아이콘 외 영역 클릭시 핸들러
 */

/**
 *
 * @param {GNBProfileProps} props
 * @returns
 */
export function GNBProfile(props) {
  const { islogin, profileimage, nickname, i18n, handlelogin, handleclickprofile, blurHandler, ismobile, locale, width = 32 } = props

  if (!islogin) {
    return (
      <ProfileContainer onClick={(e)=>handlelogin(e)}>
        { ismobile ? <Avatar imageUrl={profileimage} width={width} /> :
          <B1Medium ismobile={ismobile}>
            { i18n && i18n('account.login') }
          </B1Medium>
        }
      </ProfileContainer>
    )
  } else {
    return (
      <ProfileContainer onClick={()=> handleclickprofile()} onBlur={blurHandler}>
        <Avatar imageUrl={profileimage} width={width} />
          { ismobile &&
            <Nickname ismobile={ismobile}>
            { nickname }
            </Nickname>
          }
      </ProfileContainer>
    )
  }
}
