import React from 'react';
import { CardProject } from '~/react'
import { DraggableScroller } from '~/react/Common/Scroll/TouchDraggableScroller'

const style = {
  justifyContent: 'flex-start',
  gap: '16px ',
}

export function ProjectListHorizontalScroll({
  list=[],
  size,
  labelProjectStatus,
  getProjectlabelPriceNow,
  getCardContext,
  goProjectDetail}) {

  const renderCard = (item) => {
    return (
      <div style={{ minWidth: '224px', maxWidth: '224px' }}>
        <CardProject
          key={item.title}
          url={item.url}
          item={item}
          title={item.title}
          description={item.description}
          imageurl={item.imageUrl}
          iconnew={item.iconnew}
          projectlabel={labelProjectStatus(item.status, item.startDate, item.endDate)}
          price={getProjectlabelPriceNow(item)}
          cardcontext={getCardContext(item)}
          clickcallback={goProjectDetail}
          size={size}
        />
      </div>
    )
  }

  return (
    <>
      <DraggableScroller style={style}>
        {list.map(item => (
          renderCard(item)
        ))}
      </DraggableScroller>
    </>
  )

}
