import React, { useContext } from 'react'
import styled from '@emotion/styled'
import i18nContext from '../i18nContext'
import { B2Medium, C1Medium } from '../Foundation/Typography'
import { gray } from '../Foundation/Color'

const SaleCountStyle = styled(B2Medium)`
  text-align: right;
  margin-left: auto;
  color: ${gray[80]};
`

const SaleCountStyleMobile = styled(C1Medium)`
  text-align: right;
  margin-left: auto;
  color: ${gray[80]};
  margin-top: 2px;
`

/**
 * @param {number} count
 * @param {boolean} ismobile
 * @returns {JSX.Element}
 */
export function ShopSaleCount({ count, ismobile }) {
  const i18n = useContext(i18nContext)

  return (
    <>
      {ismobile
        ? <SaleCountStyleMobile>{i18n.t('shop.sold', {num: count.toLocaleString()})}</SaleCountStyleMobile>
        : <SaleCountStyle as="span">{i18n.t('shop.sold', {num: count.toLocaleString()})}</SaleCountStyle>
      }
    </>
  )
}
