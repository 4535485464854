import React from'react'
import { gray } from '~/react/Foundation/Color'
import { Icon } from '~/react/Foundation/Icon'
/**
 * @typedef HeaderInfoIconProps
 * @property {'xlarge' | 'larege' | 'medium' | 'small'} size 헤더 크기
 * @property {any} [clicktooltipcallback] 툴팁 아이콘 클릭 콜백
 */

/**
 * @param {HeaderInfoIconProps} props
 */
export function HeaderInfoIcon(props) {
  const { size, clicktooltipcallback } = props
  const iconSize = {
    small: 14,
    medium: 16,
    large: 18,
    xlarge: 20,
  }
  return (
    <Icon type="info" size={iconSize[size]} color={gray[60]} onClick={() => clicktooltipcallback()}></Icon>
  )
}