import React from 'react'
import styled from '@emotion/styled'
import { progressGreen, progressRed, gray } from '../../Foundation/Color'

const ProgressBarWrapper = styled.div({}, ({ ismobile }) => {
  // .project-progress-bar
  const style = {
    position: 'relative',
    width: '100%',
    height: ismobile? '2px': '4px',
    borderRadius: '24px',
    backgroundColor: gray[40],
    overflow: 'hidden',
  }
  return style
})

const ProgressBarBody = styled.div({}, ({ color, value }) => {
  let colorStyle = {}

  switch (color) {
    case 'green':
      colorStyle = progressGreen
      break
    case 'red':
      colorStyle = progressRed
      break
    case 'gray':
      colorStyle.backgroundColor = gray[40]
      break
  }

  const widthStyle= {width: value + '%'}

  return {
    height: '100%',
    borderRadius: '24px',
    ...colorStyle,
    ...widthStyle,
  }
})

/**
 *
 * @typedef {object} Props
 * @property {string} color - Bar 색상. 'red', 'green', 'blue' 등 정의된 이름으로 적어야 함.
 * @property {number} currentValue - 현재 진행도 Default. 0
 * @property {number} totalValue - 기준이 되는 수 Default. 100
 * @property {number} height - 프로그레스 바 높이
 * @property {boolean} ismobile - PC / Mobile flag
 */

/**
 * @param {Props} props
 */
export function ProgressBar(props) {
  const { color, currentValue, totalValue=100, height, ismobile } = props
  /**
   * 프로그레스바 퍼센테이지 계산
   * @returns {number}
   */
  const percentage = () => {
    if (currentValue === undefined) {
      console.error('ProgressBar - currentValue is undefined');
      return 0
    }
    if ( currentValue <= totalValue ) return (currentValue / totalValue) * 100
    return 100
  }
  return (
    <ProgressBarWrapper ismobile={ismobile} height={height}>
      <ProgressBarBody height={height} color={color} value={percentage()} ismobile={ismobile} />
    </ProgressBarWrapper>
  )
}
