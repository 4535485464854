import React from 'react'
import styled from '@emotion/styled'
import { gray } from '../../../Foundation/Color'
import { B1Medium, B2Medium, C1Bold, C2Bold } from '../../../Foundation/Typography'

const CardProjectPriceBody = styled.div({}, ({ ismobile, price }) => {
  const baseStyle = {
    position: 'relative',
    flex : '1',
    color: gray[90],
    textAlign: 'left',
  }
  const mobileStyle = {
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

/**
 * @typedef CardProjectPriceProps
 * @property {string} price
 * @property {boolean} ismobile
 * @property {'large' | 'medium' | 'small'}}size
 */

/**
 * 
 * @param {CardProjectPriceProps} props 
 * @returns 
 */

export function CardProjectPrice(props) {
  const { price, ismobile, size } = props

  const priceText = (() => {
    if (size === 'small' && ismobile) {
      return price.length >= 10 ? <C2Bold ismobile>{price}</C2Bold> : <C1Bold ismobile>{price}</C1Bold>
    }
    if (size === 'large') {
      return <B1Medium ismobile={ismobile}>{price}</B1Medium>
    }
    return <B2Medium ismobile={ismobile}>{price}</B2Medium>
  })
  return (
    <CardProjectPriceBody price={price} ismobile={ismobile}>
      {priceText()}
    </CardProjectPriceBody>
  )
}
