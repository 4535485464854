import React from 'react';
import styled from "@emotion/styled";
import { B2Medium, C2Medium } from '~/react/Foundation/Typography';
import { gray, red } from '~/react/Foundation/Color'

const InputFieldContainer = styled.div`
  margin-bottom: 20px;
`;

const TitleDescription = styled.div`
  padding-bottom: 6px;
  color: ${gray[90]};
  display: flex;
  gap: 2px;
`;

const Asterisk = styled(B2Medium)`
  color: ${red[50]};
`;

const Description = styled.div`
  margin-top: 6px;
`;

const DescriptionText = styled(C2Medium)`
  color: ${gray[70]};
`;

export function InputField({ title, required, description, children }) {
  return (
    <InputFieldContainer>
      <TitleDescription>
        <B2Medium as="span">{title}</B2Medium>
        {required && (
          <Asterisk as="span">*</Asterisk>
        )}
      </TitleDescription>
      {children}
      {description && (
        <Description>
          <DescriptionText>{description}</DescriptionText>
        </Description>
      )}
    </InputFieldContainer>
  )
}
