import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { blue, gray, white } from '~/react/Foundation/Color'
import { InputDescriptionText } from './InputDescriptionText'
import { C1_STYLES, B2_STYLES } from '~/react/Foundation/Typography/styles'
import { InputSizeStyle, InputValidStatusColorStyle } from './styles';
import { InputPostIcon } from './InputPostIcon'

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 6px;
  box-sizing: border-box;
  width: 100%;
`;

const InputContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  backgroundColor: white,
}, ({ size, validStatus, isDisabled }) => {
  const baseStyle = {
    gap: InputSizeStyle[size].gap,
    borderRadius: InputSizeStyle[size].radius,
    height: InputSizeStyle[size].height,
    boxSizing: 'border-box',
    padding: InputSizeStyle[size].padding,
    border: `1px solid ${InputValidStatusColorStyle[validStatus]}`,
    '&:focus-within': {
      ... (validStatus === 'normal' || validStatus === 'complete') && { borderColor: gray[80] }
    }
  }

  const optionStyle = {}
  if (isDisabled && validStatus === 'confirm') {
    // blue_05 색상 대신 opaicty 50%로 색상 처리
    optionStyle.backgroundColor = blue[10] + '80'
  }
  if (isDisabled && validStatus !== 'confirm') {
    optionStyle.backgroundColor = gray[20]
  }
  return { ...baseStyle, ... optionStyle}
})

const StyledInput = styled.input({
  border: 'none',
  backgroundColor: 'transparent',
  '&::placeholder': {
    color: gray[50]
  },
  padding: 0,
  width: '100%',
  flexGrow: 1,
  outlineColor: 'transparent',
  outlineWidth: 0,
  '&:disabled': {
    '-webkit-text-fill-color': gray[60], // safari
    opacity: 1, // safari
    color: gray[60],
    backgroundColor: gray[20]
  }
}, ({ size }) => {
  return {
    ... size === 'small' ? C1_STYLES.mobile.medium : B2_STYLES.mobile.regular,
  }
})

const VisibleIconWrap = styled.div({
  display: 'inline-flex',
  flexShrink: 0,
  flexWrap: 'nowrap'
}, ({ isVisible }) => {
  return !isVisible && { display: 'none' }
})

/**
 * interface는 하단에서 확인해주세요
 * @param {InputTextProps} props
 * @returns
 */
export function InputText ({size='small', placeholder='', description='', isDisabled=false, defaultValue='', onUpdate=() => {}, validatorOptions, ...props}) {
  const [innerValue, setInnerValue] = useState('')
  /** @type {[InputTextProps['validStatus'], React.Dispatch<InputTextProps['validStatus']>]} state */
  const [innerState, setInnerState] = useState('normal')
  /** @type {[InputTextProps['validStatus'], React.Dispatch<InputTextProps['validStatus']>]} state */
  const [iconState, setIconState] = useState('normal')
  const [validMessage, setValidMessage] = useState('')

  const [inputTouched, setInputTouched] = useState(false);

  const handleChangeInput = (event) => {
    setInputTouched(true)
    setInnerValue(event.currentTarget.value)
    onUpdate(event.currentTarget.value)
    handleValidationAndStatus('change')
  }

  const cancelClickCallback = () => {
    if(!isDisabled && iconState === 'active')  {
      setInnerValue('')
      onUpdate('')
    }
  }

  const setFocusStyleAndStatus = () => {
    setIconState('active')
  }

  const handleValidOnBlur = () => {
    handleValidationAndStatus('blur')
    setIconState('normal')
  }

  const handleValidationAndStatus = (triggerType) => {
    if (validatorOptions && validatorOptions.rulesInfo) {
      const result = {}
      for(let rule of validatorOptions.rulesInfo){
        if (rule.trigger === triggerType) {
          if (!rule.validatorFn(innerValue)) {
            // false 일 경우 멈추고 에러
            setInnerState('error')
            // 비동기 때문에 함께 처리
            setIconState('error')
            result[rule.ruleName] = false
            setValidMessage(rule.statusMessages?.error)
            break
          } else {
            result[rule.ruleName] = true
            setValidMessage(rule.statusMessages?.confirm ?? '')
          }
        }
      }
      if (validatorOptions?.getResultCallback) validatorOptions.getResultCallback({ result })
      if(Object.values(result).length > 0 && Object.values(result).every(testResult => testResult === true)){
        setInnerState('confirm')
        // 비동기때문에 함께 처리
        setIconState('confirm')
      }
    }
    if (!validatorOptions) {
      setInnerState(innerValue.length > 0 ? 'complete' : 'normal')
    }
  }

  const setValidationStates = () => {
    if (validatorOptions && validatorOptions.validStatus) {
      setInnerState(validatorOptions?.validStatus ?? 'normal')
      setValidMessage(validatorOptions.validationMsg ?? '')
    }
  }

  useEffect(() => {
    setInnerValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (inputTouched) {
      setValidationStates()
    }
  }, [validatorOptions])

  return(
    <Container>
      <InputContainer size={size} validStatus={innerState} isDisabled={isDisabled}>
        <StyledInput size={size} placeholder={placeholder} value={innerValue} onChange={handleChangeInput} onFocus={setFocusStyleAndStatus} onBlur={handleValidOnBlur} disabled={isDisabled} {...props} />
        <VisibleIconWrap isVisible={iconState !== 'normal'}>
          <InputPostIcon size={size} status={iconState} onMouseDown={cancelClickCallback}></InputPostIcon>
        </VisibleIconWrap>
      </InputContainer>
      {validMessage.length > 0 && <InputDescriptionText size={size} isDisabled={isDisabled} status={innerState}>{validMessage}</InputDescriptionText>}
      {description.length > 0 && <InputDescriptionText size={size} isDisabled={isDisabled} status="normal">{description}</InputDescriptionText>}
    </Container>
  )
}

/**
 * @description 유효성 검사는 상위 컴포넌트에서 진행하고 관련 상태값만 넘겨줄 때 사용하는 옵션
 * @typedef StatusOption
 * @property {'normal' | 'active' | 'complete' | 'error' | 'confirm' } [validStatus] status
 * @property {string} [validationMsg] status에 따른 msg
 */
/**
 * @description 유효성 검사를 해당 컴포넌트 내에서 사용할 때 옵션
 * @typedef RulesOption
 * @property {{ ruleName: string, trigger: 'blur' | 'change', validatorFn: (value:string) => boolean, statusMessages?: {error?: string, confirm?: string, complete?: string, normal?: string}}[]} [rulesInfo] 유효성 검사 관련 정보
 * @property {(result: {[k:string]: boolean}) => void} [getResultCallback] rulesInfo가 있을 경우, rulesInfo에 대한 결과값을 받을 메서드
 */

/**
 * @typedef { StatusOption | RulesOption } ValidatorOptionsProp
 */

/**
 * @typedef InputTextProps
 * @property {'small' | 'medium' | 'large'} size 인풋 사이즈
 * @property {string} [placeholder] 플레이스 홀더
 * @property {string} [description] 안내문구
 * @property {boolean} isDisabled disabeld 상태
 * @property {string} [defaultValue] input에 넣어줄 기본값
 * @property {(value: string) => void} onUpdate 인풋값을 받기 위한 change메서드
 * @property {ValidatorOptionsProp} [validatorOptions] 유효성검사 관련 옵션
 */
