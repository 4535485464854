import styled from '@emotion/styled'
import React, { useState } from 'react'
import Tooltip from './tooltip'

const InfoIcon = styled.div({
  position: 'relative',
  background: 'url(/image/svg-icon/info.svg) no-repeat',
  backgroundSize: 'contain',
  left: '0',
  margin: 'auto 0',

}, ({ size }) => {
  return {
    width: `${size}px`,
    height: `${size}px`,
  }
})



export default function(props) {
  const { tooltip, align = 'left', size = 16 } = props
  const [innerTooltipPosition, setinnerTooltipPosition] = useState({
    X: 0,
    Y: 0
  })

  const iconEvent = (enter, e) => {
    if (enter) {
      const { target: { offsetLeft, offsetWidth, offsetTop, offsetHeight }} = e
        setinnerTooltipPosition({
          X: 20,
          Y: offsetTop + offsetHeight + 10
        })
    } else {
      setinnerTooltipPosition({
        X: 0,
        Y: 0
      })
    }
  }
  return [
    <InfoIcon onMouseEnter={e => iconEvent(true, e)} onMouseLeave={e => iconEvent(false, e)} size={size}></InfoIcon>,
    <Tooltip tooltip={tooltip} position={innerTooltipPosition} align={align}/>
  ]
}
