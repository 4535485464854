// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from 'react'

import styled from '@emotion/styled'

/**
 *
 * -------------
 * <fixedModal show={show} :headerviewstatus={headerViewSheaderviewstatustatus} device={device} /> 
 * -------------
 * computed: {
 *  ...mapGetters([
 *     'device',
 *     'headerviewstatus'
 *   ]),
 * }
 */
function ReactPlotingComponent(props) {
  const { children, css = {}} = props

  const PlottingLayout = styled.div({
    width: 'fit-content',
    height: 'fit-content'
  },
  ({ css }) => {
    return ({ ...css })
  })

  return (
    <PlottingLayout css={css} style={{zIndex: '300'}}>
      {children}
    </PlottingLayout>)
}

export function fixedModal(props) {
  const { 
    videourl = videourl,
    imageurl = imageurl,
    videotitle = videotitle,
    videodate = videodate,
    onclosecallback = onclosecallback,
    device = 'PC',
    headerviewstatus, targetId = 'main-layout-header-mobile' } = props
  let { show = false } = props
  useEffect(() => {
    if (innerShow !== show) {
      setInnerShow(show)
    }
  }, [show])
  const imageurlString = 'url("' + imageurl + '")'
  const [innerShow, setInnerShow] = useState(false)
  const ContentBaseCss = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '16px',
    background: 'white',
    filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.15))'
  }
  const ThumbnailBaseCss = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#000000',
    // background: imageurlString,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  }
  const cssStyle = {
    baseCss: {
      width: '100%',
      height: '100%'
    },
    PC: {
      baseCss: {
        width: '640px',
        bottom: '4rem',
        right: '8rem',
        position: 'fixed',
        zIndex: 20
      },
      Thumbnail: {
        ...ThumbnailBaseCss,
        height: '360px'
      },
      Content: {
        ...ContentBaseCss,
        height: '99px'
      }
    },
    MOBILE: {
      baseCss: {
        width: '100%',
        position: 'fixed',
        zIndex: 20
      },
      Thumbnail: {
        ...ThumbnailBaseCss,
        height: '211px'
      },
      Content: {
        ...ContentBaseCss,
        height: '76px'
      }
    }
  }
  const ModalFrame = styled.div({
    ...cssStyle['baseCss']
  })
  const Thumbnail = styled.div({
    ...cssStyle[device]['Thumbnail']
  })
  const PlaybackFrame = styled.iframe({
    ...cssStyle[device]['Thumbnail']
  })  
  const Content = styled.div({
    ...cssStyle[device]['Content']
  })

  const ContentTitle = styled.div({
    width: '90%',
    fontFamily: 'Noto Sans KR',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '22px',
    color: '#333333',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical'
  })

  const ContentDate = styled.div({
    fontFamily: 'Noto Sans KR',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#888888'
  })

  const EmojiLeft = styled.div({
    width: '20px',
    height: '20px',
    marginRight: '16px',
    background: 'url(/image/equalizer.svg)'
  })
  const EmojiRight = styled.div({
    width: '16px',
    height: '16px',
    marginRight: 12,
    background: 'url(/image/baseline-close.png)',
    backgroundSize: '100%',
    cursor: 'pointer'
  })
  const [innerCSS, setInnerCSS] = useState({})
  useEffect(() => {
    const css = cssStyle[device]['baseCss']
    if (device === 'MOBILE') {
      // if (headerviewstatus) {
      //   const headerHeight = document.getElementById(targetId).clientHeight
      //   css['top'] = `${headerHeight}px`
      // } else {
        css['top'] = '0'
      // }
    }
    setInnerCSS({ ...css })
  }, [headerviewstatus, device])

  return (
    [innerShow
      ? <ReactPlotingComponent css={innerCSS} layoutFixed={device === 'PC'} headerviewstatus={headerviewstatus}>
        <ModalFrame>
          <PlaybackFrame src={videourl}>
          </PlaybackFrame>
          <Content>
            <EmojiLeft />
            <div style={{
              displat: 'flex',
              flexDirection: 'column',
              flex: 1
            }}>
              <ContentTitle>
                {videotitle}
              </ContentTitle>
              <ContentDate>
                {videodate}
              </ContentDate>
            </div>
            <EmojiRight onClick={() => {
              setInnerShow(false)
              onclosecallback()
              show = false
            }}/>
          </Content>
        </ModalFrame>
      </ReactPlotingComponent>
      : ''])
}
