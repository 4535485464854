import React, { useState, useReducer, useEffect } from 'react';
import styled from "@emotion/styled";
import { InputText, Button } from '~/react';
import { DropDown } from '~/react/Common/Select/DropDown';
import { H8Bold } from '~/react/Foundation/Typography';
import { gray } from '~/react/Foundation/Color';
import { labelTelNoCd } from '~/utils/payment';
import { isDeliveryMessageTargetCountry } from '~/utils/common';
import { InputField } from '~/react/popups/user/InputField';
import { RadioGroup } from '~/react/popups/user/RadioGroup';
import { Icon } from '~/react/Foundation/Icon'
import { applyVueInReact } from 'vuereact-combined';
import CommonPhoneNumberInput from '~/components/common/phoneNumberInput.vue';
import { nameReducer, nicknameCheckReducer, birthReducer } from '~/react/popups/user/reducers'

const VueCommonPhoneNumberInput = applyVueInReact(CommonPhoneNumberInput)

const FormWrap = styled.form`

  background-color: white;
`;

const ModalTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 24px 16px 0 16px;
`;

const ModalBody = styled.div`
  padding: 0 16px;
  max-height: calc(90vh - 100px);
  overflow-y: auto;
`;

const ModelBottom = styled.div`
  padding: 8px 16px 24px 16px;
`;

const Title = styled(H8Bold)`
  margin-bottom: 24px;
  color: ${gray[90]};
  //max-width: 260px;
  width: 100%;
`;

const FlexContainerWithGap = styled.div`
  display: flex;
  gap: 16px;
`;

const PhoneNumberCountryCode = styled.div`
  max-width: 81px;
`;

const KRNumberInput = styled.div`
  border: 1px solid ${gray[50]};
  padding: 1px 10px;
  font-size: 14px;
  font-weight: 400;
  color: ${gray[90]};
  height: 36px;
  border-radius: 6px;
  display: flex;
  align-items: center;
`;

/**
 * 사용자의 필수 정보를 입력받는 팝업 컴포넌트
 * @param {object} userProfile 유저 정보
 * @param {Array<{code: string, lang: string, name: string}>} [countryList] 국가 목록
 * @param $makeStarApi
 * @param $i18n
 * @param onSuccess 완료 시 실행될 Handler
 */
export function UserInfoUpdatePopup({ show = false, userProfile, countryList, $userApi, $i18n, onSuccess, onClose }) {

  /** LOCAL STATES */
  const [isFormValid, setIsFormValid] = useState(false);

  const [name, dispatchName] = useReducer(nameReducer, {
    value: userProfile.realName || "",
    isValid: !!userProfile.realName,
  })
  const [nickname, dispatchNickname] = useReducer(nicknameCheckReducer, {
    value: userProfile.name || "",
    isNickCheckAvailable: false,
    isValid: !!userProfile.name,
  })
  const [birth, dispatchBirth] = useReducer(birthReducer, {
    value: userProfile.birthday || "",
    formattedValue: userProfile.birthday || "",
    isValid: !!userProfile.birthday,
  })
  const [gender, setGender] = useState(userProfile.gender || "");
  const [country, setCountry] = useState(userProfile.countryCode || 'KR');

  const [mobileNumber, setMobileNumber] = useState("");
  const [deliveryNoticeType, setDeliveryNoticeType] = useState('EMAIL');

  const GENDER_LIST = [
    { key: 'F', value: $i18n.t('account.genderGbn.female')},
    { key: 'M', value: $i18n.t('account.genderGbn.male') },
    { key: 'O', value: $i18n.t('account.genderGbn.etc') }
  ]

  const getShippingOptions = (country) => {
    if (country === 'JP') {
      return [{
        key: 'EMAIL',
        value: $i18n.t('account.receiveEmail')
      }]
    }
    return [
      {
        key: 'EMAIL',
        value: $i18n.t('account.receiveEmail')
      }, {
        key: 'SMS',
        value: $i18n.t('account.receiveSMS')
      }
    ]
  }

  useEffect(() => {
    if (userProfile && Array.isArray(userProfile.userOptionDTO)) {
      const deliveryOption = userProfile.userOptionDTO.find(option => option.type === 'DLVY_MSG_TYPE');
      if (deliveryOption && deliveryOption.value) {
        setDeliveryNoticeType(deliveryOption.value)
      }
      const mobileOption = userProfile.userOptionDTO.find(option => option.type === 'TEL_NO');
      if (mobileOption && mobileOption.value) {
        setMobileNumber(mobileOption.value)
      }
    }
  }, [userProfile])

  useEffect(() => {
    const isNameValid = name.isValid
    const isNicknameValid = nickname.isValid
    const isBirthValid = birth.isValid
    const isGenderValid = GENDER_LIST.some(genderItem => genderItem.key === gender);
    if (isNameValid && isNicknameValid && isBirthValid && isGenderValid) {
      setIsFormValid(true)
    } else {
      setIsFormValid(false)
    }
  }, [name.isValid, nickname.isValid, birth.isValid, gender, country])

  const onChangeName = (value) => {
    dispatchName({
      type: "entered",
      value: value,
    })
  }

  const onChangeNickname = (value) => {
    dispatchNickname({
      type: "entered",
      value: value,
    })
  }

  const onChangeBirth = (value) => {
    dispatchBirth({
      type: "updated",
      value: value,
    })
  }

  const onSelectCountry = (countryCode) => {
    setCountry(countryCode)
  };

  const onSelectGender = (gender) => {
    setGender(gender)
  };

  const onSelectDeliveryMessageType = (value) => {
    if (value) {
      setDeliveryNoticeType(value)
    }
  }

  const validateNicknameDuplication = async () => {
    if (!nickname.isNickCheckAvailable) return;
    if (nickname.isValid) return;
    const payload = {
      name: nickname.value,
    }
    const response = await $userApi.nicknameDoubleCheck(payload)
    if (response.resCd === "1000") {
      dispatchNickname({
        type: 'serverChecked',
        isValid: true
      })
    }
  };

  const getNicknameValidationMessage = () => {
    if (nickname.isValid) {
      return $i18n.t('signup.availableNickname')
    }
    if (!nickname.isNickCheckAvailable) {
      return $i18n.t('account.nicknameInValid')
    }
    return ''
  };

  const getNicknameValidationStatus = () => {
    if (nickname.isValid) {
      return 'confirm'
    }
    if (!nickname.isNickCheckAvailable) {
      return 'error'
    }
    return 'normal'
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    if (isFormValid) {
      const payload = {
        idx: userProfile.idx,
        ...(name.value && { realName: name.value }),
        ...(nickname.value && { name: nickname.value }),
        ...(birth.formattedValue && { birthday: birth.formattedValue }),
        ...(gender && { gender }),
        ...(country && { countryCode: country }),
        ...((deliveryNoticeType && isDeliveryMessageTargetCountry(country)) && { deliveryMessageType: deliveryNoticeType }),
        ...(mobileNumber && { mobile: mobileNumber })
      };
      const response = await $userApi.insertUserInfo(payload)
      if (response.resCd === '1000') {
        // vue 쪽으로 성공 handler 실행
        onSuccess();
      }
    }
  };

  return (
    <>
      {show && (
        <FormWrap data-tagging-id={'userinfo_popup'} id={'userinfo_popup'}>
          <ModalTop>
            <Title>{$i18n.t('user.pleaseProvideAdditionalInfo')}</Title>
            <Icon type={'close'} onClick={onClose} />
          </ModalTop>
          <ModalBody>
            <InputField title={$i18n.t('account.name')} required={true}>
              <InputText
                size={'medium'}
                placeholder={$i18n.t('account.name')}
                defaultValue={name.value}
                onUpdate={onChangeName}
                validatorOptions={{
                  validStatus: name.isValid ? 'confirm' : 'error',
                  validationMsg: !name.isValid && $i18n.t('account.nameInValid')
                }}
              />
            </InputField>
            <InputField title={$i18n.t('account.nickname')} required={true}>
              <FlexContainerWithGap>
                <InputText
                  size={'medium'}
                  placeholder={$i18n.t('user.enterNickname')}
                  defaultValue={nickname.value}
                  onUpdate={onChangeNickname}
                  validatorOptions={{
                    validStatus: getNicknameValidationStatus(),
                    validationMsg: getNicknameValidationMessage()
                  }}
                />
                <Button
                  caption={$i18n.t('account.duplicationCheck')}
                  size={'medium'}
                  typeName={'outlined'}
                  width={'110px'}
                  onClick={validateNicknameDuplication}
                  isDisabled={nickname.isValid}
                />
              </FlexContainerWithGap>
            </InputField>
            <InputField title={$i18n.t('account.birth')} required={true}>
              <InputText
                size={'medium'}
                placeholder={`${$i18n.t('account.birth')} ${$i18n.t('account.birthFormat')}`}
                defaultValue={birth.value}
                onUpdate={onChangeBirth}
                validatorOptions={{
                  validStatus: birth.isValid ? 'confirm' : 'error',
                  validationMsg: !birth.isValid && $i18n.t('account.birthInValid')
                }}
              />
            </InputField>
            <InputField title={$i18n.t('account.gender')} required={true}>
              <DropDown
                itemList={GENDER_LIST}
                selectedItemKey={gender}
                placeholder={$i18n.t('user.selectGender')}
                onSelectItem={onSelectGender}
              />
            </InputField>
            <InputField title={$i18n.t('account.country')} required={true}>
              <DropDown
                itemList={countryList.map((country) => {
                  return {
                    key: country.cntryCd,
                    value: country.cntryI18n
                  }
                })}
                selectedItemKey={country}
                onSelectItem={onSelectCountry}
                scrollable={true}
              />
            </InputField>
            <InputField title={$i18n.t('account.enterPhone')} required={false} description={$i18n.t('account.phoneNumberDesc')}>
              <FlexContainerWithGap>
                <PhoneNumberCountryCode>
                  <DropDown size={'medium'} isDisabled={true} placeholder={(country && countryList) && labelTelNoCd(country, countryList)}/>
                </PhoneNumberCountryCode>
                <div style={{ width: '100%', borderRadius: '6px !important' }}>
                <VueCommonPhoneNumberInput
                  id={'i18n-vue-phone-number-input'}
                  className={'i18n-vue-phone-number-input'}
                  noCountrySelector={true}
                  defaultCountryCode={country || 'KR'}
                  style={'"width: 100%; height:36px !important; border-radius: 6px !important;"'}
                  // update={onUpdatePhoneNumber}
                  translations={{
                    phoneNumberLabel: $i18n.t('project.enterPhone'),
                  }}
                  $model={{
                    value: mobileNumber,
                    setter: (val) => setMobileNumber(val)
                  }}
                />
                </div>
              </FlexContainerWithGap>
            </InputField>
            {isDeliveryMessageTargetCountry(country) && (
              <InputField title={$i18n.t('account.receiveShippingInfo')} required={true} description={$i18n.t('account.receiveSMSDesc')}>
                <RadioGroup
                  options={getShippingOptions(country)}
                  selectedOption={deliveryNoticeType}
                  onSelectOption={onSelectDeliveryMessageType}
                />
              </InputField>
            )}
          </ModalBody>
          <ModelBottom>
            <Button
              onClick={onSubmit}
              caption={$i18n.t('user.enterInformation')}
              color={'red'}
              size={'medium'}
              typeName={'filled'}
              width={'100%'}
              isDisabled={!isFormValid}
              id={'userinfo_submit_btn'}
            />
          </ModelBottom>
        </FormWrap>
      )}
    </>
  )
}
