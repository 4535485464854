
import { Icon } from '~/react/Foundation/Icon'
import { applyReactInVue } from 'vuereact-combined'
export default {
  name: 'SimpleHeader',
  components: {
    RIcon: applyReactInVue(Icon)
  },
  props: {
    hideBack: {
      type: Boolean
    }
  },
  methods: {
    back() {
      this.routeBack()
    }
  }
}
