import styled from '@emotion/styled'
import React from 'react'
import { C1Medium } from '../Foundation/Typography'
import { gray, white } from '../Foundation/Color'

const ButtonWrap = styled.button({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: gray[90],
    opacity: '0.8',
    border: '0',
    borderRadius: '4px',
    minHeight: '28px',
    maxWidth: '140px',
    padding: '0 16px',
    color: white,
    cursor: 'pointer'
  }

  const mobileStyle = {
    padding: '8px 12px',
    height: '32px'
  }

  return ismobile ? {...baseStyle, ...mobileStyle} : baseStyle
})

const ArrowButton = styled.span`
  margin: 0 0 0 4px;
`

const InqueryC1Medium = styled(C1Medium)`
  line-height: 1;
`

export function InqueryButton(props) {
  const { inquery, handlelink, ismobile, i18n } = props

  return (
    <ButtonWrap
      onClick={() => {
        handlelink(inquery.routerPath, 'ROUTE')
      }} ismobile={ismobile}
    >
      <InqueryC1Medium>{i18n.t(inquery.i18n)}</InqueryC1Medium>
    </ButtonWrap>
  )
}
