import React from 'react'
import styled from '@emotion/styled'

const ProjectCardProgressBarWrapper = styled.div({}, ({ ismobile }) => {
  // .project-progress-bar
  const style = {
    position: 'relative',
    display: 'block',
    width: 'calc( 100% - 16px )',
    // bottom: '11px',
    top: '4px',
    left: '8px',
    padding: 'unset !important',
  }
  return style
})

const ProjectCardProgressBarContainer = styled.div({}, ({ height, ismobile }) => {
  const baseStyle = {
    marginTop: ismobile === 'isMobile' ? '10px' : '50px'
  }
  // .project-content
  const style = {
    overflow: 'hidden',
    borderRadius: '25px',
    backgroundColor: '#DDDDDD',
  }
  
  const barHeight = ismobile? 2: 4
  const heightStyle= {height: barHeight + 'px'}

  return {
    ...style,
    ...heightStyle
  }
})

const ProjectCardProgressBarBody = styled.div({}, ({ height, color, value, ismobile }) => {
  const baseStyle = {
    marginTop: ismobile === 'isMobile' ? '10px' : '50px'
  }
  // .project-content
  const style = {
    style: "max-width: 100%"
  }

  const colorStyle = {}

  switch (color) {
    case 'blue':
      colorStyle.backgroundImage = 'linear-gradient(270deg, #00A49D 0%, #5BD7D1 100%)'
      break
    case 'red':
      colorStyle.backgroundImage = 'linear-gradient(270deg, #E52E15 0%, #FC9689 100%)'
      break
    case 'gray':
      colorStyle.backgroundColor = '#DDDDDD'
      break
    case 'orange':
      colorStyle.backgroundImage = 'linear-gradient(270deg, #ffc107 0%, #ffc107 100%)'
      break      
  }

  const widthStyle= {width: value + '%'}
  const heightStyle= {height: height + 'px'}

  return {
    ...style,
    ...colorStyle,
    ...widthStyle,
    ...heightStyle
  }
})

export function ProjectCardProgressBar(props) {
  const { color, value, height, ismobile } = props
  
  return (
    <ProjectCardProgressBarWrapper ismobile={ismobile}>
      <ProjectCardProgressBarContainer height={height} ismobile={ismobile}>
        <ProjectCardProgressBarBody height={height} color={color} value={value} ismobile={ismobile}>
        </ProjectCardProgressBarBody>
      </ProjectCardProgressBarContainer>
    </ProjectCardProgressBarWrapper>

  )
}
