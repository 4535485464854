import React from 'react'
import styled from '@emotion/styled'
import { ButtonSizeStyle } from './styles'
import { black, white } from '~/react/Foundation/Color'

const Container = styled.button({
  margin: 0,
  padding: 0,
  border: 'none',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: white
}, ({ size, colorStyle, width, options, typeName }) => {
  let borderStyle
  let paddingOption
  const baseStyle = {
    width: width ? width : 'auto',
    height: typeName === 'text' ? 'auto': ButtonSizeStyle[size].height,
    padding: typeName === 'text' ? '2px 0' : ButtonSizeStyle[size].padding,
    borderRadius: ButtonSizeStyle[size].radius,
    gap: ButtonSizeStyle[size].gap,
    backgroundColor: colorStyle.backgroundColor ?? 'transparent',
    color: colorStyle.color ?? black,
  }

  if (colorStyle.borderColor && colorStyle.borderColor.length > 0 && colorStyle.borderColor !== 'none') {
    borderStyle = `1px solid ${colorStyle.borderColor}`
  }
  if (options && options.isCustomPadding) {
    paddingOption = {
      paddingLeft: options.paddingLeft,
      paddingRight: options.paddingRight
    }
  }
  return {
    ...baseStyle,
    ...borderStyle && { border: borderStyle },
    ...paddingOption && paddingOption
  }
})
/**
 * @typedef ButtonContainerProps
 * @property {'large' | 'medium' | 'small' | 'fullWidth'} size 버튼 사이즈
 * @property {'filled' | 'outlined' | 'text'} typeName 버튼 타입.
 * @property {{borderColor: string, color: string, backgroundColor: string}} colorStyle 버튼 컬러 스타일
 * @property {boolean} isDisabled 활성화 여부
 * @property {{ isCustomPadding?: boolean, paddingLeft?: string, paddingRight?: string }} [options] 좌우 시각적 패딩이 필요할 경우 사용할 옵션
 * @property {any} children
 */
/**
 * @param {ButtonContainerProps} param0
 */
export function ButtonContainer ({ size, colorStyle, typeName, width, isDisabled, options, children, ...props }){
  return (
    <Container size={size} options={options} colorStyle={colorStyle} typeName={typeName} width={width} isDisabled={isDisabled} {...props}>{children}</Container>
  )
}
