import React, { useState } from 'react';
import styled from "@emotion/styled";
import { red, white, gray } from '~/react/Foundation/Color';
import { B1Regular } from '~/react/Foundation/Typography';

const RadioGroupContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const RadioLabel = styled(B1Regular)`
  color: ${gray[90]};
  display: inline-flex;
  gap: 8px;
  align-items: center;
  flex-grow: 1;
`;

const HiddenRadioInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
`;

const CheckIcon = styled.div({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '2px',
  border: `1px solid ${gray[50]}`,
  backgroundColor: white,
  borderRadius: '50%',
  boxSizing: 'border-box',
  cursor: 'pointer'
},({ isChecked, isDisabled }) => {
  const sizeStyle = {
    width: '20px',
    height: '20px',
  }
  const checkedStyle = {
    borderColor: isDisabled ? red[10] : red[50],
    '&:after': {
      display: 'block',
      content: "''",
      width: '60%',
      height: '60%',
      backgroundColor: isDisabled ? red[10] : red[50],
      borderRadius: '50%'
    },
  }
  const disabledStyle = {
    backgroundColor: white,
    borderColor: gray[30]
  }
  return {
    ...sizeStyle,
    ... isDisabled && disabledStyle,
    ...isChecked && checkedStyle
  }
});

/**
 * 라디오 그룹 컴포넌트
 * @param name
 * @param {Array<{ key: string, value: string }>}options
 * @param {string} selectedOption
 * @param onSelectOption
 * @returns {JSX.Element}
 * @constructor
 */
export function RadioGroup({ name = "radio-group", options, selectedOption, onSelectOption }) {
  const [selectedValue, setSelectedValue] = useState(selectedOption || options[0].key || '');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onSelectOption(event.target.value);
  };

  return (
    <RadioGroupContainer>
      {options.map((option) => (
        <RadioLabel as="label" key={`option-${option.key}`}>
          <HiddenRadioInput
            type="radio"
            value={option.key}
            checked={selectedValue === option.key}
            onChange={handleChange}
            name={name}
          />
          <CheckIcon isChecked={selectedValue === option.key} />
          {option.value}
        </RadioLabel>
      ))}
    </RadioGroupContainer>
  );
}
