/* eslint-disable no-unused-vars */
import React from 'react'
import styled from '@emotion/styled'

const SectionLayOut = styled.div(
  {
    display: 'flex'
  },
  props => ({ flexDirection: props?.['is-mobile'] === true ? 'column' : '' })
)

export function ProjectSectionLayout(props) {
  const { mobile = false, children } = props

  return (
    <SectionLayOut is-mobile={mobile}>
      {children}
    </SectionLayOut>
  )
}
