export const state = () => ({
  headerViewStatus: true,
  headerFixedStatus: false,
  scrollY: 0,

  /**
   * @type {'default' | 'simple' | 'empty' | 'error'}
   */
  layoutType: 'default',

  /**
   * 햄버거 메뉴 (사이드바) 오픈 상태
   * @type {boolean}
   */
  showHamburgerMenu: false,
  rewardHtml: {
    title: '',
    html: ''
  },
  b2b: false,
  isPC: true
})

export const mutations = {
  SET_IS_PC: (state, isPC) => {
    state.isPC = isPC
  },
  SET_B2B: (state, b2b) => {
    state.b2b = b2b || false
  },
  SET_REWARD_HTML: (state, rewardHtml) => {
    state.rewardHtml = rewardHtml || ''
  },
  SET_HEADER_VIEW_STATUS: (state, headerViewStatus) => {
    state.headerViewStatus = headerViewStatus
  },
  SET_SCROLL_Y: (state, scrollY) => {
    state.scrollY = scrollY
  },
  SET_HEADER_FIXED_STATUS: (state, headerFixedStatus) => {
    state.headerFixedStatus = headerFixedStatus
  },
  SET_LAYOUT_TYPE: (state, layoutType) => {
    state.layoutType = layoutType
  },
  SET_HAMBURGER_MENU_VIEW_STATUS: (state, showHamburgerMenu) => {
    state.showHamburgerMenu = showHamburgerMenu
  }
}

export const actions = {
  setHeaderViewStatus({ commit }, headerViewStatus) {
    commit('SET_HEADER_VIEW_STATUS', headerViewStatus)
  },
  setHeaderFixedStatus({ commit }, headerFixedStatus) {
    commit('SET_HEADER_FIXED_STATUS', headerFixedStatus)
  },
  setScrollY({ commit }, scrollY) {
    commit('SET_SCROLL_Y', scrollY)
  },
  setLayoutType({ commit }, layoutType) {
    commit('SET_LAYOUT_TYPE', layoutType)
  },
  setHamburgerMenuViewStatus({ commit }, showHamburgerMenu) {
    commit('SET_HAMBURGER_MENU_VIEW_STATUS', showHamburgerMenu)
  },
  setRewardHtml: ({ commit }, rewardHtml) => {
    commit('SET_REWARD_HTML', rewardHtml)
  },
  setB2B: ({ commit }, b2b) => {
    commit('SET_B2B', b2b)
  },
  setIsPC: ({ commit }, isPC) => {
    commit('SET_IS_PC', isPC)
  }
}

export const getters = {
  getHeaderViewStatus: (state) => {
    return state.headerViewStatus
  },
  getScrollY: (state) => {
    return state.scrollY
  },
  getHeaderFixedStatus: (state) => {
    return state.headerFixedStatus
  },
  getLayoutType: (state) => {
    return state.layoutType
  },
  getHamburgerMenuViewStatus: (state) => {
    return state.showHamburgerMenu
  },
  getRewardHtml: (state) => {
    return state.rewardHtml
  },
  getB2B: (state) => {
    return state.b2b
  },
  getIsPC: (state) => {
    return state.isPC
  }
}
