import React from 'react'
import styled from '@emotion/styled'
import { C1Medium, C1Regular } from '~/react/Foundation/Typography'
import { gray } from '~/react/Foundation/Color'
import { InputValidStatusColorStyle } from './styles'


const StyledC1Medium = styled(C1Medium)({}, ({ status, isDisabled }) => {
  let color = gray[70]
  if (isDisabled) {
    color = gray[60]
    if (status){
      // disabled-confirm 상태일 때
      color = InputValidStatusColorStyle[status]
    }
  } else if (status) {
    color = status === 'normal' ? gray[70] : InputValidStatusColorStyle[status]
  }

  return {
    whiteSpace: 'pre-line',
    color,
    '-webkit-text-fill-color': color, // safari
    opacity: 1, // safari
  }
})
const StyledC1Regular = styled(C1Regular)({}, ({ status, isDisabled }) => {
  let color = gray[70]
  if (isDisabled) {
    color = gray[60]
    if (status){
      // disabled-confirm 상태일 때
      color = InputValidStatusColorStyle[status]
    }
  } else if (status) {
    color = status === 'normal' ? gray[70] : InputValidStatusColorStyle[status]
  }

  return {
    whiteSpace: 'pre-line',
    color,
    '-webkit-text-fill-color': color, // safari
    opacity: 1, // safari
  }
})
/**
 * @typedef InputDescriptionTextProps
 * @property {'small' | 'medium' | 'large'} size 인풋 사이즈
 * @property {'normal' | 'complete' | 'error' | 'confirm'} status 상태값
 * @property {boolean} isDisabled disabeld 상태
 * @property {any} children
 */
/**
 *
 * @param {InputDescriptionTextProps} props
 * @returns
 */
export function InputDescriptionText ({ size, status, isDisabled, children }){
  if (size === 'small') {
    return <StyledC1Regular size={size} status={status} isDisabled={isDisabled}>{children}</StyledC1Regular>
  } else {
    return <StyledC1Medium size={size} status={status} isDisabled={isDisabled}>{children}</StyledC1Medium>
  }

}