import React from 'react'
import styled from '@emotion/styled'

const ProjectCardStatusTextBody = styled.div({}, ({ ismobile, color  }) => {
    
  const baseStyle = {
    // project-status-text
    position: 'relative',
    display: 'block',
    right: '8px',
    fontWeight: '700',
    fontSize: '13px',
    lineHeight: '23px !important',
    textAlign: 'right',
    opacity: '0.8'
  }
  
  switch (color) {
    case 'blue': baseStyle.color = '#00A49D'; break
    case 'red': baseStyle.color = '#E8351D'; break
    case 'gray': baseStyle.color = '#999999'; break
    case 'black': baseStyle.color = '#000000'; break
  }
  
  const mobileStyle = {
    // bottom: '19px',
    fontWeight: '700',
    fontSize: '11px',
    lineHeight: '16px !important',
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle

})

export function ProjectCardStatusText(props) {
  const { ismobile, locale, statusText, color } = props
  
  return (
    <ProjectCardStatusTextBody color={color} ismobile={ismobile} locale={locale}>
    {statusText}
  </ProjectCardStatusTextBody>
  )
}
