import React from'react'
import styled from '@emotion/styled'
import { B2Medium, } from '~/react/Foundation/Typography'
import { gray } from '~/react/Foundation/Color'
import { Icon } from '~/react/Foundation/Icon/Icon'

export function HeaderTextButton(props) {
  const {clickcallback, clickcallbackparam, textbuttonstring, ismobile} = props
  const expUrl = /^http[s]?:\/\/([\S]{3,})/i;

  const SeeMoreDiv = styled.div({}, ({ismobile}) => {
    const baseStyle = {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      gap: '2px',
      color: gray[70],
      padding: '4px 0'
    }
    const mobileStyle = {
      margin: 'auto 0px'
    }
    return ismobile?
    {
      ...baseStyle,
      ...mobileStyle

    } : baseStyle
  })

  const SeeMoreButton = styled.a({}, ({ismobile}) => {
    const baseStyle = {
      color: gray[80],
      textDecoration: 'none',
      padding: '4px 0'
    }
    const mobileStyle = {
    }
    return ismobile?
    {
      ...baseStyle,
      ...mobileStyle

    } : baseStyle
  })

  if (textbuttonstring === undefined || clickcallback === undefined) {
    return []
  } else if (expUrl.test(clickcallback)) {
    return (
      <SeeMoreButton href={clickcallback}>
        <B2Medium ismobile={ismobile}>
          {textbuttonstring}
        </B2Medium>
        <Icon type="arrow_right_small" color={gray[70]} size={16} />
      </SeeMoreButton>
    )
  } else {
    return (
    <SeeMoreDiv onClick={() => clickcallback(clickcallbackparam)} ismobile={ismobile}>
      <B2Medium ismobile={ismobile}>
        {textbuttonstring}
      </B2Medium>
      <Icon type="arrow_right_small" color={gray[70]} size={16} />
    </SeeMoreDiv>
    )
  }
}
