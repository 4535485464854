import React from'react'
import styled from '@emotion/styled'
import { HeaderTitle } from './HeaderTitle'
import { HeaderDescription } from './HeaderDescription'
import { HeaderTextButton } from './HeaderTextButton'
import { HeaderTitleCount } from './HeaderTitleCount'
import { HeaderInfoIcon } from './HeaderInfoIcon'

const HeaderContainer = styled.div``

const TitleContainer = styled.div({}, ({ textalign }) => {
  return {
    display: 'flex',
    justifyContent: textalign === 'center' ? 'center' : 'space-between',
    alignItems: 'flex-start',
    gap: '0 24px'
  }
})
const TitleWrapper = styled.div({}, ({ textalign }) => {
  return {
    display: 'flex',
    textAlign: textalign === 'center' ? 'center' : 'left',
    alignItems: 'center',
    gap:'2px',
    flex: 1
  }
})


/**
 * @typedef HeaderProps
 * @property {string} title 타이틀 영역 텍스트
 * @property {string} [description] 설명영역 텍스트
 * @property {'xlarge' | 'larege' | 'medium' | 'small'} size 헤더 크기
 * @property {boolean} ismobile PC / Mobile 구분
 * @property {string} [textbuttonstring] 텍스트 버튼에 들어가는 문자열
 * @property {(param) => void} [clickcallback] 텍스트 버튼 눌렀을 때의 콜백
 * @property {any} [clickcallbackparam] 텍스트 버튼 콜백함수의 파라미터
 * @property {any} [isshowcount] 타이틀 영역에 카운트가 필요한지 여부
 * @property {any} [count] 타이틀 오른쪽에 표시됨
 * @property {any} [isshowtooltip] 툴팁 아이콘 표시 여부
 * @property {any} [clicktooltipcallback] 툴팁 아이콘 클릭 콜백
 * @property {'left' | 'center'} [textalign] 타이틀과 디스크립션 정렬 기준. 기본 left
 */
/**
 *
 * @param {HeaderProps} props
 * @returns
 */
export function Header(props) {
  const { title, description, size, textbuttonstring, clickcallback, clickcallbackparam, ismobile = true, isshowcount, count, textalign='left', isshowtooltip, clicktooltipcallback} = props

  return (
    <HeaderContainer>
      <TitleContainer textalign={textalign}>
        <TitleWrapper textalign={textalign}>
          <HeaderTitle size={size} ismobile={ismobile} isshowcount={isshowcount} count={count}isshowtooltip={isshowtooltip} clicktooltipcallback={clicktooltipcallback}> { title } </HeaderTitle>
          {isshowcount && count > 0 && <HeaderTitleCount size={size} ismobile={ismobile} count={count} />}
          {isshowtooltip && clicktooltipcallback && <HeaderInfoIcon size={size} ismobile={ismobile} clicktooltipcallback={clicktooltipcallback} />}
        </TitleWrapper>
        { textbuttonstring && clickcallback &&
          <HeaderTextButton textbuttonstring={textbuttonstring} clickcallback={clickcallback} clickcallbackparam={clickcallbackparam} ismobile={ismobile}>
          </HeaderTextButton>
        }
      </TitleContainer>
      { description?.length > 0 &&
        <HeaderDescription textalign={textalign} size={size} ismobile={ismobile}>{description}</HeaderDescription>
      }
    </HeaderContainer>
  )
}
