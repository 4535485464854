import Vue from 'vue'
import { log } from '~/communicator/utils/logging'
const mixin = {
  methods: {
    routePush(path, func = undefined) {
      // console.log('path instanceof Object : ', path instanceof Object)
      // console.log(this.$route, this.$route.path, path)
      // if (path instanceof Object) {
      //   let path = {}
      //   if(path['params'])
      //   if(path['query'])
      // }
      // const beforeUrl = new URL()
      // beforeUrl.
      // const afterUrl = new URL()

      // const redirectUrl = new URL(url.searchParams.get('redirectUrl'), url.origin)

      // for (const key of url.searchParams.keys()) {
      //   /**
      //    * route는 ga url 통계를 위해 존재함 해서 처음 들어올떄만 있으면되니 쓰임을 다하였으니 삭제
      //    */
      //   if (!['redirectUrl', 'route'].includes(key)) {
      //     redirectUrl.searchParams.set(key, url.searchParams.get(key))
      //   }
      // }
      if (this.$route.path !== path) {
        try {
          this.$router.push(path, func)
        } catch (e) {

        }
      }
    },
    routeGo(n) {
      this.$router.go(n)
    },
    routeBack() {
      try {
        // 히스토리가 없는 경우 (직접 링크 진입 등) 홈 화면으로 돌아가게끔 예외처리
        if (window.history.length && window.history.length <= 1) {
          this.$router.push('/')
        } else {
          this.$router.go(-1)
        }
      } catch (e) {
        log('error', e)
        this.$router.go(-1)
      }
    }
  }
}
Vue.mixin(mixin)
