import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { InputDescription } from './InputDescription'

const InnerInput = styled.input({
  width: '250px',
  height: '40px',
  paddingRight: '44px',
  outline: '0',
  color: '#333',
  fontSize: '15px',
  textIndent: '10px',
  verticalAlign: 'middle',
  borderRadius: '5px',
  border: '1px solid #d8d8d8',
  '&:disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)'
  }
})
// input:valid {
//   color: black;
//   border: 5px solid #dadadada;
//   border-radius: 7px;
// }
// input:invalid {
//   color: navy;
//   outline: none;
//   border-color: #ff1050;
//   box-shadow: 0 0 10px #ff0000;
// }
export function Input(props) {
  const { value, update, description, disabled = false, placeholder, rule, pattern, required, ismobile } = props
  const [innerValue, setInnerValue] = useState('')
  // const [vaild, setVaild] = useState(false)
  useEffect(() => {
    setInnerValue(value)
  }, [value])

  const updateFn = ({ target }) => {
    // const { checkValidity, checkVisibility } = target
    update(target.value); setInnerValue(target.value)
  }

  const components = [
    <InnerInput value={innerValue} disabled={disabled} placeholder={placeholder} onChange={updateFn} pattern={pattern} required={required}/>,
    <InputDescription value={description}></InputDescription>
  ]
  if (rule) {
    components.push(<div class='ml-1 mt-1 fs-14 color-red2'>{rule} </div>)
  }
  return components
}
