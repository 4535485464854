import codeConst from '../codeConst'
import { getRemainDate, getRemainDateREACT, isEmpty } from '../common'
import moment from 'moment'
import Vue from 'vue'

export function labelProjectStatus(status, startDate, endDate) {
  if (status === codeConst.STATUS['20']) {
    return getRemainDate(this, endDate)
  } else if (status === codeConst.STATUS['30'] || status === codeConst.STATUS['40']) {
    const date_m = moment.utc(startDate).local()

    let ms = date_m.diff(moment())
    let d = moment.duration(ms)

    const day = d.asDays()
    const hours = d.asHours()
    const minute = d.asMinutes()

    if (Math.floor(day) >= 1) {
      const date_m_day = date_m.startOf('day')
      const cur_m_day = moment().startOf('day')
      ms = date_m_day.diff(cur_m_day)
      d = moment.duration(ms)

      return 'D-' + Math.floor(d.asDays())
    } else if (Math.floor(hours) > 0) {
      return Math.floor(hours) + this.$t('project.hours')
    } else if (Math.floor(minute) > 0) {
      return Math.floor(minute) + this.$t('project.minute')
    } else {
      return 1 + this.$t('project.minute')
    }
  } else if (status === codeConst.STATUS['50']) {
    return this.$t('project.toStop')
  } else if (status === codeConst.STATUS['60']) {
    return this.$t('project.cancel')
  } else if (status === codeConst.STATUS['80']) {
    return this.$t('project.toEnd')
  } else if (status === codeConst.STATUS['10']) {
    return this.$t('project.projectStatusText')
  }

  return status
}

export function labelProjectStatusREACT(status, startDate, endDate) {
  if (status === codeConst.STATUS['20']) {
    return getRemainDateREACT(this, endDate)
  } else if (status === codeConst.STATUS['30'] || status === codeConst.STATUS['40']) {
    const date_m = moment.utc(startDate).local()

    let ms = date_m.diff(moment())
    let d = moment.duration(ms)

    const day = d.asDays()
    const hours = d.asHours()
    const minute = d.asMinutes()

    if (Math.floor(day) >= 1) {
      const date_m_day = date_m.startOf('day')
      const cur_m_day = moment().startOf('day')
      ms = date_m_day.diff(cur_m_day)
      d = moment.duration(ms)

      return 'D-' + Math.floor(d.asDays())
    } else if (Math.floor(hours) > 0) {
      return Math.floor(hours) + '시간'
    } else if (Math.floor(minute) > 0) {
      return Math.floor(minute) + '분'
    } else {
      return 1 + '분'
    }
  } else if (status === codeConst.STATUS['50']) {
    return '일시정지'
  } else if (status === codeConst.STATUS['60']) {
    return '취소'
  } else if (status === codeConst.STATUS['80']) {
    return '종료'
  } else if (status === codeConst.STATUS['10']) {
    return '작업중'
  }

  return status
}

export function labelPriceByPaymentType(price, PAYMENT_TYPE, paymentType, local) {
  if (
    paymentType === PAYMENT_TYPE.LOCAL_CREDIT_CARD.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_MONEY_TRANSFER.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_CELLULAR_PHONE.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_CULTURAL_GIFT.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_KAKAOPAY.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_PAYCO.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_MONEY_DEPOSIT.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_NAVERPAY.CD ||
    paymentType === PAYMENT_TYPE.LOCAL_NAVERPAY_POINT.CD ||
    (paymentType === PAYMENT_TYPE.FREE.CD && local === 'ko') ||
    paymentType === PAYMENT_TYPE.LOCAL_TOSS_VIRTUAL_ACCOUNT.CD
  ) {
    return Math.ceil(price)
  } else if (
    paymentType === PAYMENT_TYPE.GLOBAL_ECONTEXT.CD ||
    (paymentType === PAYMENT_TYPE.GLOBAL_CREDIT_CARD.CD && local === 'ja') ||
    (paymentType === PAYMENT_TYPE.FREE.CD && local === 'ja')
  ) {
    return Math.ceil(price)
  } else if (
    paymentType === PAYMENT_TYPE.GLOBAL_ALIPAY.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_MOLPAY.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_WECHAT.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_PAYPAL.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_CREDIT_CARD.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_ALIPAY_ICB.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_ALIPAY_ICB_GB.CD ||
    paymentType === PAYMENT_TYPE.GLOBAL_WECHAT_ICB.CD ||
    paymentType === PAYMENT_TYPE.FREE.CD ||
    paymentType === PAYMENT_TYPE.TOSS_PAYPAL.CD
  ) {
    price = price ? Number(price) : 0

    return Number(price.toFixed(2))
  }
}

export function labelPrice(price, locale) {
  if (locale === 'en' || locale === 'zh' || locale === 'es') {
    price = price ? Number(price) : 0

    return Number(price.toFixed(2))
  } else {
    return Math.ceil(price)
  }
}

/**
 * @param {'KRW' | 'USD' | 'JPY' | 'CNY' | 'EUR'} currency
 * @param {number} price
 * @returns {string} 화폐를 포함한 포맷팅된 가격
 */
export function formatPrice(currency, price) {
  const langCrcyChar = getCurrencyCharacter(currency)
  const formattedPrice = formatCurrencyPrice(price, currency)
  return langCrcyChar + formattedPrice
}

export function getCurrencyCharacter(currency) {
  switch (currency) {
    case 'KRW':
      return '₩'
    case 'USD':
      return '$'
    case 'JPY':
      return '¥'
    case 'CNY':
      return 'Ұ'
    case 'EUR':
      return '€'
    default:
      return '₩'
  }
}

function getCurrencyScale(currency) {
  switch (currency) {
    case 'KRW':
    case 'JPY':
      return 0
    default:
      return 2
  }
}

export function formatCurrencyPrice(price, currency) {
  const scale = getCurrencyScale(currency)
  return (Number((Math.ceil((price * 100).toFixed(2)) / 100).toFixed(scale).toString())).toLocaleString()
}

export function isCustoms(cntryCd) {
  if (isEmpty(cntryCd)) {
    return false
  }

  if (
    cntryCd === 'GB' ||
      cntryCd === 'MS' ||
      cntryCd === 'BM' ||
      cntryCd === 'VG' ||
      cntryCd === 'AI' ||
      cntryCd === 'GI' ||
      cntryCd === 'KY' ||
      cntryCd === 'TC' ||
      cntryCd === 'FK') {
    return true
  }

  return false
}

export function calculationSalePercent(price, dcFee) {
  let calPercent = dcFee / price * 100
  // 할인 퍼센트가 1보다 작을 경우 1로 올림
  if (calPercent < 1) {
    calPercent = 1
  }
  return Math.floor(calPercent)
}

export function getDiffProjectRemain(endDate) {
  const d = moment.utc(endDate).local()
  const now = moment().local()
  const t = moment.duration(d.diff(now))
  const seconds = (Math.floor(t.asSeconds()) % 60)
  const minutes = (Math.floor(t.asMinutes()) % 60)
  const hours = (Math.floor(t.asHours()) % 60)
  const days = Math.floor(t.asDays())

  if (t.asSeconds() > 0) {
    return {
      total: t,
      days,
      hours,
      minutes,
      seconds
    }
  } else return null
}

/**
 * 프로젝트 남은 일자 계산하여 반환
 * @param status
 * @param endDate
 * @returns {null|number}
 */
export function getLabelProjectRemainDate(status, endDate) {
  if (status === codeConst.STATUS['20'] || status === codeConst.STATUS['30'] || status === codeConst.STATUS['40']) {
    const diff = getDiffProjectRemain(endDate)
    if (diff) { // diff가 존재하는 경우;
      if (diff.days > 0) { // 날짜가 0 이상일 경우;
        return diff.days
        // if ((diff.hours % 24) > 0) this.remainDays += 1// 마감 D-DAY까지 포함하지 않음
      }
    }
  }
  return null
}

/**
 * 프로젝트 남은 시간 계산하여 반환
 * 24시간 이내일 경우만 반환
 * @param status
 * @param endDate
 * @returns {null|*|{total: moment.Duration, hours: number, seconds: number, minutes: number, days: number}|{total: moment.Duration, hours: number, seconds: number, minutes: number, days: number}}
 */
export function getLabelProjectRemainTime(status, endDate) {
  if (status === codeConst.STATUS['20'] || status === codeConst.STATUS['30'] || status === codeConst.STATUS['40']) {
    const diff = getDiffProjectRemain(endDate)
    if (diff) { // diff가 존재하는 경우;
      // console.log('diff : ' + JSON.stringify(diff, null, 4))
      if (diff.days === 0) { // 날짜가 0일 경우;
        return diff
      }
    }
  }
  return null
}

export function getLabelProjectStatus(status) {
  if (status === codeConst.STATUS['50']) {
    return this.$t('project.toStop')
  } else if (status === codeConst.STATUS['60']) {
    return this.$t('project.cancel')
  } else if (status === codeConst.STATUS['80']) {
    return this.$t('project.toEnd')
  } else if (status === codeConst.STATUS['10']) {
    return this.$t('project.projectStatusText')
  }
}

/**
 * 프로젝트 남은 시간에 대하여 타이머 설정 및 실행
 * @param item
 * @param fnTimerName
 * @param refName
 * @param speed
 */
Vue.prototype.timerProjectRemain = function(itemStatus, itemEndDate, fnTimerName, refName, speed) {
  /*
  console.log('timerProjectRemain() ----------')
  console.log('itemStatus : ' + itemStatus)
  console.log('itemEndDate : ' + itemEndDate)
  console.log('fnTimerName : ' + fnTimerName)
  console.log('refName : ' + refName)
  console.log('speed : ' + speed)
  window.clearTimerProjectRemain(fnTimerName)
   */
  if (window[refName]) {
    const a_ref = window.document.getElementsByClassName(refName)
    const remainTimes = getLabelProjectRemainTime(itemStatus, itemEndDate)
    const txt = ('0' + remainTimes.hours).slice(-2) + ':' + ('0' + remainTimes.minutes).slice(-2) + ':' + ('0' + remainTimes.seconds).slice(-2)
    let i = 0
    const iLen = a_ref.length
    let el_ref
    for (; i < iLen; ++i) {
      el_ref = a_ref[i]
      if (el_ref.getElementsByClassName('project-content').length) {
        const el = el_ref.getElementsByClassName('project-content')[0]
        el.innerText = txt
      } else {
        const el = window.document.createElement('div')
        el.classList.add('project-content')
        el.classList.add('countdown')
        el.innerText = txt
        el_ref.innerHTML = ''
        el_ref.appendChild(el)
      }
      window.clearTimerProjectRemain(fnTimerName)
      window.setTimerProjectRemain(fnTimerName, speed)
    }
  }
}

/**
 * 프로젝트 남은 시간에 대하여 타이머 해제
 * @param fnTimerName
 */
Vue.prototype.clearTimerProjectRemain = function(fnTimerName) {
  if (window[fnTimerName + 'Obj']) window.clearTimeout(window[fnTimerName + 'Obj'])
  delete window[fnTimerName + 'Obj']
}

/**
 * 프로젝트 남은 시간에 대하여 타이머 설정
 * @param fnTimerName
 */
Vue.prototype.setTimerProjectRemain = function(fnTimerName, speed) {
  window[fnTimerName + 'Obj'] = window.setTimeout(window[fnTimerName], speed)
}

export function priceNumCropByDecimalPointFormat(type, num) {
  let scale = -1
  switch (type) {
    case 'KRW':
      scale = 0
      break
    case 'USD':
      scale = 2
      break
    case 'CNY':
      scale = 2
      break
    case 'JPY':
      scale = 0
      break
    case 'EUR':
      scale = 2
      break
  }
  return Number((Math.ceil((num * 100).toFixed(2)) / 100).toFixed(scale).toString())
}

export function saleCurrencyPrice(crcyStd, price, percent) {
  return formatPrice(crcyStd, priceNumCropByDecimalPointFormat(crcyStd, price * percent))
}
