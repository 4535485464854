import React from 'react'
import styled from '@emotion/styled'
import { CommonCardImage } from '../commonCard/CommonCardImage'
import { CardTitleInfo } from '../commonCard/CardTitleInfo'

const ArtistCardContainer = styled.div({}, ({ ismobile }) => {
  
  const cardSize = {}

  if (ismobile) {
    cardSize.width = '127px'
    cardSize.height = '156px' // image 127 + title 29
  } else {
    cardSize.width = '208px'
    cardSize.height = '262px' // image 208 + title 54
  }
    
  const baseStyle = {
    ...cardSize,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    boxShadow: 'none',
    transition: 'box-shadow 0.5s',
    position: 'relative',
  }
  const mobileStyle = {
    marginBottom: '32px'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ArtistCardImageWrapper = styled.div({}, ({ ismobile }) => {

  const baseStyle = {
  }
  
  const mobileStyle = {
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ArtistCardInfoWrapper = styled.div({}, ({ ismobile }) => {

  const baseStyle = {
    width :'208px',
    height: '42px'
  }
  
  const mobileStyle = {
    width :'127px',
    height: '29px'
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function ArtistCard(props) {
  const { title, imageurl, clickcallback, ismobile } = props
  const size = {
    pc: {
      width: '208px',
      height: '208px'
    }, 
    mobile:{
      width: '127px',
      height: '127px'
    }
  }
  const isHoverNotiOn = true
  const radius = '12%'

  return (
    <ArtistCardContainer  onClick={()=>clickcallback(title)} ismobile={ismobile}>
      <ArtistCardImageWrapper>
        <CommonCardImage imageUrl={imageurl} size={size} hoverEffect={'enlarge'} borderRadius={radius} ismobile={ismobile}>
        </CommonCardImage>
      </ArtistCardImageWrapper>
      <ArtistCardInfoWrapper ismobile={ismobile}>
        <CardTitleInfo title={title} ismobile={ismobile}>
        </CardTitleInfo>
      </ArtistCardInfoWrapper>      
    </ArtistCardContainer>
  )
}

