import React from'react'
import styled from '@emotion/styled'

import InfoIcon from '../infoIcon'

const CommonSectionHeaderContainer = styled.div({}, ({ismobile}) => {
  const baseStyle = {
    margin: '0 0 16px'
  }

  const mobileStyle = {

  }

  return ismobile?
  {
    ...baseStyle,
    ...mobileStyle

  } : baseStyle
})

const TitleWrapper = styled.div({}, ({ismobile}) => {
  const baseStyle = {
    display: 'flex',
    justifyContent: 'space-between;'
  }
  const mobileStyle = {
  }
  return ismobile?
  {
    ...baseStyle,
    ...mobileStyle

  } : baseStyle
})

const Title = styled.div({}, ({headertype, ismobile}) => {

  const baseStyleByType = headertype === 'main' ? {
    fontSize: '20px !important',
    lineHeight: '28px'
  }
  : headertype === 'sub' ? {
    fontSize: '18px !important',
    lineHeight: '26px'
  }
  : headertype === 'sub24' ? {
    fontSize: '24px !important',
    lineHeight: '32px'
  } : {}

  const baseStyle = {
    ...baseStyleByType,
    fontWeight: '700',
    color: '#333333',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center'
  }

  const mobileStyleByType = headertype === 'main' ? {
    fontSize: '18px !important',
    lineHeight: '22px'
  }
  : {
    fontSize: '16px !important',
    lineHeight: '20px'
  }

  const mobileStyle = {
    ...mobileStyleByType
  }
  return ismobile?
  {
    ...baseStyle,
    ...mobileStyle

  } : baseStyle
})

const DescriptionWrapper = styled.div({}, ({ismobile}) => {
  const baseStyle = {
    paddingTop : '4px',
    fontSize: '15px',
    lineHeight: '20px',
    color: '#888',
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'space-between;'
  }

  const mobileStyle = {
    fontSize: '14px !important',
    fontWeight: '400'
  }

  return ismobile?
  {
    ...baseStyle,
    ...mobileStyle

  } : baseStyle
})

const IconTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
`

function SeeMore(props) {
  const {clickcallback, clickcallbackparam, stringseemore, ismobile} = props
  const expUrl = /^http[s]?:\/\/([\S]{3,})/i;

  const SeeMoreDiv = styled.div({}, ({ismobile}) => {
    const baseStyle = {
      cursor: 'pointer',
      fontSize: '14px !important',
      fontWeight: '400',
      color: '#888888',
    }
    const mobileStyle = {
      fontSize: '13px !important',
      paddingRight: '16px'
    }
    return ismobile?
    {
      ...baseStyle,
      ...mobileStyle

    } : baseStyle
  })

  const SeeMoreAtag = styled.a({}, ({ismobile}) => {
    const baseStyle = {
      color: '#555555 !important',
      textDecoration: 'none !important'

    }
    const mobileStyle = {
    }
    return ismobile?
    {
      ...baseStyle,
      ...mobileStyle

    } : baseStyle
  })

  if (stringseemore === undefined || clickcallback === undefined) {
    return []
  } else if (expUrl.test(clickcallback)) {
    return (
      <SeeMoreDiv ismobile={ismobile}>
        <SeeMoreAtag href={clickcallback}> {stringseemore + '  >'} </SeeMoreAtag>
      </SeeMoreDiv>
    )
  } else {
    return (
    <SeeMoreDiv onClick={() => clickcallback(clickcallbackparam)} ismobile={ismobile}>
      {stringseemore + '  >'}
    </SeeMoreDiv>
    )
  }
}

export function CommonSectionHeader(props) {
  const { title, description, headertype, stringseemore, clickcallback, clickcallbackparam, ismobile, tooltip = ""} = props

  return (
    <CommonSectionHeaderContainer ismobile={ismobile}>
      <TitleWrapper ismobile={ismobile}>
        <IconTitleWrapper>
          <Title headertype={headertype} ismobile={ismobile}>{title}</Title>
          {tooltip!=="" && <InfoIcon tooltip={tooltip} ismobile={ismobile}></InfoIcon>}
        </IconTitleWrapper>
        <SeeMore stringseemore={stringseemore} clickcallback={clickcallback} clickcallbackparam={clickcallbackparam} ismobile={ismobile}>
        </SeeMore>
      </TitleWrapper>
      <DescriptionWrapper ismobile={ismobile}> {description}
      </DescriptionWrapper>
    </CommonSectionHeaderContainer>
  )
}
