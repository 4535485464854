import React from 'react'
import styled from '@emotion/styled'
import { red, white } from '../../Foundation/Color'
import { C1Bold } from '../../Foundation/Typography'

const BadgeNewC1Bold = styled(C1Bold)`
  line-height: 1;
`

const BadgeNewBody = styled.div({}, ({}) => {
  const baseStyle = {
    position: 'absolute',
    display: 'flex',
    color: white,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: 'rgba(232, 53, 29, 0.7)',
    backgroundColor: red[50] + 'CC',
    borderRadius: '4px',
    boxSizing: 'border-box',
  }

  const pcStyle = {
    width: '36px',
    height: '20px',
    top: '8px',
    left: '8px',
  }

  const mobileStyle = {
    width: '36px',
    height: '18px',
    top: '8px',
    left: '8px',
  }

  return {
    ...baseStyle,
    ...mobileStyle
  }
})

export function BadgeNew() {
  return (
    <BadgeNewBody>
      <BadgeNewC1Bold>
        {'NEW'}
      </BadgeNewC1Bold>
    </BadgeNewBody>
  )
}
