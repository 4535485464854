import React from 'react'
import styled from '@emotion/styled'

const CardTitleDescWrapper = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    marginTop: ismobile === 'isMobile' ? '10px' : '50px'
  }
  const style = {

  }

  return style
})

const CardTitle = styled.div({}, ({ styleopt, ismobile }) => {

  const baseStyle = {
    marginLeft: "8px",
    marginRight: "8px",

    // .project-title
    position: 'relative',
    //display: block;
    minHeight: '18px',
    maxHeight: '38px',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '19px !important',
    color: '#333333',
    margin: '0 8px',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    overflowY: 'hidden',
  }
  const mobileStyle = {
    fontSize: '13px',
    lineHeight: '17px',
    minHeight: '19px',
    maxHheight: '36px'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const CardDescription = styled.div({}, ({ ismobile }) => {
  const style = {
    marginLeft: "8px",
    marginRight: "8px",

    // .project_description
    position: 'relative',
    //display: block;
    minHeight: '16px',
    maxHeight: '32px',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '16px !important',
    color: '#999999',
    marginTop: '4px',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    overflowY: 'hidden',
  }
  return style
})



export function CardTitleDescInfo(props) {
  const { title, description, styleopt, ismobile } = props
  const titlestyleopt = (styleopt === undefined) ? { } : {
    ...styleopt
  }
  
  return (description === undefined)? (
    <CardTitleDescWrapper ismobile={ismobile}>
      <CardTitle styleopt={titlestyleopt} ismobile={ismobile}> {title}
      </CardTitle>
    </CardTitleDescWrapper>
  ) : (
    <CardTitleDescWrapper ismobile={ismobile}>
    <CardTitle styleopt={titlestyleopt} ismobile={ismobile}> {title}
    </CardTitle>
    <CardDescription ismobile={ismobile}> {description}
    </CardDescription>
  </CardTitleDescWrapper>
  )
}
