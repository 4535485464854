/**
 * @deprecated
 * 모바일형 PC 웹사이트로 전환하면서 더 이상 Device의 너비로 PC/Mobile을 구분하지 않습니다.
 * 해당 스토어를 사용(조회)하는 부분이 있다면 가급적 삭제해주세요.
 */
export const state = () => ({
  waitingEntry: false, // PC or MOBILE
  device: '', // PC or MOBILE
  standardWidth: 1024 // 모바일 기준 사이즈
})

export const mutations = {
  /**
   * @deprecated
   */
  SET_DEVICE: (state, device) => {
    state.device = device
  },
  SET_WAITING_ENTRY: (state, waitingEntry) => {
    state.waitingEntry = waitingEntry
  }
}

export const actions = {
  /**
   * @deprecated
   */
  setDevice({ commit }, device) {
    // console.log('Current Device : ', device)
    commit('SET_DEVICE', device)
  },
  setWaitingEntry({ commit }, waitingEntry) {
    // console.log('Current Device : ', device)
    commit('SET_WAITING_ENTRY', waitingEntry)
  }
}

export const getters = {
  /**
   * @deprecated
   */
  getDevice: (state) => {
    return state.device
  },
  getWaitingEntry: (state) => {
    return state.waitingEntry
  }
}
