
import { mapGetters } from 'vuex'
import Vue from 'vue'
import moment from 'moment'
import { isNotEmpty, getGtmLoginInfo } from '~/utils/common'
// import MainHeader from '~/components/layouts/mainHeader'
// import MainFooter from '~/components/layouts/mainFooter'
// import SiteNoticeDialog from '~/components/layouts/siteNoticeDialog'
// import WaitingRoom from '~/components/layouts/waitingRoom'
import Enlargement from '@/react/Common/Enlargement'
import { applyReactInVue } from 'vuereact-combined'

export default {
  name: 'MobileDefaultLayout',
  directives: {},
  components: {
    REnlargement: applyReactInVue(Enlargement)
    // MainHeader,
    // MainFooter,
    // SiteNoticeDialog,
    // WaitingRoom
  },
  filters: {},
  data() {
    return {
      // app,
      isShowEnlargement: false,
      scrollTop: 0,
      ignoreNames: [
        'shop-list-category-cd',
        'shop-products',
        'shop-id',
        'faq'
      ],
      dialogNoticeList: []
    }
  },
  computed: {
    ...mapGetters([
      'rewardHtml',
      'loginInfo',
      'device',
      'standardWidth',
      'headerViewStatus',
      'layoutType'
    ]),
    isRightMenuBar() {
      return this.$store.getters.scrollY > 100
    },
    isLogin() {
      return this.$store.getters.loginInfo.loggedInUser
    },
    isShowEnlargementBtn() {
      let result = false
      const regex1 = /^\/shop\/PD\d+$/
      const regex2 = /^\/projects\/[a-zA-Z0-9_]+\/story$/

      if (regex1.test(this.$route.path)) {
        result = true
      }
      if (regex2.test(this.$route.path)) {
        result = true
      }
      return result
    }
  },
  watch: {
    scrollTop(newVal, oldVal) {
      this.$store.dispatch('modules/layout/setScrollY', newVal)

      if (newVal === 'GET_SCROLL_POSITION_ERROR' || oldVal === 'GET_SCROLL_POSITION_ERROR') {
        // 스크롤 가져오기 에러
        this.$store.dispatch('modules/layout/setHeaderViewStatus', true)
      } else if (newVal > oldVal && newVal > 100) {
        // 스크롤 내릴 때
        this.$store.dispatch('modules/layout/setHeaderViewStatus', false)
      } else {
        // 스크롤 올릴 때
        this.$store.dispatch('modules/layout/setHeaderViewStatus', true)
      }
    }
  },
  mounted() {
    if (!sessionStorage.getItem('isFirstLoad')) {
      sessionStorage.setItem('isFirstLoad', 'true')
    }
    this.registEventListener()
    window.addEventListener('beforeunload', this.handleRefresh)
    if (this.isLogin) {
      this.getCartTotal()
    }
  },
  async created() {
    // if (this.isNoWaiting) {
    await this.$store.dispatch('modules/layout/setHeaderViewStatus', true)
    await this.checkLogin()
    await this.getMainNotice()
    if (process.client) {
      // persistedState 로그인 정보 방어 코드
      // const res = await this.$makeStarApi.user.loginUserProfile()
      // const { loggedInUser } = res.resData || {}
      // if (!loggedInUser) {
      //   await this.$store.dispatch('modules/loginuserinfo/setLoginInfo', {})
      // }

      // this.setLoginDataLayer()
    }
  },
  // 화면 삭제시 이벤트 삭제
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollWatcher)
    window.removeEventListener('beforeunload', this.handleRefresh)
  },
  methods: {
    enlargementClose() {
      this.isShowEnlargement = false
    },
    handleEnlargement() {
      this.isShowEnlargement = true
    },
    setLoginDataLayer() {
      if (this.loginInfo.idx !== undefined) {
        this.$gtm.push({
          ...getGtmLoginInfo(this.loginInfo)
        })
      }
    },
    handleRefresh(event) {
      sessionStorage.setItem('isFirstLoad', '')
      sessionStorage.setItem('waitingEntry', '')
    },
    isNotEmpty,
    /**
     * 페이지 meta 값에 따른 풀사이즈 설정 여부
     */
    async checkLogin() {
      Vue.prototype.hiddenToast = true
      try {
        const res = await this.$makeStarApi.user.loginUserProfile()

        if (res.resCd === '1000') {
          if (res.resData.loggedInUser) {
            await this.$store.dispatch('modules/loginuserinfo/setLoginInfo', res.resData)
            return true
          } else {
            return false
          }
        }
      } catch {
        console.log('API 에러')
      } finally {
        Vue.prototype.hiddenToast = false
      }

      return false
    },

    async getMainNotice() {
      try {
        const res = await this.$makeStarApi.user.selectSiteNotice()
        this.dialogNoticeList = res.resData

        for (let i = 0; this.dialogNoticeList.length; i++) {
          const result = this.dialogNoticeList[i]
          const cookie = window.localStorage.getItem(result.cookieId)

          if (isNotEmpty(cookie)) {
            if (moment(cookie, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
              result.isShow = true
            }
          } else {
            result.isShow = true
          }
        }
      } catch {

      }
    },
    registEventListener() {
      if (process.browser && window) {
        // eslint-disable-next-line nuxt/no-globals-in-created
        window.addEventListener('scroll', this.scrollWatcher)
      }
    },
    /**
     * 스크롤 감지, 헤더 변경
     */
    scrollWatcher() {
      if (process.browser) {
        try {
          this.$nextTick(() => {
            const scrollTop = Math.floor(window.pageYOffset || window.scrollY)
            if (Number.isInteger(scrollTop)) {
              this.scrollTop = scrollTop
            } else {
              this.scrollTop = 'GET_SCROLL_POSITION_ERROR'
            }
          })
        } catch (e) {
          this.scrollTop = 'GET_SCROLL_POSITION_ERROR'
        }
      }
    },
    handleScrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    handleCloseDialog(index) {
      this.dialogNoticeList[index].isShow = false
      this.$forceUpdate()
    },
    async getCartTotal() {
      try {
        const res = await this.$makeStarApi.shopping.getShoppingTotal()
        if (res.resCd === '1000') {
          this.$store.dispatch('modules/user/setCartAmount', res.resData)
        }
      } catch (error) {

      }
    }
  }
}
