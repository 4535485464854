import React from 'react'
import { CardUpdate } from '~/react/Common/Card/Update/CardUpdate'
import { DraggableScroller } from '~/react/Common/Scroll/TouchDraggableScroller'

const style = {
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '16px ',
  overflowY: 'hidden',
  scrollBarWidth: 'none',
  whiteSpace: 'wrap'
}

export function UpdateListHorizontal({list = [], handleRouting, size, $i18n}) {

  return (
    <DraggableScroller style={style}>
      {list.map((item, index) => (
        <CardUpdate
          key={'react-card-update' + index}
          title={item.title.length ? item.title.split('::')[1] : ''}
          description={item.title.length ? item.title.split('::')[0] : ''}
          timefromnowtext={$i18n.t(item.formattedDate.suffixI18n, {val: item.formattedDate.formatted})}
          imageurl={item.imageUrl}
          iconnew={item.iconnew}
          size={size}
          ismobile={true}
          clickcallbackparam={['UPDATE', item.projectId, item.orderNo]}
          clickcallback={handleRouting}
        />
      ))}
    </DraggableScroller>
  )
}
