import Vue from 'vue'

import moment from 'moment'
import { isEmpty } from '~/utils/common'

export default ({ app }) => {
  /**
   * 10000 => "10,000"
   * @param {number} num
   */
  Vue.filter('toThousandFilter', (num) => {
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
  })

  // 날짜 포멧팅
  Vue.filter('formatDate', ({ date, divider }) => {
    return `${date.substr(0, 4)}${divider}${date.substr(4, 2)}${divider}${date.substr(6, 2)}`
  })

  // 가격요소에 콤마 추가
  Vue.filter('addComma', (str) => {
    const reg = /^[0-9]+$/
    if (reg.test(str)) {
      const regexp = /\B(?=(\d{3})+(?!\d))/g
      return str.toString().replace(regexp, ',')
    } else {
      return str
    }
  })

  Vue.filter('toFormat', ({ date, format }) => {
    if (isEmpty(date)) {
      return ''
    }

    const localText = moment.utc(date).local().format(format)

    return localText
  })

  Vue.filter('toAfterDate', (obj) => {
    const status = obj.status
    const localTime = moment.utc(obj.date).local()

    const currentTime = moment()

    // const ms = currentTime.diff(localTime)
    // const d = moment.duration(ms)

    const year = currentTime.year()
    const month = currentTime.month()
    const date = currentTime.date()
    const hours = currentTime.hour()
    const minute = currentTime.minute()

    const _year = localTime.year()
    const _month = localTime.month()
    const _date = localTime.date()
    const _hours = localTime.hour()
    const _minute = localTime.minute()

    if (year - _year > 0) {
      return app.i18n.t('filters.fewYearsAgo', { val: year - _year, val2: status })
    } else if (month - _month > 0) {
      return app.i18n.t('filters.fewMonthAgo', { val: month - _month, val2: status })
    } else if (date - _date > 0) {
      return app.i18n.t('filters.fewDayAgo', { val: date - _date, val2: status })
    } else if (hours - _hours > 0) {
      return app.i18n.t('filters.fewHoursAgo', { val: hours - _hours, val2: status })
    } else if (minute - _minute > 0) {
      return app.i18n.t('filters.fewMinutesAgo', { val: minute - _minute, val2: status })
    } else {
      return 1 + app.i18n.t('filters.fewMinutesAgo', { val2: status })
    }
  })

  Vue.filter('applyTextFilter', ({ key, options }) => {
    const textValue = options.reduce((acc, cur) => {
      acc[cur.key] = cur.name
      return acc
    }, {})
    return textValue[key]
  })

  Vue.filter('filterStartDt', (date) => {
    if (date !== undefined && date !== null) {
      const allDateArr = date.split(' ')
      const dateArr = allDateArr[0].split('-')
      const timeArr = allDateArr[1].split(':')
      const month = dateArr[1]
      const day = dateArr[2]
      const hour = Number(timeArr[0]) <= 12 ? `오전 ${timeArr[0]}` : `오후 ${Number(timeArr[0]) - 12}`

      return `${month}월 ${day}일<br>${hour}시 방송예정`
    }
  })

  Vue.filter('toKrFormat', ({ date, format }) => {
    if (isEmpty(date)) {
      return ''
    }

    const localText = moment(date).format(format)

    return localText
  })

  Vue.filter('filterRemindTime', (date) => {
    const date_m = moment.utc(date).local()
    const cur_m = moment()

    let ms = date_m.diff(cur_m)
    let d = moment.duration(ms)

    const day = d.asDays()
    const hours = d.asHours()
    const minute = d.asMinutes()

    if (Math.floor(day) > 0) {
      const date_m_day = date_m.startOf('day')
      const cur_m_day = cur_m.startOf('day')
      ms = date_m_day.diff(cur_m_day)
      d = moment.duration(ms)

      return 'D-' + Math.floor(d.asDays())
    } else if (Math.floor(hours) > 0) {
      return Math.floor(hours) + app.i18n.t('project.hours')
    } else if (Math.floor(minute) > 0) {
      return Math.floor(minute) + app.i18n.t('project.minute')
    }

    return 1 + app.i18n.t('project.minute')
  })
}
