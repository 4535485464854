/** MSA 서버로 직접 요청하는 경우 Base URL 사용 */
const baseUrl = process.env.API_SERVER_URL

export default function(request) {
  return {
    selectCommentList(event, id, data) {
      const url = baseUrl + '/board/v1/' + event + '/' + id + '/comment'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    insertComment(event, id, data) {
      const url = '/board/v1/' + event + '/' + id + '/comment'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    insertUpdateComment(id, orderNo, data) {
      const url = '/board/v1/project/' + id + '/update/' + orderNo + '/comment'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    updateComment(event, id, commentId, data) {
      const url = '/board/v1/' + event + '/' + id + '/comment/' + commentId
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    updateUpdateComment(projectId, orderNo, commentIdx, data) {
      const url = '/board/v1/project/' + projectId + '/update/' + orderNo + '/comment/' + commentIdx
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    updateLike(event, id, commentId) {
      const url = '/board/v1/' + event + '/' + id + '/comment/' + commentId + '/like'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, {}, config)
    },
    updateUpdateLike(projectId, orderNo, commentIdx) {
      const url = '/board/v1/project/' + projectId + '/update/' + orderNo + '/comment/' + commentIdx + '/like'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, {}, config)
    },
    deleteComment(projectType, projectId, commentId) {
      const url = '/board/v1/' + projectType + '/' + projectId + '/comment/' + commentId + '/delete'

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, {}, config)
    },
    deleteUpdateComment(projectId, orderNo, commentIdx) {
      const url = '/board/v1/project/' + projectId + '/update/' + orderNo + '/comment/' + commentIdx + '/delete'

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, {}, config)
    },
    uploadImage(data) {
      const url = '/board/v1/upload'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    reportComment(projectType, projectId, commentId, data) {
      const url = '/board/v1/' + projectType + '/' + projectId + '/comment/' + commentId + '/report'

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    reportUpdateComment(projectId, orderNo, commentIdx, data) {
      const url = '/board/v1/project/' + projectId + '/update/' + orderNo + '/comment/' + commentIdx + '/report'

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    // 메인 페이지 투표, 이벤트
    mainProjectEctList(data) {
      const url = baseUrl + `/board/v1/main`

      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    insertQna(data) {
      const url = '/board/v1/qna'

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    insertQnaV2(data) {
      const url = '/board/v2/qna'

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    searchVoteList(data) {
      const url = '/board/v1/poll/search'

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    searchOnlyArtistByKeywordVoteList(data) {
      const url = baseUrl + '/board/v1/poll/searchOnlyArtistByKeyword'

      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    searchEventList(data) {
      const url = '/board/v1/event/search'

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    selectUpdateCommentList(data, projectId, orderNo) {
      const url = baseUrl + '/board/v1/project/' + projectId + '/update/' + orderNo + '/comment'

      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    insertPartnerShip(data) {
      const url = '/board/v1/partner'

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    // 1:1 문의 리스트
    oneToOneQnaList(data) {
      const url = baseUrl + '/board/v1/qna'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    // 1:1 문의 상세
    oneToOneQnaDetail(idx) {
      const url = baseUrl + '/board/v1/qna/' + idx
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    // 1:1 문의 댓글 목록
    oneToOneQnaCommentList(idx) {
      const url = baseUrl + '/board/v1/qna/' + idx + '/comment'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    // 1:1 문의 댓글 완료
    oneToOneQnaComplete(idx) {
      const url = '/board/v1/qna/' + idx + '/complete'
      const data = {
      }

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    oneToOneQnaComment(idx, data) {
      const url = '/board/v1/qna/' + idx + '/comment'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    gayoTop10Content() {
      const url = baseUrl + '/board/v1/topten/search'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    getRecentOrderHistory() {
      const url = baseUrl + '/board/v1/qna/recent/order/history'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    }
  }
}
