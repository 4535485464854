import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { B1Bold, B2Bold, B2Medium, C1Medium } from '~/react/Foundation/Typography'
import { gray, red } from '~/react/Foundation/Color'
import { getCurrencyCharacter, formatCurrencyPrice, calculationSalePercent } from '~/utils/project'

const CardShopPriceContainer = styled.div({}, () => {
  return {
    display: 'flex',
    gap: '4px'
  }
})

const PriceWrap = styled.div`
  display: flex;
  gap: 1px;
`

const PriceTextPC = styled(B1Bold)`
  color: ${gray[90]};
`
const PriceTextMobile = styled(B2Bold)`
  color: ${gray[90]};
`

const DiscountTextPC = styled(B2Medium)`
  color: ${red[60]};
  margin-top: 2px;
`
const DiscountTextMobile = styled(C1Medium)`
  color: ${red[60]};
  margin-top: 2px;
`

const MAX_SALE_PRICE = 999999999999999;

/**
 * @typedef CardShopPriceProps
 * @property {number} price 기준 가격
 * @property {number} salePrice 할인 가격
 * @property {string} currency 화폐 단위
 * @property {string} dcDiv 할인 구분
 * @property {number} dcRatio 할인 비율
 * @property {number} dcFee 할인 금액
 * @property {boolean} isDiscount 할인 여부
 * @property {boolean} ismobile
 */

/**
 *
 * @param {CardShopPriceProps} props
 * @returns {JSX.Element}
 */
export function CardShopPrice(props) {
  const {
    dcRatio,
    price,
    salePrice,
    currency,
    dcDiv,
    dcFee,
    isDiscount
  } = props

  const [localizedCurrency, setLocalizedCurrency] = useState('')
  const [formattedPrice, setPrice] = useState('')

  const [discountRatio, setDiscountRatio] = useState(0)

  useEffect(() => {
    const langCurrency = getCurrencyCharacter(currency)
    setLocalizedCurrency(langCurrency)

    if (salePrice < MAX_SALE_PRICE) {
      const priceText = formatCurrencyPrice(salePrice, currency)
      setPrice(priceText)
    }

    if (isDiscount) {
      const dcRatioNumber = calcDiscountRatio(dcDiv, price, dcFee, dcRatio)
      setDiscountRatio(dcRatioNumber)
    }
  }, [dcRatio, price, salePrice, currency, dcDiv, dcFee])

  const calcDiscountRatio = (dcDiv, price, dcFee, dcRatio) => {
    try {
      if (dcDiv === 'P') {
        return calculationSalePercent(price, dcFee)
      }
      return Math.floor(dcRatio)
    } catch (e) {
      return 0
    }
  }

  const renderPrice = () => {
    return (
      <PriceWrap>
        <PriceTextMobile>{localizedCurrency}</PriceTextMobile>
        <PriceTextMobile>{formattedPrice}</PriceTextMobile>
      </PriceWrap>
    )
  }

  const renderDiscountText = () => {
    return <DiscountTextMobile>{`${discountRatio}%`}</DiscountTextMobile>
  }

  return (
    <CardShopPriceContainer>
      {renderPrice()}
      {(isDiscount && discountRatio >= 1) && renderDiscountText()}
    </CardShopPriceContainer>
  )
}
