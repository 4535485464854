import React from 'react'
import styled from '@emotion/styled'

const Description = styled.div({
  fontSize: '14px',
  marginTop: '8px',
  color: '#232323'
})
export function InputDescription({ value }) {
  return (
    <Description dangerouslySetInnerHTML={ { __html: value } }/>
  )
}
