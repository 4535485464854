// @ts-check

/** MSA 서버로 직접 요청하는 경우 Base URL 사용 */
const baseUrl = process.env.API_SERVER_URL

export default function(request) {
  return {
    fetchShoppingHomData(data) {
      const url = baseUrl + '/shopping/v1/home'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    getShoppingEachData(data) {
      const url = '/shopping/v1/product/dtl'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const params = data
      return request.$post(url, params, config)
    },

    /**
     * @typedef {object} ExclusiveEvent
     * @property {string} exclusiveEventId
     * @property {string} exclusiveEventName
     */

    /**
     * 쇼핑 이벤트 팝업 정보를 조회합니다.
     * @param {object} data
     * @returns {Promise<{resCd: string, resMsg: string, resData: ExclusiveEvent}>}
     */
    getShoppingEventPopup(data) {
      const { productCode, langCode } = data
      const url = baseUrl + '/shopping/v1/product/event/popup'
      const headers = {
        'Content-Type': 'application/json'
      }
      const params = {
        productCode,
        lang: langCode
      }
      return request.$get(url, { params, headers })
    },

    getShoppingDataByBrand(data) {
      const url = baseUrl + '/shopping/v1/brand'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    getCategoryDataByBrand(data) {
      const url = baseUrl + '/shopping/v1/brand/category'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    getExhibitionList(data) {
      const url = '/shopping/v1/pe/list'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const params = data
      return request.$post(url, params, config)
    },
    putItemToCart(data) {
      const url = '/shopping/v1/cart'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const params = data
      return request.$post(url, params, config)
    },
    handleCartItemAmount(data) {
      const url = '/shopping/v1/cart'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const params = data
      return request.$put(url, params, config)
    },
    getCartItem(langCd) {
      const url = baseUrl + '/shopping/v1/cart/list/' + langCd
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      return request.$get(url, config)
    },
    deleteCartItem(seqNo) {
      const url = '/shopping/v1/cart/' + seqNo
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      return request.$delete(url, config)
    },
    deleteCartItemMultiSelect(data) {
      const url = '/shopping/v1/cart/deleteMultiSelect'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    getliveShoppingList(data) {
      const url = '/shopping/v1/live/list'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const params = data
      return request.$post(url, params, config)
    },
    getShoppingList(data) {
      const url = '/shopping/v1/product/list'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const params = data
      return request.$post(url, params, config)
    },
    // 인기 상품 리스트;
    getShoppingPopularList(data) {
      const url = '/shopping/v1/product/popularlist'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const params = data
      return request.$post(url, params, config)
    },
    // 추천 상품 리스트;
    getShoppingRecomList(data) {
      const url = '/shopping/v1/product/recomlist'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const params = data
      return request.$post(url, params, config)
    },
    getShoppingTotal() {
      const url = baseUrl + '/shopping/v1/cart/cnt'
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      return request.$get(url, config)
    },
    getExhibitionDetail(data) {
      const url = '/shopping/v1/pe/dtl'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const params = data
      return request.$post(url, params, config)
    },
    searchOnlyArtistByKeyword(data) {
      const url = baseUrl + '/shopping/v1/searchOnlyArtistByKeyword'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    search(data) {
      const url = '/shopping/v1/search'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const params = data
      return request.$post(url, params, config)
    },
    searchB2B(data) {
      const url = '/shopping/v1/b2b/search'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const params = data
      return request.$post(url, params, config)
    },
    searchByKeyword(data) {
      const url = '/shopping/v1/searchByKeyword'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const params = data
      return request.$post(url, params, config)
    },
    searchB2BByKeyword(data) {
      const url = '/shopping/v1/b2b/searchByKeyword'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const params = data
      return request.$post(url, params, config)
    },
    getMessengerList(projectIdx = '') {
      const url = baseUrl + '/shopping/v1/messenger/msg-list' + (projectIdx && `/${projectIdx}`)
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },

    /**
     * 동일 신분증 구매 횟수를 체크하여 구매 가능 여부를 가져옵니다.
     * @param {productCode: string, taxID: string} data
     * @returns {Promise<any>}
     */
    checkShoppingPurchaseLimitWithID(data) {
      const { productCode, taxID } = data
      const url = baseUrl + `/shopping/v1/product/repretative/check`
      const headers = {
        'Content-Type': 'application/json'
      }
      const params = {
        pdCd: productCode,
        taxId: taxID
      }
      return request.$get(url, { params, headers })
    },

    /**
     * 배송지가 중국인 경우, 중국 전용 도/시 데이터를 조회합니다.
     * @returns {Promise<any>}
     */
    getChinaProvinceData(data = {}) {
      const { logisCd } = data
      const url = baseUrl + `/shopping/v1/address/cn/province-municipality`
      const headers = {
        'Content-Type': 'application/json'
      }
      const params = logisCd ? { logisCd } : undefined
      return request.$get(url, { params, headers })
    },

    /**
     * 국가 코드를 기준으로 해당 국가의 주/도 데이터를 조회합니다.
     * @param countryCode
     * @returns {Promise<any>}
     */
    getCountryStateData(countryCode) {
      const url = baseUrl + `/shopping/v1/address/country/state`
      const params = { countryCd: countryCode }
      const headers = {
        'Content-Type': 'application/json'
      }
      return request.$get(url, { headers, params })
    }
  }
}
