import React from'react'
import styled from '@emotion/styled'

const CategoryHeaderContainer = styled.div({}, ({ismobile}) => {
  const pcStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '24px'
  }

  const mobileStyle = {
    borderTop: '1px solid #DEDEDE',
    borderBottom: '1px solid #DEDEDE',
    paddingTop: '16.5px',
    paddingBottom: '32px',
    paddingLeft: '20px',
  }

  return ismobile? mobileStyle : pcStyle
})
const CategoryHeaderScrollWindow = styled.div({
  position: 'relative',
  width: '100%',
  height: '38px',
  
  overflowX : 'scroll',
  overFlowY : 'hidden',
  '-ms-overflow-style': 'none', /* IE, Edge */
  'scrollbar-width': 'none', /* Firefox */
  '&::-webkit-scrollbar' : {
    display: 'none' /* Chrome, Safari, Opera */
  }
}, ()=> {

})
const CategoryHeaderScrollInnerWindow = styled.div({},() => {
  const mobileStyle = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  }
  return mobileStyle
}) 

const CategoryHeaderScrollFadeOut = styled.div({},()=>{
  const mobileStyle = {
    position: 'absolute',
    left: '80%',
    width: '20%',
    height: '30px',
    backgroundImage:'linear-gradient(270deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))'
  }
  return mobileStyle
} )

const CateogryWrapper = styled.div({}, ({ismobile}) => {
  const baseStyle = {
    position: 'relative',
    cursor: 'pointer',
    color: '#333333',

    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '18px',
    opacity: '0.5',

    // borderRight: '1px solid #DEDEDE', // Category Delimeter
    paddingRight: '10px',
    marginRight: '10px'
  }
  const mobileStyle = {
    borderRight: 'unset',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '16px',
    height: '16px',
    whiteSpace: 'nowrap', // Category �̸��� �ٹٲ� ����
    opacity: '1'
  }
  return ismobile? 
  {
    ...baseStyle,
    ...mobileStyle

  } : baseStyle
})

/** @deprecated LNB에서만 사용할 것은 따로 구현되어 있음 */
export function CategoryHeader(props) {
  const { ctgrlist, underctgr, clickcallback, ismobile } = props

  if (ctgrlist.length < 1) {
    return null
  } else {

    const categoryList = []
    for (let i = 0; i < ctgrlist.length; i++ ) {
      if (ctgrlist[i].code === underctgr) {
        if (ismobile) {
          const item = (
            <CateogryWrapper onClick={()=>clickcallback(ctgrlist[i].code)} style={{color: '#E8351D'}} ismobile={ismobile}>
              {ctgrlist[i].displayName}
            </CateogryWrapper>          
          )
          categoryList.push(item)
        } else {
          const item = (
            <CateogryWrapper onClick={()=>clickcallback(ctgrlist[i].code)} style={{opacity: '1', borderRight: ctgrlist.length - 1 > i ? '1px solid #DEDEDE' : 'unset'}} ismobile={ismobile}>
              {ctgrlist[i].displayName}
            </CateogryWrapper>          
          )
          categoryList.push(item)
        }
      } else {
        const item = (
          <CateogryWrapper onClick={()=>clickcallback(ctgrlist[i].code)} style={{borderRight: ctgrlist.length - 1 > i ? '1px solid #DEDEDE' : 'unset'}}ismobile={ismobile}>
            {ctgrlist[i].displayName}
          </CateogryWrapper>          
        )
        categoryList.push(item)
      }
    }
    return ismobile? (
      <CategoryHeaderContainer ismobile={ismobile}>
        <div style={{position: 'absolute', width: '100%', height: '38px'}}>
          <CategoryHeaderScrollWindow>
            <CategoryHeaderScrollInnerWindow>
              {categoryList}
              <CateogryWrapper style={{paddingRight: '20px', borderRight: 'unset'}}></CateogryWrapper>
            </CategoryHeaderScrollInnerWindow>
          </CategoryHeaderScrollWindow>
        </div>
        {/* <CategoryHeaderScrollFadeOut>
        </CategoryHeaderScrollFadeOut> */}
      </CategoryHeaderContainer>
    ) : (
      <CategoryHeaderContainer ismobile={ismobile}>
        {categoryList}
      </CategoryHeaderContainer>
    )
  }
}