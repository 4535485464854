/** MSA 서버로 직접 요청하는 경우 Base URL 사용 */
const baseUrl = process.env.API_SERVER_URL

export default function(request) {
  return {
    // 메인 페이지 인기, 추천, 진행중 프로젝트
    mainProjectList(data) {
      const url = baseUrl + '/project/v1/main'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },

    // 메인 페이지 프로젝트 업데이트
    mainProjectUpdateList(data) {
      const url = baseUrl + `/project/v1/update`
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    }

  }
}
