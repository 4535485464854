
import { mapGetters } from 'vuex'
import { MainFooter } from '../../react/footer/MainFooter.jsx'
import { applyReactInVue } from 'vuereact-combined'

// const app = null

export default {
  name: 'MainFooter',
  directives: {},
  components: {
    RMainFooter: applyReactInVue(MainFooter)
  },
  filters: {},
  props: { forceDevice: null },
  data() {
    return {
      // app,
      langcode: this.$i18n.locale,
      logo: require('~/static/image/logo.svg'),
      footerNavData: null,
      footerButtonData: null,
      menuList: [
        { i18n: 'footer.noticeList', linkType: 'ROUTE', link: '/notice' },
        { i18n: 'footer.faq', linkType: 'ROUTE', link: '/faq' },
        { i18n: 'footer.terms', linkType: 'ROUTE', link: '/about/terms' },
        { i18n: 'footer.privacy', linkType: 'ROUTE', link: '/about/privacy' },
        { i18n: 'footer.about', linkType: 'NEW_PAGE', link: 'http://www.makestarcorp.com' },
        { i18n: 'footer.recruit', linkType: 'NEW_PAGE', link: 'https://makestarrecruit.oopy.io' }
      ],
      menuListJa: [
        { i18n: 'footer.noticeList', linkType: 'ROUTE', link: '/notice' },
        { i18n: 'footer.faq', linkType: 'ROUTE', link: '/faq' },
        { i18n: 'footer.terms', linkType: 'ROUTE', link: '/about/terms' },
        { i18n: 'footer.privacy', linkType: 'ROUTE', link: '/about/privacy' },
        { i18n: 'specificCommercialLaw.title', linkType: 'ROUTE', link: '/specificCommercialLaw' },
        { i18n: 'footer.about', linkType: 'NEW_PAGE', link: 'http://www.makestarcorp.com' },
        { i18n: 'footer.recruit', linkType: 'NEW_PAGE', link: 'https://makestarrecruit.oopy.io' }
      ],
      selectLangClick: false,
      inqueryList: [
        { i18n: 'footer.inquery', routerPath: '/qna', keyId: 'footer.inquery2' },
        { i18n: 'footer.partner', routerPath: '/partnership', keyId: 'footer.partner3' }
      ],
      mobileBsnsView: false,
      langText: {
        ko: 'common.ko',
        en: 'common.en',
        zh: 'common.zh',
        ja: 'common.ja',
        es: 'common.es'
      }
    }
  },
  computed: {
    ...mapGetters([
      'device',
      'loginInfo'
    ]),
    isMobile() {
      return this.$store.getters.device === 'MOBILE'
    },
    footerMenuList() {
      return this.getNavMenuBar()
    },
    footerButtonList() {
      return this.getInqueryButton()
    }

  },
  mounted() {
  },
  created() {
    this.footerNavData = this.getNavMenuBar()
    this.footerButtonData = this.getInqueryButton()
  },
  destroyed() {
  },
  methods: {
    handleSelectLang(key) {
      // this.$router.replace({ query: updatedQuery }).then((res) => {
      //   const { fullPath } = res
      //   this.$i18n.setLocale(key)
      //   window.location.href = fullPath
      // })
      const query = Object.assign({}, this.$route.query)
      query.lang = key

      const { href } = this.$router.resolve({ query })
      window.location.href = href
    },
    getLangCode() {
      return this.langText[this.$i18n.locale] ?? ''
    },
    getNavMenuBar() {
      if (this.$i18n.locale === 'ja') {
        return this.menuListJa
      } else {
        return this.menuList
      }
    },
    getInqueryButton() {
      return this.inqueryList
    },
    pageMoveByRoutePath(routePath, routeType) {
      if (routeType === 'ROUTE') {
        this.$router?.push({ path: routePath })
      }
      if (routeType === 'NEW_PAGE') {
        window.open(routePath)
      }
    }
  }
}
