import { useState, useEffect } from 'react';

/**
 * @typedef useDropdown
 * @property {boolean} isListVisible
 * @property {key: string, value: string} selectedItem
 * @property {() => void} toggleDropdown
 * @property {() => void} closeDropdown
 * @property {(key: string) => void} onClickItem
 */

/**
 * Dropdown Hook
 * @param {{key: string, value: string}[]} itemList 드롭다운 리스트 목록
 * @param {string} selectedItemKey 선택한 드롭다운 아이템의 key
 * @param {(key: string) => void} onSelectItem 드롭다운 선택 시 실행할 function
 * @returns {{useDropdown}}
 */
export function useDropdown(itemList, selectedItemKey, onSelectItem, isDisabled) {
  const [isListVisible, setIsListVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState({
    key: '',
    value: ''
  })

  useEffect(() => {
    if (selectedItemKey !== undefined) {
      const selected = itemList.find((item) => item.key === selectedItemKey)
      if (selected)  setSelectedItem(selected)
    }
  }, [itemList, selectedItemKey])

  const toggleDropdown = () => {
    if (isDisabled) return
    setIsListVisible(!isListVisible)
  }

  const closeDropdown = () => {
    setIsListVisible(false)
  }

  const onClickItem = (key) => {
    onSelectItem(key)
    setIsListVisible(false)
  }

  return {
    isListVisible,
    selectedItem,
    toggleDropdown,
    closeDropdown,
    onClickItem
  }
}
