import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Chips } from '~/react'
import { H5Bold } from '~/react/Foundation/Typography'
import { log } from '~/communicator/utils/logging'
import { InputText } from '~/react'
import { gray } from '~/react/Foundation/Color'

const MakestarLogo = styled.img`
  height: 24px;
  margin-bottom: 32px;
  cursor: pointer;
  color: ${gray[70]};
`;

const TitleContainer = styled.div`
  margin-bottom: 16px;
  max-width: 368px;
`;

const Title = styled(H5Bold)`
  white-space: pre-line;
`

const SearchBarContainer = styled.div`
  width: ${({b2b})=>b2b ? 336 : 368}px;
  height: 44px;
  margin-bottom: 32px;
`;

const ArtistsFlexbox = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  flex-wrap: wrap;
  max-height: 126px;
  overflow: hidden;
`;

export function WebsiteMainContent({ $makeStarApi, $i18n, navigateTo, closeHamburgerMenu, b2b }) {

  const [artists, setArtists] = useState([])

  useEffect(async() => {
    try {
      const params = {
        langCd: $i18n.locale
      }
      const { resData } = await $makeStarApi.artist.fetchArtistTags(params)
      setArtists(resData.brandSearchTags)
    } catch (e) {
      log('error', e)
    }
  }, [$i18n])

  const onSearchBarKeyDown = (event) => {
    if (event.keyCode === 13) {
      // 엔터 키 event인 경우 검색결과로 이동
      navigateTo(`/search?keyword=${event.target.value}&search_at=es`)
    }
  }

  const onClickArtistTag = (artist) => {
    navigateTo(`/artists/${artist.brandIdx}/all`)

    // MAK-1401
    // NOTE: Mobile 햄버거바 메뉴에 대한 상태 관리가 전역에서 되고 있기 때문에, Mobile 외부에서 조작이 필요하다면 강제로 닫아줘야 함
    closeHamburgerMenu()
  }

  const home = () => {
    navigateTo(`/`)
    closeHamburgerMenu()
  }

  return (
    <>
      <MakestarLogo src={'/image/MAKESTAR_logo.svg'} onClick={home} />
      <TitleContainer>
        <Title>{$i18n.t('artist.artistSearchbarTitle')}</Title>
      </TitleContainer>
      <SearchBarContainer b2b={b2b}>
        <InputText size={'large'} placeholder={$i18n.t('artist.artistSearchbarPlaceholder')} onKeyDown={onSearchBarKeyDown} />
      </SearchBarContainer>
      {artists.length > 0 && (
        <ArtistsFlexbox>
          {artists.map((artist) =>
            <Chips
              key={artist.brandIdx}
              caption={artist.localizedName}
              size={'small'}
              clickcallback={() => onClickArtistTag(artist)}
              textAttributes={{
                'data-tagging-id': 'search_badge',
                'data-tagging-name': artist.localizedName
              }}
            />
          )}
        </ArtistsFlexbox>
      )}
    </>
  )
}
