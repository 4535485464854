import React from 'react'
import styled from '@emotion/styled';
const InnerComponent = styled.span(
  {
    // display:'flex',
    width: 'fit-content',
    color: '#e8351d',
    fontSize: '14px',

    fontFamily: '"Noto Sans","Noto Sans KR",sans-serif',
  },
  (props) => ({
    fontWeight: props?.bold || 400,
  })
);

export function Required(props) {
  const { bold, children } = props;
  return <InnerComponent bold={bold}>{children}</InnerComponent>;
}
