import React from 'react'
import { B1Medium, B2Medium, C1Medium, H7Medium } from '~/react/Foundation/Typography'
import { gray } from '~/react/Foundation/Color'

/**
 * @typedef ButtonCaptionProps
 * @property {'large' | 'medium' | 'small' | 'fullWidth'} size 버튼 사이즈
 * @property {any} children
 */
/**
 * @param {ButtonCaptionProps} param0
 */
export function ButtonCaption ({ size, type, isDisabled, children }){
  const style = (type === 'outlined' && isDisabled) ? { color: gray[50] } : {};

  switch(size){
    case 'large': return <B1Medium style={style}>{children}</B1Medium>
    case 'medium': return <B2Medium style={style}>{children}</B2Medium>
    case 'small': return <C1Medium style={style}>{children}</C1Medium>
    case 'fullWidth': return <H7Medium style={style}>{children}</H7Medium>
    default: return <B2Medium>{children}</B2Medium>
  }
}
