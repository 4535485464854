import React from 'react';
import styled from '@emotion/styled'
import { B2Medium } from '../../Foundation/Typography';
import { red, gray } from '../../Foundation/Color'

const Footer = styled.div({}, () => ({
  display:'flex',
  flexDirection: 'column',
  gap: '16px 0',
  padding: '16px 24px 40px',
  backgroundColor: gray[20],
}))
const LanguageContainer = styled.div({}, () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

const LanguageText = styled(B2Medium)({}, ({ active }) => ({
  color: active ? red[50] : gray[80],
  cursor: 'pointer'
}))

const LogoutText = styled(B2Medium)({}, () => {
  return {
    cursor: 'pointer',
    color: gray[80]
  }
})

/**
 * @typedef GNBFullSizeMenuFooterProps
 * @property {boolean} ismobile
 * @property {boolean} islogin
 * @property {(path: string) => void} handlelogout 로그아웃핸들러
 * @property {(text: string) => string} i18n 다국어 메서드
 * @property {string} logouttext 로그아웃 다국어 텍스트
 * @property {string} selectedlang 현재 선택된 언어 코드
 * @property {{i18n: string, key: string}[]} selectablelanglist 선택가능한 언어 리스트
 * @property {(langKey: string) => void} handlechangelang 언어 변경 callback
 * @property {() => void} handleMenuClose 로그아웃시 메뉴 클로즈
 */

/**
 * Mobile일 때만 나오는 Fullsize menu의 제일 하단
 * @param {GNBFullSizeMenuFooterProps} props
 * @returns
 */
export function GNBFullSizeMenuFooter ({ismobile, islogin, handlelogout, i18n, logouttext, selectedlang, selectablelanglist, handlechangelang, style,handleMenuClose,...props }){
  const makeLanguageMenu = selectablelanglist.map((item) => {
    return (
      <LanguageText
        key={`lang-${item.key}`}
        ismobile={ismobile}
        active={item.key === selectedlang}
        onClick={() => handlechangelang(item.key, 'hm')}
      >
        { (i18n && i18n(item.i18n)) ?? item.i18n }
      </LanguageText>
    )
  })

  const logout = () => {
    handleMenuClose();
    handlelogout();
  }
  return (
    <Footer style={style}>
      <LanguageContainer {...props} >
        {makeLanguageMenu}
      </LanguageContainer>
      { islogin && <LogoutText ismobile={ismobile} onClick={logout}>{logouttext}</LogoutText> }
    </Footer>
  )
}
