import React from 'react'
import { Icon } from '../Foundation/Icon'
import { gray } from '../Foundation/Color'

const getIconSize = (ismobile) => {
  if (ismobile) {
    return 24
  }
  return 28
}

const iconMap = {
  YouKu: 'youku',
  Youtube: 'youtube',
  Twitter: 'twitter',
  Instagram: 'instagram',
  FaceBook: 'facebook',
  Weibo: 'weibo',
  Naver: 'naverblog',
}

export function ExternalLinkIcon(props) {
  const { icon, ismobile } = props

  return (
    <a href={icon.link} target='_blank' rel='noopener noreferrer' title={icon.iconName}>
      <Icon type={iconMap[icon.iconName]} size={getIconSize(ismobile)} color={gray[60]} />
    </a>
  )
}
