import React from 'react'
import styled from '@emotion/styled'

const ShopCardPriceBody = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
  }
  const mobileStyle = {
  }
  return ismobile
    ? {
      ...baseStyle,
      ...mobileStyle
    }
    : baseStyle
})

const ShopCardFormattedPrice = styled.span({}, ({ ismobile, hasPrice }) => {
  const baseStyle = {
    // sale-price
    // fontSize: '16px',
    lineHeight: '23.68px',
    ...(hasPrice && { fontWeight: '700' }),
    // fs-15
    fontSize: '15px',
    color: '#333!important',

    marginRight: '4px'

  }
  const mobileStyle = {
    fontSize: '14px',
    lineHeight: '20px'
    // fontWeight: '700'
  }
  return ismobile
    ? {
      ...baseStyle,
      ...mobileStyle
    }
    : baseStyle
})

const ShopCardFormattedDcRatio = styled.span({}, ({ ismobile }) => {
  const baseStyle = {
    color: '#E8351D',
    fontSize: '13px',
    fontWeight: '500'
  }
  const mobileStyle = {
    color: '#E8351D',
    fontSize: '11px',
    fontWeight: '700'
  }
  return ismobile
    ? {
      ...baseStyle,
      ...mobileStyle
    }
    : baseStyle
})

export function ShopCardPrice(props) {
  const {
    formattedPrice,
    ismobile
  } = props

  const formattedSalePrice = formattedPrice.salePrice
  const formattedDcRatio = formattedPrice.dcRatio
  const hasPrice = /[₩$¥€Ұ]/.test(formattedSalePrice) // 입력 데이터에 화폐 기호가 포함되어 있으면 true, 그렇지 않으면 false 반환

  const spanFormattedSalePrice = (
    <ShopCardFormattedPrice ismobile={ismobile} hasPrice={hasPrice}>
      {formattedSalePrice}
    </ShopCardFormattedPrice>
  )

  const templateFormattedDcRatio = (
    <ShopCardFormattedDcRatio ismobile={ismobile}>
      {formattedDcRatio}
    </ShopCardFormattedDcRatio>
  )

  return (
    <ShopCardPriceBody ismobile={ismobile}>
      {spanFormattedSalePrice}
      {templateFormattedDcRatio}
    </ShopCardPriceBody>
  )
}
