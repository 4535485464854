import React from 'react';
import styled from '@emotion/styled';
import { AppStoreButton } from '~/react/Common/Button/AppStoreButton';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #311EEC 37.65%, #271AA1 100%);
`;

const LinkFlexContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 100px;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TitleWrap = styled.div`
  position: relative;
  top: -18px;
  margin-bottom: 32px;
`;

const Title = styled.div`
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  color: #FFFFFF;
`;

const TitleSub = styled.div`
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #FFFFFF;
`;

const Image = styled.img`
  width: 100%;
  min-height: 400px;
`

export function VoteLanding({ $i18n }) {

  const AppStoreUrl = 'https://apps.apple.com/kr/app/makestar/id6477535973';
  const AndroidUrl = 'https://play.google.com/store/apps/details?id=com.makestar.superapp';
  const apkUrl = 'https://makestar-app-public.s3.ap-east-1.amazonaws.com/apk/MAKESTAR.apk'

  const id = (type) => {
    if (type === 'iOS') {
      return 'vote_app_install_ios'
    }
    if (type === 'Android') {
      return 'vote_app_install_aos'
    }
    if (type === 'ChinaAPK') {
      return 'vote_app_install_apk'
    }
  };

  return (
    <>
      <Container>
        <Image src={'https://cfw-makesta-real-production.s3.ap-northeast-2.amazonaws.com/file/landing/make-mate-1.png'} />
        <TitleWrap>
          <Title>{ $i18n.t('pocaAlbum.voteDescription1') }</Title>
          <TitleSub>{ $i18n.t('pocaAlbum.voteDescription2') }</TitleSub>
        </TitleWrap>
        <LinkFlexContainer>
          {$i18n.locale === 'zh' ? (
            <LinkContainer>
              <AppStoreButton type={'iOS'} url={AppStoreUrl} id={id('iOS')} />
              <AppStoreButton type={'ChinaAPK'} url={apkUrl} id={id('ChinaAPK')} />
            </LinkContainer>
          ) : (
            <LinkContainer>
              <AppStoreButton type={'iOS'} url={AppStoreUrl} id={id('iOS')} />
              <AppStoreButton type={'Android'} url={AndroidUrl} id={id('Android')} />
            </LinkContainer>
          )}
        </LinkFlexContainer>
      </Container>
    </>
  )
}
