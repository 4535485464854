// eslint-disable-next-line no-unused-vars
import React from 'react'
import { ProjectInfoLayout } from './ProjectInfoLayout'
import { ProjectInfoContent } from './ProjectInfoContent'
export function ProjectInfo(props) {
  const { contents, thunmbnail = undefined, ismobile } = props
  const contentProps = {
    projectTitle: undefined,
    projectProductTitle: undefined,
    quantity: undefined,
    amount: undefined,
    ismobile
  }
  const keys = Object.keys(contentProps)

  for (const key of keys) {
    const item = contents[key]
    const flexAddList = ['quantity', 'amount']
    if (item) {
      const childComponentProps = {
        flexAttr: flexAddList.includes(key) ? 1 : 0,
        marginTop: Number(key) !== 0 ? 12 : undefined,
        title: item.title,
        content: item.value,
        ismobile
      }
      contentProps[key] = (
        <ProjectInfoContent {...childComponentProps} />
      )
    }
  }
  return <ProjectInfoLayout {...contentProps} thunmbnail={thunmbnail}/>
}
