export default function({ route, redirect }) {
  // project 라우팅 규칙 변경에 따른 Redirection
  // AS-IS: /projects/list/:category/:type
  // TO-BE: /projects/list/:type
  const regex = /^\/projects\/list\/([^/]+)\/([^/]+)/
  const match = route.path.match(regex)
  if (match) {
    const type = match[2]
    return redirect(301, `/projects/list/${type}`)
  }
}
