/** 스타일 초기화 및 폰트패밀리 선언 - Pretendard JP, Noto Sans SC  */
const commonFontStyle = {
  fontFamily: '"PretendardJP", "Noto Sans SC", sans-serif'
}

const pc_h5_bold_700 = {
  ...commonFontStyle,
  fontSize: '24px',
  lineHeight: '36px',
  fontWeight: 700
}
const pc_h5_medium_500 = {
  ...commonFontStyle,
  fontSize: '24px',
  lineHeight: '36px',
  fontWeight: 500
}
const mobile_h5_bold_700_mobile = {
  ...commonFontStyle,
  fontSize: '22px',
  lineHeight: '32px',
  fontWeight: 700
}

const mobile_h5_medium_500_mobile = {
  ...commonFontStyle,
  fontSize: '22px',
  lineHeight: '32px',
  fontWeight: 500
}
const pc_h7_bold_700 = {
  ...commonFontStyle,
  fontSize: '20px',
  lineHeight: '30px',
  fontWeight: 700
}
const pc_h7_medium_500 = {
  ...commonFontStyle,
  fontSize: '20px',
  lineHeight: '30px',
  fontWeight: 500
}
const mobile_h7_bold_700_mobile = {
  ...commonFontStyle,
  fontSize: '20px',
  lineHeight: '30px',
  fontWeight: 700
}
const mobile_h7_medium_500_mobile = {
  ...commonFontStyle,
  fontSize: '20px',
  lineHeight: '30px',
  fontWeight: 500
}
const pc_h8_bold_700 = {
  ...commonFontStyle,
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: 700
}
const pc_h8_medium_500 = {
  ...commonFontStyle,
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: 500
}
const mobile_h8_bold_700_mobile = {
  ...commonFontStyle,
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: 700
}
const mobile_h8_medium_500_mobile = {
  ...commonFontStyle,
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: 500
}

const b1_bold_700 = {
  ...commonFontStyle,
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 700
}
const b1_medium_500 = {
  ...commonFontStyle,
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 500
}
const b1_regular_400 = {
  ...commonFontStyle,
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 400
}
const b2_bold_700 = {
  ...commonFontStyle,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 700
}
const b2_medium_500 = {
  ...commonFontStyle,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 500
}
const b2_regular_400 = {
  ...commonFontStyle,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 400
}
const c1_bold_700 = {
  ...commonFontStyle,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 700
}
const c1_medium_500 = {
  ...commonFontStyle,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 500
}
const c1_regular_400 = {
  ...commonFontStyle,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 400
}
const c2_bold_700 = {
  ...commonFontStyle,
  fontSize: '10px',
  lineHeight: '14px',
  fontWeight: 700
}
const c2_medium_500 = {
  ...commonFontStyle,
  fontSize: '10px',
  lineHeight: '14px',
  fontWeight: 500
}

export const H5_STYLES = {
  pc: {
    bold: pc_h5_bold_700,
    medium: pc_h5_medium_500
  },
  mobile: {
    bold: mobile_h5_bold_700_mobile,
    medium: mobile_h5_medium_500_mobile
  }
}
export const H7_STYLES = {
  pc: {
    bold: pc_h7_bold_700,
    medium: pc_h7_medium_500
  },
  mobile: {
    bold: mobile_h7_bold_700_mobile,
    medium: mobile_h7_medium_500_mobile
  }
}
export const H8_STYLES = {
  pc: {
    bold: pc_h8_bold_700,
    medium: pc_h8_medium_500
  },
  mobile: {
    bold: mobile_h8_bold_700_mobile,
    medium: mobile_h8_medium_500_mobile
  }
}

export const B1_STYLES = {
  pc: {
    bold: b1_bold_700,
    medium: b1_medium_500,
    regular: b1_regular_400
  },
  mobile: {
    bold: b1_bold_700,
    medium: b1_medium_500,
    regular: b1_regular_400
  }
}
export const B2_STYLES = {
  pc: {
    bold: b2_bold_700,
    medium: b2_medium_500,
    regular: b2_regular_400
  },
  mobile: {
    bold: b2_bold_700,
    medium: b2_medium_500,
    regular: b2_regular_400
  }
}

export const C1_STYLES = {
  pc: {
    bold: c1_bold_700,
    medium: c1_medium_500,
    regular: c1_regular_400
  },
  mobile: {
    bold: c1_bold_700,
    medium: c1_medium_500,
    regular: c1_regular_400
  }
}
export const C2_STYLES = {
  pc: {
    bold: c2_bold_700,
    medium: c2_medium_500
  },
  mobile: {
    bold: c2_bold_700,
    medium: c2_medium_500
  }
}
