export default async function() {
  const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined'
  const isServer = !isBrowser

  // Mock Service Worker 시작
  if (process.env.NODE_ENV === 'dev') {
    if (isBrowser && 'serviceWorker' in navigator) {
      const { worker } = require('../mocks/browser')
      const workerResponse = await worker.start({ onUnhandledRequest: 'bypass' })
      if (workerResponse) {
        console.log(`MSW running in browser...`)
      }
    }
    if (isServer) {
      const { server } = require('../mocks/server')
      server.listen({ onUnhandledRequest: 'bypass' })
    }
  }
}
