import React from 'react'
import styled from '@emotion/styled'
import { IconNew } from '../../IconNew'

const ProjectCardImageContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    // .card-item
    height: '100%',
    width :'288px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    // .relative-content 332
    boxShadow: 'none',
    transition: 'box-shadow 0.5s',

    // .image-hover-zoom 347
    overflow: 'hidden',

    // .card-thumbnail
    position: 'relative',
    flexShrink: '0',
    overflow: 'hidden',
    border: '0.5px solid #ccc',
    borderRadius: '4px 4px 0px 0px',
  }

  const mobileStyle = {
    width :'164px'
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ProjectCardImageBody = styled.img({
    '&:hover': {
      transform: 'scale(1.1)',
      // opacity: '1',
      // visibility: 'visible'
    }
  }, ({ ismobile }) => {

  const baseStyle = {
    height: '162px',
    objectFit: 'cover',
    maxWidth: '100%',

    // .image_container
    maxWidth: '100%',
    backgroundColor: '#eaeaea',
    position: 'relative',
    
    // .image-wrapper
    transition: 'all 0.5s ease'
  }
  
  const mobileStyle = {
    height: '92px',
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function ProjectCardImage(props) {
  const { imageUrl, iconnew, ismobile } = props

  const iconNewElement = iconnew ? (
    <IconNew ismobile={ismobile} size={'list'}></IconNew>
  ) : null

  return (
    <ProjectCardImageContainer ismobile={ismobile}>
      <ProjectCardImageBody src={imageUrl} ismobile={ismobile}>
      </ProjectCardImageBody>
      { iconNewElement }
    </ProjectCardImageContainer>    
  )
}
