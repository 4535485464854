

import Vue from 'vue'

import codeConst from '~/utils/codeConst'

export default {
  name: 'Toast',
  data() {
    return {
      message: null,
      toastIcon: null,
      codeConst
    }
  },
  mounted() {
    this.message = Vue.prototype.toastMessage
    this.toastIcon = Vue.prototype.toastIcon
  },
  methods: {

  }
}
