import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default (context, inject) => {
  Vue.use(VueGtag, {
    config: {
      id: context.env.GOOGLE_ANALYTICS_ID,
      params: {
        send_page_view: false
      }
    }
  })
  Vue.prototype.$ga4Data = {
    clientId: null,
    sessionId: null
  }
  Vue.prototype.$gtag.query('get', process.env.GOOGLE_ANALYTICS_ID, 'client_id', (result) => {
    Vue.prototype.$ga4Data.clientId = result
  })
  Vue.prototype.$gtag.query('get', process.env.GOOGLE_ANALYTICS_ID, 'session_id', (result) => {
    Vue.prototype.$ga4Data.sessionId = Number(result)
  })

  inject('gtag', Vue.prototype.$gtag)
  inject('ga4Data', Vue.prototype.$ga4Data)
}

