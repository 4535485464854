import React from 'react'
import styled from '@emotion/styled'


const ButtonContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    width: '100%',
    height: '61px',
    display: 'flex',
    justifyContent: 'center',
  }
  
  const mobileStyle = {
    width: '100%',
    height: '30px',
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ButtonDiv = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    cursor: 'pointer',
    width: 'auto',
    height: '42px',
    
    border: '1px solid #d8d8d8',
    borderRadius: '30px',

    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#333333',

    margin: '20px auto 20px auto',
    padding: '9px 22px',
    transition: 'box-shadow .4s ease',
  }
  
  const mobileStyle = {
    margin: '12px auto 0 auto',
    lineHeight: '22px',
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})


export function ButtonMorePage(props) {
  const { stringseemore, clickcallback, clickcallbackparam, ismobile } = props

  return (
  <ButtonContainer  ismobile={ismobile}>
      <ButtonDiv onClick={() => clickcallback(clickcallbackparam)} ismobile={ismobile} >
        { stringseemore }
      </ButtonDiv>
    </ButtonContainer>
  )
}


