import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { gray, white } from '~/react/Foundation/Color';
import { B2_STYLES, C2_STYLES } from '~/react/Foundation/Typography/styles';
import { InputValidStatusColorStyle } from './styles'
import { InputDescriptionText } from './InputDescriptionText'

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 6px;
  box-sizing: border-box;
  width: 100%;
  gap: 2px;
  `;

const TextAreaContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  boxSizing: 'border-box',
  padding: '10px 10px 6px',
  backgroundColor: white,
  border: `1px solid ${InputValidStatusColorStyle.normal}`,
  borderRadius: '6px',
  '&:focus-within': {
    borderColor: InputValidStatusColorStyle.active
  },
}, ({ isDisabled }) => {
  return {
    ... isDisabled && { backgroundColor: gray[20] }
  }
})

const StyledTextArea = styled.textarea({
  resize: 'none',
  ...B2_STYLES.mobile.regular,
  color: gray[90],
  '&::placeholder': {
    color: gray[50]
  },
  outlineColor: 'transparent',
  backgroundColor: 'transparent',
  outlineWidth: 0,
  borderRadius: '6px',
  boxSizing: 'border-box',
  border: 'none',
  '&::-webkit-scrollbar' : {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb' : {
    backgroundColor: gray[60],
    borderRadius: '50px'
  },
  '&:disabled': {
    color: gray[60],
    '-webkit-text-fill-color': gray[60], // safari
    opacity: 1, // safari
    '&::-webkit-scrollbar' : {
      backgroundColor: gray[20]
    },
  },
}, ({ width, height }) => {
  return {
    width,
    height,
    '&:after': {
      position: 'relative',
      bottom: '0',
      display: 'block',
      content: "'123'",
      backgroundColor: 'white'
    }
  }
})

const CharLimitCount = styled.div({
  ...C2_STYLES.mobile.medium,
  textAlign: 'right',
  color: gray[70]
}, () => {})
/**
 * @typedef InputTextFieldProps
 * @property {(value: string) => void} onUpdate 인풋값을 받기 위한 change메서드
 * @property {boolean} isDisabled disabeld 상태
 * @property {string} [width] 없을 경우 기본값 100%
 * @property {string} [height] 없을 경우 기본값 auto, 최소 높이 80px
 * @property {string} [placeholder] 플레이스 홀더
 * @property {string} [description] 안내문구
 * @property {string} [defaultValue] input에 넣어줄 기본값
 * @property {boolean} isShowCharCount Textarea 글자 카운트 옵션
 * @property {number} max Textarea 글자 카운트 옵션
 */

/**
 * @param {InputTextFieldProps} props
 */
export function InputTextField ({width='100%', height='80px', placeholder='', description, isDisabled=false, defaultValue, onUpdate, isShowCharCount, max}) {
  const [innerValue, setInnerValue] = useState('')
  const [charCount, setCharCount] = useState(0)
  const handleChange = (event) => {
    setInnerValue(event.target.value)
    setCharCount(event.target.value.length)
    onUpdate(event.target.value)
  }
  useEffect(() => {
    setInnerValue(defaultValue ?? '')
    setCharCount(defaultValue.length ?? 0)
  }, [defaultValue])
  return (
    <Container>
      <TextAreaContainer isDisabled={isDisabled}>
        <StyledTextArea width={width} height={height} onChange={handleChange} disabled={isDisabled} value={innerValue}></StyledTextArea>
        { isShowCharCount && max && <CharLimitCount>{charCount}/{max}</CharLimitCount>}
      </TextAreaContainer>
      {description.length > 0 && <InputDescriptionText size="medium" isDisabled={isDisabled} status="normal">{description}</InputDescriptionText>}
    </Container>
    )
}