import Vue from 'vue'
import VueScrollPicker from 'vue-scroll-picker'
import 'vue-scroll-picker/dist/style.css'

// export default {
//   components: {
//     VueScrollPicker
//   }
// }
Vue.use(VueScrollPicker)
