import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// 플러그인 사용 설정
dayjs.extend(utc)
dayjs.extend(timezone)

const dateUtil = {}

dateUtil.toRemainHour = function(data) {
  const hour = Number(data)

  if (hour <= 0) {
    return this.$t('project.end')
  }

  if (hour <= 24) {
    return hour + this.$t('project.hours')
  }

  return 'D-' + Math.floor(hour / 24)
}

dateUtil.getLocalDatetime = (utcDate) => {
  if (!utcDate) return null
  return dayjs.utc(utcDate).tz('Asia/Seoul')
}

dateUtil.formatDate = ({
  date, format = 'YYYY.MM.DD HH:mm:ss'
}) => {
  return dayjs(date).format(format)
}

dateUtil.getRemainDate = (endDate) => {
  const localEndDate = dayjs.utc(endDate).local()
  const now = dayjs()
  const days = localEndDate.diff(now, 'day')
  const hours = localEndDate.diff(now, 'hour') % 24
  const minutes = localEndDate.diff(now, 'minute') % 60

  return {
    days,
    hours,
    minutes
  }
}

export default dateUtil

