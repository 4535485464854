import React, { useCallback } from 'react';
import styled from '@emotion/styled'
import { GNBProfile } from './GNBProfile';
import { GNBNotiIcon } from './GNBNotiIcon';
import { B1Bold, B1Regular, H8Medium, C1Bold, B2Medium } from '../../Foundation/Typography';
import { red, gray } from '../../Foundation/Color'
import { Icon } from '../../Foundation/Icon/Icon';

const UserInfoContainer = styled.div({}, () => {
  const baseStyle = {
    padding: '16px',
    display: 'flex',
    gap: '0 16px'
  }
  return baseStyle
})

const SubMenuContainer = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}, () => {})

const SubMenuText = styled(B2Medium)({
  width: '100%',
  padding: '8px',
  textAlign: 'center'
})

const UserNameAndNotiContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  // flexbox가 튀어나가는 것 방지
  minWidth: 0,
  alignItems: 'stretch',
  justifyContent: 'center',
  gap: '4px'
}, () => {})

const UserNamAndNotiIcon = styled.div({
  display: 'flex',
  gap: '0 16px',
  alignItems: 'center',
}, () => {})

const UserNameAndArrowIcon = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  // flexbox가 튀어나가는 것 방지
  minWidth: 0,
}, () => {})
const UserNameB1Bold = styled(B1Bold)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}, () => {})
const UserEmailB1Regular = styled(B1Regular)({}, () => ({
  color: gray[80]
}))
const Divider = styled.div({
  width: '100%',
  height: '4px',
  boxSizing: 'border-box',
  backgroundColor: gray[20],
  borderTop: `1px solid ${gray[30]}`,
}, {})
const FullSizeMenu = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '18px 0',
  padding: '32px 24px',
}, () => {})
const GNBMenuTextWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '0 4px',
  cursor: 'pointer',
  height: '100%',
  boxSizing: 'border-box',
}, () => {
});

const FixedMenu = styled(C1Bold)({
    color: red[50]
}, () => {})

const NoShirinkIcon = styled(Icon)({
  marginLeft: 'auto',
  flexShrink: 0
}, () => {})

/**
 * @typedef Menu
 * @property {string} i18n 다국어 키
 * @property {string} routeName 라우팅 이름
 * @property {string} searchKey 라우팅 key
 * @property {string} routePath 라우팅 패스
 */

/**
 * @typedef UserInfo
 * @property {string} [profileImageUrl] 유저 프로필 이미지
 * @property {boolean} loggedInUser 유저 로그인 여부
 * @property {string} name 이름
 * @property {string} email 이메일
*
*/

/**
 * @typedef GNBFullSizeMenuProps
 * @property {boolean} ismobile
 * @property {boolean} alarm 알람메세지 유무
 * @property {UserInfo} userinfo 로그인한 유저 정보
 * @property {(path: string) => void} handlelogin 로그인 핸들러
 * @property {(text:string) => string} i18n 다국어 메서드
 * @property {string} i18nlogintext 로그인 다국어 텍스트 키
 * @property {string} i18nguidemessagetext 로그인 안내 가이드 다국어 텍스트 키
 * @property {Menu[]} menulist 전체 메뉴
 * @property {{i18n: string, routePath:string}[]} submenulist 유저정보와 함께 보여질 서브 메뉴(마이메뉴)
 * @property {(path: string) => void} menuclickcallback
 * @property {boolean} isb2brole 계정 권한이 B2B 권한인지 여부
 * @property {() => void} handleMenuClose fullsize메뉴 닫기 버튼 callback
 * @property {() => void} handleopennoti noti popover 열고닫기
 * @property {() => void} handlegologinprofilelogin 프로필 버튼 클릭시 로그인
 * @property {() => void} handlegologinprofilesignup 가이드 텍스트 버튼 클릭시 로그인 또는 회원가입
 */

/**
 * Mobile일 때만 나오는 Fullsize menu
 * @param {GNBFullSizeMenuProps} props
 * @returns
 */
export function GNBFullSizeMenu ({ismobile, alarm, userinfo, handlelogin, menulist, submenulist, i18n, i18nlogintext, i18nguidemessagetext, isb2brole, menuclickcallback, handleMenuClose, handleopennoti, handlegologinprofilelogin, handlegologinprofilesignup, ...props }){

  const menus = menulist.map((item, index) => {
    return (
      <GNBMenuTextWrapper
        key={`GNB-${index}`}
        onClick={()=>menuclickcallback(item.routePath)}
        ismobile={ismobile}
      >
        <H8Medium ismobile={ismobile}>
          { (i18n && i18n(item.i18n)) ?? item.i18n }
        </H8Medium>
          {
            item.routeName === 'Shop' && isb2brole && (
            <FixedMenu ismobile={ismobile}>B2B</FixedMenu>
            )
          }
      </GNBMenuTextWrapper>
    )
  })

  const subMenus = submenulist.map((item, index) => {
    return (
      <SubMenuText
        key={`SubMenu-${index}`}
        onClick={()=>menuclickcallback(item.routePath)}
      >
        { (i18n && i18n(item.i18n)) ?? item.i18n }
      </SubMenuText>
    )
  })

  const getLoginText = useCallback(() => {
    if(i18n && i18nlogintext) return i18n(i18nlogintext)
    return '로그인'
  }, [i18n, i18nlogintext])

  const getGuideText = useCallback(() => {
    if(i18n && i18nguidemessagetext) return i18n(i18nguidemessagetext)
    return '아직 회원이 아니신가요?'
  }, [i18n, i18nguidemessagetext])

  const clickUserName = useCallback((e) => {
    if(!userinfo.loggedInUser){
      e.stopPropagation();
      handleMenuClose();
      handlegologinprofilelogin(e)
    }
  }, [userinfo, handlegologinprofilelogin])

  const clickUserEmail = useCallback((e) => {
    if(!userinfo.loggedInUser){
      e.stopPropagation();
      handleMenuClose();
      handlegologinprofilesignup(e)
    }
  }, [userinfo, handlegologinprofilesignup])

  const goProfile = () => {
    handleMenuClose();
    menuclickcallback('/user/profile')
  }

  const handleLoginAndMenuClose = () => {
    handleMenuClose();
    handlelogin()
  }

  return (
    <div {...props} >
      <UserInfoContainer >
        <div style={{display: 'flex', width: '100%', minWidth: 0}} onClick={goProfile}>
          <GNBProfile ismobile={ismobile} profileimage={userinfo.profileImageUrl} width={56} islogin={userinfo.loggedInUser} handlelogin={handleLoginAndMenuClose} handleclickprofile={() => menuclickcallback('/user/profile')} />
          <UserNameAndNotiContainer>
            <UserNamAndNotiIcon>
              <UserNameAndArrowIcon onClick={(e) => clickUserName(e)}>
                <UserNameB1Bold ismobile={ismobile}>
                  { userinfo.loggedInUser ? userinfo.name : getLoginText() }
                </UserNameB1Bold>
                { userinfo.loggedInUser && <NoShirinkIcon size={24} type="arrow_right_small" />}
              </UserNameAndArrowIcon>
              { userinfo.loggedInUser &&
                <GNBNotiIcon
                  style={{marginLeft: 'auto'}}
                  alarm={alarm}
                  onClick={(e) => {e.stopPropagation() ; handleopennoti();}}
                  ismobile={ismobile}
                />
              }
            </UserNamAndNotiIcon>
            <div onClick={clickUserEmail}>
              <UserEmailB1Regular ismobile={ismobile}> {userinfo.loggedInUser ? userinfo.email : getGuideText() } </UserEmailB1Regular>
            </div>
          </UserNameAndNotiContainer>
        </div>
        <NoShirinkIcon type="close" onClick={handleMenuClose} />
      </UserInfoContainer>
      <SubMenuContainer>
        {subMenus}
      </SubMenuContainer>
      <Divider />
      <FullSizeMenu>
        {menus}
      </FullSizeMenu>
    </div>
  )
}
