import React from 'react'
import styled from '@emotion/styled'
import { CardProject } from '../../Common/Card/Project/CardProject'
import { LayoutContainer  } from '../../Common/Layout/LayoutContainer'
import { MoreButton } from './MoreButton'
import codeConst from '/utils/codeConst'

/**
 * @typedef {keyof codeConst.VIEW_MODE} Viewmode
 */

/**
 * @typedef ProjectListProps
 * @property {ListItem[]} list
 * @property {Viewmode} viewmode
 * @property {string} getprojectlabel 작업중 / 진행중 / 프리오픈 / 커밍순 등 상태값 + 시작날짜, 종료날짜를 토대로 라벨 텍스트를 불러옴(다국어 적용)
 * @property {(item: ListItem) => ProjectCardContext} getcardcontext 상태에 따른 카드 상태 색상, 프로그레스바 색상, 프로그레스바 밸류(percentage) 리턴
 * @property {(item: ListItem, locale:string) => string} getprice 다국어에 따른 가격(통화기호 포함)
 * @property {(itemIndex: string) => void} gotoprojectdetail
 * @property {() => void} getmore 더보기 버튼 핸들러
 * @property {string} getmorecaption 더보기 버튼 캡션(다국어 적용)
 * @property {boolean} getmorecondition 더보기 버튼 표시 여부
 * @property {boolean} ismobile
 * @property {string} locale
 */


const ContentsWrapper = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    padding: '0 0 24px'
  }
  const mobileStyle = {
    padding: '16px 0 32px'
  }
  return ismobile ? mobileStyle : baseStyle
})

const ColumnContainer = styled.div({}, ({ ismobile, viewmode }) => {
  const baseStyle = {
    display: 'grid',
    gridTemplateColumns: viewmode === codeConst.VIEW_MODE.LIST ? '1fr 1fr' : '1fr 1fr 1fr 1fr',
    gap: '24px 16px'
  }
  const mobileStyle = {
    gridTemplateColumns: viewmode === codeConst.VIEW_MODE.LIST ? '1fr' : '1fr 1fr',
    gap: '16px'
  }
  return ismobile ? { ...baseStyle, ...mobileStyle} : baseStyle
})
const PageContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    marginBottom: '80px'
  }
  const mobileStyle = {
    marginBottom: '64px'
  }
  return ismobile ? mobileStyle : baseStyle
})
/**
 *
 * @param {ProjectListProps} props
 * @returns
 */
export function ProjectList(props) {
  const { list, viewmode, getprojectlabel, getcardcontext, getprice, gotoprojectdetail, getmore, getmorecaption, getmorecondition, ismobile, locale } = props
  let validViewmode = codeConst.VIEW_MODE.GRID
  if( viewmode === undefined) {
    validViewmode = codeConst.VIEW_MODE.GRID
  } else {
    validViewmode = viewmode
  }
  if ( list === undefined || list.length === 0 ) {
    list = []
    // 빈화면 출력
      // <div v-if="isLoading" class="loader">...</div>
      // <div v-else class="d-flex flex-column justify-center align-center">
      //   <div class="fs-18 bold color-gray6" style="margin-top:5rem;">
      //     {{ $t('project.noProjectMessage') }}
      //   </div>
      //   <div class="mr-5 ml-5 mb-10" style="width: 400px;">
      //     <img class="img mt-2" src="~/static/image/list_blank.png">
      //   </div>
      // </div>
  }

  const projectItems = list.map((item, index) => (
    <CardProject
      key={`card-${item.title}`}
      item={item}
      url={item.url}
      title={item.title}
      description={item.description}
      imageurl={item.imageUrl}
      iconnew={item.iconNew === 'new' ? true : false}
      projectlabel={getprojectlabel(item.status, item.startDate, item.endDate)}
      cardcontext={getcardcontext(item)}
      viewmode={viewmode}
      price={getprice(item, locale)}
      clickcallback={gotoprojectdetail}
      ismobile={ismobile}
      isshowdescription={ismobile && viewmode === codeConst.VIEW_MODE.GRID ? false : true }/>
  ))
  return (
    <PageContainer ismobile={ismobile}>
      <ContentsWrapper ismobile={ismobile}>
        <LayoutContainer gutter={'16px'} ismobile={ismobile}>
          <ColumnContainer ismobile={ismobile} viewmode={viewmode}>
            {projectItems}
          </ColumnContainer>
        </LayoutContainer>
      </ContentsWrapper>
      <LayoutContainer ismobile={ismobile}>
        { getmorecondition && <MoreButton
          getmore={getmore}
          getmorecondition={getmorecondition}
          getmorecaption={getmorecaption}
          ismobile={ismobile} />}
      </LayoutContainer>
    </PageContainer>
  )
}

/**
 * @typedef ListItem
 * @property {string} id
 * @property {string} url
 * @property {string} title
 * @property {string} description
 * @property {string} imageUrl
 * @property {string | null} urlVideo
 * @property {number} percent
 * @property {number | null} follower
 * @property {number} goal
 * @property {number} amount
 * @property {number} cnt
 * @property {string} startDate
 * @property {string} endDate
 * @property {string} status ALL - "" / PROCEEDING - "20" / END-"80"
 * @property {string} projectType ALL - "all" / PRE_ORDER - "pre_order" / FUNDING-"funding"
 * @property {string} fundingSubtype
 * @property {number} remainHour
 * @property {string} postStatus
 * @property {string | null} commentStatus
 * @property {string} currencyInfo
 * @property {number} refundable
 * @property {string} iconNew
 * @property {number} idx
 */

/**
 * @typedef ProjectCardContext
 * @property {object} timerColor
 * @property {string} statusText
 * @property {string} statusColor
 * @property {string} progreeBarColor
 * @property {number} [progreeBarValue]
 */