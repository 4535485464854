import React, { useContext } from 'react'
import dateUtil from '../../utils/dateUtil'
import codeConst from '../../utils/codeConst'
import { BadgeDday } from '../Common/Badge/BadgeDday'
import i18nContext from '../i18nContext'

const { SALE_STAT } = codeConst

/**
 * 쇼핑 카드에 들어가는 디데이 뱃지
 * @param {Date} endDate
 * @param {SALE_STAT} saleStatus
 * @returns {JSX.Element}
 */
export function ShopDdayBadge({ endDate, saleStatus = SALE_STAT.SALE }) {

  const i18n = useContext(i18nContext)

  const getLabel = () => {
    if (saleStatus === SALE_STAT.END) {
      return i18n.t('project.end')
    }
    const { days, hours, minutes } = dateUtil.getRemainDate(endDate)
    if (days >= 1) {
      return `D-${days}`
    }
    if (hours > 0) {
      return `${hours} ${i18n.t('project.hours')}`
    }
    if (minutes > 0) {
      return `${minutes} ${i18n.t('project.minute')}`
    }
    return 1 + i18n.t('project.minute')
  }

  const getColor = () => {
    if (saleStatus !== SALE_STAT.SALE) {
      return 'gray'
    }
    return 'green'
  }

  return (
    <>
      <BadgeDday
        projectLabel={getLabel()}
        color={getColor()}
      />
    </>
  )
}
