import React from 'react'
import styled from '@emotion/styled'

// PC Detail : 43
// PC List : 37
// Mobile Detail : 37
// Mobile List : 30

const IconNewBody = styled.div({}, ({ size, ismobile }) => {
  const baseStyle = {
    position: 'absolute',
    display: 'block',
    color: '#ffffff',
    textAlign: 'center',
    verticalAlign: 'middle',
    backgroundColor: 'rgba(232, 53, 29, 0.7)',
    borderRadius: '4px'
  }
  
  const pcDetailStyle = {  // PC Detail : 43
    width: '43px',
    height: '24px',
    top: '16px',
    left: '16px',
    fontSize: '15px',
    fontWeight: 'bold',

    letterSpacing: '-0.05em', // pc detail style only
  }

  const pcListStyle = {
    width: '37px',
    height: '20px',
    top: '8px',
    left: '8px',
    fontSize: '13px',
    fontWeight: 'bold',

    // paddingTop: '4px'
  }

  const mobileListStyle = {
    width: '30px',
    height: '16px',
    top: '8px',
    left: '8px',
    fontSize: '10px',
    fontWeight: '700',

    paddingTop: '4px',
    lineHeight: '8px' // mobile list tyle only
  }
  
  if (ismobile) {
    if ( size === 'list') {
      return {
        ...baseStyle,
        ...mobileListStyle
      }
    } else {
      return {
        ...baseStyle,
        ...pcListStyle
      }
    }
    
  } else {
    if (size === 'list') {
      return {
        ...baseStyle,
        ...pcListStyle
      }
    } else {
      return {
        ...baseStyle,
        ...pcDetailStyle
      }
    }
  }

})

export function IconNew(props) {
  const { size, ismobile } = props
  return (
    <IconNewBody ismobile={ismobile} size={size}>
      {'NEW'}
    </IconNewBody>
  )
}
