import React from 'react'
import styled from '@emotion/styled';
import CloseIcon from '/assets/icon/close.svg'

export function ExclusiveInfoPopup({ i18n, isMobile, onClose, navigateToEventDetail, targetEvent }) {

  const goToEventDetail = () => {
    navigateToEventDetail(targetEvent.id);
  };

  return (
    <PopupWrap
      isMobile={isMobile}
      data-tagging-id={'shopping_to_event_popup'}
      id={'shopping_to_event_popup'}>
      <IconContainer>
        <IconWrap src={CloseIcon} onClick={onClose}  alt="close icon"/>
      </IconContainer>
      <Title isMobile={isMobile}>
        { i18n('shop.notIncludeExclusiveItem') }
      </Title>
      <Description>
        { i18n('shop.notProvideExclusiveItem') }
      </Description>
      <BreakLine></BreakLine>
      <Description>{ i18n('shop.eventWithExclusive') }</Description>
      <EventDescription>{targetEvent.name}</EventDescription>
      <ButtonWrap>
        <Button isMobile={isMobile} isConfirm={false} onClick={onClose}>
          { i18n('shop.purchaseWithoutExclusive') }
        </Button>
        <Button isMobile={isMobile} isConfirm={true} onClick={goToEventDetail} data-tagging-id={'shopping_to_event_btn'}>
          { i18n('shop.gotoEvent') }
        </Button>
      </ButtonWrap>
    </PopupWrap>
  )
}

const PopupWrap = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
  padding: ${props => (props.isMobile ? '16px' : '20px')};
`

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${props => (props.isMobile ? '16px' : '12px')};
`
const IconWrap = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const Title = styled.div`
  color: #111;
  font-weight: 700;
  font-size: 22px;
  font-style: normal;
  margin-bottom: 12px;
`
const Description = styled.div`
  color: #333;
  font-weight: 400;
  font-size: 15px;
  font-style: normal;
  white-space: pre-line;
`
const EventDescription = styled(Description)`
  font-weight: 700;
  margin-bottom: 32px;
`

const BreakLine = styled.div`
  height: 1px;
  align-self: stretch;
  background: #ccc;
  margin-top: 16px;
  margin-bottom: 16px;
`

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`

const Button = styled.button`
  width: 100%;
  padding: ${props => (props.isMobile ? '10px 16px' : '12px 16px')};
  color: ${props => (props.isConfirm ? '#FFFFFF' : '#333333')};
  background: ${props => (props.isConfirm ? '#E52E15' : '#FFFFFF')};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #CCC;
  word-break: keep-all;
`
