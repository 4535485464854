import Vue from 'vue'
import Vuetify, {
  VLayout, VBtn, VRow, VCol, VTextarea, VCarousel, VItemGroup, VProgressLinear, VWindow
  , VProgressCircular, VDialog, VOverlay, VThemeProvider
  , VIcon, VMenu
  , VList, VListItem, VListItemTitle
  , VTextField
  , VSelect
  , VAutocomplete
  , VForm
  , VApp
  , VLazy
  , ClickOutside
  , VSimpleTable
  , VRadio
  , VRadioGroup
} from 'vuetify/lib'

import colors from 'vuetify/lib/util/colors'
// import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify, {
  components: {
    VApp,
    VLayout,
    VBtn,
    VRow,
    VCol,
    VTextarea,
    VCarousel,
    VItemGroup,
    VProgressLinear,
    VWindow,
    VProgressCircular,
    VDialog,
    VOverlay,
    VThemeProvider,
    VIcon,
    VMenu,
    VList,
    VListItem,
    VListItemTitle,
    VTextField,
    VSelect,
    VForm,
    VLazy,
    VAutocomplete,
    VSimpleTable,
    VRadio,
    VRadioGroup
  },
  directives: {
    ClickOutside
  }
})

const opts = {
  treeShake: true,
  customVariables: ['~/assets/variables/_colours.scss'],
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: colors.blue.darken2,
        accent: colors.grey.darken3,
        secondary: colors.amber.darken3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3
      }
    }
  }
// ...myOtherOptions,
// theme: {
//   disable: true
// }
}
export default (ctx) => {
  const vuetifyOptions = typeof opts === 'function' ? opts(ctx) : opts

  vuetifyOptions.icons = vuetifyOptions.icons || {}
  vuetifyOptions.icons.iconfont = 'mdi'

  const vuetify = new Vuetify(vuetifyOptions)

  ctx.app.vuetify = vuetify
  ctx.$vuetify = vuetify.framework
}
