import React from 'react'
import styled from '@emotion/styled'

const CardArtistCircledImageContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    width :'160px',
    height: '160px',
    margin: '8px',
    overflow: 'hidden',
    borderRadius: '50%',
    boxSizing: 'border-box',
    '&:hover .card-artist-image': {
      transform: 'scale(1.05)',
    },
  }

  const mobileStyle = {
    width :'96px',
    height: '96px',
    margin: '4px',
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const CardArtistImage = styled.img({
  }, () => {
  const baseStyle = {
    borderRadius: '50%',
    display: 'block',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    transition: 'all 0.5s ease',
  }

  return baseStyle
})

export function CardArtistCircledImage(props) {
  const { imageUrl, ismobile } = props

  return (
    <CardArtistCircledImageContainer ismobile={ismobile}>
      <CardArtistImage className="card-artist-image" src={imageUrl} ismobile={ismobile}>
      </CardArtistImage>
    </CardArtistCircledImageContainer>
  )
}
