/** MSA 서버로 직접 요청하는 경우 Base URL 사용 */
const baseUrl = process.env.API_SERVER_URL

export default function(request) {
  return {
    selectCountry(langCd) {
      const url = baseUrl + '/order/v1/project/countryList/' + langCd
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    selectShippingCountry(langCd) {
      const url = baseUrl + '/order/v1/project/shippingCountryList/' + langCd
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    selectDeliveryPrice(data) {
      const url = '/order/v1/project/dlvyPrice'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    insertOrder(data) {
      const url = '/order/v1/project/order'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const { clientId, sessionId } = window.$nuxt.context.$ga4Data
      data['ga4ClientId'] = clientId ?? null
      data['ga4SessionId'] = sessionId ?? null

      return request.$post(url, data, config)
    },
    selectPaymentType(data) {
      const url = '/order/v1/project/payMtd'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    selectOrderCheck(ordNo) {
      const url = baseUrl + '/order/v1/project/order/chk/' + ordNo
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    selectOrderDetail(data) {
      const url = '/order/v1/my/project/detail'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    selectOrderList(data) {
      const url = '/order/v1/my/project/list'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    selectLastAddr() {
      const url = baseUrl + '/order/v1/project/lastAddr'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    selectCardList(data) {
      const url = '/order/v1/product/list/cart'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    selectPdList(data) {
      const url = '/order/v1/product/list/pd'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    selectProductDeliveryPrice(data) {
      const url = '/order/v1/product/dlvyPrice'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    insertProductOrder(data) {
      const url = '/order/v1/product'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const { clientId, sessionId } = window.$nuxt.context.$ga4Data
      data['ga4ClientId'] = clientId ?? null
      data['ga4SessionId'] = sessionId ?? null

      return request.$post(url, data, config)
    },
    selectShoppingDetail(data) {
      const url = '/order/v1/my/shopping/detail'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    selectShoppingList(data) {
      const url = '/order/v1/my/shopping/list'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    selectB2BOrderList(data) {
      const url = '/order/v1/my/shopping/invoice/b2b-list'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    selectProjectKrDlvyPrice(data) {
      const url = '/order/v1/project/krDlvyPrice'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    selectProjectExcInfo() {
      const url = baseUrl + '/order/v1/project/excInfo'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    selectProductKrDlvyPrice(data) {
      const url = '/order/v1/product/krDlvyPrice'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    updateProjectEvent(data) {
      const url = '/order/v1/my/project/event'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$put(url, data, config)
    },
    updateProjectDlvy(data) {
      const url = '/order/v1/my/project/dlvy'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$put(url, data, config)
    },
    updateShoppingCncl(data) {
      const url = '/order/v1/my/shopping/cncl'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    updateProjectDigital(data) {
      const url = '/order/v1/my/project/digital'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    insertProjectCncl(data) {
      const url = '/order/v1/my/project/cncl'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    insertShoppingCncl(data) {
      const url = '/order/v1/my/shopping/cncl'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    updateShoppingDlvy(data) {
      const url = '/order/v1/my/shopping/dlvy'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$put(url, data, config)
    },
    getProjectLimitOptQty(data) {
      const url = '/order/v1/project/limitOptQty'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    getOdrNo() {
      const url = '/order/v1/project/check/odrNo'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, {}, config)
    },
    getShoppingOdrNo(data) {
      const url = '/order/v1/product/check/odrNo'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    selectProjectInvoiceList(data) {
      const url = '/order/v1/my/project/invoice'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    selectShoppingInvoiceList(data) {
      const url = '/order/v1/my/shopping/invoice'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    insertChinaAgreeInfo(data) {
      const url = '/order/v1/product/cnDlvyAgreeInfo'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    insertChinaInfo(data) {
      const url = '/order/v1/product/cnDlvyInfo'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    deleteChinaInfo(data) {
      const url = '/order/v1/product/deleteCnDlvyInfo'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    selectKRWPrice(data) {
      const url = '/order/v1/product/KRWPrice'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    invoiceHistory(data) {
      const url = '/order/v1/my/shopping/invoiceHistory'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    newPreOrder(data) {
      const url = '/order/v2/preOrder'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$post(url, data, config)
    },
    /**
     * @param {string} cntryCd 국가코드
     * @param {string[]} pdCdList 리워드 상품코드 리스트
     * @returns ShoppingBuyLimitCountListResponse
     */
    getLogisLimitCount(cntryCd, pdCdList) {
      const cntryCdParam = `cntryCd=${cntryCd}`
      const pdCdListParam = new URLSearchParams()
      for (const idx of pdCdList) {
        pdCdListParam.append('pdCdList', idx)
      }
      const url = baseUrl + '/order/v1/shopping/buy-limit?' + cntryCdParam + '&' + pdCdListParam.toString()
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$get(url, config)
    }
  }
}

/**
 * @typedef ShoppingBuyLimitCountListResponse
 * @property {{[idx: string]: ShoppingBuyLimitCountItem}} resData
 */
/**
 * @typedef ShoppingBuyLimitCountItem
 * @property {string} pdCd 요청한 상품의 인덱스
 * @property {string} cntryCd 국가 코드
 * @property {number} sumAlbumCountQty 한 주문건 당 앨범을 몇 개로 볼 것인지 수
 * @property {'Y' | 'N'} shoppingLogisBuyLimitCountYn 상품 별 수량제한이 되어 있는지 여부. N이면 shoppingBuyLimits 빈 배열
 * @property {LogisItem[]} logisBuyLimitList 배송사별 설정되어 있는 수량제한
 * @property {LogisItem & {projectRewardIdx:number}[]} shoppingBuyLimits 상품 별 설정된 수량제한
 */

/**
 * @typedef LogisItem
 * @property {string} logisCd 배송사코드
 * @property {string} cntryCd 국가코드
 * @property {string} limitCount 제한 수량
 * @property {string} regId 등록된 옵션 아이디
 */

