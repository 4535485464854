import React from 'react'
import styled from '@emotion/styled'
import { CircledCardImage } from '../commonCard/CircledCardImage'
// import { CardTitleInfo } from '../commonCard/CardTitleInfo'
import { CircledCardTitleDescInfo  } from '../commonCard/CircledCardTitleDescInfo'

const ArtistCardCircledContainer = styled.div({}, ({ ismobile }) => {
  
  const cardSize = {}

  if (ismobile) {
    cardSize.width = '96px'
    cardSize.height = '140px' // image 144 + title 32
  } else {
    cardSize.width = '144px'
    cardSize.height = '203px' // image 144 + title 32
  }
    
  const baseStyle = {
    ...cardSize,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    boxShadow: 'none',
    transition: 'box-shadow 0.5s',
    position: 'relative',
    // border: '0.5px solid #ccc',
    // borderRadius: '4px 4px 0px 0px',
  }
  const mobileStyle = {
    // padLeft: '8px',
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ArtistCardCircledImageWrapper = styled.div({}, ({ ismobile }) => {

  const baseStyle = {
    width : '144px',
    height: '144px'    
  }
  
  const mobileStyle = {
    width : '96px',
    height: '96px'
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ArtistCardCircledInfoWrapper = styled.div({}, ({ ismobile }) => {

  const baseStyle = {
    width :'144px',
    height: '39px'
  }
  
  const mobileStyle = {
    width :'96px',
    height: '34px'
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function ArtistCardCircled(props) {
  const { title, description, imageurl, clickcallback, clickcallbackparam, ismobile } = props

  return (
    <ArtistCardCircledContainer onClick={()=>clickcallback(clickcallbackparam)} ismobile={ismobile}>
      <ArtistCardCircledImageWrapper ismobile={ismobile}>
        <CircledCardImage imageUrl={imageurl} ismobile={ismobile}>
        </CircledCardImage>
      </ArtistCardCircledImageWrapper>
      <ArtistCardCircledInfoWrapper ismobile={ismobile}>
        {/* <CardTitleInfo title={title} ismobile={ismobile}>
        </CardTitleInfo> */}
        <CircledCardTitleDescInfo title={title} description={description} ismobile={ismobile}>

        </CircledCardTitleDescInfo>
      </ArtistCardCircledInfoWrapper>      
    </ArtistCardCircledContainer>
  )
}

