import React from 'react'
import styled from '@emotion/styled'

const DesktopWrapper = styled.div({}, ({ }) => {
  const style = {
    width: '100%',
    height : 'auto',
  }

  return style
})

const DesktopBody = styled.div({}, ({ }) => {

  const style = {
    width: '1200px',
    margin: '0 auto',
    padding: '0 32px'
  }

  return style
})

const DesktopFlexGap = styled.div({}, ({ gutter }) => {

  return gutter === '' ? {}
  : {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '24px ' + gutter,
  }

})

export function Desktop(props) {
  const { children, column, gutter='' } = props
  
  return (
    <DesktopWrapper>
      <DesktopBody>
        <DesktopFlexGap gutter={gutter}>
          {children}
        </DesktopFlexGap>
      </DesktopBody>
    </DesktopWrapper>
  )
}