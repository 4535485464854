import React from 'react';
import styled from "@emotion/styled";
import { ConfirmField } from '~/react/popups/payment/ConfirmField';
import codeConst from '~/utils/codeConst';
import { log } from '~/communicator/utils/logging';

const ModalContainer = styled.div`
  padding: 24px 16px;
  background-color: white;
`;

const ModalBody = styled.div`
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
`;

const ModalBottom = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

const BackButton = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #cccccc;
`;

const ConfirmButton = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: #ED3E27;
  color: white;
  border: none;
`;

const InfoContainer = styled.div`
  width: 100%;
  background-color: #F2F2F2;
  padding: 10px 8px;
  border-radius: 4px;
  border: none;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
`;

const Description = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #555555;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

/**
 * 이벤트 결제 확인 팝업 컴포넌트
 * @param {ShippingInfo} [shippingInfo] 배송 데이터
 * @param {EventInfo} [eventInfo] 이벤트 응모 데이터
 * @param {() => void} onCancel
 * @param {() => void} onConfirm
 * @param {VueI18n} $i18n
 * @returns {JSX.Element}
 */
export function PaymentConfirmPopup({ eventInfo, shippingInfo, onCancel, onConfirm, $i18n }) {

  const getMessengerData = (messengerType, messengerId) => {
    try {
      const { MESSENGER_CODE_I18N_MAPPER } = codeConst;
      const typeString = $i18n.t(MESSENGER_CODE_I18N_MAPPER[messengerType])
      return `${typeString} / ${messengerId}`
    } catch (e) {
      log('error', '이벤트 주문 확인 팝업 - 응모 메신저 타입 변환 실패')
      return ''
    }
  };

  const getFullAddress = (shippingInfo) => {
    if (!shippingInfo) {
      throw new Error('Shipping Info 객체가 존재하지 않음');
    }
    const addresses = [shippingInfo.address, shippingInfo.addressDetail, shippingInfo.city, shippingInfo.state].filter(item => item !== undefined && item !== null && item !== '')
    return `${addresses.join(',')}`
  };

  return (
    <ModalContainer>
      <ModalBody>
        <InfoContainer>
          <Title>{$i18n.t('payMent.paymentConfirmTitle')}</Title>
          <Description>{$i18n.t('payMent.paymentConfirmDescription')}</Description>
        </InfoContainer>
        {eventInfo && (
          <List>
            <ConfirmField name={$i18n.t('payMent.applicantName')} data={eventInfo.name} />
            <ConfirmField name={$i18n.t('project.birthDayOnly')} data={eventInfo.birthday} />
            <ConfirmField name={$i18n.t('payMent.applicantNumber2')} data={eventInfo.telNumber} />
            <ConfirmField name={$i18n.t('payMent.messengerInformation')} data={getMessengerData(eventInfo.messengerType, eventInfo.messengerId)} />
          </List>
        )
        }
        {
          shippingInfo && (
            <List>
              <ConfirmField name={$i18n.t('payMent.recipientNumber')} data={shippingInfo.telNumber} />
              <ConfirmField name={$i18n.t('payMent.address')} data={getFullAddress(shippingInfo)} />
            </List>
          )
        }
      </ModalBody>
      <ModalBottom>
        <BackButton onClick={onCancel}>{$i18n.t('payMent.edit')}</BackButton>
        <ConfirmButton data-tagging-id={"event_pay_ok_btn"} onClick={onConfirm}>{$i18n.t('project.ok')}</ConfirmButton>
      </ModalBottom>
    </ModalContainer>
  )
}


/**
 * @typedef ShippingInfo 배송 정보
 * @property {string} telNumber
 * @property {string} address
 * @property {string} addressDetail
 * @property {string} [city]
 * @property {string} [state]
 */


/**
 * @typedef EventInfo 이벤트 응모 정보
 * @property {string} name
 * @property {string} telNumber
 * @property {string} birthday
 * @property {string} messengerType
 * @property {string} messengerId
 */
