import React from 'react'
import styled from '@emotion/styled'
import { gray, white } from '~/react/Foundation/Color'
import { B2Regular, C1Medium } from '~/react/Foundation/Typography'
import { Icon } from '~/react/Foundation/Icon'
import { DropDownList } from './DropDownList'
import { useDropdown } from './useDropdown'

function ToggleArrow({isShow, ...props}) {
  return isShow ? (
    <Icon type='arrow_up_small' color={gray[70]} {...props}></Icon>
  ) : (
    <Icon type='arrow_down_small' color={gray[70]} {...props}></Icon>
  )
}

const SizeStyle = {
  large: { height: '44px', radius: '6px', iconsize: 24 },
  medium: { height: '36px', radius: '6px', iconsize: 22 }
}

const Container = styled.div({
  position: 'relative',
  display: 'flex',
  boxSizing: 'border-box'
}, ({ size }) => {
  return {
    width: SizeStyle[size].width,
    height: SizeStyle[size].height,
  }
})

const SelectedContainer = styled(C1Medium)({}, ({ isListOpen, size, isDisabled }) => {
  const baseStyle = {
    width: '100%',
    padding: '8px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    border: `1px solid ${gray[50]}`,
    borderRadius: SizeStyle[size].radius,
    backgroundColor: white,
    color: gray[90],
    textAlign: 'left',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
  if (isListOpen) {
    return {
      ...baseStyle,
      border: `1px solid ${gray[70]}`
    }
  }
  if (isDisabled) {
    return {
      ...baseStyle,
      backgroundColor: gray[20],
      color: gray[50]
    }
  }
  return baseStyle
});

const Placeholder = styled(B2Regular)`
  color: ${gray[60]}
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const NativeSelect = styled.select({
  width: '100%',
  padding: '8px',
  paddingRight: '32px',
  fontSize: '14px',
  border: `1px solid ${gray[50]}`,
  borderRadius: '6px',
  backgroundColor: white,
  color: gray[90],
  cursor: 'pointer',
  appearance: 'none',
  '-webkit-appearance': 'none',
  '-moz-appearance': 'none',
  textAlignLast: 'left'
}, ({ size, isDisabled }) => ({
  height: SizeStyle[size].height,
  ...(isDisabled && {
    backgroundColor: gray[20],
    color: gray[50],
    cursor: 'not-allowed'
  })
}));

const ArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  pointer-events: none;  // select를 클릭해도 아이콘이 클릭 이벤트를 방해하지 않도록 설정
`;

/**
 * @typedef DropDownProps
 * @property {'medium' | 'large'} size 사이즈
 * @property {{key: string, value: string}[]} itemList 드롭다운 리스트 목록
 * @property {(key: string) => void} onSelectItem 드롭다운 선택 시 실행할 function
 * @property {string} selectedItemKey
 */

/**
 * 기본 DropDown Component
 * @param {DropDownProps} props
 * @returns {JSX.Element}
 */
export function DropDown({
    size='medium',
    itemList,
    onSelectItem,
    selectedItemKey,
    scrollable = false,
    placeholder = '',
    isDisabled = false
  }) {
  const {
    isListVisible,
    selectedItem,
    toggleDropdown,
    closeDropdown,
    onClickItem
  } = useDropdown(itemList, selectedItemKey, onSelectItem, isDisabled)

  return (
    <Container tabIndex={0} onClick={toggleDropdown} onBlur={closeDropdown} size={size}>
      {scrollable ? (
        <SelectWrapper>
          <NativeSelect
            value={selectedItem.key}
            onChange={(e) => onClickItem(e.target.value)}
            size={size}
            disabled={isDisabled}
          >
            {placeholder && <option value="" disabled hidden>{placeholder}</option>}
            {itemList.map(item => (
              <option key={item.key} value={item.key}>
                {item.value}
              </option>
            ))}
          </NativeSelect>
          <ArrowWrapper>
            <ToggleArrow isShow={false} size={SizeStyle[size].iconsize} />
          </ArrowWrapper>
        </SelectWrapper>
      ) : (
        <>
          <SelectedContainer size={size} isListOpen={isListVisible} isDisabled={isDisabled}>
            {selectedItem.key ? (
              <B2Regular>{selectedItem.value}</B2Regular>
            ) : (
              <Placeholder>{placeholder}</Placeholder>
            )}

            <ToggleArrow isShow={isListVisible} size={SizeStyle[size].iconsize} />
          </SelectedContainer>
          {isListVisible &&
            <DropDownList
              selectedKey={selectedItem.key}
              infoList={itemList}
              onchange={onClickItem}
              scrollable={scrollable}
            />}
        </>
      )}
    </Container>
  )
}

