import styled from '@emotion/styled';
import { C1_STYLES, C2_STYLES } from './styles';

/** 
 * Caption 1 (C1) 
 * Font-weight: 700
 * Font-size: PC, Mobile: 12px
*/
export const C1Bold = styled.div({}, ({ ismobile }) => {
  return ismobile ? C1_STYLES.mobile.bold : C1_STYLES.pc.bold
});
/** 
 * Caption 1 (C1) 
 * Font-weight: 500
 * Font-size: PC, Mobile: 12px
*/
export const C1Medium = styled.div({}, ({ ismobile }) => {
  return ismobile ? C1_STYLES.mobile.medium : C1_STYLES.pc.medium
});
/** 
 * Caption 1 (C1) 
 * Font-weight: 400
 * Font-size: PC, Mobile: 12px
*/
export const C1Regular = styled.div({}, ({ ismobile }) => {
  return ismobile ? C1_STYLES.mobile.regular : C1_STYLES.pc.regular
});
/** 
 * Caption 2 (C2) 
 * Font-weight: 700
 * Font-size: PC, Mobile: 10px
*/
export const C2Bold = styled.div({}, ({ ismobile }) => {
  return ismobile ? C2_STYLES.mobile.bold : C2_STYLES.pc.bold
});
/** 
 * Caption 2 (C2) 
 * Font-weight: 500
 * Font-size: PC, Mobile: 10px
*/
export const C2Medium = styled.div({}, ({ ismobile }) => {
  return ismobile ? C2_STYLES.mobile.medium : C2_STYLES.pc.medium
});

/** @deprecated C1Bold, C1Medium 중 하나 사용  */
export const C1Caption = styled.div({}, ({weight = 'medium', ismobile = false }) => {
  const baseStyle = {
    all: 'unset',
    fontSize: '12px',
    lineHeight: '16px'
  }
  const weightStyle = weight === 'bold' ? {
    fontWeight: '700'
  } : weight === 'medium' ? {
    fontWeight: '500'
  } : {
    fontWeight: '400'
  }

  const mobileStyle = {
    fontSize: '12px',
    lineHeight: '16px'
  }
  return ismobile ? {
    ...baseStyle, ...weightStyle, ...mobileStyle
  } : { 
    ...baseStyle, ...weightStyle
  }
})
/** @deprecated C2Bold, C2Medium 중 하나 사용  */
export const C2Caption = styled.div({}, ({weight = 'medium', ismobile = false }) => {
  const baseStyle = {
    all: 'unset',
    fontSize: '10px',
    lineHeight: '14px'
  }
  const weightStyle = weight === 'bold' ? {
    fontWeight: '700'
  } : weight === 'medium' ? {
    fontWeight: '500'
  } : {
    fontWeight: '400'
  }

  const mobileStyle = {
    fontSize: '10px',
    lineHeight: '14px'
  }
  return ismobile ? {
    ...baseStyle, ...weightStyle, ...mobileStyle
  } : { 
    ...baseStyle, ...weightStyle
  }
})