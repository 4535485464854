import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Icon } from '~/react/Foundation/Icon';
import { gray, red, white } from '~/react/Foundation/Color'

const ContainerLabel = styled.label({
  position: 'relative',
  display: 'inline-flex',
  minWidth: 0,
  alignItems: 'center',
  gap: '6px'
}, () => {});

const StyledCheckbox = styled.input({
  padding: 0,
  margin: 0,
  position: 'absolute',
  width: '100%',
  height: '100%',
  opacity: 0,
}, () => {
})

const CheckIcon = styled.div({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '2px',
  border: `1px solid ${gray[50]}`,
  backgroundColor: white,
  borderRadius: '2px',
  boxSizing: 'border-box',
  cursor: 'pointer'
},({ size, isChecked, isDisabled }) => {
  const sizeStyle = {
    width: size === 'small' ? '16px' : '20px',
    height: size === 'small' ? '16px' : '20px',
  }
  const checkedStyle = {
    borderColor: isDisabled ? red[10] : red[50],
    backgroundColor: isDisabled ? red[10] : red[50],
  }
  const disabledStyle = {
    backgroundColor: gray[20]
  }
  return {
    ...sizeStyle,
    ... isDisabled && disabledStyle,
    ...isChecked && checkedStyle
  }
})

/**
 * @typedef CheckboxProps
 * @property {'small' | 'medium'} size 사이즈
 * @property {string} value checkbox 값
 * @property {boolean} [isChecked] 체크상태
 * @property {boolean} [isDisabled] 활성화 상태
 * @property {(value:string) => void} [onChange] change 이벤트
 * @property {React.ReactNode} [children] 추가 라벨 등이 필요할 경우
 * @property {string} [name] html name 속성
 */
/**
 * @param {CheckboxProps} param
 * @returns
 */
export function Checkbox ({ size, value='on', isChecked=false, isDisabled=false, onChange=()=>{}, children, name, ...props }){
  const [innerChecked, setInnerChecked] = useState(false)

  const handleChange = (event) => {
    setInnerChecked(event.currentTarget.checked)
    onChange(event.currentTarget.checked)
  }

  useEffect(() => {
    setInnerChecked(isChecked)
  }, [isChecked])

  return (
    <ContainerLabel>
      <StyledCheckbox type="checkbox" value={value} onChange={handleChange} name={name} disabled={isDisabled}></StyledCheckbox>
      <CheckIcon isChecked={innerChecked} size={size} isDisabled={isDisabled}>
        <Icon type="check" size={size === 'small' ? 16 : 20} color={innerChecked ? white : 'transparent'}></Icon>
      </CheckIcon>
      {children}
    </ContainerLabel>
  )
}
