/** MSA 서버로 직접 요청하는 경우 Base URL 사용 */
const baseUrl = process.env.API_SERVER_URL

export default function(request) {
  return {
    eventList(data) {
      const url = baseUrl + '/board/v1/event'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    eventDetail(params, Idx) {
      const url = baseUrl + '/board/v1/event/' + Idx
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params
      }
      return request.$get(url, config)
    },
    eventDetailContent(Idx) {
      const url = baseUrl + '/board/v1/event/' + Idx + '/detail'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    eventSearchList(data) {
      const url = '/board/v1/event/search'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    }

  }
}
