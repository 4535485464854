export default function(request) {
  return {
    getShortUrl(longUrl) {
      const params = {
        url: longUrl
      }
      const url = '/external/v1/shortUrl'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, params, config)
    },
    getS3RemoteConfig(keyword) {
      const params = {
        keyword
      }
      const url = '/external/v1/remote/config'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, params, config)
    }
  }
}
