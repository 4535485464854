export const state = () => ({
  cartAmount: 0,
  commentItem: '',
  redirectUrl: '',

  /**
   * 소셜로그인 OAuth 인증 후 로그인 불가 시
   * 서비스 가입 처리를 위해 필요한 데이터
   */
  socialLoginException: {
    socialType: '',
    socialId: '',
    email: '',
    error: '',
    exception: ''
  }
})

export const mutations = {
  SET_CART_AMOUNT: (state, amount) => {
    state.cartAmount = amount
  },
  SET_REDIRECT_URL: (state, redirectUrl) => {
    state.redirectUrl = redirectUrl
  },
  SET_COMMENMT_ITEM: (state, commentItem) => {
    state.commentItem = commentItem
  },
  SET_SOCIAL_LOGIN_EXCEPTION: (state, socialLoginException) => {
    state.socialLoginException = socialLoginException
  }
}

export const actions = {
  setUserInfo({ commit }, userInfo) {
    // console.log('2222', userInfo)
    commit('SET_SOCIAL_LOGIN', userInfo)
  },
  setCartAmount({ commit }, amount) {
    commit('SET_CART_AMOUNT', amount)
  },
  setRedirectUrl({ commit }, redirectUrl) {
    commit('SET_REDIRECT_URL', redirectUrl)
  },
  setCommentItem({ commit }, commentItem) {
    commit('SET_COMMENMT_ITEM', commentItem)
  },
  setSocialLoginException({ commit }, socialLoginException) {
    commit('SET_SOCIAL_LOGIN_EXCEPTION', socialLoginException)
  }
}

export const getters = {
  getCommentItem: (state) => {
    return state.commentItem
  },
  getSocialLoginException: (state) => {
    return state.socialLoginException
  }
}
