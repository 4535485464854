import React from 'react'
import { Desktop, Mobile } from '../../Foundation/Spacing'

export function LayoutContainer(props) {
  const { children, ismobile, gutter='' } = props
  return ismobile ? 
    <Mobile gutter={gutter}>
      { children }
    </Mobile>
    : <Desktop gutter={gutter}>
      { children }
    </Desktop>
}