export function welcomeToMakestar() {
  const red = 'color:#E8351D'
  const org = 'color:#E17D37'
  const grn = 'color:#00A49D'
  const blu = 'color:#006A8B'
  const blk = 'color:#000000'
  const l1 = '%c  _     _     %c_    %c_   _   %c____   %c___  ______   _     ____ \n'
  const l2 = '%c | \\   / |   %c/ \\  %c| | / / %c| ___| %c/ __||__  __| / \\   / __ \\ \n'
  const l3 = '%c |  \\ /  |  %c//\\ \\ %c| |/ /  %c||___ %c| |___   | |  //\\ \\  ||__| |  \n'
  const l4 = '%c ||\\   / | %c//__\\ \\%c| /\\ \\  %c| ___| %c\\__  \\  | | //__\\ \\ ||_  / \n'
  const l5 = '%c |_ \\_/|_|%c/_/  \\__%c|_| \\_\\ %c||___  %c/____|  |_|/_/  \\__||| \\_\\ \n'
  const l6 = '%c                        Make Your Own Star \n'
  console.log(l1 + l2 + l3 + l4 + l5 + l6, red, org, grn, blu, red, red, org, grn, blu, red, red, org, grn, blu, red, red, org, grn, blu, red, red, org, grn, blu, red, blk)
}
