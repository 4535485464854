import React from 'react'
import styled from '@emotion/styled'

const BannerCardInfoContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    // padding: '12px 20px 16px 20px',
    position: 'absolute',
    width: '569px',
    height: '320px',
    
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'


  }
  const mobileStyle = {
    position: 'absolute',
    height: '211px',
    width: '100%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  } : {
    ...baseStyle
  }
})

const CardTitle = styled.div({}, ({ ismobile }) => {

  const baseStyle = {
    position: 'absolute',
    // position: 'relative',
    // minHeight: '18px',
    // maxHeight: '38px',
    // color: '#FFFFFF',
    // textOverflow: 'ellipsis',
    // wordWrap: 'break-word',
    // overflowY: 'hidden',
    // fontSize: '15px',
    // fontWeight: '400',
    // lineHeight: '20px',
    // letterSpacing: '0em',
    // textAlign: 'left'

    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: '#FFFFFF',
    zIndex: '11'

  }
  const mobileStyle = {
    fontSize: '13px',
    lineHeight: '17px !important',
    minHeight: '19px',
    maxHeight: '36px',
    textAlign: 'center',
    verticalAlign: 'middle'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const CardDescription = styled.div({}, ({ ismobile }) => {
  const style = {
    marginLeft: "8px",
    marginRight: "8px",

    position: 'relative',
    minHeight: '16px',
    maxHeight: '32px',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '28px',
    color: '#FFFFFF',
    marginTop: '4px',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    overflowY: 'hidden'
  }
  return style
})



export function BannerCardInfo(props) {
  const { title, description, ismobile } = props
  const descCaption = ismobile ? '' : description
  return (
    <BannerCardInfoContainer ismobile={ismobile}>
      <CardTitle ismobile={ismobile}> {title}
      </CardTitle>
      {/* <CardDescription ismobile={ismobile}> {descCaption}
      </CardDescription> */}
    </BannerCardInfoContainer>

  )
}
