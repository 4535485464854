import React from 'react'
import styled from '@emotion/styled'
import { CardTitle } from '../CardTitle'
import { CardDescription } from '../CardDescription'

const StyledContainer = styled.div({}, ({ ismobile, size }) => {
  const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: size === 'large' ? '4px': '2px',
    ...size === 'medium' && { minHeight: '70px' }
  }

  const mobileStyle = {
    ... size !== 'large' && { minHeight: '54px' }
  }
  return ismobile ? { ...baseStyle, ...mobileStyle } : baseStyle;
})
/**
 * @typedef CardProjectTitleAndDescProps
 * @property {boolean} ismobile
 * @property {'large' | 'medium' | 'small'} size 카드 사이즈
 * @property {string} title
 * @property {string} validDescription
 * @property {boolean} isshowdescription 설명 표시 여부
 */

/**
 *
 * @param {CardProjectTitleAndDescProps} param
 * @returns
 */
export function CardProjectTitleAndDesc ({ ismobile, size, title, validDescription, isshowdescription}) {
  return (
    <StyledContainer ismobile={ismobile} size={size}>
      <CardTitle title={title} ismobile={ismobile} size={size} />
      { size !== 'small' && isshowdescription && <CardDescription size={size} ismobile={ismobile} description={validDescription} />}
    </StyledContainer>
  )
}