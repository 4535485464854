import React from 'react'
import styled from '@emotion/styled'
import { gray } from '../../../Foundation/Color'
import { Icon } from '../../../Foundation/Icon'
import { B2Medium } from '../../../Foundation/Typography'


const ResetWrapper = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '2px'
  }
  return baseStyle
})

/**
 * @typedef ResetButtonProps
 * @property {() => void} handler 리셋 핸들러
 * @property {string} resetText 리셋 다국어 텍스트
 */
/**
 * @param {ResetButtonProps} props
 */
export function ResetButton ({ handler, resetText }){
  return (
    <ResetWrapper onClick={handler}>
      <Icon type={'restore'} color={gray[90]} size={16} />
      <B2Medium>{ resetText ?? '초기화' }</B2Medium>
    </ResetWrapper>
  )
}