
// const app = null

export default {
  name: 'Error',
  directives: {},
  components: {

  },
  filters: {},
  layout: 'errorLayout',
  data() {
    return {
      // app
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      }
    }
  },
  watch: {

  },
  mounted() {

  },
  created() {

  },
  destroyed() {

  },
  methods: {

  }
}
