import React from'react'
import styled from '@emotion/styled'
import { BannerCardImage } from './BannerCardImage'
import { CommonCardImage } from '../commonCard/CommonCardImage'
import { BannerCardInfo } from './BannerCardInfo'

const BannerCardContainer = styled.div({}, ({viewyn, size, ismobile}) => {
  
  const cursorStyle = viewyn === 'Y' ? {cursor: 'pointer'} : {cursor: 'not-allowed'}
  
  const baseStyle = {
    ...cursorStyle,
    position: 'relative',
    width : size.pc.width,
    height: size.pc.height
  }
  const mobileStyle = {
    width : size.mobile.width,
    height: size.mobile.height,
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle

})

const BannerCardWrapper = styled.div({}, ({ismobile}) => {
  const baseStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%'
  }
  const mobileStyle = {
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})


const BannerCardDimmer = styled.div({}, ({size, ismobile}) => {
  const baseStyle = {
    position: 'absolute',
    width : size.pc.width,
    height: size.pc.height,
    zIndex: '10',
    background: 'rgba(0, 0, 0, 0.7)'

  }

  const mobileStyle = {
    width : size.mobile.width,
    height: size.mobile.height,
  }
  
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function BannerCard(props) {
  const { imageurl, title, description, viewyn, pollclosedstring, clickcallback, routeurl, ismobile } = props

  const size = {
    pc: {
      width :'569px',
      height: '320px'
    }, 
    mobile:{
      width :'100%',
      height: '211px'
    }
  }
  const radius = '0px 0px 0px 0px'

  return (
    (viewyn === 'Y') ? 
    <BannerCardContainer viewyn={viewyn} ismobile={ismobile} size={size} onClick={() => clickcallback(routeurl)}>
      <BannerCardWrapper>
        <CommonCardImage imageUrl={imageurl} size={size} hoverEffect={'enlarge'} borderRadius={radius} ismobile={ismobile}>
        </CommonCardImage>
      </BannerCardWrapper>
    </BannerCardContainer>
    : 
    <BannerCardContainer viewyn={viewyn} ismobile={ismobile} size={size}>
      <BannerCardWrapper>
        <BannerCardImage imageUrl={imageurl} size={size} borderRadius={radius} ismobile={ismobile}>
        </BannerCardImage>
        <BannerCardInfo title={pollclosedstring} description={description} ismobile={ismobile}>
        </BannerCardInfo>
      </BannerCardWrapper>
      <BannerCardDimmer size={size} ismobile={ismobile}></BannerCardDimmer>
  </BannerCardContainer>
  )
}