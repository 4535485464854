

export default {
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  mounted() {}
}
