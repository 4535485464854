import React, {useState, useEffect, useCallback} from 'react'
import styled from '@emotion/styled'
import { gray, white } from '../../Foundation/Color'
import { H7Bold } from '../../Foundation/Typography'
import { ButtonFullWidth } from '../Button/ButtonFullWidth'
import { FilterButtonsWithLabel } from './Filter/FilterButtonsWithLabel'
import { ResetButton } from './Filter/ResetButton';
import { CloseIconButton } from '../Button/CloseIconButton'
import codeConst from '/utils/codeConst'

/**
 * @description 하단 타입(FilterItemType ~ SheetsProps 들은 추가 정의가 더 필요함 */
/**
 * @typedef {'sort' | 'view' | 'text'} FilterItemType 해당 라벨 타입 분류
 */

/**
 * @typedef FilterItem
 * @property {string} title 필터 라벨
 * @property {FilterItemType} type 필터 라벨의 타입 - 정렬, 보기형태, 텍스트
 * @property {(labelValue) => void} handler 필터 라벨 내 옵션 값 선택 시 수행할 handler
 */

/**
 * @typedef {FilterItem[]} FilterData
 */

/**
 * @typedef CategoryData
 * @property {string} title 카테고리 타이틀
 * @property {string[]} list 카테고리 리스트
 * @property {(categoryValue) => void} 카테고리 선택후, '보기'버튼 눌렀을 때 수행할 handler
 */

/**
 * @typedef SheetsProps
 * @property {'filter' | 'category'} sheetType sheets의 레이아웃 타입
 * @property {?FilterData} filterData sheets의 타입이 filter 일 때 데이터
 * @property {?CategoryData} categoryData sheets의 타입이 category일 때 데이터
 * @property {(closeState) => void} closeHandler dim 또는 close버튼을 클릭했을 때 수행할 handler
 */


const SheetsContainer = styled.div({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: white,
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
  bottom: '0',
  left: '0',
})

const SheetsHeader = styled.div({
  position: 'relative',
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  padding: '16px',
  justifyContent: 'space-between',
  borderBottom: '1px solid ' + gray[30],
  boxSizing: 'border-box',
  height: '72px',
  color: gray[90],
})

const SheetsBody = styled.div({
  padding: '24px 16px'
})

const FilterButtonChipContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    width: '100%',
    display: 'flex',
    height: 'auto',
    flexDirection: 'column',
    gap: '24px'
  }
  return baseStyle
})


const TitleWrapper = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
  return baseStyle
})


/**
 * @typedef Category
 * @property {string} code
 * @property {string} displayName
 */

/**
 * @typedef OptionItem
 * @property {string} key
 * @property {string} value - i18n displayName
 */

/**
 * @typedef SheetsPropsFromVue
 * @property {string} resettext 리셋 다국어 텍스트
 * @property {OptionItem[]} filterlist - status
 * @property {string} filterselectedkey
 * @property {string} filterlistcaption 필터의 라벨 캡션
 * @property {(key: string) => void} filteronchange
 * @property {OptionItem[]} sortlist - orderBy
 * @property {string} sortselectedkey
 * @property {string} sortlistcaption 정렬의 라벨 캡션
 * @property {(key: string) => void} sortonchange
 * @property {OptionItem[]} viewmodelist
 * @property {keyof codeConst.VIEW_MODE} viewmode
 * @property {string} viewmodelistcaption 보기 방식의 라벨 캡션
 * @property {(key: string) => void} viewmodehandle
 * @property {(key: keyof codeConst.DIALOG_TYPE) => void} closehandler
 * @property {(filterKey:string, sortKey:string, viewmodeKey: keyof codeConst.VIEW_MODE) => void} applysheethandle 필터 모두 설정 후 버튼 클릭 시 사용할 handler
 * @property {string} buttoncaption 버튼 캡션
 * @property {string} locale 국가 언어 코드
 */
/**
 *
 * @param {SheetsPropsFromVue} props
 * @returns
 */
export function Sheets(props) {
  const {
    resettext, title,
    filterlist=[], filterselectedkey, filterlistcaption, filteronchange,
    sortlist=[], sortselectedkey, sortlistcaption, sortonchange,
    viewmodelist=[], viewmode, viewmodelistcaption, viewmodehandle,
    closehandler, applysheethandle, buttoncaption,
    locale } = props

  const [filterValue, setFilterValue] = useState(filterselectedkey)
  const [sortValue, setSortValue] = useState(sortselectedkey)
  const [viewmodeValue, setViewmodeValue] = useState(viewmode)

  const handleFilterToggle = (param) => {
    setFilterValue(param)
  }
  const handleSortToggle = (s) => {
    setSortValue(s)
  }
  const handleViewmodeToggle = (v) => {
    setViewmodeValue(v)
  }
  const handleReset = () => {
    setFilterValue('')
    setSortValue('10')
    setViewmodeValue(codeConst.VIEW_MODE.GRID)
  }

  useEffect(() => {
  }, [filterValue, sortValue, viewmodeValue])

  const viewModeArrayWithIconName = useCallback(() => viewmodelist.map(viewmodeItem => ({
      ...viewmodeItem,
      iconName: `view_${(viewmodeItem.key).toLowerCase()}`
    })
  ), [viewmodelist])

  return (
    <SheetsContainer>
      <SheetsHeader>
        <ResetButton resetText={resettext} handler={handleReset} ></ResetButton>
        <TitleWrapper> <H7Bold ismobile> {title} </H7Bold> </TitleWrapper>
        <div style={{padding: '8px 0'}}>
          <CloseIconButton handler={()=>closehandler(codeConst.DIALOG_TYPE.FILTER)}></CloseIconButton>
        </div>
      </SheetsHeader>
      <SheetsBody>
        <FilterButtonChipContainer>
          {filterlist.length > 0 && <FilterButtonsWithLabel list={filterlist} selectedKey={filterValue} title={filterlistcaption} handleselect={handleFilterToggle} ismobile></FilterButtonsWithLabel>}
          {sortlist.length > 0 && <FilterButtonsWithLabel list={sortlist} selectedKey={sortValue} title={sortlistcaption} handleselect={handleSortToggle} ismobile></FilterButtonsWithLabel>}
          {viewmodelist.length > 0 && <FilterButtonsWithLabel list={viewModeArrayWithIconName()} selectedKey={viewmodeValue} title={viewmodelistcaption} handleselect={handleViewmodeToggle} ismobile></FilterButtonsWithLabel>}
        </FilterButtonChipContainer>
      </SheetsBody>
      <ButtonFullWidth clickcallback={applysheethandle} clickcallbackparam={[filterValue, sortValue, viewmodeValue]} text={buttoncaption} color={'red50'} type={'fill-width'} ismobile></ButtonFullWidth>
    </SheetsContainer>
  )
}
