import styled from '@emotion/styled';
import { H5_STYLES, H7_STYLES, H8_STYLES } from './styles';

/** 
 * Head 5 (H5) 
 * Font-weight: 700
 * Font-size: PC- 24px, Mobile: 22px
*/
export const H5Bold = styled.div({}, ({ ismobile }) => {
  return ismobile ? H5_STYLES.mobile.bold : H5_STYLES.pc.bold
});
/** 
 * Head 5 (H5) 
 * Font-weight: 500
 * Font-size: PC- 24px, Mobile: 22px
*/
export const H5Medium = styled.div({}, ({ ismobile }) => {
  return ismobile ? H5_STYLES.mobile.medium : H5_STYLES.pc.medium
});

/** 
 * Head 7 (H7) 
 * Font-weight: 700
 * Font-size: PC, Mobile: 20px
*/
export const H7Bold = styled.div({}, ({ ismobile }) => {
  return ismobile ? H7_STYLES.mobile.bold : H7_STYLES.pc.bold
});
/** 
 * Head 7 (H7) 
 * Font-weight: 500
 * Font-size: PC, Mobile: 20px
*/
export const H7Medium = styled.div({}, ({ ismobile }) => {
  return ismobile ? H7_STYLES.mobile.medium : H7_STYLES.pc.medium
});

/** 
 * Head 8 (H8) 
 * Font-weight: 700
 * Font-size: PC, Mobile: 18px
*/
export const H8Bold = styled.div({}, ({ ismobile }) => {
  return ismobile ? H8_STYLES.mobile.bold : H8_STYLES.pc.bold
});
/** Head 8 - 18px - medium - PC */
export const H8Medium = styled.div({}, ({ ismobile }) => {
  return ismobile ? H8_STYLES.mobile.medium : H8_STYLES.pc.medium
});

/** 이전 헤딩스타일 1 */
export const H1Heading = styled.h1({

}, ({weight = 'bold', ismobile = false }) => {
  const baseStyle = {
    all: 'unset',
    fontSize: '32px',
    lineHeight: '46px'
  }
  const weightStyle = weight === 'regular' ? {
    fontWeight: '500'
  } : {
    fontWeight: '700'
  }
  const mobileStyle = {
    fontSize: '28px',
    lineHeight: '40px'
  }
  return ismobile ? {
    ...baseStyle, ...weightStyle, ...mobileStyle
  } : { 
    ...baseStyle, ...weightStyle
  }
})
/** 이전 헤딩스타일 3 */
export const H3Heading = styled.h3({}, ({weight = 'bold', ismobile}) => {
  const baseStyle = {
    all: 'unset',
    fontSize: '28px',
    lineHeight: '34px'
  }
  const weightStyle = weight === 'medium' ? {
    fontWeight: '500'
  } : {
    fontWeight: '700'
  }
  const mobileStyle = {
    fontSize: '24px',
    lineHeight: '36px'
  }
  return ismobile ? {
    ...baseStyle, ...weightStyle, ...mobileStyle
  } : { 
    ...baseStyle, ...weightStyle
  }
})
/** @deprecated H5Bold, H5Medium 중 하나 사용  */
export const H5Heading = styled.h5({}, ({weight = 'bold', ismobile}) => {
  const baseStyle = {
    all: 'unset',
    fontSize: '24px',
    lineHeight: '36px'
  }
  const weightStyle = weight === 'medium' ? {
    fontWeight: '500'
  } : {
    fontWeight: '700'
  }
  const mobileStyle = {
    fontSize: '22px',
    lineHeight: '32px'
  }
  return ismobile ? {
    ...baseStyle, ...weightStyle, ...mobileStyle
  } : { 
    ...baseStyle, ...weightStyle
  }
})

/** @deprecated H7Bold, H7Medium 중 하나 사용  */
export const H7Heading = styled.div({}, ({weight = 'bold', ismobile}) => {
  const baseStyle = {
    all: 'unset',
    fontSize: '20px',
    lineHeight: '30px'
  }
  const weightStyle = weight === 'medium' ? {
    fontWeight: '500'
  } : {
    fontWeight: '700'
  }
  const mobileStyle = {
    fontSize: '20px',
    lineHeight: '30px'
  }
  return ismobile ? {
    ...baseStyle, ...weightStyle, ...mobileStyle
  } : { 
    ...baseStyle, ...weightStyle
  }
})

/** @deprecated H8Bold, H8Medium 중 하나 사용  */
export const H8Heading = styled.div({}, ({weight = 'bold', ismobile}) => {
  const baseStyle = {
    all: 'unset',
    fontSize: '18px',
    lineHeight: '26px'
  }
  const weightStyle = weight === 'medium' ? {
    fontWeight: '500'
  } : {
    fontWeight: '700'
  }
  const mobileStyle = {
    fontSize: '18px',
    lineHeight: '26px'
  }
  if( ismobile ){
    console.log('There is no H8Heading for Mobile, Please check Design Guide')
  }
  return ismobile ? {
    ...baseStyle, ...weightStyle, ...mobileStyle
  } : { 
    ...baseStyle, ...weightStyle
  }
})