export const red = {
  10: '#FBD5CF',
  20: '#F7ABA0',
  30: '#F48171',
  40: '#F05742',
  50: '#ED3E27',
  60: '#E22B12',
  70: '#BD240F',
  80: '#971D0C',
  90: '#6D1509'
} 

export const orange = {
  10: '#FFE1CC',
  20: '#FFC499',
  30: '#FFA666',
  40: '#FF8833',
  50: '#FF7614',
  60: '#F56600',
  70: '#D65900',
  80: '#A34400',
  90: '#753100',
}
export const green = {
  10: '#D2F9F8',
  20: '#A3EFEC',
  30: '#69E0DA',
  40: '#42D8D2',
  50: '#15C1B9',
  60: '#01ADA6',
  70: '#008E88' ,
  80: '#016964',
  90: '#00524E',

}
export const cyan = {
  10: '#D2F5FF',
  20: '#97E0F7',
  30: '#5ECCED',
  40: '#1AB5E6',
  50: '#00ABE0',
  60: '#008DB8',
  70: '#017598',
  80: '#005E7A',
  90: '#00465B',
}
export const blue = {
  10: '#D2EAFF',
  20: '#CEE7FD',
  30: '#9DD0FB',
  40: '#6CB8F9',
  50: '#3BA0F7',
  60: '#178EF4',
  70: '#006FCE',
  80: '#00539B',
  90: '#00325E',
}
export const cherryred = {
  10: '#FFE0E0',
  20: '#FFC2C2',
  30: '#FF8F8F',
  40: '#FF6666',
  50: '#FF4949',
  60: '#ED1515',
  70: '#CC0000',
  80: '#A30000',
  90: '#660000',
}


export const white = '#FFFFFF'
export const black = '#000000'
export const gray = {
  10: '#FAFAFA',
  20: '#F2F2F2',
  30: '#EAEAEA',
  40: '#DEDEDE',
  50: '#CCCCCC',
  60: '#AAAAAA',
  70: '#888888',
  80: '#555555',
  90: '#333333',
  100: '#111111'  
}


export const progressGreen = {
  background: 'linear-gradient(270deg, #00C7BE 0%, #A3EFEB 100%)',
}
export const progressRed = {
  background: 'linear-gradient(270deg, #ED3E27 0%, #F8ABA0 100%)',
}


