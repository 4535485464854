import React, {useState} from 'react'

import styled from '@emotion/styled'
import { B2Bold, B2Regular } from '../../Foundation/Typography'
import { gray, white } from '../../Foundation/Color'

const InfoOption = styled.div({}, ({ isSelected, isHovering }) => {
  return {
    backgroundColor: isHovering ? gray[20] : white,
    cursor: 'pointer',
    padding: '10px 8px',
    display: 'flex',
    alignItems: 'center'
  }
})

export function DropDownListItem(props) {
  const { content, onchange, id, isSelected } = props
  const [isHovering, setIsHovering] = useState(0)


  const handleSelectValue = (id) => {
    onchange(id)
  }

  return (
    <InfoOption
      onMouseOver={() => setIsHovering(1)}
      onMouseOut={() => setIsHovering(0)}
      isSelected={isSelected}
      isHovering={isHovering}
      onClick={() => {
        handleSelectValue(id)
      }}>
      { isSelected ? (<B2Bold>{content}</B2Bold>) : (<B2Regular>{content}</B2Regular>)}

    </InfoOption>
  )
}

