import SecureLS from 'secure-ls'
import { getGtmLoginInfo } from '~/utils/common'

export default async function({ $gtm, $makeStarApi, store }) {
  // gtm loginInfo 데이터를 사용하려면 SecureL로 암호화된 데이터가 필요로함.
  const ls = new SecureLS({ isCompression: false, encryptionSecret: process.env.VUEXSECRETKEY })

  // localstorage 정보 여부 확인
  const data = ls.get('u@ins') || '{}'

  // store로 입력한 데이터가 LocalStorage에 저장이 되고 해당 값을 가져오기는 부분
  const result = data ? JSON.parse(data) : {}

  const { modules: { loginuserinfo: { loginInfo } = { loginInfo: false }} = { loginuserinfo: {}}} = result

  // 로그인 중인지 아닌지 확인하기 위해 api 호출을 진행
  const res = await $makeStarApi.user.loginUserProfile()

  const { loggedInUser } = res.resData || {}
  if (!loggedInUser) {
    // 로그인 되어 있지 않으면, localStorage 데이터와 store데이터를 모두 초기화 해야해서 다 초기화 함.
    // ls.remove('u@ins')
    await store.dispatch('modules/loginuserinfo/setLoginInfo', {})
  }

  // 새로고침 후 gtm이 초기화 되기 전 dataLayer에 초기 값을 넣어주는 부분
  if (loginInfo && JSON.stringify(loginInfo) !== '{}') {
    $gtm.push({ ...getGtmLoginInfo(loginInfo) })
  }

  $gtm.init(process.env.GOOGLE_TAG_ID)
}
