import React from 'react'
import styled from '@emotion/styled';
const SectionForm = styled.div({}, (props) => {
  const style = {
    border: '1px solid #d8d8d8',
    padding: '50px',
    marginTop: '10px',
    backgroundColor: 'white'
  }
  return props['ismobile'] ? { width: '100%' } : style
});
export function ProjectForm(props) {
  const { ismobile, children } = props
  return (
    <SectionForm ismobile={ismobile}>
      {children}
    </SectionForm>
  )
}
