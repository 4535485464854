import React from 'react';
import styled from '@emotion/styled';
const Wrapper = styled.div`
    display: flex;
    background-color: #000000B2;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 99999;
    position: fixed ;
`
const ReactiveContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 1136px;
  height: calc(100vh - 136px);
  background-color: white;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  @media (min-width: 849px) {
    width: calc(100% - 96px);
  }
  @media (max-width: 849px) {
    width: calc(100% - 80px);
  }
`
function App(props) {
  const { title, htmlContent, close } = props

  return (
    <Wrapper>
      <ReactiveContent>
        <div style={{
          display: 'flex',
          height: '72px',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '0 20px 0 16px',
          fontSize: '18px',
          fontWeight: '700',
          fontFamily: 'Pretendard JP'
        }}>
          <div>
            {title}
          </div>
          <div onClick={()=>close(false)}>
            <img src={require('/assets/icon/close.svg')} width="18px" style={{cursor: 'pointer'}}/>
          </div>
        </div>
        <div style={{
          flex: '1',
          width: '100%',
          height: '100%',
          padding: '60px 32px 0',
          overflowY: 'auto'
        }}
        dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </ReactiveContent>
    </Wrapper>
  );
}

export default App;
