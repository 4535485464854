import React from 'react'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
export const FlexCenter = styled.div({
  display: 'flex',
  alignItems: 'center'
})
const scale = keyframes({
  '0%': {
    transform: 'scale(0)'
  },
  '100%': {
    transform: 'scale(1)'
  }
})

const RadioCom = styled.input({
  display: 'none',
  '& + div:before, & + div:after': {
    content: '""',
    border: '1px solid #232323',
    width: '22px',
    height: '22px',
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: '50%'
  },
  '&:checked + div:after': {
    // backgroundColor: '#E8351D',
    border: '6px solid #E8351D',
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    animation: `${scale} .1s ease-in`,
    boxShadow: 'inset 0 0 0 6px #FFF'
  }
}
)

const RadioIcon = styled(FlexCenter)({
  position: 'absolute',
  width: '22px',
  height: '22px'
})
const RadioContent = styled(FlexCenter)({
  marginLeft: '25px'
})

export function Radio(props) {
  const { id, name, checked = true, onChange, value, children } = props
  const radioAttr = {
    id, name, checked, onChange: e => onChange(e), value
  }
  // console.log('checked', checked, value, name)
  return (
    <FlexCenter>
      <RadioCom
        type='radio'
        {...radioAttr}
      >
      </RadioCom>
      <RadioIcon/>
      <RadioContent>{children} </RadioContent>
    </FlexCenter>
  )
}
