/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { createContext, useContext, useMemo, useState } from 'react'
import { Radio } from '../Radio'

const ItemGroupContext = createContext({ activeId: 0, onSelect: undefined })
function ItemGroup({ children, activeId, onSelect }) {
  const value = useMemo(
    () => ({
      activeId,
      onSelect
    }),
    [activeId, onSelect]
  )
  return (
    <ItemGroupContext.Provider value={value}>
      {children}
    </ItemGroupContext.Provider>
  )
}
function useItemGroup() {
  const value = useContext(ItemGroupContext)
  if (value === undefined) {
    throw new Error('Item component should be used within ItemGroup')
  }
  return value
}

const FlexCenter = styled.div({
  display: 'flex',
  alignItems: 'center'
})

const LabelCom = styled.label({
  fontSize: '16px',
  fontWeight: 700,
  color: '#333',
  fontFamily: '"Noto Sans","Noto Sans KR",sans-serif',
  marginBottom: '8px',
  position: 'relative'
},
({ locale }) => ({
  width: locale === 'ko' ? '25%' : 'fit-content',
  marginRight: locale === 'ko' ? '' : '12px'
}))

const RadioBox = styled(FlexCenter)({
  flexFlow: 'row wrap',
  margin: '20px 0 8px 0'

})

const VenderSelect = styled.select({
  width: '100%',
  height: '40px',
  textIndent: '10px',
  border: '1px solid #d8d8d8',
  borderRadius: '5px',
  margin: '8px 0px',
  backgroundImage: 'linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)',
  backgroundPosition: 'calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em',
  backgroundSize: '5px 5px, 5px 5px, 1.5em 1.5em',
  backgroundRepeat: 'no-repeat'
})

function VenderRadioGrop({ list, locale }) {
  const { activeId, onSelect } = useItemGroup()
  const result = []

  list.map((item, index) => {
    const radioAttr = {
      type: 'radio',
      name: 'VenderSelect',
      id: `vendor-${index}`,
      value: item.value,
      onChange: (e) => {
        const { target } = e

        onSelect(target.value)
      }
    }

    result.push(
      <LabelCom locale={locale} key={`vendor-${index}`}>
        <Radio {...radioAttr} checked={activeId === item.value}>
          <span className='fs-16 bold color-black2'>
            {item.name}
          </span>
        </Radio>
      </LabelCom>
    )
  })

  return (
    <RadioBox>
      {result}
    </RadioBox>
  )
}

function VenderSelectBox({ list, choiceplz = '선택 하세요.' }) {
  const { activeId, onSelect } = useItemGroup()
  const result = []
  const selectAttr = {
    defaultValue: '',
    name: 'VenderSelect',
    id: `vendorSelect`,
    value: activeId,
    onChange: (e) => {
      const { target } = e
      onSelect(target.value)
    }
  }
  list.map((item, index) => {
    result.push(
      <option key={index} value={item.value}> {item.name}</option>
    )
  })
  // class="border-gray border-radius"
  return (
    <VenderSelect {...selectAttr}>
      <option value='' disabled selected>{ choiceplz }</option>
      {result}
    </VenderSelect>
  )
}

export function VendorSelect(props) {
  const { value, update, locale, ismobile } = props
  let { list } = props
  const [activeId, setActiveId] = useState(value)
  useEffect(() => {
    setActiveId(value)
  }, [value])

  list = list !== undefined
    ? list
    : [
      {
        name: '신한카드',
        value: 0
      },
      {
        name: '페이코',
        value: 1
      },
      {
        name: '현대카드',
        value: 2
      },
      {
        name: '메이크스타카드',
        value: 3
      },
      {
        name: '삼성카드',
        value: 4
      }
    ]

  return (
    <ItemGroup activeId={activeId} onSelect={(value) => {
      if (update !== undefined) update(value)
      setActiveId(value)
    }}>
      {ismobile ? <VenderSelectBox list={ list } /> : <VenderRadioGrop list={ list } locale={locale}/>}
    </ItemGroup>
  )
}
