import React from 'react'
import styled from '@emotion/styled'
import { BadgeText } from '~/react'

const CardShopBadgeBody = styled.div({}, () => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px 4px',
    flexWrap: 'wrap'
  }
})

/**
 * @typedef Badge
 * @property {number} badgeIdx
 * @property {string} fileUrl
 * @property {Date} created_date
 * @property {number} priority
 */

/**
 * @param {{badgeList: Badge[]}} props
 * @returns {JSX.Element}
 */
export function CardShopBadge(props) {
  const { badgeList } = props

  /**
   * @param {Badge[]} badges
   */
  const sortBadgeList = (badges) => {
    const sortedBadges = [...badges];
    sortedBadges.sort((a,b)=> {
      if (a.priority !== b.priority) {
        return b.priority - a.priority
      }
      else {
        return new Date(a.created_date) - new Date(b.created_date)
      }
    })
    return sortedBadges
  }

  const spanBadgeList = sortBadgeList(badgeList).map(badge=>
    <BadgeText key={badge.badgeIdx} text={badge.name} />
  )

  return (
    <CardShopBadgeBody>
      {spanBadgeList}
    </CardShopBadgeBody>
  )
}
