/* eslint-disable no-const-assign */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Radio } from './Radio'

const Label = styled.label({},
  ({ locale }) => {
    const style = {}
    if (locale === 'ko') {
      style['fontSize'] = '20px'
    }
    return (style)
  })

export function DeliveryPriceRadio(props) {
  const { locale, children, callback, update, index, activeid, value, name } = props

  let innerValue = value
  if (innerValue === undefined) {
    innerValue = index
  }

  const radioAttr = {
    type: 'radio',
    name,
    value,
    onChange: (e) => {
      update(e.target.value)
      if (callback !== undefined) callback()
    }
  }
  return (<Label locale={locale}>
    <Radio {...radioAttr} checked={value === activeid}>
      <span className='fs-16 bold color-black2'>
        {children}
      </span>
    </Radio>
    <span className='checkmark'/>
  </Label>)
}
