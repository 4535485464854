/** MSA 서버로 직접 요청하는 경우 Base URL 사용 */
const baseUrl = process.env.API_SERVER_URL

export default function(request) {
  return {
    getPaymnetResultStepCheck(trxNo) {
      const url = baseUrl + '/pay/v1/query/result/step-check/' + trxNo
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    refundBankList(locale = 'ko') {
      /**
       * 은행 코드는 다음 개발자문서 참고
       * https://docs.tosspayments.com/reference/codes#%EC%9D%80%ED%96%89-%EC%BD%94%EB%93%9C
       */
      const bankList = {
        39: { ko: '경남은행', en: 'KYONGNAM BANK', code: '경남' },
        34: { ko: '광주은행', en: 'GWANGJU BANK', code: '광주' },
        12: { ko: '단위농협(지역농축협)', en: 'LOCAL NONGHYUP BANK', code: '단위농협' },
        32: { ko: '부산은행', en: 'BUSAN BANK', code: '부산' },
        45: { ko: '새마을금고', en: 'KOREAN FEDERATION OF COMMUNITY', code: '새마을' },
        64: { ko: '산림조합', en: 'SANLIM', code: '산림' },
        88: { ko: '신한은행', en: 'SHINHAN BANK', code: '신한' },
        48: { ko: '신협', en: 'SHINHYEOP', code: '신협' },
        27: { ko: '씨티은행', en: 'CITIBANK KOREA', code: '씨티' },
        20: { ko: '우리은행', en: 'OORI BANK', code: '우리' },
        71: { ko: '우체국예금보험', en: 'KOREA POST OFFICE', code: '우체국' },
        50: { ko: '저축은행중앙회', en: 'KOREA REDERATION OF SAVINGS BANK', code: '저축' },
        37: { ko: '전북은행', en: 'JEONBUK BANK', code: '전북' },
        35: { ko: '제주은행', en: 'JEJU BANK', code: '제주' },
        90: { ko: '카카오뱅크', en: 'KAKAOBANK', code: '카카오' },
        92: { ko: '토스뱅크', en: 'TOSSBANK', code: '토스' },
        81: { ko: '하나은행', en: 'KEB HANA BANK', code: '하나' },
        54: { ko: '홍콩상하이은행', en: 'HSBC', code: '-' },
        3: { ko: 'IBK기업은행', en: 'INDUSTRIAL BANK OF KOREA', code: '기업' },
        4: { ko: 'KB국민은행', en: 'KOOKMIN BANK', code: '국민' },
        31: { ko: 'DGB대구은행', en: 'DAEGU BANK', code: '대구' },
        2: { ko: 'KDB산업은행', en: 'KOREA DEVELOPMENT BANK', code: '산업' },
        11: { ko: 'NH농협은행', en: 'NONGHYUP BANK', code: '농협' },
        23: { ko: 'SC제일은행', en: 'STANDARD CHARTERED BANK KOREA', code: 'SC제일' },
        7: { ko: 'Sh수협은행', en: 'SUHYUP BANK', code: '수협' },
        89: { ko: '케이뱅크', en: '케이뱅크', code: '케이' }
      }
      const returnValue = []

      for (const key in bankList) {
        const item = bankList[key]
        item['key'] = key
        item['name'] = locale === 'ko' ? item.ko : item.en
        returnValue.push(item)
      }

      return returnValue
    }
  }
}
