import React from 'react'
import styled from '@emotion/styled'

const MobileWrapper = styled.div({}, ({  }) => {
  const style = {
    height : 'auto'
  }

  return style
})

const MobileBody = styled.div({}, ({ fullwidth }) => {

  const style = {
    width: '100%',
    margin: '0 auto',
    padding: fullwidth? '0 0 0 0' : '0 15px 0 16px'
  }


  return style
})

const MobileFlexGap = styled.div({}, ({ gutter }) => {

  return gutter === '' ? {}
  : {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '16px ' + gutter
  }
})

export function Mobile(props) {
  const { children, gutter='', fullwidth=false } = props
  return (
    <MobileWrapper>
      <MobileBody fullwidth={fullwidth}>
        <MobileFlexGap gutter={gutter}>
          {children}
        </MobileFlexGap>
      </MobileBody>
    </MobileWrapper>
  )
}

export const SpacingFlexibleColumn = styled.div({}, ({ column, ismobile }) => {

  if (ismobile) {
    if (column.mobile === 'static') {
      return ''
    }
  } else {
    if (column.pc === 'static') {
      return ''
    }
  }
  return ismobile ? {
    display: 'flex',
    flexWrap: 'wrap',
    flexBasis: 'calc(' + 100/(column.mobileCol) + '% - ' + (column.mobileCol - 1)*8 + 'px)',
    maxWidth: 'calc(' + 100/(column.mobileCol) + '% - ' + (column.mobileCol - 1)*8 + 'px)'
  } : {
    display: 'flex',
    flexWrap: 'wrap',
    flexBasis: 'calc(' + 100/(column.pcCol) + '% - ' + (column.pcCol - 1)*8 + 'px',
    maxWidth: 'calc(' + 100/(column.mobileCol) + '% - ' + (column.mobileCol - 1)*8 + 'px)'
  }
})
