import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
const InnerSelectBox = styled.select({
  width: '250px',
  height: '40px',
  textIndent: '10px',
  border: '1px solid #d8d8d8',
  borderRadius: '5px',
  backgroundImage: 'linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)',
  backgroundPosition: 'calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em',
  backgroundSize: '5px 5px, 5px 5px, 1.5em 1.5em',
  backgroundRepeat: 'no-repeat'
})
export function SelectBox({ placeholder, list, id, name, onchange, value = '', valueKey = 'value', displayKey = 'name' }) {
  useEffect(() => {
    setActiveId(value)
  }, [value])

  const [activeId, setActiveId] = useState(undefined)
  const attr = {
    id,
    name,
    value: activeId,
    onChange: (e) => {
      onchange(placeholder ? e.target.selectedIndex - 1 : e.target.selectedIndex)
    }
  }
  return (
    <InnerSelectBox
      {...attr}>
      {placeholder ? <option value='' disabled selected>{ placeholder }</option> : ''}
      {
        list?.map((item, index) => (<option key={index} value={item[valueKey]} selected={value === item[valueKey] }>{item[displayKey]}</option>))
      }

    </InnerSelectBox>
  )
}
