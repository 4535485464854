import codeConst from '../codeConst'

const { SALE_STAT } = codeConst

export const getNotAvailableForSaleText = (saleStatus) => {
  if (saleStatus === SALE_STAT.SALE) {
    return null
  }
  if (saleStatus === SALE_STAT.END) {
    return 'shop.endOfSale'
  }
  if (saleStatus === SALE_STAT.WAIT) {
    return 'shop.waitingDp'
  }
  return 'shop.soldOut'
}
