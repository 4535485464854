import React from 'react'
import styled from '@emotion/styled'
import { CommonCardImage } from '../commonCard/CommonCardImage'
import { ArtistCardSpreadInfo } from './ArtistCardSpreadInfo'

const ArtistCardContainer = styled.div({}, ({ ismobile }) => {
  
  const cardSize = {}

  if (ismobile) {
    cardSize.width = '100%'
    cardSize.height = '64px' // image 127 + title 29
  } else {
    cardSize.width = 'auto' // 341 + 20(arrow)
    cardSize.height = '80px' // image 208 + title 54
  }
    
  const baseStyle = {
    ...cardSize,
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    boxShadow: 'none',
    transition: 'box-shadow 0.5s',
    position: 'relative',
    alignItems: 'center'
  }
  const mobileStyle = {
    // marginBottom: '32px'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ImageWrapper = styled.div({}, ({ ismobile }) => {

  const baseStyle = {
    display: 'flex',
    alignItems: 'center',
    marginLeft : '0px'
  }
  
  const mobileStyle = {
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const InfoWrapper = styled.div({}, ({ ismobile }) => {

  const baseStyle = {
    marginLeft : '12px',
    width: 'calc(100% - 105px)',
    display: 'flex',
    justifyContent: 'space-between', 
    flexDirection:'row'
  }
  
  const mobileStyle = {
    width: 'calc(100% - 64px)',
  }  

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

function IconRightWrapper(props) {
  const { showicon, ismobile } = props
  const IconRight = styled.div({}, ({ismobile}) => {
    const baseStyle = {
      width: '20px',
      height: '15px',
      display: 'flex',
      alignItems: 'center',
      background: 'url(/image/arrow_next02.png)',
      backgroundRepeat: 'no-repeat'
    }    
    const mobileStyle = {
      width :'20px'
    }
    return ismobile
    ? {
      ...baseStyle,
      ...mobileStyle
    }
    : baseStyle
  })
  return (
    [showicon ?
    <IconRight ismobile={ismobile}>
    </IconRight>
    : '']
  )
}

export function ArtistCardSpread(props) {
  const { imageurl, titleprimary, titlesecondary, showicon, clickcallback, clickcallbackparam, ismobile } = props
  const imageSize = {
    pc: {
      width: '80px',
      height: '80px'
    }, 
    mobile:{
      width: '64px',
      height: '64px'
    }
  }
  const isHoverNotiOn = true
  const radius = '12%'

  return (
    <ArtistCardContainer onClick={()=>clickcallback(clickcallbackparam)} ismobile={ismobile}>
      <ImageWrapper>
        <CommonCardImage imageUrl={imageurl} size={imageSize} hoverEffect={''} borderRadius={radius} ismobile={ismobile}>
        </CommonCardImage>
      </ImageWrapper>
      <InfoWrapper ismobile={ismobile}>
        <ArtistCardSpreadInfo titleprimary={titleprimary} titlesecondary={titlesecondary} ismobile={ismobile}>
        </ArtistCardSpreadInfo>
        <IconRightWrapper showicon={showicon} ismobile={ismobile}>
      </IconRightWrapper>
      </InfoWrapper>
    </ArtistCardContainer>
  )
}

