import React from 'react'
import styled from '@emotion/styled'
import { C1Regular, B2Medium } from '../Foundation/Typography'
import { gray } from '../Foundation/Color'

const getResponsiveStyle = (baseStyle, mobileStyle, isMobile) => {
  return isMobile ? { ...baseStyle, ...mobileStyle } : baseStyle
}

const MenuContent = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    color: gray[80],
    // fontSize: '15px',

    '&:not(:last-child)::after': {
      content: '""',
      width: '1px',
      height: '14px',
      margin: '0 16px',
      // borderLeft: '1px solid #aaa',
      boxSizing: 'border-box'
    },
    '& span': {
      cursor: 'pointer',
      padding: '10px 0'
    }
  }
  const mobileStyle = {
    // fontSize: '12px',
    margin: '2px 8px 2px 0',
    '&::after': {
      content: '""',
      display: 'block',
      height: '0',
      margin: '0'
    },
    '&:not(:last-child)::after': {
      content: '""',
      width: '1px',
      height: '0',
      margin: '0',
      borderLeft: 'none',
      boxSizing: 'border-box'
    },
    '& span': {
      cursor: 'pointer',
      padding: '12px 0'
    }
  }

  return getResponsiveStyle(baseStyle, mobileStyle, ismobile)
})

const TextMobile = styled(C1Regular)`
  color: ${gray[80]};
`

const TextPC = styled(B2Medium)`
  color: ${gray[80]};
`

const ClickableSpan = styled.span`
  cursor: pointer;
`

export function MenuList(props) {
  const { menu, handlelink, ismobile, i18n: i18nObj } = props
  const { link, linkType, i18n } = menu

  return ismobile ? (
    <MenuContent ismobile={ismobile}>
      <ClickableSpan onClick={() => {
        handlelink(link, linkType)
      }}><TextMobile>{ i18nObj.t(i18n) }</TextMobile></ClickableSpan></MenuContent>
  ) : (
    <div>
      <ClickableSpan onClick={() => {
        handlelink(link, linkType)
      }}><TextPC>{ i18nObj.t(i18n) }</TextPC></ClickableSpan></div>
  )
}
