import React from 'react'
import { CardShop } from '~/react'
import { SpacingFlexibleColumn } from '../Foundation/Spacing'
import { LayoutContainer } from '../Common/Layout/LayoutContainer'
import i18nContext from '../i18nContext'

export function ShopList(props) {
  const {
    list=[],
    gotoshopdetail,
    isSpecialPriceDisplayUser = false,
    $i18n,
    columns = 2
  } = props

  const flexibleColumn = {
    mobile: 'flexible',
    mobileCol: columns,
    pc: 'static',
    pcCol: 0
  }

  if ( list === undefined || list?.length === 0 ) {
    // TODO: 빈화면 필요
    return null
  }

  return (
    <i18nContext.Provider value={$i18n}>
      <LayoutContainer gutter={'16px'} ismobile={true}>
        {list.map((item, index) => (
          <SpacingFlexibleColumn key={`${item.pdCd} - ${index}`} column={flexibleColumn} ismobile={true}>
            <CardShop
              imageurl={item.fileUrl}
              iconnew={item.iconNew === 'new'}
              recomyn={item.recomYn === 'Y'}
              pdnm={item.pdNm}
              pdcd={item.pdCd}
              ctgrnm={item.ctgrNm}
              dcRatio={item.dcRatio}
              price={item.price}
              salePrice={item.salePrice}
              dcFee={item.dcFee}
              currency={item.langCrcy}
              dcDiv={item.dcDiv}
              isDiscount={item.dcYn === 'Y'}
              badgelist={item.badgeList}
              clickcallback={gotoshopdetail}
              ismobile={true}
              saleStatus={item.saleStat}
              isStarPartnersOnly={item.specialPdYn === 'Y' && isSpecialPriceDisplayUser === false}
              pdType={item.pdType}
              saleTermData = {{
                displaySaleTerm: item.displaySaleTerm === 'Y',
                saleEndDate: item.saleEdDt
              }}
              saleQuantityData = {{
                displaySaleQuantity: item.displaySaleQuantity === 'Y',
                quantity: item.totalSaleQuantity
              }}
            />
          </SpacingFlexibleColumn>
        ))}
      </LayoutContainer>
    </i18nContext.Provider>
  )
}

