import React from'react'
import styled from '@emotion/styled'
import { CommonCardImage } from '../commonCard/CommonCardImage'
import { CardTitleDescInfo } from '../project/projectCard/CardTitleDescInfo'

const VideoClipCardContainer = styled.div({}, ({ismobile}) => {
  const baseStyle = {
    cursor: 'pointer',
    width :'288px',
    height: '100%'
  }
  const mobileStyle = {
    width :'248px',
    height: '221px'
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  } : baseStyle
})

const VideoClipCardInfoWrapper = styled.div({}, ({ismobile}) => {
  const baseStyle = {
    marginTop: '8px',
  }
  const mobileStyle = {
    height: '81px'
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  } : baseStyle
})

export function VideoClipCard(props) {
  const { videourl, imageurl, title, date, clickcallback, ismobile } = props
  const imageSize = {
    pc: {
      width :'288px',
      height: '162px'
    }, 
    mobile:{
      width :'248px',
      height: '140px'
    }
  }
  const radius = '4px 4px 0px 0px'

  return (
    <VideoClipCardContainer onClick={()=>clickcallback(videourl, imageurl, title, date)} ismobile={ismobile}>
      <CommonCardImage imageUrl={imageurl} size={imageSize} hoverEffect={'enlarge'} borderRadius={radius} ismobile={ismobile}>
      </CommonCardImage>
      <VideoClipCardInfoWrapper ismobile={ismobile}>
        <CardTitleDescInfo title={title} description={date} ismobile={ismobile}>
        </CardTitleDescInfo>
      </VideoClipCardInfoWrapper>
    </VideoClipCardContainer>
  )
}