import React from'react'
import styled from '@emotion/styled'
import { red } from '../../Foundation/Color'

import { H8Medium, C2Bold, B2Medium } from '../../Foundation/Typography'

const GNBMenuListItemContainer = styled.div({}, ({ ismobile }) => {
  const basicStyle = {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    height: '100%'
  }
  const desktopStyle = {
    gap: '20px'
  }
  const mobileStyle = {
    textAlign: 'center',
    flex: '1',
    flexBasis: '0',
    gap: 0,
    width: '100%'
  }
  return ismobile ? { ...basicStyle, ... mobileStyle } : { ... basicStyle, ...desktopStyle }
})

const GNBMenuTextWrapper = styled.div({
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  boxSizing: 'border-box',
  borderBottom: '2px solid transparent',
  textWrap: 'nowrap',
  '&:hover' : {
    borderColor: red[50],
  }
}, ({ ismobile, active }) => {
  const baseStyle = {
    position: 'relative',
    ...active && { borderColor : red[50] }
  }
  const mobileStyle = {
    width: '100%'
  }
  return ismobile ? { ...baseStyle, ...mobileStyle } : baseStyle
});

const FixedMenu = styled.div({
  position: 'absolute',
    color: red[50]
}, ({ ismobile }) => {
  const desktopPosition = {
    top: '6px',
    right: '4px',
    transform: 'translate(100%, -100%)'
  }
  const mobilePosition = {
    top: '3px',
    right: '3px',
    transform: 'translate(100%, -100%)'
  }
  return ismobile ? mobilePosition : desktopPosition
})

const DesktopMenuText = styled(H8Medium)`
  position: relative;
`;
const MobileMenuText = styled(B2Medium)`
  position: relative;
`;

/**
 * @typedef Menu
 * @property {string} i18n 다국어 키
 * @property {string} routeName 라우팅 이름
 * @property {string} searchKey 라우팅 key
 * @property {string} routePath 라우팅 패스
 * @property {boolean} isShowMobileMenu 모바일메뉴 표시 여부
 */

/**
 * @typedef GNBMenuListProps
 * @property {Menu[]} menulist
 * @property {Menu | undefined} activemenu
 * @property {boolean} isb2brole 계정 권한이 B2B 권한인지 여부
 * @property {(text: string) => string} i18n 다국어 메서드
 * @property {(routePath: string) => void} menuclickcallback
 * @property {boolean} ismobile PC / mobile flag
 * @property {string} locale 언어모드
 */

/**
 *
 * @param {GNBMenuListProps} props
 * @returns
 */

export function GNBMenuList(props) {
  const {menulist, activemenu, isb2brole, i18n, menuclickcallback, ismobile, locale,} = props

  const menulistselected = ismobile ? menulist.filter((item)=>item.isShowMobileMenu === true) : menulist

  const menus = menulistselected.map((item, index) => {
    return (
      <GNBMenuTextWrapper
        key={`GNB-${index}`}
        active={activemenu && item.routeName===activemenu.routeName}
        onClick={()=>menuclickcallback(item.routePath)}
        ismobile={ismobile}
      >
        { ismobile ?
          <MobileMenuText ismobile={ismobile}>
            { item.routeName === 'Shop' && isb2brole && (
              <FixedMenu ismobile={ismobile}>
                <C2Bold ismobile={ismobile}>
                  {'B2B'}
                </C2Bold>
              </FixedMenu>
            )}
            { (i18n && i18n(item.i18n)) ?? item.i18n }
          </MobileMenuText>
          : <DesktopMenuText>
              { item.routeName === 'Shop' && isb2brole && (
                <FixedMenu ismobile={ismobile}>
                  <C2Bold ismobile={ismobile}>
                    {'B2B'}
                  </C2Bold>
                </FixedMenu>
              )}
              { (i18n && i18n(item.i18n)) ?? item.i18n }
            </DesktopMenuText>
        }
      </GNBMenuTextWrapper>
    )
  })
  return (
    <GNBMenuListItemContainer ismobile={ismobile}>
      {menus}
    </GNBMenuListItemContainer>
  )
}