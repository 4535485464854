import React from 'react'
import styled from '@emotion/styled'

const StyledContainer = styled.div({}, ({ ismobile, size, direction }) => {
  let width = 'auto'
  let height = 'auto'

  if (!ismobile) {
    if(direction === 'vertical'){
      switch(size){
        case 'medium': {
          width = '272px';
          break;
        }
        case 'large': {
          width = '464px';
          break;
        }
        default:
          width = '100%';
          break;
      }
    } else {
      // horizontal 스타일
      switch(size){
        case 'medium': {
          width = '560px';
          height = '153px';
          break;
        }
        default:
          break;
      }
    }
  }
  const baseStyle = {
    flexDirection: direction === 'vertical' ? 'column' : 'row',
    display: 'flex',
    width: '100%',
    height: 'fit-content',
    minWidth: '164px',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box'
  }

  return baseStyle
})

/**
 * @typedef CardProjectContainerProps
 * @property {boolean} ismobile
 * @property {'large' | 'medium' | 'small'} size 카드 사이즈
 * @property {'horizontal' | 'vertical'} direction 카드 방향
 * @property {React.ReactNode} children
 */

/**
 *
 * @param {CardProjectContainerProps} param0
 * @returns
 */

export function CardProjectContainer ({ismobile, size, direction, children, ...props}) {
  return (
    <StyledContainer size={size} direction={direction} ismobile={ismobile} { ... props}>
      {children}
    </StyledContainer>
  )
}