import React from 'react';
import styled from "@emotion/styled";

const Container = styled.div`
  width: 100%;
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #888888;
`;

const Data = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin-bottom: 2px;
`;

export function ConfirmField({ name, data }) {
  return (
    <Container>
      {name && <Name>{ name }</Name>}
      {data && <Data>{ data }</Data>}
    </Container>
  )
}
