/** MSA 서버로 직접 요청하는 경우 Base URL 사용 */
const baseUrl = process.env.API_SERVER_URL

export default function(request) {
  return {
    // 아티스트 인기 목록
    getHotArtistList() {
      const url = baseUrl + '/artist/v1/hot'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    // 아티스트 인기 목록
    getTrendArtistList(data) {
      const url = baseUrl + '/artist/v1/trend'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    // 아티스트 검색 리스트
    artistaSearchList(data) {
      // NOTE: Axios GET 쿼리스트링 대괄호 인코딩이 안 되는 문제로 인해 encodeURIComponent로 추가 인코딩
      data['searchWord'] = encodeURIComponent(data['searchWord'])
      const url = baseUrl + '/artist/v1/search'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    // 최신 아이돌 사진(메인)
    newIdolPictureMain() {
      const url = baseUrl + '/artist/v1/photo'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    // 최신 아이돌 사진 목록
    newIdolPictureList(artistIdx, inquery) {
      const url = baseUrl + '/artist/v1/photo/' + artistIdx
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: inquery
      }
      return request.$get(url, config)
    },
    // 최신 아이돌 사진 상세
    idolPictureDetail(artistIdx, imageIdx) {
      const url = baseUrl + '/artist/v1/photo/' + artistIdx + '/' + imageIdx
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    // 최신 아이돌 무대영상(메인)
    newIdolVideoMain() {
      const url = baseUrl + '/artist/v1/youtube'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    // 최신 아이돌 영상 목록
    newIdolVideoList(artistIdx, data) {
      const url = baseUrl + '/artist/v1/youtube/' + artistIdx
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    // 최신 아이돌 영상 상세
    idolVideoDetail(artistIdx, videoIdx) {
      const url = baseUrl + '/artist/v1/youtube/' + artistIdx + '/' + videoIdx
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    // K-POP 뉴스
    latestKPopNeus() {
      const url = baseUrl + '/artist/v1/news/new'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    // 아이돌 정보 상세
    idolInfoDetail(artistIdx) {
      const url = baseUrl + '/artist/v1/search/' + artistIdx
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    selectProjectArtistList(projectIdx) {
      const url = baseUrl + '/artist/v1/' + projectIdx
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    // 아티스트 페이지 리뉴얼 2023/2
    // 아티스트 상세 정보
    getArtistInfoByBrand(brandIdx, locale) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async(resolve, reject) => {
        const url = baseUrl + '/brand/v1/' + brandIdx
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        }

        const { resCd, resData, resMsg } = await request.$get(url, config)

        const resChangeData = JSON.parse(JSON.stringify(resData))
        // 국가 코드에 따른 이름 스위치 한국어 이외의 언어는 영어로 표시 될 수 있도록 수정
        if (locale !== 'ko') {
          resChangeData['brandName'] = resData.brandEnglishName
          resChangeData['brandEnglishName'] = resData.brandKoreanName
          resChangeData['brandKoreanName'] = resData.brandEnglishName
        }
        resolve({
          resCd, resData: resChangeData, resMsg
        })
      })
    },
    // 아티스트 그룹 정보
    getArtistGroupInfoByBrand(brandIdx, locale) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async(resolve, reject) => {
        const url = baseUrl + '/brand/v1/' + brandIdx + '/groupInfo'
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        }

        const { resCd, resData, resMsg } = await request.$get(url, config)

        const resChangeData = JSON.parse(JSON.stringify(resData))
        // 국가 코드에 따른 이름 스위치 한국어 이외의 언어는 영어로 표시 될 수 있도록 수정
        if (locale !== 'ko' && resChangeData) {
          for (const index in resData) {
            const { brandKoreanName, brandEnglishName } = resData[index]

            resChangeData[index].brandKoreanName = brandEnglishName
            resChangeData[index].brandEnglishName = brandKoreanName
          }
        }
        resolve({
          resCd, resData: resChangeData, resMsg
        })
      })
    },
    // 아티스트 카테고리 정보
    getArtistCategoryInfoByBrand(brandIdx, locale = undefined) {
      let url = baseUrl + '/brand/v1/' + brandIdx + '/categoryInfo'
      if (locale) {
        url += `?langCd=${locale}`
      }
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    getArtistSearchIndices() {
      const url = baseUrl + '/brand/v1/searchIndices'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    getArtistRecommendList() {
      const url = baseUrl + '/brand/v1/recommend'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    getArtistTrendingList() {
      const url = baseUrl + '/brand/v1/trending'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    getArtistKpopList() {
      const url = baseUrl + '/brand/v1/kpop'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    getArtistSearchBrandWithIndex(index) {
      const url = baseUrl + '/brand/v1/searchBrandWithIndex' + `?searchIndex=${encodeURIComponent(index)}`
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    getArtistSearch(data) {
      const url = baseUrl + '/brand/v1/search'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    getArtistSearchBrandWithEtc() {
      const url = baseUrl + '/brand/v1/searchBrandWithEtc'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    fetchArtistTags(data) {
      const { langCd } = data
      const url = baseUrl + `/brand/v1/search/tags`
      const headers = {
        'Content-Type': 'application/json'
      }
      const params = langCd ? { langCd } : undefined
      return request.$get(url, { params, headers })
    }
  }
}
