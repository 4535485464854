import React from 'react'
import styled from '@emotion/styled'
import { gray, red, white } from '../../Foundation/Color'
import { Icon } from '../../Foundation/Icon'
import { B1Bold, B2Bold, B1Regular, B2Regular } from '../../Foundation/Typography'

const ChipBaseStyle =  {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '40px',
  gap: '4px',
  boxSizing: 'border-box',
}

const SmallSizeContainer = {
  height: '34px',
  padding: '0 12px'
}

const MediumSizeContainer = {
  height: '40px',
  padding: '0 16px',
}
const ChipSelectedStyle = {
  color: red[50],
  borderColor: red[50],
}

const ChipDisabledStyle = {
  cursor: 'unset',
  borderColor: gray[40],
  color: gray[40]
}

const ChipTextBase = {
  lineHeight: 'normal',
  maxWidth: '248px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
}
const ChipSelectedTextMedium = styled(B1Bold)({}, ({}) => {
  return ChipTextBase
})
const ChipSelectedTextSmall = styled(B2Bold)({}, ({}) => {
  return ChipTextBase
})
const ChipNormalTextMedium = styled(B1Regular)({}, ({}) => {
  return ChipTextBase
})
const ChipNormalTextSmall = styled(B2Regular)({}, ({}) => {
  return ChipTextBase
})

/**
 * @typedef ChipsContainerProps
 * @property {boolean} ismobile
 * @property {boolean} disabled
 * @property {boolean} selected
 */


/** @type ChipsContainerProps */
const ChipsContainer = styled.div({
  border: `1px solid ${gray[50]}`,
  color: gray[80],
  backgroundColor: white,
  minWidth: '49px'
}, ({ disabled, selected, size }) => {
  return {
    ...ChipBaseStyle,
    ...(size === 'small' ? SmallSizeContainer : MediumSizeContainer),
    ...(disabled && ChipDisabledStyle),
    ...(selected && {
      ...ChipSelectedStyle,
    })
  }
})

function ChipText({ size, selected, caption, ...textAttributes }) {
  if (selected) {
    if (size === 'small') return <ChipSelectedTextSmall {...textAttributes}>{caption}</ChipSelectedTextSmall>
    return <ChipSelectedTextMedium {...textAttributes}>{caption}</ChipSelectedTextMedium>
  } else {
    if (size === 'small') return <ChipNormalTextSmall {...textAttributes}>{caption}</ChipNormalTextSmall>
    return <ChipNormalTextMedium size={size} {...textAttributes}>{caption}</ChipNormalTextMedium>
  }
}

/**
 * @typedef ChipsProps
 * @property {string} caption chip에 들어가는 텍스트
 * @property {string} iconName 아이콘 이름
 * @property {boolean} selected 선택 여부
 * @property {boolean} disabled 활성화 여부
 * @property {any} value callback에 넣어줄 value값
 * @property {(value) => void} clickcallback
 * @property {string} size
 * @property {object} textAttributes ChipText 에 삽입할 custom attribute
 */

/**
 * @param {ChipsProps} props
 */
export function Chips(props) {
  const { caption, iconName = '', selected, disabled, clickcallback, value, size = 'medium', textAttributes } = props

  return (
    <ChipsContainer onClick={() => clickcallback(value)} selected={selected} disabled={disabled} size={size} {...textAttributes}>
      { iconName && <Icon type={iconName} color={selected? red[50] : gray[80]} size={16} />}
      <ChipText caption={caption} size={size} selected={selected} {...textAttributes} />
    </ChipsContainer>
  )
}
