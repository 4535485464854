import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { MenuList } from './MenuList'
import { DropDown } from '../Common/Select/DropDown'
import { ExternalLinkIcon } from './ExternalLinkIcon'
import { InqueryButton } from './InqueryButton'
import { Desktop, Mobile } from '../Foundation/Spacing'
import { B1Medium, B2Medium, B2Regular, C1Regular } from '../Foundation/Typography'
import { gray } from '../Foundation/Color'
import { MakestarLogo } from '~/react/footer/MakestarLogo'

function FooterContainer(props) {
  const { children, ismobile, gutter='' } = props
  return [ ismobile ?
    <Mobile gutter={gutter}> { children } </Mobile>
    : <Desktop gutter={gutter}> { children } </Desktop>]
}

export const getResponsiveStyle = (baseStyle, mobileStyle, isMobile) => {
  return isMobile ? { ...baseStyle, ...mobileStyle } : baseStyle
}

const MainLayoutFooter = styled.footer({}, ({ ismobile }) => {
  const baseStyle = {
    height: 'auto',
    backgroundColor: gray[20]
  }
  const mobileStyle = {
    height: 'auto '
  }
  return getResponsiveStyle(baseStyle, mobileStyle, ismobile)
})

const FooterSection = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    margin: '0px auto'
  }
  const mobileStyle = {
    margin: '0px auto 16px'
  }
  return ismobile ? mobileStyle : baseStyle
})

const DescriptionLeft = styled.div({}, ({ ismobile }) => {
  const desktopStyle = {
    marginRight: '16px',
    width: '440px'
  }
  const mobileStyle = {
  }

  return ismobile ? mobileStyle : desktopStyle
})

const DescriptionRight = styled.div({}, ({ismobile}) => {
  const baseStyle = {
    color: gray[90]
  }
  const mobileStyle = {
    marginBottom: '24px'
  }
  return ismobile? { ...baseStyle, ...mobileStyle }
  : baseStyle
})

const MakeStarInfoWrap = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    marginBottom: '40px'
  }
  const mobileStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '0'
  }
  return getResponsiveStyle(baseStyle, mobileStyle, ismobile)
})

const FooterNavWrap = styled.nav({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 auto'
  }
  const mobileStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 auto',
    flexWrap: 'wrap',
    lineHeight: '22px'
  }
  return ismobile ? mobileStyle : baseStyle
})

const FooterHeadWrap = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    margin: '16px 0 24px 0', // Design SYstem
    display: 'flex',
    justifyContent: 'space-between'
  }
  const mobileStyle = {
    margin: '12px 0 16px 0'
  }
  return getResponsiveStyle(baseStyle, mobileStyle, ismobile)
})

const DescTitle = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    height: '24px',
    fontWeight: '500',
    marginBottom: '8px',
    color: gray[90]
  }

  const mobileStyle = {
    height: '20px',
    marginBottom: '4px',
    flexWrap: 'wrap'
  }

  return getResponsiveStyle(baseStyle, mobileStyle, ismobile)
})

const CompanyInfoPC = styled(B2Regular)`
  color: ${gray[80]};
`
const CompanyInfoMobile = styled(C1Regular)`
  color: ${gray[80]};
`

const CompanyInfoContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '16px'
  }
  const mobileStyle = {
    gap: '6px',
    marginBottom: '8px'
  }
  return getResponsiveStyle(baseStyle, mobileStyle, ismobile)
})

const NavDivider = styled.div`
  border-bottom: 1px solid #DEDEDE;
  width: 100%;
`

const ToggleButton = styled.img`
  cursor: pointer;
  transition: transform 0.4s ease 0s;
  transform: ${({ isRotated }) => isRotated ? 'rotate(180deg)' : 'none'};
`
const MakeStarRight = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    color: gray[80],
    marginTop: '16px',
    height: '20px'
  }
  const mobileStyle = {
    marginTop: '0px',
    marginBottom: '16px',
    color: gray[70],
  }
  return getResponsiveStyle(baseStyle, mobileStyle, ismobile)
})

const DisplayContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    padding: '16px 0',
    width: '100%',
    backgroundColor: gray[20],
    margin: '0px auto'
  }
  const mobileStyle = {
    padding: '16px',
    backgroundColor: gray[20],
  }
  return ismobile ? mobileStyle : baseStyle
})

const TopDisplayContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    padding: '12px 0',
    width: '100%',
    backgroundColor: gray[20],
    margin: '0px auto'
  }
  const mobileStyle = {
    padding: '16px',
    backgroundColor: gray[20],
    borderTop: `0.5px solid ${gray[50]}`,
    borderBottom: `0.5px solid ${gray[50]}`
  }
  return ismobile ? mobileStyle : baseStyle
})

const SocialIconWrap = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    alignItems: 'center'
  }
  const mobileStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center'
  }
  return getResponsiveStyle(baseStyle, mobileStyle, ismobile)
})

const LeftInfoWrap = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    color: '#555555',
    fontSize: '14px'
  }
  const mobileStyle = {
    fontSize: '11px'
  }
  return getResponsiveStyle(baseStyle, mobileStyle, ismobile)
})

const MenuListWrap = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
}, ({ isMobile }) => {
  const pcStyle = {
    gap: '32px'
  }
  const mobileStyle = {
    gap: '16px'
  }
  return getResponsiveStyle(pcStyle, mobileStyle, isMobile)
})

const DescTitleB1Medium = styled(B1Medium)`
  color: ${gray[90]};
`
const DescTitleB2Medium = styled(B2Medium)`
  color: ${gray[90]};
`

const ArrowBottomDiv = styled.div`
  display: flex;
`

const ExternalLinkIconWrap = styled.div`
  display: flex;
  gap: 12px;
`

const InqueryButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;
  align-items: center;
`

const SOCIAL_ICONS = [
  { i18n: 'footer.socialIcon0', class: 'icon-twitter', link: 'https://www.twitter.com/Makestarcorp', iconName: 'Twitter' },
  { i18n: 'footer.socialIcon1', class: 'icon-youtube', link: 'https://www.youtube.com/channel/UC27NRDagT3OOkpGlkfbsheg', iconName: 'Youtube' },
  { i18n: 'footer.socialIcon2', class: 'icon-instagram', link: 'https://www.instagram.com/makestar_official', iconName: 'Instagram' },
  { i18n: 'footer.socialIcon3', class: 'icon-facebook', link: 'https://www.facebook.com/makestar.co', iconName: 'FaceBook' },
  { i18n: 'footer.socialIcon4', class: 'icon-naver', link: 'http://blog.naver.com/makestar_nav', iconName: 'Naver' },
  { i18n: 'footer.socialIcon5', class: 'icon-weibo', link: 'https://www.weibo.com/makestarco', iconName: 'Weibo' },
  { i18n: 'footer.socialIcon6', class: 'icon-youku', link: 'http://i.youku.com/makestarco', iconName: 'YouKu' }
];

const LANGUAGE_MAP = {
  ko: 'common.ko',
  en: 'common.en',
  zh: 'common.zh',
  ja: 'common.ja',
  es: 'common.es'
}


export function MainFooter(props) {
  const { navmenu, inquery, handlelink, handlelang, ismobile, i18n } = props
  const [isDevicePc, setIsDevicePc] = useState(true)
  const [isRotated, setIsRotated] = useState(false)

  const LANGUAGES = [
    { key: 'ko', value: i18n.t('common.ko') },
    { key: 'en', value: i18n.t('common.en') },
    { key: 'zh', value: i18n.t('common.zh') },
    { key: 'ja', value: i18n.t('common.ja') },
    { key: 'es', value: i18n.t('common.es') }
  ]

  useEffect(() => {
    if (ismobile) {
      setIsDevicePc(false)
    } else {
      setIsDevicePc(true) // 모바일 화면 토글이 된 상태에서, pc로 바뀌면 다시 설명이 보여져야 하니까 else에도 코드 넣어준다.
    }
  }, [ismobile])

  const handleMobileToggle = () => {
    if (!ismobile) return
    setIsDevicePc(!isDevicePc)
    setIsRotated(!isRotated)
  }

  const renderCompanyInfo = () => {
    if (ismobile) {
      return (
        <CompanyInfoContainer ismobile={true}>
          <div>
            <CompanyInfoMobile>{i18n.t('footer.address')}</CompanyInfoMobile>
            <CompanyInfoMobile>{i18n.t('footer.ceo')}</CompanyInfoMobile>
          </div>
          <div>
            <CompanyInfoMobile>{i18n.t('footer.registrationNo')}</CompanyInfoMobile>
            <CompanyInfoMobile>{i18n.t('footer.salesLicense')}</CompanyInfoMobile>
            <CompanyInfoMobile>{i18n.t('footer.headOfSecurity')}</CompanyInfoMobile>
            <CompanyInfoMobile>{i18n.t('footer.managerOfSecurity')}</CompanyInfoMobile>
          </div>
        </CompanyInfoContainer>
      )
    }
    return (
      <CompanyInfoContainer ismobile={false}>
        <div>
          <CompanyInfoPC>{i18n.t('footer.address')}</CompanyInfoPC>
          <CompanyInfoPC>{i18n.t('footer.ceo')}</CompanyInfoPC>
        </div>
        <div>
          <CompanyInfoPC>{i18n.t('footer.registrationNo')}</CompanyInfoPC>
          <CompanyInfoPC>{i18n.t('footer.salesLicense')}</CompanyInfoPC>
          <CompanyInfoPC>{i18n.t('footer.headOfSecurity')}</CompanyInfoPC>
          <CompanyInfoPC>{i18n.t('footer.managerOfSecurity')}</CompanyInfoPC>
        </div>
      </CompanyInfoContainer>
    )
  }

  return (
    <MainLayoutFooter ismobile={ismobile}>
      <TopDisplayContainer ismobile={ismobile}>
        <FooterContainer ismobile={ismobile}>
          <FooterNavWrap ismobile={ismobile}>
            <MenuListWrap isMobile={ismobile}>
              { navmenu.map(menu => <MenuList menu={menu} handlelink={handlelink} ismobile={ismobile} key={menu.link} i18n={i18n}/>) }
            </MenuListWrap>
            { !ismobile && <DropDown itemList={LANGUAGES} onSelectItem={handlelang} selectedItemKey={i18n.locale}/> }
          </FooterNavWrap>
        </FooterContainer>
      </TopDisplayContainer>
      {!ismobile && <NavDivider/>}
      <DisplayContainer ismobile={ismobile}>
        <FooterContainer ismobile={ismobile}>
          <FooterSection ismobile={ismobile}>
            <FooterHeadWrap ismobile={ismobile}>
              <MakestarLogo ismobile={ismobile}></MakestarLogo>
              <ExternalLinkIconWrap>
                { !ismobile && SOCIAL_ICONS.map(icon => <ExternalLinkIcon icon={icon} key={icon.class} />) }
              </ExternalLinkIconWrap>
            </FooterHeadWrap>
            <MakeStarInfoWrap ismobile={ismobile}>
              <DescriptionLeft ismobile={ismobile}>
                <DescTitle onClick={handleMobileToggle} ismobile={ismobile} >
                    { !ismobile && <DescTitleB1Medium>{ i18n.t('footer.business') } </DescTitleB1Medium> }
                    { ismobile && (
                      <ArrowBottomDiv>
                        <DescTitleB2Medium>{`${i18n.t('footer.business')}`}&nbsp;</DescTitleB2Medium>
                        <DescTitleB2Medium>{i18n.t('footer.businessInfo')}&nbsp;</DescTitleB2Medium>
                        <ToggleButton isRotated={isRotated} src='/image/arrow_bottom.png'/>
                      </ArrowBottomDiv>
                      )}
                </DescTitle>
                { isDevicePc &&
                <LeftInfoWrap ismobile={ismobile}>
                  {renderCompanyInfo()}
                </LeftInfoWrap>
                }
                <C1Regular>
                  <MakeStarRight dangerouslySetInnerHTML={ { __html: i18n.t('footer.businessInfo_3') } } ismobile={ismobile}/>
                </C1Regular>
              </DescriptionLeft>
              <DescriptionRight ismobile={ismobile}>
                { !ismobile &&
                <div>
                  <B1Medium>
                    <div style={{marginBottom: '7px', color: gray[90]}} dangerouslySetInnerHTML={ { __html: i18n.t('footer.businessInfo_4_1') } }/>
                  </B1Medium>
                  <B2Regular>
                    <div dangerouslySetInnerHTML={ { __html: i18n.t('footer.businessInfo_4_2') } }/>
                  </B2Regular>
                </div> }
                { ismobile &&
                <div>
                  <B2Medium>
                    <div style={{marginBottom: '4px', color: gray[90]}} dangerouslySetInnerHTML={ { __html: i18n.t('footer.businessInfo_4_1') } }/>
                  </B2Medium>
                  <C1Regular>
                    <div dangerouslySetInnerHTML={ { __html: i18n.t('footer.businessInfo_4_2') } }/>
                  </C1Regular>
                </div> }
                <InqueryButtonContainer>
                  { inquery.map(inqueryData => <InqueryButton ismobile={ismobile} inquery={inqueryData} handlelink={handlelink} key={inqueryData.keyId} i18n={i18n}/>) }
                </InqueryButtonContainer>
              </DescriptionRight>
              <SocialIconWrap ismobile={ismobile}>
                { ismobile && SOCIAL_ICONS.map(icon => <ExternalLinkIcon icon={icon} key={icon.class} ismobile={ismobile}/>) }
              </SocialIconWrap>
            </MakeStarInfoWrap>
          </FooterSection>
          </FooterContainer>
      </DisplayContainer>
    </MainLayoutFooter>
  )
}
