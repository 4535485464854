import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
const ls = new SecureLS({ isCompression: false, encryptionSecret: process.env.VUEXSECRETKEY })
export default ({ store }) => {
  createPersistedState({
    key: 'u@ins', // localstorage에 들어갈 키 이름
    paths: [
      'modules.loginuserinfo',
      'modules.artists.recentSearchWordList',
      'modules.ditoland.ditoland.id'
    ], // 저장할 state  modules.loginuserinfo -> modules/loginuserinfo
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    }
  })(store)
}
