import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { CheckboxWLabel, ButtonFullWidth } from '~/react'
import { C1Medium, H1Heading } from '~/react/Foundation/Typography'
import { red } from '~/react/Foundation/Color'
import { Icon } from '~/react/Foundation/Icon'

const HeadingContainer = styled.div`
  margin-bottom: 28px;
`;

const TermsCheckAllContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #DEDEDE;
`;

const TermsDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 8px;
  margin: 16px 0;
`;

const InfoTextC1Medium = styled(C1Medium)`
  color: #888888;
  padding: 8px;
`;

const CheckSectionItem = styled.div`
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
`;

const InfoTextWrapper = styled.div`
  margin-bottom: 28px;
`;

const SeeDetailButtonText = styled.span`
  color: #888888;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  display: flex;
  align-items: center;
`;

const SeeDetailButtonWrap = styled.div`
  display: flex;
  gap: 4px;
`;

const CheckboxArea = styled.div`
  max-width: 80%;
`;

function SeeDetailButton({ onClick, label = '보기' }) {
  return (
    <SeeDetailButtonWrap onClick={onClick}>
      <SeeDetailButtonText>
        {label}
      </SeeDetailButtonText>
      <Icon type={'dk_icon_nextpage_mini'} size={6} style={{height: '100%'}}/>
    </SeeDetailButtonWrap>
  )
}

/**
 * 약관동의 Page
 * @param {({use: boolean, privacy: boolean, marketing: boolean, isOlderThan14: boolean}) => void} onClickNext
 * @param {(key: string) => void} onNavigateToDetail
 * @param {VueI18n} $i18n
 * @returns {JSX.Element}
 */
export function TermsBox({ onClickNext = () => {}, onNavigateToDetail = (key) => {}, $i18n }) {
  const [checkAll, setCheckAll] = useState(false);

  const [agreedCommonUse, setAgreedCommonUse] = useState(false);
  const [agreedCommonPrivacy, setAgreedCommonPrivacy] = useState(false);
  const [agreedUse, setAgreedUse] = useState(false);
  const [isOlderThan14, setIsOlderThan14] = useState(false);
  const [agreedMarketing, setAgreedMarketing] = useState(false);

  const [isNextbuttonAvailable, setIsNextbuttonAvailable] = useState(false);

  useEffect(() => {
    const agreedAllRequiredTerms = agreedCommonUse && agreedCommonPrivacy && agreedUse && isOlderThan14;
    if (agreedAllRequiredTerms && agreedMarketing) {
      setCheckAll(true)
    } else {
      setCheckAll(false)
    }

    if (agreedAllRequiredTerms) {
      setIsNextbuttonAvailable(true)
    } else {
      setIsNextbuttonAvailable(false)
    }
  }, [agreedCommonUse, agreedCommonPrivacy, agreedUse, isOlderThan14, agreedMarketing])

  const onChangeCheckbox = (key, value) => {
    if (key === 'commonUse') {
      setAgreedCommonUse(value)
    }
    if (key === 'commonPrivacy') {
      setAgreedCommonPrivacy(value)
    }
    if (key === 'use') {
      setAgreedUse(value)
    }
    if (key === 'isOlderThan14') {
      setIsOlderThan14(value)
    }
    if (key === 'marketing') {
      setAgreedMarketing(value)
    }
  };

  const setCheckAllTerms = (value) => {
    setCheckAll(value);
    setAgreedCommonUse(value);
    setAgreedCommonPrivacy(value);
    setAgreedUse(value);
    setIsOlderThan14(value);
    setAgreedMarketing(value);
  };

  const onSubmit = () => {
    if (isNextbuttonAvailable) {
      onClickNext({
        use: agreedUse,
        marketing: agreedMarketing,
        isOlderThan14: isOlderThan14
      })
    }
  };

  return (
    <div>
      <HeadingContainer>
        <H1Heading ismobile={true}>{$i18n.t('pageTitle.terms')}</H1Heading>
      </HeadingContainer>
      <TermsCheckAllContainer>
        <CheckboxWLabel
          isChecked={checkAll}
          size={'medium'}
          label={$i18n && $i18n.t('terms.agreeAll')}
          onChange={setCheckAllTerms}
        />
      </TermsCheckAllContainer>
      <TermsDetailContainer>
        <CheckSectionItem>
          <CheckboxArea>
            <CheckboxWLabel
              isChecked={agreedCommonUse}
              size={'small'}
              label={$i18n && $i18n.t('terms.commonTerms')}
              description={$i18n.t('terms.essential')}
              descriptionColor={red[50]}
              onChange={(value) => onChangeCheckbox('commonUse', value)}
            />
          </CheckboxArea>
          <SeeDetailButton label={$i18n && $i18n.t('terms.view')} onClick={() => onNavigateToDetail('/about/terms?tab=common')} />
        </CheckSectionItem>
        <CheckSectionItem>
          <CheckboxArea>
            <CheckboxWLabel
              isChecked={agreedCommonPrivacy}
              size={'small'}
              label={$i18n && $i18n.t('privacy.commonPrivacyPolicy')}
              description={$i18n.t('terms.essential')}
              descriptionColor={red[50]}
              onChange={(value) => onChangeCheckbox('commonPrivacy', value)}
            />
          </CheckboxArea>
          <SeeDetailButton label={$i18n && $i18n.t('terms.view')} onClick={() => onNavigateToDetail('/about/privacy?tab=common')} />
        </CheckSectionItem>
        <CheckSectionItem>
          <CheckboxArea>
            <CheckboxWLabel
              isChecked={agreedUse}
              size={'small'}
              label={$i18n && $i18n.t('terms.makestarTerms')}
              description={$i18n.t('terms.essential')}
              descriptionColor={red[50]}
              onChange={(value) => onChangeCheckbox('use', value)}
            />
          </CheckboxArea>
          <SeeDetailButton label={$i18n && $i18n.t('terms.view')} onClick={() => onNavigateToDetail('/about/terms?tab=makestar')} />
        </CheckSectionItem>
        <CheckSectionItem>
          <CheckboxWLabel
            isChecked={isOlderThan14}
            size={'small'}
            label={$i18n && $i18n.t('user.isOlderThan14')}
            description={$i18n.t('terms.essential')}
            descriptionColor={red[50]}
            onChange={(value) => onChangeCheckbox('isOlderThan14', value)}
          />
        </CheckSectionItem>
        <CheckSectionItem>
          <CheckboxArea>
            <CheckboxWLabel
              isChecked={agreedMarketing}
              size={'small'}
              label={$i18n && $i18n.t('terms.marketingCollectAgree')}
              description={$i18n.t('terms.choice')}
              onChange={(value) => onChangeCheckbox('marketing', value)}
            />
          </CheckboxArea>
          <SeeDetailButton label={$i18n && $i18n.t('terms.view')} onClick={() => onNavigateToDetail('/about/marketing?tab=collect')} />
        </CheckSectionItem>
        <CheckSectionItem>
          <CheckboxArea>
            <CheckboxWLabel
              isChecked={agreedMarketing}
              size={'small'}
              label={$i18n && $i18n.t('terms.marketingAdAgree')}
              description={$i18n.t('terms.choice')}
              onChange={(value) => onChangeCheckbox('marketing', value)}
            />
          </CheckboxArea>
          <SeeDetailButton label={$i18n && $i18n.t('terms.view')} onClick={() => onNavigateToDetail('/about/marketing?tab=advertise')} />
        </CheckSectionItem>
      </TermsDetailContainer>
      <InfoTextWrapper>
        <InfoTextC1Medium>{$i18n && $i18n.t('terms.needAgreeTerms')}</InfoTextC1Medium>
      </InfoTextWrapper>
      <ButtonFullWidth
        color={isNextbuttonAvailable ? 'red50' : 'gray50'}
        text={$i18n && $i18n.t('project.next')}
        clickcallback={onSubmit}
        clickcallbackparam={[agreedMarketing]}
      />
    </div>
  )
}
