import React from 'react'
import styled from '@emotion/styled'
import { B1Medium, B2Medium } from '~/react/Foundation/Typography'
import { gray } from '~/react/Foundation/Color'
import { Icon } from '~/react/Foundation/Icon'
import { LayoutContainer } from '~/react/Common/Layout/LayoutContainer'

const MoreButtonContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '24px'
  }

  const mobileStyle = {
    paddingTop: 0
  }

  return ismobile ? { ...baseStyle, ...mobileStyle } : baseStyle
})

const ButtonLayout = styled.button({}, ({ ismobile }) => {
  const baseStyle = {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2px',
    borderRadius: '6px',
    width: '272px',
    height: '44px',
    border: `1px solid ${gray[50]}`,
    boxSizing: 'border-box'
  }
  const mobileStyle = {
    width: '164px',
    height: '36px',
    padding: '9px 0',
  }
  return ismobile ? { ...baseStyle, ...mobileStyle } : baseStyle
})

/**
 * @typedef ShopMoreButtonProps
 * @property {() => void} getmore
 * @property {string} getmorecaption
 * @property {boolean} ismobile
 * @property {any} getmorecategory
 * @property {any} getmoresort
 */

/**
 *
 * @param {ShopMoreButtonProps} props
 * @returns
 */
export function ShopMoreButton(props) {
  const { getmore, getmorecaption='', getmorecategory, getmoresort, ismobile = true } = props
  return (
    <LayoutContainer ismobile={ismobile}>
      <MoreButtonContainer ismobile={ismobile}>
        <ButtonLayout ismobile={ismobile} type="click" onClick={() => getmore(getmorecategory)}>
          { ismobile ? <B2Medium ismobile={ismobile}>{getmorecaption}</B2Medium> : <B1Medium >{getmorecaption}</B1Medium>}
          <Icon type="add" size={18}/>
        </ButtonLayout>
      </MoreButtonContainer>
    </LayoutContainer>
  )
}
