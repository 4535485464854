import Vue from 'vue'
import Toast from 'vue-toastification'

import '~/assets/customToast.css'
import 'vue-toastification/dist/index.css'

const options = {
  timeout: 2000,
  closeOnClick: false,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.type === toast.type
    ).length !== 0) {
      // Returning false discards the toast
      return false
    }
    // You can modify the toast if you want
    return toast
  }

}

Vue.use(Toast, options)

export default ({ app }, inject) => {
  inject('toast', Vue.prototype.$toast)
}
