
import { mapGetters } from 'vuex'
import MobileLayout from './mobileDefaultLayout.vue'
import { applyReactInVue } from 'vuereact-combined'
import { MainWebsiteLayout } from '~/react/Common/Layout/MainWebsiteLayout'

const RMainWebsiteLayout = applyReactInVue(MainWebsiteLayout)

export default {
  name: 'DefaultLayout',
  directives: {},
  components: {
    MobileLayout,
    RMainWebsiteLayout
  },
  data() {
    return {}
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      }
    }
  },
  computed: {
    ...mapGetters([
      'showHamburgerMenu',
      'standardWidth',
      'b2b',
      'isPC'
    ])
  },
  watch: {
    '$route.path': {
      handler() {
        this.changeTitle()
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.changeTitle()
    this.registerEventListener()
    this.$store.dispatch('modules/device/setDevice', 'MOBILE')
    this.updateTokenStatusFromApp()
  },
  beforeDestroy() {
    this.unregisterEventListener()
  },
  methods: {
    /**
     * key에 맞게 다국어 변경
     * @param {'ko' | 'en' | 'zh' | 'ja' | 'es'} key
     */
    onChangeLanguage(key) {
      const query = Object.assign({}, this.$route.query)
      query.lang = key
      query.lang_at = 'dr'

      const { href } = this.$router.resolve({ query })
      window.location.href = href
    },
    closeBurgerMenu() {
      // NOTE: Mobile 햄버거바 메뉴에 대한 상태 관리가 전역에서 되고 있기 때문에, Mobile 외부에서 조작이 필요하다면 강제로 닫아줘야 함
      document.getElementsByTagName('body')[0].style.removeProperty('overflow')
      this.$store.dispatch('modules/layout/setHamburgerMenuViewStatus', false)
    },
    registerEventListener() {
      if (process.browser && window) {
        this.checkDeviceType()
        window.addEventListener('resize', this.checkDeviceType)
        if (window.flutter_inappwebview) {
          window.addEventListener('appLogin', this.updateTokenStatusFromApp)
          window.addEventListener('appLogout', this.updateTokenStatusFromApp)
        }
      }
    },
    unregisterEventListener() {
      if (process.browser && window) {
        window.removeEventListener('resize', this.checkDeviceType)
        if (window.flutter_inappwebview) {
          window.removeEventListener('appLogin', this.updateTokenStatusFromApp)
          window.addEventListener('appLogout', this.updateTokenStatusFromApp)
        }
      }
    },
    /**
    * 기기 유형 확인
    */
    checkDeviceType() {
      if (process.browser) {
        this.$nextTick(() => {
          let isPC = true
          try {
            const width = document.body.clientWidth || window.innerWidth

            if (this.standardWidth > Number(width)) {
              isPC = false
            } else {
              isPC = true
            }
          } catch (e) {
            this.$store.dispatch('modules/layout/setIsPC', isPC)
          }
          this.$store.dispatch('modules/layout/setIsPC', isPC)
        })
      }
    },
    /**
     * 페이지 타이틀 변경
     */
    changeTitle() {
      /**
       * process.browser: SSR Not Found 방지
       */
      if (process.browser) {
        let titleText = 'MAKESTAR'

        if (this && this.$route && this.$route.meta && this.$route.meta.pageTitle) {
          const title = this.$t(`pageTitle.${this.$route.meta.pageTitle}`)
          if (title) {
            titleText = title + ' | ' + titleText
          }
        }
        document.title = titleText
      }
    }
  }
}
