import React, {useState} from 'react'
import styled from '@emotion/styled'
import { BadgeNew } from '../../Badge/BadgeNew'
import { gray } from '../../../Foundation/Color'
import { CardEmptyImage } from '~/react/Common/Card/CardEmptyImage'
import { ImageResizeTag } from '~/react/common'

const CardProjectImageContainer = styled.div({}, ({ size, direction, ismobile }) => {
  let width = ''
  let minWidth = '164px'
  if (!ismobile) {
    if (direction === 'horizontal') {
      switch(size){
        case 'medium': {
          width = '272px';
          break;
        }
        case 'large': {
          width = '464px';
          break;
        }
        default:
          width = '100%';
          break;
      }
    } else {
      width = '100%'
    }
  } else {
    // 모바일 mIn width 설정
    switch(size){
      case 'medium': {
        minWidth = '224px';
        break;
      }
      case 'large': {
        minWidth = '344px';
        break;
      }
      default:
        break;
    }
  }

  const baseStyle = {
    width: ismobile ? '100%' : width,
    minWidth,
    aspectRatio: '16 / 9',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '4px',
    flexShrink: 0,
    border: `0.5px solid ${gray[50]}`
  }

  return baseStyle
})
const CardProjectImageBody = styled.img({
    '&:hover': {
      transform: 'scale(1.1)',
    }
  }, () => {
  const baseStyle = {
    width : '100%',
    height: '100%',
    objectFit: 'cover',
    backgroundColor: gray[30],
    position: 'relative',
    transition: 'all 0.5s ease'
  }

  return baseStyle
})


/**
 * @typedef CardProjectImageProps
 * @property {'large' | 'medium' | 'small'} size 이미지 사이즈
 * @property {string} imageUrl 이미지 url
 * @property {boolean} iconnew new 뱃지 유무
 * @property {boolean} ismobile PC, Mobile
 * @property {'vertical' | 'horizontal'} direction 이미지 방향
 */

/**
 *
 * @param {CardProjectImageProps} props
 * @returns
 */

export function CardProjectImage(props) {
  const { imageUrl, iconnew, size, direction='vertical', ismobile } = props

  const imgProps = ImageResizeTag({
    imageUrl: imageUrl,
  })

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <CardProjectImageContainer size={size} direction={direction} ismobile={ismobile}>
      {imageError ? (
        <CardEmptyImage />
      ) : (
        <CardProjectImageBody {...imgProps} size={size} ismobile={ismobile} onError={handleImageError}>
        </CardProjectImageBody>
      )}
      { iconnew && <BadgeNew ismobile={ismobile} size={'list'}></BadgeNew>}
    </CardProjectImageContainer>
  )
}
