import React from 'react'
import styled from '@emotion/styled'


const ButtonContainer = styled.div({}, ({ color, type, disabled, ismobile }) => {
  const colorStyle = {}
  if (color === 'red') {
    colorStyle.backgroundColor = '#E8351D'
  } else if (color === 'orange') {
    colorStyle.backgroundColor = '#E17D37'
  } else if (color === 'green') {
    colorStyle.backgroundColor = '#00A49D'
  } else if (color === 'blue') {
    colorStyle.backgroundColor = '#006A8B'
  } else if (color === 'gray') {
    colorStyle.backgroundColor = '#CCCCCC'
  }
  const widthStyle = {}
  if (type === 'fill-width') {
    widthStyle.width = '100%'
    widthStyle.borderRadius = '0px'
  } else if (type === 'normal') {
    widthStyle.width = '335px'
    widthStyle.borderRadius = '4px'
  }
  const baseStyle = {
    cursor: 'pointer',
    height: '48px'
  }
  if (disabled) 
  {
    colorStyle.backgroundColor = '#CCCCCC'
    baseStyle.cursor = 'unset'
  }
  const mobileStyle = {
  }  

  return ismobile ? { ...colorStyle, ...baseStyle, ...mobileStyle, ...widthStyle } 
  : {...colorStyle, ...baseStyle, ...widthStyle}
})

const ButtonDiv = styled.div({}, ({ color, ismobile }) => {
  const baseStyle = {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#fff',
    textAlign: 'center',
    padding: '16px 10px 16px 10px'
  }
  
  const mobileStyle = {
  }  

  return ismobile ? { ...baseStyle, ...mobileStyle } 
  : baseStyle
})

export function ButtonColor(props) {
  const { text, color, type, disabled, clickcallback, clickcallbackparam, ismobile } = props
  const clickfunction = disabled ? ()=>{} : () => clickcallback(clickcallbackparam)

  return (
  <ButtonContainer color={color} type={type} disabled={disabled} onClick={clickfunction} ismobile={ismobile}>
      <ButtonDiv color={color} ismobile={ismobile} >
        { text }
      </ButtonDiv>
    </ButtonContainer>
  )
}


