export const state = () => ({
  recentSearchWordList: []
})

export const mutations = {
  SET_RECENT_SEARCH_WORD_LIST: (state, recentSearchWordList) => {
    state.recentSearchWordList = recentSearchWordList
  }
}

export const actions = {
  setRecentSearchWordList({ commit }, recentSearchWordList) {
    commit('SET_RECENT_SEARCH_WORD_LIST', recentSearchWordList)
  }
}

export const getters = {
  getRecentSearchWordList: (state) => {
    console.log('getter : ' + state.recentSearchWordList)
    return state.recentSearchWordList
  }
}
