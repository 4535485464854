import React from 'react'
import styled from '@emotion/styled'
import { uniqueId } from './../common'
import { BottomLine } from '../BottomLine'
const MainBox = styled.div({
  display: 'flex',
  alignItems: 'center'
},
({ ismobile }) => {
  const pc = {
    border: '3px solid rgb(172, 171, 163)',
    backgroundColor: 'rgb(240, 239, 230)',
  }
  const mobile = {
    flexDirection: 'column-reverse',
    padding: '12px 16px',
    border: '1px solid #dee2e6',
    backgroundColor: 'rgb(242, 242, 242)'
  }
  return ismobile ? mobile : pc
})

const ContentBox = styled.div({
  margin: '0 20px 0 16px',
  height: '80px',
  display: 'flex',
  alignItems: 'center'
},
({ ismobile }) => {
  return {
    width: ismobile ? '100%' : 'fit-content',
    flexDirection: ismobile ? 'column' : ''
  }
})

const Text = styled.div({
  fontSize: '14px'
})

const Value = styled.div({
  fontSize: '18px',
  fontWeight: 'bold'
})

const ContentBox1 = styled.div({
  display: 'flex',
  alignItems: 'center'
},
({ ismobile }) => {
  const mobileStyle = {
    width: '100%',
    height: '100%',
    [Text]: {
      flex: 1
    },
    [Value]: {
      flex: 1,
      textAlign: 'end'
    }
  }
  return ismobile ? mobileStyle : ''
})

const RightWall = styled.div({
  borderLeft: '2px solid rgb(192, 191, 187)',
  height: '40px',
  marginLeft: '20px',
  marginRight: '16px'
})

const Amount = styled.div({
  height: '40px',
}, ({ismobile}) => {
  const mobile = {
  }
  const pc = {
    backgroundColor: 'white',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginLeft: '10px',
    marginRight: '20px',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content'
  }
  return ismobile ? mobile : pc
})

const ResultBox = styled.div({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}, ({ ismobile }) => {
  const pc = {
    width: 'fit-content',
  }
  const mobile = {
    width: '100%',
    [ContentBox1]: {
      [Text]: {
        fontSize: '1.0625rem',
        fontWeight: 600,
        color: '#2d2d2d'
      }
    }
  }
  return ismobile ? mobile : pc
})

const GrayWall = styled.span({
  color: '#9b9b9b',
  margin: '0 8px'
})

const MonetaryUnit = styled.span({
  fontSize: '20px',
  fontWeight: 700,
  fontFamily: '"Noto Sans","Noto Sans KR",sans-serif',
  color: '#232323'
})
const AmountUnit = styled(MonetaryUnit)({
  color: '#da2a2e'
})

export function AmountPanel({ ismobile }) {
  return (
    <MainBox ismobile={ismobile}>
      <ContentBox ismobile={ismobile}>
        <ContentBox1 ismobile={ismobile}>
          <Text>리워드</Text>
          <Value>16.300</Value>
        </ContentBox1>
        {ismobile ? '' : <RightWall />}
        <ContentBox1 ismobile={ismobile}>
          <Text>배송비</Text>
          <Value>2.500</Value>
        </ContentBox1>
        {ismobile ? '' : <RightWall />}
      </ContentBox>
      {ismobile ? <BottomLine color='#dee2e6' top='8'/> : ''}
      <ResultBox ismobile={ismobile}>
        <ContentBox1 ismobile={ismobile}>
          <Text>최종 결제 금액</Text>
          <Value>
            <Amount key={uniqueId()} ismobile={ismobile}>
              {ismobile ? '' : [<MonetaryUnit>KRW</MonetaryUnit>, <GrayWall>|</GrayWall>]}
              <AmountUnit>18,800</AmountUnit>
            </Amount>
          </Value>
        </ContentBox1>
      </ResultBox>
    </MainBox>
  )
}
