import React from "react";
import styled from "@emotion/styled";
import { B1Bold, B2Bold, B2Regular, C1Bold, C1Medium, H7Bold } from "../../Foundation/Typography";
import { white, gray, cyan } from '../../Foundation/Color';
import { Icon } from "../../Foundation/Icon";
import { PopOverContainer } from './PopOverContainer'

const NotiHeader = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${gray[30]}`
  }

  const mobileStyle = {
    height: '72px'
  }
  return ismobile ? { ...baseStyle, ...mobileStyle} : baseStyle
})

const NotiTextAndIcon = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    alignItems: 'center'
  }
  const mobileStyle = {
    gap: '0 10px',
  }
  return ismobile ? { ...baseStyle, ...mobileStyle } : baseStyle
})

const NotiPopOverListItem = styled.div({}, ({ checkYn }) => {
  const baseStyle = {
    padding: '12px 16px',
    width: '100%',
    display: 'flex',
    gap: '0 16px',
    cursor: 'pointer',
    backgroundColor: checkYn === 'N' ? gray[30] : white,
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: gray[20]
    }
  }
  return baseStyle
})

const NotiItemImageContainer = styled.div({}, () => ({
  width: '72px',
  height: '72px',
  borderRadius: '8px',
  overflow: 'hidden',
  boxSizing: 'border-box',
  border: `0.5px solid ${gray[50]}`,
  flexShrink: 0,
  'img': {
    width: '100%',
    objectFit: 'cover',
  }
}))

const NotiItemText = styled(B2Regular)({
  padding: '4px 0 8px'
}, () => {})

const NotiItemDateText = styled(C1Medium)({
  color: gray[70]
}, () => {})

const NotiHeaderAllReadText = styled(B2Regular)({
  color: cyan[60],
  cursor: 'pointer'
}, () => {})

const NotiContentsWrapper = styled.div({
  overflowY: 'scroll',
  overflowX: 'hidden',
  marginBottom: '4px'
}, () => {})
/**
 * @typedef NotiItem
 * @property {'Y' | 'N'} checkYn 알림의 읽음 / 안읽음 여부
 * @property {number} idx 알림메세지 인덱스번호
 * @property {string} image 알림메세지 썸네일 이미지
 * @property {string} notiType 알림메세지 타입
 * @property {string} regDate
 * @property {string} [toAfterDateText] regDate로부터 몇일전인지 표시하는 텍스트
 * @property {string} text 알림메세지 내용
 * @property {string} title 알림메세지 제목
 * @property {string} url 알림 연결 링크
 * @property {number} userIdx 해당 알림 아이템과 연결된 Useridx
 */
/**
 * @typedef GNBNotiPopOverProps
 * @property {boolean} isshow popover display 여부
 * @property {boolean} ismobile PC, mobile
 * @property {string} notiheadtext 알림 다국어 텍스트
 * @property {string} allreadstatustext 전체 읽음 영역 다국어 텍스트
 * @property {() => void} handlereadallnoti 전체 읽음 처리
 * @property {(item:NotiItem ) => void} handleclicknotiitem NotiItem 클릭 시 처리
 * @property {string} [morenotitext] 더보기 다국어 텍스트
 * @property {() => void} [onclickmorenotilist] 더보기 콜백
 * @property {NotiItem[]} list 알람 리스트
 * @property {number} notilistamount 표시할 최대 알림 수
 * @property {() => void} handleCloseMobile 모바일일 때 뒤로가기 클릭 시 close
 */

/**
 *
 * @param {GNBNotiPopOverProps} props
 * @returns
 */
export function GNBNotiPopOver ({isshow, ismobile, handlereadallnoti, list, handleclicknotiitem, notiheadtext, allreadstatustext, morenotitext, onclickmorenotilist, notilistamount, handleCloseMobile = 10}) {

  const makeNotiList = list.map((item, index) => (
    <NotiPopOverListItem key={`${index}-${item.title}`} onMouseDown={() => handleclicknotiitem(item)} checkYn={item.checkYn}>
      <NotiItemImageContainer>
        <img src={item.image} />
      </NotiItemImageContainer>
      <div>
        <B2Bold>{item.title}</B2Bold>
        <NotiItemText>{item.text}</NotiItemText>
        <NotiItemDateText>{item.toAfterDateText}</NotiItemDateText>
      </div>
    </NotiPopOverListItem>
  ))
  return (
    <PopOverContainer isShow={isshow} isMobile={ismobile} height="727px" right="6%" style={{paddingTop: 0}}>
      <NotiHeader ismobile={ismobile}>
        <NotiTextAndIcon ismobile={ismobile}>
          { ismobile && <Icon type="arrow_left_small" size={24} onMouseDown={handleCloseMobile}></Icon>}
          { ismobile ? <H7Bold ismobile={ismobile}>{notiheadtext}</H7Bold> : <B1Bold>{notiheadtext}</B1Bold>}
        </NotiTextAndIcon>
        <NotiHeaderAllReadText onMouseDown={handlereadallnoti}>{allreadstatustext}</NotiHeaderAllReadText>
      </NotiHeader>
      <NotiContentsWrapper>
        {makeNotiList}
      </NotiContentsWrapper>
    </PopOverContainer>
  )
}