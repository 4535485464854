import React from 'react'
import styled from '@emotion/styled'
import { DropDownList } from './DropDownList'
import { useDropdown } from './useDropdown'
import { Icon } from '../../Foundation/Icon'
import { B2Regular } from '../../Foundation/Typography'
import { gray, white } from '../../Foundation/Color'

const SelectWrap = styled.div({}, ({ size }) => {
  let minWidth = '120px'
  switch(size){
    case 'medium': {
      minWidth = '208px';
      break;
    }
    case 'large': {
      minWidth = '256px';
      break;
    }
    default:
      break;
  }

  const baseStyle = {
    position: 'relative',
    backgroundColor: white,
    color: gray[70],
    marginTop: '2px',
    maxHeight: '250px',
    width: '144px',
    minWidth,
    height: '30px'
  }
  return baseStyle
})

const SelectedValue = styled.div({}, ({ismobile}) => {
  const baseStyle = {
    width: '100%',
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px'
  }
  const mobileStyle = {
  }
  return ismobile? { ...baseStyle, ...mobileStyle }
  : baseStyle
})

const DropdownText = styled(B2Regular)({}, () => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: gray[90],
    width: '100%'
  }
})

function ToggleArrow(props) {
  const { isShow=false } = props
  return isShow ? (
    <Icon type={'arrow_up_small'} size={20} color={gray[70]}></Icon>
  ) : (
    <Icon type={'arrow_down_small'} size={20} color={gray[70]}></Icon>
  )
}

/**
 * @typedef DropDownQuiteProps
 * @property {{key: string, value: string}[]} infoList 'Select Box 리스트
 * @property {string} selectedKey 초기 선택 옵션
 * @property {string} type 메뉴 타입
 * @property {(key:string) => void} onchange 옵션 선택 후 콜백
 * @property {boolean} ismobile PC/Mobile flag
 * @property {'large' | 'medium' | 'small'} size 드롭다운 사이즈
 */

/**
 * @param {DropDownQuiteProps} props
 * @returns
 */

export function DropDownQuite(props) {
  const { infoList = [], selectedKey = '', onchange, ismobile, size } = props

  const {
    isListVisible,
    selectedItem,
    toggleDropdown,
    closeDropdown,
    onClickItem
  } = useDropdown(infoList, selectedKey, onchange)

  return (
    <SelectWrap size={size} tabIndex={0} onBlur={closeDropdown} onClick={toggleDropdown} ismobile={ismobile}>
      <SelectedValue>
        {selectedItem?.value && <DropdownText ismobile={ismobile}> { selectedItem.value } </DropdownText>}
        <ToggleArrow isShow={isListVisible}></ToggleArrow>
      </SelectedValue>

      { isListVisible && <DropDownList
        selectedKey={selectedItem.key}
        infoList={infoList}
        onchange={onClickItem}
        ismobile={ismobile}/> }
    </SelectWrap>
  )
}

