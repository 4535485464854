import React from'react'
import styled from '@emotion/styled'
import { gray } from '../../Foundation/Color'
import { H7Bold } from '../../Foundation/Typography'
import { B2Bold } from '../../Foundation/Typography'

const CategoryHeaderContainer = styled.div({}, ({ctgrtype, ismobile}) => {
  const baseStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    marginBottom: '0px',
    padding: ctgrtype === 'major' ? '0' : '6px 0px'
  }

  const mobileStyle = {
    padding: '0',
    gap: '20px'
  }

  return ismobile? { ...baseStyle, ...mobileStyle } : baseStyle
})

const MajorCategory = styled(H7Bold)({}, ({ ismobile, selected }) => {
  const baseStyle = {
    position: 'relative',
    cursor: 'pointer',
    color: selected ? gray[90]: gray[60],
    display: 'flex',
    gap: '16px',
  }

  const mobileStyle = {
    gap: '24px',
  }
  return ismobile?
  {
    ...baseStyle,
    ...mobileStyle

  } : {
    ...baseStyle
  }
})
const SubCateogry = styled(B2Bold)({}, ({ ismobile, selected }) => {
  const baseStyle = {
    position: 'relative',
    cursor: 'pointer',
    color: selected ? gray[90]: gray[60],
    display: 'flex',
    gap: '12px',
    '&::after' : {
      display: 'block',
      marginTop: '3px',
      content: '""',
      width: '1px',
      height: '12px',
      backgroundColor: gray[40],
    },
    '&:last-child': {
      '&::after' : {
        display: 'none'
      }
    }
  }

  const mobileStyle = {
    gap: '24px',
    '&::after' : {
      display: 'none'
    }
  }
  return ismobile?
  {
    ...baseStyle,
    ...mobileStyle

  } : {
    ...baseStyle
  }
})


export function LnbHeader(props) {
  const { ctgrlist, selectedCateogry, ctgrtype = 'sub', clickcallback, ismobile } = props
  if (ctgrlist.length < 1) {
    return null
  } else {
    const categoryList = ctgrlist.map((category) => {
      return ctgrtype === 'sub' ? (
      <SubCateogry key={category.code} onClick={()=>clickcallback(category.code)} selected={category.code === selectedCateogry} ismobile={ismobile}> {category.displayName} </SubCateogry>
      ) : (
      <MajorCategory key={category.code} onClick={()=>clickcallback(category.code)} selected={category.code === selectedCateogry} ismobile={ismobile}> {category.displayName} </MajorCategory>
      )
    })
    return (
      <CategoryHeaderContainer ctgrtype={ctgrtype} ismobile={ismobile}>
        {categoryList}
      </CategoryHeaderContainer>
    )
  }
}
