import React from 'react'
import styled from '@emotion/styled';
import { Required } from './../../react';
const InnerContentTitle = styled.span({
  width: '244px',
  color: '#232323',
  fontSize: '15px',
  fontWeight: 700,
  fontFamily: '"Noto Sans","Noto Sans KR",sans-serif',
});
export function ContentTitle(props) {
  const { required, children } = props;
  return (
    <InnerContentTitle>
      {children}
      {required ? <Required bold={700}>*</Required> : ''}
    </InnerContentTitle>
  );
}
