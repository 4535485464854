export const state = () => ({
  ditoland: {}
})

export const mutations = {
  SET_DITOLAND_ID: (state, id) => {
    state.ditoland.id = id
  },
  REMOVE_DITOLAND_ID: (state) => {
    delete state.ditoland.id
  }
}

export const actions = {
  setDitolandId({ commit }, id) {
    commit('SET_DITOLAND_ID', id)
  },
  removeDitolandId({ commit }) {
    commit('REMOVE_DITOLAND_ID')
  }
}

export const getters = {
  getDitolandInfo: (state) => {
    return state.ditoland
  }
}
