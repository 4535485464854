import React from 'react'
import styled from '@emotion/styled'
import { Required } from '../Required'
const SectionHeader = styled.div({}, ({ ismobile, index }) => {
  const baseStyle = {
    marginTop: index === 0 ? '10px' : '50px'
  }
  const style = {
    margin: '12px 8px 0',
    padding: '20px',
    backgroundColor: '#fff',
    boxShadow: 'inset 0 2px 3px 0 rgb(0 0 0 / 10%)',
    border: '1px solid #9b9b9b'
  }
  return ismobile
    ? {
      ...baseStyle,
      ...style
    }
    : baseStyle
})
export function ProjectSection(props) {
  const { title, required, index, children, essential, ismobile } = props

  return (
    <SectionHeader index={index} ismobile={ismobile}>
      <span dangerouslySetInnerHTML={ { __html: title } }/> {required ? <Required> { essential || '(* 필수)'}</Required> : ''}
      {children}
    </SectionHeader>
  )
}
