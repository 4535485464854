import React from'react'
import { H8Bold, H7Bold, H5Bold, B1Bold } from '../../Foundation/Typography'

/**
 * @typedef HeaderTitleProps
 * @property {string} children 내부에 들어갈 텍스트
 * @property {'xlarge' | 'larege' | 'medium' | 'small'} size 헤더 크기
 * @property {boolean} ismobile PC / Mobile 구분
 */

/**
 * @param {HeaderTitleProps} props
 */
export function HeaderTitle(props) {
  const { size, ismobile, children, locale, } = props
  if (ismobile) {
    switch(size){
      case 'small':
        return (
          <B1Bold ismobile={ismobile}> {children} </B1Bold>
        )
      case 'medium':
        return (
          <H8Bold ismobile={ismobile}> {children} </H8Bold>
        )
      case 'large':
        return (
          <H7Bold ismobile={ismobile}> {children} </H7Bold>
        )
      case 'xlarge':
        return (
          <H5Bold ismobile={ismobile}> {children} </H5Bold>
        )
    }
  } else {
    switch(size){
      case 'small':
        return (
          <H8Bold ismobile={ismobile}> {children} </H8Bold>
        )
      case 'medium':
        return (
          <H7Bold ismobile={ismobile}> {children} </H7Bold>
        )
      case 'large':
        return (
          <H5Bold ismobile={ismobile}> {children} </H5Bold>
        )
    }
  }
}