
const codeConst = {}

// ENUM 정의 키, 벨류
codeConst.FUNDING_SUB_TYPE = function(self) {
  return {
    10: { CD: '10', NM: self.$t('project.limitedQuantity') },
    20: { CD: '20', NM: self.$t('project.limitedTime') },
    30: { CD: '30', NM: self.$t('project.exclusiveReservationSale') },
    40: { CD: '40', NM: self.$t('project.exclusiveSale') },
    50: { CD: '50', NM: self.$t('project.preOrder') }
  }
}

codeConst.PROJECT_PROJECT_TYPE = {
  ALL: '',
  FUNDING: '10',
  PRE_ORDER: '20',
  UNKNOWN: '90'
}

codeConst.PROJECT_TYPE = {
  PROJECT: 'project',
  EVENT: 'event',
  POLL: 'poll',
  ARTIST: 'artist',
  UPDATE: 'update'

}

codeConst.VOTING_TYPE = function(self) {
  return {
    10: self.$t('content.polls.votingType'),
    20: self.$t('content.polls.votingType2'),
    30: self.$t('content.polls.votingType3'),
    40: self.$t('content.polls.votingType4'),
    50: self.$t('content.polls.votingType5'),
    99: self.$t('content.polls.votingType6')
  }
}

codeConst.PROJECT_CARD_TYPE = {
  NORMAL: 'NORMAL',
  SECOND: 'SECOND',
  THIRD: 'THIRD',
  FOURTH: 'FOURTH'
}

codeConst.TOAST_ICON = {
  WARNING: 'WARNNING',
  ERROR: 'ERROR',
  INFO: 'INFO',
  CHECK: 'CHECK'
}

codeConst.STATUS = {
  10: '10', // 작업중
  20: '20', // 진행중
  30: '30', // 프리오픈
  40: '40', // 커밍순(숨김)
  50: '50', // 일시정지
  60: '60', // 프로젝트 취소(보이기)
  70: '70', // 프로젝트 취소(숨기기)
  80: '80' // 종료
}

codeConst.LANGUAGE = {
  KO: 'ko',
  EN: 'en',
  JA: 'ja',
  ZH: 'zh',
  ES: 'es'
}

codeConst.PAYMENT_TYPE = function(self) {
  return {
    LOCAL_CREDIT_CARD: { CD: 'LOCAL_CREDIT_CARD', NM: self.$t('payMent.creditCard') },
    LOCAL_MONEY_TRANSFER: { CD: 'LOCAL_MONEY_TRANSFER', NM: self.$t('payMent.bankTransfer') },
    LOCAL_CELLULAR_PHONE: { CD: 'LOCAL_CELLULAR_PHONE', NM: self.$t('payMent.cellPhone') },
    LOCAL_CULTURAL_GIFT: { CD: 'LOCAL_CULTURAL_GIFT', NM: self.$t('payMent.giftCard') },
    LOCAL_KAKAOPAY: { CD: 'LOCAL_KAKAOPAY', NM: self.$t('payMent.kakaoPay') },
    LOCAL_PAYCO: { CD: 'LOCAL_PAYCO', NM: self.$t('payMent.payCo') },
    LOCAL_MONEY_DEPOSIT: { CD: 'LOCAL_MONEY_DEPOSIT', NM: self.$t('payMent.virtualAccount') },
    LOCAL_NAVERPAY: { CD: 'LOCAL_NAVERPAY', NM: self.$t('payMent.naverPay') },
    LOCAL_NAVERPAY_POINT: { CD: 'LOCAL_NAVERPAY_POINT', NM: self.$t('payMent.naverPayPoint') },
    DIRECT_MONEY_TRANSFER: { CD: 'DIRECT_MONEY_TRANSFER', NM: self.$t('payMent.directTransfer') },
    FREE: { CD: 'FREE', NM: self.$t('payMent.free') },
    GLOBAL_CREDIT_CARD: { CD: 'GLOBAL_CREDIT_CARD', NM: self.$t('payMent.overseasCreditCard') },
    GLOBAL_ALIPAY: { CD: 'GLOBAL_ALIPAY', NM: self.$t('payMent.overseasAlipay') },
    GLOBAL_MOLPAY: { CD: 'GLOBAL_MOLPAY', NM: self.$t('payMent.overseasMolpay') },
    GLOBAL_WECHAT: { CD: 'GLOBAL_WECHAT', NM: self.$t('payMent.overseasWeChat') },
    GLOBAL_PAYPAL: { CD: 'GLOBAL_PAYPAL', NM: self.$t('payMent.overseasPAYPAL') },
    GLOBAL_ECONTEXT: { CD: 'GLOBAL_ECONTEXT', NM: self.$t('payMent.overseaseContext') },
    GLOBAL_ALIPAY_ICB: { CD: 'GLOBAL_ALIPAY_ICB', NM: self.$t('payMent.overseasAlipay') },
    GLOBAL_ALIPAY_ICB_GB: { CD: 'GLOBAL_ALIPAY_ICB_GB', NM: self.$t('payMent.overseasAlipayP') },
    GLOBAL_WECHAT_ICB: { CD: 'GLOBAL_WECHAT_ICB', NM: self.$t('payMent.overseasWeChat') },

    AS_IS_CREDIT_CARD: { CD: 'AS_IS_CREDIT_CARD', NM: self.$t('payMent.creditCard') },
    AS_IS_MONEY_TRANSFER: { CD: 'AS_IS_MONEY_TRANSFER', NM: self.$t('payMent.bankTransfer') },
    AS_IS_MOO: { CD: 'AS_IS_MOO', NM: self.$t('payMent.virtualAccount') },
    AS_IS_CELLULAR_PHONE: { CD: 'AS_IS_CELLULAR_PHONE', NM: self.$t('payMent.cellPhone') },
    AS_IS_CULTURAL_GIFT: { CD: 'AS_IS_CULTURAL_GIFT', NM: self.$t('payMent.giftCard') },
    AS_IS_T_CARD: { CD: 'AS_IS_T_CARD', NM: self.$t('payMent.tMoney') },
    AS_IS_CREDIT_CARD_PAYPAL: { CD: 'AS_IS_CREDIT_CARD_PAYPAL', NM: self.$t('payMent.creditCard') },
    AS_IS_CREDIT_CARD_PAYGATE: { CD: 'AS_IS_CREDIT_CARD_PAYGATE', NM: self.$t('payMent.creditCard') },
    AS_IS_ALIPAY_PAYGATE: { CD: 'AS_IS_ALIPAY_PAYGATE', NM: self.$t('payMent.overseasAlipay') },
    AS_IS_CHINAPAY_PAYGATE: { CD: 'AS_IS_CHINAPAY_PAYGATE', NM: self.$t('payMent.overseasChinapay') },
    AS_IS_TENPAY_PAYGATE: { CD: 'AS_IS_TENPAY_PAYGATE', NM: self.$t('payMent.overseasTenpay') },
    AS_IS_T_CARD_PAYLETTER: { CD: 'AS_IS_T_CARD_PAYLETTER', NM: self.$t('payMent.tMoney') },
    AS_IS_CREDIT_CARD_PAYLETTER: { CD: 'AS_IS_CREDIT_CARD_PAYLETTER', NM: self.$t('payMent.creditCard') },
    AS_IS_CREDIT_CARD_PAYGATE_LOCAL: { CD: 'AS_IS_CREDIT_CARD_PAYGATE_LOCAL', NM: self.$t('payMent.creditCard') },
    AS_IS_MONEY_TRANSFER_CARD_PAYGATE_LOCAL: { CD: 'AS_IS_MONEY_TRANSFER_CARD_PAYGATE_LOCAL', NM: self.$t('payMent.bankTransfer') },
    AS_IS_MONEY_DEPOSIT_PAYGATE_LOCAL: { CD: 'AS_IS_MONEY_DEPOSIT_PAYGATE_LOCAL', NM: self.$t('payMent.virtualAccount') },
    AS_IS_DIRECT: { CD: 'AS_IS_DIRECT', NM: self.$t('payMent.bankTransfer') },
    TOSS_PAYPAL: { CD: 'TOSS_PAYPAL', NM: 'PAYPAL' },
    LOCAL_TOSS_VIRTUAL_ACCOUNT: { CD: 'LOCAL_TOSS_VIRTUAL_ACCOUNT', NM: self.$t('payMent.virtualAccount') }
  }
}

codeConst.PAY_STAT = function(self) {
  return {
    W: { CD: 'W', NM: self.$t('payMent.paymentWaiting') },
    E: { CD: 'E', NM: self.$t('payMent.paymentComple') },
    F: { CD: 'F', NM: self.$t('payMent.paymentFail') },
    C: { CD: 'C', NM: self.$t('payMent.paymentCancel') }
  }
}

codeConst.ODR_STAT = function(self) {
  return {
    W: { CD: 'W', NM: self.$t('payMent.orderWaiting') },
    E: { CD: 'E', NM: self.$t('payMent.orderComple') },
    F: { CD: 'F', NM: self.$t('payMent.orderFail') },
    C: { CD: 'C', NM: self.$t('payMent.orderCancel') },
    P: { CD: 'P', NM: self.$t('payMent.partCancel') }
  }
}

codeConst.DEVICE_TYPE = {
  PC: 'PC',
  MOBILE: 'MOBILE'
}

codeConst.REWARD_DELIVERY = {
  10: '10', // 배송필요
  20: '20' //  배송불필요 리워드 항목이 없어 배송할 필요가없음

}

codeConst.CONTENT_STATUS = {
  10: '10', // 작업중
  20: '20', // 열림
  30: '30', // 닫힘
  40: '40' // 숨김
}

codeConst.CURRENCY = {
  USD: 'USD',
  KRW: 'KRW',
  JPY: 'JPY',
  CNY: 'CNY',
  EUR: 'EUR'
}

codeConst.DELIVERY_TYPE = {
  KO: 'LGS001',
  EMS: 'EMS',
  DHL: 'DHL'
}

/**
 * @deprecated
 * use MESSENGER_CODE_I18N_MAPPER instead.
 * @param self
 */
codeConst.MESSENGER = function(self) {
  return {
    K: { CD: 'K', NM: self.$t('project.messenger.K') },
    W: { CD: 'W', NM: self.$t('project.messenger.W') },
    L: { CD: 'L', NM: self.$t('project.messenger.L') },
    LS: { CD: 'LS', NM: self.$t('project.messenger.LS') },
    D: { CD: 'D', NM: self.$t('project.messenger.D') }
  }
}

codeConst.MESSENGER_CODE_I18N_MAPPER = {
  K: 'project.messenger.K',
  W: 'project.messenger.W',
  L: 'project.messenger.L',
  LS: 'project.messenger.LS',
  D: 'project.messenger.D'
}

codeConst.SNS_TYPE = {
  FACEBOOK: 'FACEBOOK',
  KAKAO_STORY: 'KAKAO_STORY',
  TWITTER: 'TWITTER',
  LINE: 'LINE',
  BAND: 'BAND',
  KAKAO: 'KAKAO',
  PINTEREST: 'PINTEREST',
  AMEBLO: 'AMEBLO',
  HATENA: 'HATENA',
  MIXI: 'MIXI',
  REDDIT: 'REDDIT',
  BAIDU: 'BAIDU',
  QQ: 'QQ',
  SINA: 'SINA',
  QZONE: 'QZONE',
  TENCENT: 'TENCENT',
  WECHAT: 'WECHAT'
}

codeConst.PROJ_STD_CD = function(self) {
  return {
    WORK: { CD: 'WORK', NM: self.$t('payMent.work') },
    WAIT: { CD: 'WAIT', NM: self.$t('payMent.wait') },
    ON: { CD: 'ON', NM: self.$t('payMent.ing') },
    STOP: { CD: 'STOP', NM: self.$t('payMent.stop') },
    END: { CD: 'END', NM: self.$t('payMent.end') },
    SOLDOUT: { CD: 'SOLDOUT', NM: self.$t('payMent.soldout') }
  }
}

/** 상품 판매 상태 */
codeConst.SALE_STAT = {
  /** 판매 */
  SALE: 'S',

  /** 대기 */
  WAIT: 'W',

  /** 품절(자동) */
  SOLDOUT_AUTO: 'O',

  /** 품절(수동) */
  SOLEOUT_MANUAL: 'Q',

  /** 중지 */
  PAUSE: 'P',

  /** 종료 */
  END: 'E'
}

codeConst.CNCL_DIV = function(self) {
  return {
    A: { CD: 'A', NM: self.$t('payMent.allCancel') },
    P: { CD: 'P', NM: self.$t('payMent.partCancel') }
  }
}

codeConst.LOGIS_CD = {
  LGS001: 'LGS001',
  DHL: 'DHL',
  EMS: 'EMS',
  SFEXPRESS: 'SFExpress',
  JDL: 'JDL',
  UPS: 'UPS',
  UPS_B2B: 'UPS_B2B',
  FEDEX: 'FEDEX',
  FEDEX_B2B: 'FEDEX_B2B'
}

codeConst.REWARD_TYPE = {
  NORMAL: 'NORMAL',
  LIMITED: 'LIMITED',
  TICKET_FIXED_SEAT: 'TICKET_FIXED_SEAT',
  TICKET_UNRESERVED_SEAT: 'TICKET_UNRESERVED_SEAT',
  GROUP_PURCHASE: 'GROUP_PURCHASE',
  FREE: 'FREE'
}

codeConst.CNCL_RSN = function(self) {
  return {
    C: { CD: 'C', NM: self.$t('payMent.changeHeart') },
    O: { CD: 'O', NM: self.$t('payMent.optionChange') },
    M: { CD: 'M', NM: self.$t('payMent.orderMiss') },
    S: { CD: 'S', NM: self.$t('payMent.serviceDissatisfaction') },
    D: { CD: 'D', NM: self.$t('payMent.goodsDifferent') },
    E: { CD: 'E', NM: self.$t('payMent.other') }
  }
}

codeConst.RFD_PRC_STAT = function(self) {
  return {
    W: { CD: 'W', NM: self.$t('payMent.refundState.W') },
    E: { CD: 'E', NM: self.$t('payMent.refundState.E') },
    P: { CD: 'P', NM: self.$t('payMent.refundState.P') },
    F: { CD: 'F', NM: self.$t('payMent.refundState.F') }
  }
}

codeConst.FUNDING_TYPE = {
  ALL_OR_NOTHING: 'ALL_OR_NOTHING',
  PRE_ORDER: 'PRE_ORDER'
}

codeConst.REWARD_TYPE2 = {
  1: '1', // 한정 리워드 수량
  9: '9' // 자유후원
}

codeConst.COMMENT_STATUS = {
  10: '10', // 노출
  20: '20' // 미노출
}

codeConst.CLOSE_CODE = {
  10: '10', // 판매
  20: '20' // 판매 중지

}

codeConst.ITEM_TYPE = {
  10: '10', //  배송 리워드
  20: '20', // 디지털 리워드
  30: '30' // 아이템 리워드
}

codeConst.CHAINA_REGION_INFO = {
  11: '北京',
  12: '天津',
  13: '河北',
  14: '山西',
  15: '内蒙古',
  21: '辽宁',
  22: '吉林',
  23: '黑龙江',
  31: '上海',
  32: '江苏',
  33: '浙江',
  34: '安徽',
  35: '福建',
  36: '江西',
  37: '山东',
  41: '河南',
  42: '湖北',
  43: '湖南',
  44: '广东',
  45: '广西',
  46: '海南',
  50: '重庆',
  51: '四川',
  52: '贵州',
  53: '云南',
  54: '西藏',
  61: '陕西',
  62: '甘肃',
  63: '青海',
  64: '宁夏',
  65: '新疆',
  71: '台湾',
  81: '香港',
  82: '澳门',
  91: '国外'
}

codeConst.DATE_FILTER_STAT = function(self) {
  return {
    A: { CD: '0', NM: self.$t('filters.dateSelectFilter.A') },
    O: { CD: '1', NM: self.$t('filters.dateSelectFilter.O') },
    T: { CD: '3', NM: self.$t('filters.dateSelectFilter.T') },
    S: { CD: '6', NM: self.$t('filters.dateSelectFilter.S') }
  }
}

codeConst.DIRECT_MONEY_TRANSFER_SALE = {
  RATE: 0.05
}

codeConst.USER_OPTION_TYPE = {
  TEL_NO: 'TEL_NO',
  DLVY_MSG_TYPE: 'DLVY_MSG_TYPE'
}

codeConst.SHOPPING_MENU = {
  1: {
    key: 'B2B',
    menuId: 1,
    URL: '/shop/list/B2B/TOTAL'
  },
  2: {
    key: 'SHOPPINGLIST',
    menuId: 2,
    URL: '/shop/list/SHOPPINGLIST/TOTAL'
  },
  3: {
    key: 'MAKERSMARKET',
    menuId: 3,
    URL: '/shop/list/MAKERSMARKET/TOTAL'
  },
  4: {
    key: 'CGROUPBUY', // 중국 공동구매
    menuId: 4,
    URL: '/shop/list/CGROUPBUY/TOTAL'
  },
  menuIds: {
    B2B: 1,
    SHOPPINGLIST: 2,
    MAKERSMARKET: 3,
    CGROUPBUY: 4
  }
}

codeConst.PRODUCT_TYPE = Object.freeze({
  /** 일반 */
  NORMAL: 'N',

  /** 공동구매 */
  GROUPBUY: 'G',

  /** B2B */
  B2B: 'B',

  /** 메이커스마켓 */
  MAKERSMARKET: 'M',

  /** 중국 공동구매 */
  CHINAGROUPBUY: 'C'
})

codeConst.VALIDATION_MESSAGE = Object.freeze({
  VALID: 'VALID_INPUT',
  INVALID: 'INVALID_INPUT',
  EMPTY: 'EMPTY_INPUT'
})

codeConst.VIEW_MODE = {
  LIST: 'LIST',
  GRID: 'GRID'
}

codeConst.DIALOG_TYPE = {
  TYPE: 'TYPE',
  FILTER: 'FILTER'
}

/**
 * 976: 웹사이트 백그라운드 컨텐츠가 보여지기 시작하는 Breakpoint
 * @type {number}
 */
codeConst.WEB_CONTENT_CONTAINER_SIZE = 976

/**
 * 464: 모바일 웹 영역에 대한 Breakpoint. 해당 지점부터 웹사이트용 백그라운드가 보여집니다.
 * @type {number}
 */
codeConst.DEFAULT_MOBILE_LAYOUT_SIZE = 464
codeConst.DEFAULT_MOBILE_B2BLAYOUT_SIZE = 560

/**
 * 375: 모바일 웹 영역의 최소 너비
 * @type {number}
 */
codeConst.MIN_MOBILE_LAYOUT_SIZE = 375

codeConst.SOCIAL_TYPE = {
  makestar: 0,
  google: 1,
  twitter: 2,
  facebook: 3,
  apple: 4,
  naver: 5,
  kakao: 6,
  spotify: 7
}

codeConst.LOGIN_RESULT_STATUS = {

  /** 로그인 성공 */
  SUCCESS: 'SUCCESS',

  /**
   * 추가 액션이 필요한 예외케이스인 경우
   * ex. 회원가입 필요, 토큰 재인증 필요 등
   */
  ALLOWED_SERVICE_EXCEPTION_CASE: 'ALLOWED_SERVICE_EXCEPTION_CASE',

  /** 로그인 실패 */
  FAIL: 'FAIL'
}

/**
 * 중국 공동구매 페이지 접근 가능 언어
 */
codeConst.CHINA_GROUPBUY_ALLOWED_LOCALE = ['zh']

codeConst.LOCALE_FORMATS = {
  ko: 'YYYY.MM.DD HH:mm',
  en: 'DD.MM.YYYY HH:mm',
  zh: 'YYYY.MM.DD HH:mm',
  ja: 'YYYY.MM.DD HH:mm',
  es: 'DD.MM.YYYY HH:mm'
}

export default codeConst
