/** MSA 서버로 직접 요청하는 경우 Base URL 사용 */
const baseUrl = process.env.API_SERVER_URL

export default function(request) {
  return {
    selectArtist(data) {
      const url = baseUrl + '/project/v1/artist'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    selectArtistProjectList(data) {
      const url = baseUrl + '/project/v1/brand'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    selectWishlist(data) {
      const url = baseUrl + '/project/v1/wishlist/' + data
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    insertWishlist(projectId) {
      const url = '/project/v1/wishlist/' + projectId + '/add'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, {}, config)
    },
    deleteWishlist(projectId) {
      const url = '/project/v1/wishlist/' + projectId + '/delete'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$delete(url, {}, config)
    },
    deleteWishlistMultiSelect(data) {
      const url = '/project/v1/wishlist/deleteMultiSelect'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    selectSpecificArtist(idx, data) {
      const url = baseUrl + `/project/v1/artist/${idx}`
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },

    selectAnimation(data) {
      const url = baseUrl + '/project/v1/animation'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    selectFanMade(data) {
      const url = baseUrl + '/project/v1/fanmades'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    selectDetail(id, data) {
      const url = baseUrl + '/project/v1/' + id + '/story'

      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    selectUpdateList(id) {
      const url = baseUrl + '/project/v1/' + id + '/update'

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    selectNoticeList(id) {
      const url = baseUrl + '/project/v1/' + id + '/notice'

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    selectUpdateDetail(id, orderNo) {
      const url = baseUrl + '/project/v1/' + id + '/update/' + orderNo
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    updateFollow(id) {
      const url = '/project/v1/' + id + '/follow'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, {}, config)
    },
    searchOnlyArtistByKeyword(data) {
      const url = baseUrl + '/project/v1/searchOnlyArtistByKeyword'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    search(data) {
      const url = '/project/v1/search'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    searchByKeyword(data) {
      const url = '/project/v1/searchByKeyword'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    insertRequestProject(projectId, data) {
      const url = '/project/v1/' + projectId + '/requestProject'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$post(url, data, config)
    },
    selectRewardItems(projectId, rewardCode) {
      const url = baseUrl + '/project/v1/' + projectId + '/reward/' + rewardCode
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    selectRelatedContentList(id) {
      const url = baseUrl + '/project/v1/brand/event/' + id
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    selectRelatedPollList(id) {
      const url = baseUrl + '/project/v1/brand/poll/' + id
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return request.$get(url, config)
    },
    selectProjectRun(data) {
      const url = baseUrl + '/project/v1/run'
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        params: data
      }
      return request.$get(url, config)
    },
    /**
     * @param {string} cntryCd 국가코드
     * @param {string[]} rewardCodeList 리워드 코드 리스트
     * @returns ProjectRewardLimitCountListResponse
     */
    getLogisLimitCount(cntryCd, rewardCode) {
      const cntryCdParam = `cntryCd=${cntryCd}`
      const rewardCodeParam = `rewardCodeList=${rewardCode}`
      const url = baseUrl + `/project/v1/reward/buy-limit?${cntryCdParam}&${rewardCodeParam}`
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return request.$get(url, config)
    },
    checkProjectPurchaseLimitWithID(data) {
      const { projectId, taxID } = data
      const url = baseUrl + `/project/v1/order/buy-check`
      const headers = {
        'Content-Type': 'application/json'
      }
      const params = {
        projCd: projectId,
        taxId: taxID
      }
      return request.$get(url, { params, headers })
    }
  }
}

/**
 * @typedef ProjectRewardLimitCountListResponse
 * @property {{[idx:string]: ProjectRewardLimitCountItem}} resData
 */
/**
 * @typedef ProjectRewardLimitCountItem
 * @property {string} projectRewardIdx 요청한 프로젝트 리워드의 인덱스
 * @property {string} rewardCode 리워드 코드
 * @property {string} cntryCd 국가 코드
 * @property {number} sumAlbumCountQty 한 주문건 당 앨범을 몇 개로 볼 것인지 수
 * @property {'Y' | 'N'} projectRewardLogisBuyLimitCountYn 리워드 별 수량제한이 되어 있는지 여부. N이면 projectBuyLimitList 빈 배열
 * @property {LogisItem[]} logisBuyLimitList 배송사별 설정되어 있는 수량제한
 * @property {LogisItem & {projectRewardIdx:string}[]} projectBuyLimitList 상품 별 설정된 수량제한
 */

/**
 * @typedef LogisItem
 * @property {string} logisCd 배송사코드
 * @property {string} cntryCd 국가코드
 * @property {string} limitCount 제한 수량
 * @property {string} regId 등록된 옵션 아이디
 */
