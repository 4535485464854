import React from 'react'
import styled from '@emotion/styled';
const Title = styled.div({
  fontSize: '14px',
  color: '#232323',
  fontFamily: '"Noto Sans","Noto Sans KR",sans-serif',
})
const Content = styled(Title)({
  fontWeight: 700,
});
const MainLayout = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
  },
  ({ flexAttr, marginTop }) => ({
    flex: flexAttr,
    marginTop: marginTop ? `${marginTop}px` : '',
  })
);
export function ProjectInfoContent(props) {
  const { title, content, flexAttr = 0, marginTop, ismobile } = props;
  return (
    <MainLayout flexAttr={flexAttr} marginTopp={marginTop}>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </MainLayout>
  );
}
