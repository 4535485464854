import { red, white, gray } from '~/react/Foundation/Color'

export const ButtonSizeStyle = {
  large: { height: '44px', padding: '11px 20px', radius: '6px', iconsize: 18, gap: '4px' },
  medium: { height: '36px', padding: '8px 16px', radius: '6px', iconsize: 16, gap: '2px' },
  small: { height: '32px', padding: '8px 12px', radius: '4px', iconsize: 14, gap: '2px' },
  fullWidth: { height: '56px', padding: '12px 24px', radius: '0', iconsize: 20, gap: '6px' }
}

export const ButtonTypeColor = {
  filled: {
    red: {
      backgroundColor: red[50],
      textColor: white
    },
    black: {
      backgroundColor: gray[90],
      textColor: white
    }
  },
  outlined: {
    white: {
      borderColor: gray[50],
      textColor: gray[90]
    },
    red: {
      borderColor: red[50],
      textColor: red[50]
    }
  }
}

export const TextButtonColor = {
  default: {
    backgroundColor: white,
    textColor: gray[90]
  },
  disabled: {
    backgroundColor: white,
    textColor: gray[50]
  }
}
export const ButtonDisabledTypeColor = {
  filled: {
    red: {
      backgroundColor: gray[40],
      textColor: white
    },
    black: {
      backgroundColor: gray[50],
      textColor: white
    }
  },
  outlined: {
    white: {
      borderColor: gray[50],
      textColor: gray[50]
    },
    red: {
      borderColor: gray[50],
      textColor: gray[50]
    }
  }
}

export const FloatingButtonSizeStyle = {
  large: { height: '56px', padding: '16px', iconsize: 24 },
  medium: { height: '44px', padding: '12px', iconsize: 20 },
  small: { height: '32px', padding: '9px', iconsize: 14 }
}
