import React from 'react'
import styled from '@emotion/styled'
import { ProjectCardImage } from './ProjectCardImage'
import { CommonCardImage } from '../../commonCard/CommonCardImage'
import { ProjectCardTimer } from './ProjectCardTimer'
import { ProjectCardPrice } from './ProjectCardPrice'
import { ProjectCardStatusText } from './ProjectCardStatusText'
import { ProjectCardProgressBar } from './ProjectCardProgressBar'
import { CardTitleDescInfo } from './CardTitleDescInfo'

const ProjectCardContainer = styled.div({}, ({ direction, ismobile }) => {
  
  const cardSize = {}
  
  cardSize.width = '248px' // TODO
  cardSize.height = '258px' // TODO
  
  const baseStyle = {
    ...cardSize,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    // .project-card-container
    boxShadow: 'none',
    transition: 'box-shadow 0.5s',
    // project-link
    position: 'relative',
    
    // display: 'block',
    
    // 추가
    // marginLeft: '8px',
    // marginRight: '8px',
  }
  const mobileStyle = {
    // project-link
    
    // 추가
    padLeft: '8px',
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const ProjectCardImageWrapper = styled.div({}, ({ }) => {
  
  const mobileStyle = {
  }  

  return mobileStyle
  
})

const ProjectCardInfoWrapper = styled.div({}, ({ direction }) => {

  const mobileStyle = {
    display: 'flex',
    flexDirection: 'column',    
    width :'248px', 
    height: '118px'
  }  

  return mobileStyle
  
})

const ProjectCardInfoHeader = styled.div({}, ({ }) => {

  const mobileStyle = {
    height: '78px'
  }
  return mobileStyle  
})

const ProjectCardInfoFooter = styled.div({}, ({ }) => {
  const mobileStyle = {
    height: '40px'
  }
  return mobileStyle
})

const ProjectCardStatusWrapper = styled.div({}, ({  }) => {
  const mobileStyle = {
    display: 'flex',
    width : '248px'
  }  
  return mobileStyle
})

export function ProjectCard15xMobile(props) {
  const { item, title, description, imageurl, iconnew, projectlabel, cardcontext, price, clickcallback, locale } = props
  
  const imageSize = {
    pc: {
      width :'0',
      height: '0'
    }, 
    mobile:{
      width: '248px',
      height: '140px'
    }
  }

  const infoStyleOpt = {
    pc: {
      fontSize :'0',
      lineHeight: '0'
    }, 
    mobile:{
      fontSize: '13px',
      lineHeight: '18px'
    }
  }

  return (
    <ProjectCardContainer onClick={()=>clickcallback(item.url)}>
      <ProjectCardImageWrapper>
        {/* <ProjectCardImage imageUrl={imageurl} ismobile={ismobile}>
        </ProjectCardImage> */}
        <CommonCardImage imageUrl={imageurl} iconnew={iconnew} size={imageSize} hoverEffect={'enlarge'} borderRadius={'0'} ismobile={true}>
        </CommonCardImage>        
      </ProjectCardImageWrapper>
      <ProjectCardInfoWrapper>
        <ProjectCardInfoHeader>
          <ProjectCardTimer 
            // className = {'project-timer-' + item.idx}
            projectLabel={projectlabel}
            color={cardcontext.timerColor}
            ismobile={true}>
          </ProjectCardTimer>
          <CardTitleDescInfo title={title} styleopt={infoStyleOpt} ismobile={true}>
          </CardTitleDescInfo>
        </ProjectCardInfoHeader>
        <ProjectCardInfoFooter ismobile={true}>
          <ProjectCardStatusWrapper ismobile={true}>
            <ProjectCardPrice price={price} ismobile={true}>
            </ProjectCardPrice>
            <ProjectCardStatusText 
              statusText={cardcontext.statusText}
              color={cardcontext.statusColor}
              locale={locale}>
            </ProjectCardStatusText>
          </ProjectCardStatusWrapper>
          <ProjectCardProgressBar color={cardcontext.progreeBarColor} value={cardcontext.progreeBarValue} height={4}>
          </ProjectCardProgressBar>
        </ProjectCardInfoFooter>
      </ProjectCardInfoWrapper>      
    </ProjectCardContainer>
  )
}

