import React from 'react'
import styled from '@emotion/styled'
import { DropDownListItem } from './DropDownListItem'
import { gray, white } from '../../Foundation/Color'

const InfoHistoryListWrap = styled.div({}, ({scrollable}) => {

  const baseStyle = {
    position: 'absolute',
    top: 'calc(100% + 2px)',
    left: '0',
    zIndex: '120',
    width: '100%',
    backgroundColor: white,
    color: gray[90],
    marginTop: '2px',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.20)',
    borderRadius: '4px',
    border: `1px solid ${gray[50]}`,
    padding: '8px 0'
  }
  const scrollStyle = {
    overflowY: 'scroll',
    maxHeight: '150px'
  }
  return scrollable? { ...baseStyle, ...scrollStyle }
  : baseStyle
})

export function DropDownList(props) {
  const { infoList = [], onchange, selectedKey, scrollable = false} = props
  const handleSelectValue = (key) => {
    onchange(key)
  }
  return (
    <InfoHistoryListWrap scrollable={scrollable}>
      {
        infoList?.map(item => (<DropDownListItem
          key={item.key}
          isSelected={selectedKey === item.key}
          content={item.value}
          id={item.key}
          onchange={handleSelectValue}/>))
      }
    </InfoHistoryListWrap>
  )
}

