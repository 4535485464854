import React from 'react';
import styled from '@emotion/styled';
import { WebsiteMainContent } from '~/react/main/WebsiteMainContent'
import { WebsiteFooter } from '~/react/main/WebsiteFooter'
import { NavigationBar } from '~/react/main/NavigationBar'
import codeConst from '~/utils/codeConst'

const { WEB_CONTENT_CONTAINER_SIZE, DEFAULT_MOBILE_LAYOUT_SIZE, DEFAULT_MOBILE_B2BLAYOUT_SIZE, MIN_MOBILE_LAYOUT_SIZE } = codeConst

const MainDefaultLayout = styled.div`
  width: 100vw;
  height: 100vh;
`;

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const ColoredBackground = styled.div`
  background: linear-gradient(0deg, #e6f4f8 50%, #f2f2f2 100%);
  height: calc(100% - 270px);
  width: 100%;
`;

const ContentWrapper = styled.div`
  @media screen and (min-width: ${WEB_CONTENT_CONTAINER_SIZE}px) {
    width: ${WEB_CONTENT_CONTAINER_SIZE}px;
    position: fixed;
    top: calc((100% - 270px) / 2);
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-flex;
  }

  @media screen and (min-width: ${DEFAULT_MOBILE_LAYOUT_SIZE}px) and (max-width: ${WEB_CONTENT_CONTAINER_SIZE - 1}px) {
    width: 100%;
    height: 100%;
  }
`;

const MobileSizeApp = styled.div`
  z-index: 1;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */

  background-color: white;
  height: 100%;

  @media screen and (min-width: ${WEB_CONTENT_CONTAINER_SIZE}px) {
    max-width: ${(props)=>props.b2b? DEFAULT_MOBILE_B2BLAYOUT_SIZE : DEFAULT_MOBILE_LAYOUT_SIZE}px;
    position: relative;
    left: ${(props)=>props.b2b? 'calc(50% - 94px)' : '50%'};
  }

  @media screen and (min-width: ${DEFAULT_MOBILE_LAYOUT_SIZE}px) and (max-width: ${WEB_CONTENT_CONTAINER_SIZE - 1}px) {
    z-index: 1;
    width: 100%;
    max-width: ${(props)=>props.b2b? DEFAULT_MOBILE_B2BLAYOUT_SIZE : DEFAULT_MOBILE_LAYOUT_SIZE}px;
    position: relative;
    margin: 0 auto;
    top: -100%;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const MobileInnerLayout = styled.div`
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.20);
`;

const DesktopView = styled.div`
  width: ${(props)=> props.b2b ? 336 : 368}px;
  margin: 0 111px 0 16px;

  // 기본 상태에서는 엘리먼트를 보이게 설정합니다.
  display: block;

  // 화면 너비가 WEB_CONTENT_CONTAINER_SIZE 미만인 경우에만 숨깁니다.
  @media screen and (max-width: ${WEB_CONTENT_CONTAINER_SIZE - 1}px) {
    display: none;
  }

  // 화면 높이가 675px 이하인 경우에도 숨깁니다.
  @media screen and (max-height: 675px) {
    display: none;
  }
`;

const MainWebFooter = styled.div`
  background-color: white;
  height: 270px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 0;

  @media screen and (min-width: ${WEB_CONTENT_CONTAINER_SIZE}px) {
    width: ${WEB_CONTENT_CONTAINER_SIZE}px;
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  @media screen and (max-width: ${WEB_CONTENT_CONTAINER_SIZE - 1}px) {
    display: none;
  }
`;

export function MainWebsiteLayout ({ $makeStarApi, $i18n, routePush, routeGo, onSelectLanguage, closeHamburgerMenu, children, b2b, isPC }) {

  const forward = () => {
    closeHamburgerMenu()
    routeGo(1)
  }

  const back = () => {
    closeHamburgerMenu()
    routeGo(-1)
  }

  const home = () => {
    closeHamburgerMenu()
    routePush('/')
  }

  const refresh = () => {
    closeHamburgerMenu()
    routeGo(0)
  }

  return (
    <MainDefaultLayout>
      <Background>
        <ColoredBackground />
        <MainWebFooter b2b={isPC && b2b}>
          <WebsiteFooter
            $i18n={$i18n}
            navigateTo={routePush}
            onSelectLanguage={onSelectLanguage}
          />
        </MainWebFooter>
      </Background>
      <ContentWrapper>
        <DesktopView b2b={isPC && b2b}>
          <WebsiteMainContent
            $makeStarApi={$makeStarApi}
            $i18n={$i18n}
            navigateTo={routePush}
            closeHamburgerMenu={closeHamburgerMenu}
            b2b={isPC && b2b}
          />
        </DesktopView>
      </ContentWrapper>
      <MobileSizeApp b2b={isPC && b2b}>
        <MobileInnerLayout>
          {children}
        </MobileInnerLayout>
      </MobileSizeApp>
    </MainDefaultLayout>
  )
}
