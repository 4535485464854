import React from 'react'
import styled from '@emotion/styled'
import { B1Medium, B2Medium, B2Regular, C1Regular } from '../../../Foundation/Typography'
import { gray } from '../../../Foundation/Color'

const CardTitleDescContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
  const mobileStyle = {
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const CardTitle = styled.div({}, ({ ismobile }) => {

  const baseStyle = {
    position: 'relative',
    color: gray[90],
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    overflowY: 'hidden',
  }
  const mobileStyle = {
    whiteSpace: 'pre',
    textOverflow: 'ellipsis'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const CardDescription = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    position: 'relative',
    color: gray[70],
    marginTop: '4px',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordWrap: 'break-word',
    overflowY: 'hidden',
  }
  const mobileStyle = {
    marginTop: '2px'
  }

  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

export function CardArtistTitleDescInfo(props) {
  const { title, description, ismobile } = props
  return (
    <CardTitleDescContainer ismobile={ismobile}>
      <CardTitle ismobile={ismobile}>
        {ismobile? <B2Medium ismobile={ismobile}>{title} </B2Medium> : <B1Medium weight={"medium"}>{title} </B1Medium>}
      </CardTitle>
      <CardDescription ismobile={ismobile}>
        {ismobile? <C1Regular ismobile={ismobile}>{description} </C1Regular> : <B2Regular weight={"regular"}>{description} </B2Regular>}
      </CardDescription>
    </CardTitleDescContainer>
  )
}
