import React from "react";
import styled from '@emotion/styled'
import { white } from '~/react/Foundation/Color/index';
import {
  IconAlarm,
  IconAlarmWBadge,
} from "~/react/Foundation/Icon";

const GNBNotiIconButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  display: block;
  background-color: ${white};
`;
export function GNBNotiIcon(props) {
  const { alarm, handleopennoti, ismobile, ...extraProps } = props;
  return (
    alarm ? (
      <GNBNotiIconButton {...extraProps}>
        <IconAlarmWBadge
          style={{ width: "28px", height: "28px" }}
        ></IconAlarmWBadge>
      </GNBNotiIconButton>
    ) : (
      <GNBNotiIconButton {...extraProps}>
        <IconAlarm
          style={{ width: "28px", height: "28px" }}
        ></IconAlarm>
      </GNBNotiIconButton>
    ))
  ;
}