import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import { gray } from '~/react/Foundation/Color'

const Image = styled.img({}, ({width}) => {
  return {
    width: `${width}px`,
    aspectRatio: '1',
    position: 'relative',
    backgroundColor: gray[50],
    borderRadius: '50%',
    overflow: 'hidden'
  }
})

const DEFAULT_IMAGE_PATH = '/image/user-default.png'

/**
 * AvatarProps
 * @param {string} imageUrl Image Src
 * @param {number} [width=56] Optional
 * @param props
 * @returns {JSX.Element}
 */
export function Avatar({imageUrl = '', width = 56, ...props}) {

  const onErrorImageLoad = useCallback((event) => {
    event.target.src = DEFAULT_IMAGE_PATH
  }, [imageUrl])

  return (
      <Image src={imageUrl} alt={`avatar image`} width={width} onError={onErrorImageLoad} {...props}></Image>
  )
}
