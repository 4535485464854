import Vue from 'vue'
import { log } from '~/communicator/utils/logging'
// import { serialize } from '~/utils/common'
import codeConst from '~/utils/codeConst'
import toastContent from '~/components/toast/toastContent.vue'

const { LOGIN_RESULT_STATUS, TOAST_ICON } = codeConst

const authMixin = {
  methods: {
    /**
     * 유저 로그인 정보 디스패치
     * @returns {Promise<void>}
     */
    async dispatchUserProfileInfo() {
      const result = await this.$makeStarApi.user.loginUserProfile()
      if (!result) return
      if (result.resCd === '1000' && result.resData.loggedInUser) {
        await this.$store.dispatch('modules/loginuserinfo/setLoginInfo', result.resData)
      }
    },

    async dispatchSocialUserProfileInfo() {
      const result = await this.$makeStarApi.user.loginUserSocalProfile()
      if (!result) return
      if (result.resCd === '1000' && result.resData.loggedInUser) {
        await this.$store.dispatch('modules/loginuserinfo/setLoginInfo', result.resData)
      }
    },

    /**
     * 로그아웃 처리
     * @returns {Promise<void>}
     */
    async logoutAndClearData(redirectUrl = null) {
      try {
        const response = await this.$makeStarApi.user.userLogout()
        if (response.resCd === '1000') {
          this.clearUserData()
          this.routePush(redirectUrl || '/')
        } else {
          this.showAuthErrorToast()
        }
      } catch (e) {
        log('error', e)
        this.showAuthErrorToast()
      }
    },

    /**
     * 유저 정보 클리어
     * @param {{fromEventListener: boolean}} [params]
     */
    clearUserData(params) {
      this.$store.dispatch('modules/loginuserinfo/setTokenInfo', {})
      this.$store.dispatch('modules/loginuserinfo/setLoginInfo', {})
      if (params && params.fromEventListener) {
        return
      }
      this.broadcastTokensToApp(null)
    },

    /**
     * 토큰 로그인 처리
     * @param {{fromEventListener: boolean}} [params] 외부에서 발생한 세션 변화인가?
     * @returns {Promise<{status: ('SUCCESS' | 'ALLOWED_SERVICE_EXCEPTION_CASE' | 'FAIL'), code?: string, message?: string}>}
     */
    async tokenLogin(params) {
      try {
        const authResult = await this.$makeStarApi.user.tokenLogin()
        const successfulResCodes = ['1000', '6233', '6234', '6235', '6237', '6238']
        if (authResult.resCd === '1000') {
          const { accessToken, refreshToken } = authResult.resData

          await this.$store.dispatch('modules/loginuserinfo/setTokenInfo', {
            accessToken,
            refreshToken
          })

          await this.dispatchUserProfileInfo()
          const email = this.$store.getters.loginInfo.email

          if (params && params.fromEventListener) {
            return {
              status: LOGIN_RESULT_STATUS.SUCCESS
            }
          }
          this.broadcastTokensToApp(accessToken, refreshToken, email)

          return {
            status: LOGIN_RESULT_STATUS.SUCCESS
          }
        } else if (successfulResCodes.includes(authResult.resCd)) {
          // 그 외 예외 코드인 경우
          // ex. 회원가입 필요, 토큰 재인증 필요, 로그인 필요 등
          return {
            status: LOGIN_RESULT_STATUS.ALLOWED_SERVICE_EXCEPTION_CASE,
            code: authResult.resCd,
            message: authResult.resMsg
          }
        } else {
          this.clearUserData()
          return {
            status: LOGIN_RESULT_STATUS.FAIL
          }
        }
      } catch (e) {
        log('error', 'catching tokenLogin error...')
        log('error', e)
        this.clearUserData()
        this.showAuthErrorToast()
        return {
          status: LOGIN_RESULT_STATUS.FAIL
        }
      }
    },

    /**
     * 인증 에러 토스트 얼럿을 띄워줍니다.
     * @param [message]
     */
    showAuthErrorToast(message = null) {
      const loginErrorMessage = message || this.$t('account.loginErrorMessage')
      Vue.prototype.toastIcon = TOAST_ICON.ERROR
      Vue.prototype.toastMessage = loginErrorMessage
      this.$toast.error(toastContent, {
        position: 'top-right',
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: 'button',
        icon: false,
        rtl: false
      })
    },

    /**
     * 토큰을 App 으로 전송
     * @param {string} accessToken
     * @param {string} refreshToken
     * @param {string} email
     */
    broadcastTokensToApp(accessToken = '', refreshToken = '', email = '') {
      // 웹뷰 환경이 아니라면 실행하지 않습니다.
      if (!window.flutter_inappwebview) return
      const payload = accessToken
        ? {
          accessToken,
          refreshToken,
          email
        }
        : null
      const params = JSON.stringify(payload)
      window.flutter_inappwebview.callHandler('sessionBroadcastHandler', params)
    },

    /**
     * App에서 localStorage로 전송된 토큰 상태를 통해
     * 자동 로그인/로그아웃 처리를 수행합니다.
     */
    async updateTokenStatusFromApp() {
      // 웹뷰 환경이 아니라면 실행하지 않습니다.
      if (!window.flutter_inappwebview) return

      try {
        const makestarAppSessionString = window.localStorage.getItem('makestarAppSession')
        if (!makestarAppSessionString) {
          this.clearUserData({
            fromEventListener: true
          })
          return
        }
        const makestarAppSession = JSON.parse(makestarAppSessionString)
        const { accessToken, refreshToken } = makestarAppSession
        if (!accessToken || !refreshToken) {
          this.clearUserData({
            fromEventListener: true
          })
          return
        }

        await this.$store.dispatch('modules/loginuserinfo/setTokenInfo', {
          accessToken,
          refreshToken
        })

        await this.tokenLogin({
          fromEventListener: true
        })
      } catch (e) {
        log('error', e)
      }
    }
  }
}
Vue.mixin(authMixin)
