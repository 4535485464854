import artist from './modules/artist'
import board from './modules/board'
import event from './modules/event'
import main from './modules/main'
import order from './modules/order'
import polls from './modules/polls'
import project from './modules/project'
import user from './modules/user'
import shopping from './modules/shopping'
import system from './modules/system'
import external from './modules/external'
import pay from './modules/pay'
import auth from './modules/auth'

export default request => ({
  artist: artist(request),
  board: board(request),
  event: event(request),
  main: main(request),
  order: order(request),
  polls: polls(request),
  project: project(request),
  user: user(request),
  shopping: shopping(request),
  system: system(request),
  external: external(request),
  pay: pay(request),
  auth: auth(request)
})
