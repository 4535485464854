
const getters = {
  waitingEntry: state => state.modules.device.waitingEntry,
  device: state => state.modules.device.device,
  standardWidth: state => state.modules.device.standardWidth,
  socialLoginException: state => state.modules.user.socialLoginException,
  tokenInfo: state => state.modules.loginuserinfo.tokenInfo,
  loginInfo: state => state.modules.loginuserinfo.loginInfo,
  headerViewStatus: state => state.modules.layout.headerViewStatus,
  headerFixedStatus: state => state.modules.layout.headerFixedStatus,
  layoutType: state => state.modules.layout.layoutType,
  showHamburgerMenu: state => state.modules.layout.showHamburgerMenu,
  token: state => state.user.token,
  scrollY: state => state.modules.layout.scrollY,
  cartList: state => state.modules.shop.cartList,
  cartAmount: state => state.modules.user.cartAmount,
  commentItem: state => state.modules.user.commentItem,
  signUpInfo: state => state.modules.loginuserinfo.signUpInfo,
  recentSearchWordList: state => state.modules.artists.recentSearchWordList,
  ditoland: state => state.modules.ditoland.ditoland,
  rewardHtml: state => state.modules.layout.rewardHtml,
  b2b: state => state.modules.layout.b2b,
  isPC: state => state.modules.layout.isPC,
  isRefreshingToken: state => state.modules.request.isRefreshingToken
}
export default getters
