import React from 'react'
import styled from '@emotion/styled'
import { ImageResizeTag } from '../common'

const CircledCardImageContainer = styled.div({}, ({ ismobile }) => {
  const baseStyle = {
    // .card-item
    width :'144px',
    height: '144px',
    
    // .relative-content 332
    boxShadow: 'none',
    transition: 'box-shadow 0.5s',

    // .image-hover-zoom 347
    overflow: 'hidden',

    borderRadius: '50%'
  }

  const mobileStyle = {
    width :'96px',
    height: '96px'
  }
  return ismobile
  ? {
    ...baseStyle,
    ...mobileStyle
  }
  : baseStyle
})

const CircledCardImageBody = styled.img({
  }, ({ ismobile }) => {

  const pcStyle = {
    '&:hover': {
      transform: 'scale(1.1)',
    },
    borderRadius: '50%',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    position: 'relative',
    // .image-wrapper
    transition: 'all 0.5s ease',
  }
  
  const mobileStyle = {
    borderRadius: '50%',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    position: 'relative',
    // .image-wrapper
    transition: 'all 0.5s ease',
  }  

  return ismobile
  ? mobileStyle : pcStyle
})

export function CircledCardImage(props) {
  const { imageUrl, ismobile } = props
   
  const imgProps = ImageResizeTag({
    imageUrl: imageUrl,
    width: ismobile ? 96 : 144
  })
  return (
    <CircledCardImageContainer ismobile={ismobile}>
      <CircledCardImageBody {...imgProps} ismobile={ismobile}>
      </CircledCardImageBody>
    </CircledCardImageContainer>    
  )
}
