import React from 'react'
import styled from '@emotion/styled'
import { CardUpdateImage } from './CardUpdateImage'
import { CardUpdateInfo } from './CardUpdateInfo'

const CardUpdateContainer = styled.div({}, ({ ismobile, size }) => {
  let width = ''
  let height = ''

  if (ismobile) {
    height = 'auto'
    switch(size){
      case 'medium': {
        width = '224px'
        break;
      }
      case 'large': {
        width = '344px'
        break;
      }
      default:
        width = '100%';
        break;
    }
  } else {
    width ='560px'
    height = '116px'
  }

  const baseStyle = {
    width: width,
    height: height,
    minWidth: width,
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    boxShadow: 'none',
    transition: 'box-shadow 0.5s',
    position: 'relative',

  }
  const mobileStyle = {
    flexDirection: 'column'
  }

  return ismobile? { ...baseStyle, ...mobileStyle }
  : baseStyle
})

const CardUpdateImageWrapper = styled.div({}, ({ ismobile, size }) => {
  let width = ''
  let height = ''
  if (ismobile) {
    width = '100%'
    switch(size){
      case 'medium': {
        height = '126px'
        break;
      }
      case 'large': {
        height = '192px'
        break;
      }
      default:
        width = '100%';
        break;
    }
  } else {
    width ='206px'
    height = '116px'
  }
  const baseStyle = {
    width,
    height
  }

  return baseStyle
})

const CardUpdateInfoWrapper = styled.div({}, ({ ismobile, size }) => {

  const baseStyle = {
    width :'322px',
    height: '116px',
    padding: '0px 16px',
    lineHeight: '0',
  }
  const mobileStyle = {
    width :'100%',
    height: size === 'large' ? '86px': '96px',
    padding: 0
  }

  return ismobile? { ...baseStyle, ...mobileStyle }
  : baseStyle
})


/**
 * @typedef CardUpdateProps
 * @property {string} title 제목
 * @property {string} description
 * @property {string} timefromnowtext 업데이트 날짜와 현재의 시간 차이 텍스트
 * @property {number} commentcount 댓글 수
 * @property {string} imageurl
 * @property {string} iconnew new 뱃지 여부
 * @property {(flag: string, id?:string, updateNo?:string) => void} clickcallback 라우팅 함수
 * @property {string[]} clickcallbackparam 콜백 파라미터
 * @property {boolean} ismobile PC/Mobile
 * @property {'samll' | 'medium' | 'large'} size 카드 사이즈
 */
/**
 *
 * @param {CardUpdateProps} props
 * @returns
 */

export function CardUpdate(props) {
  const { title, description, timefromnowtext, commentcount, imageurl, iconnew, clickcallback, clickcallbackparam, ismobile, size } = props

  return (
    <CardUpdateContainer onClick={()=>clickcallback(...clickcallbackparam)} ismobile={ismobile} size={size}>
      <CardUpdateImageWrapper ismobile={ismobile} size={size}>
        <CardUpdateImage imageUrl={imageurl} iconnew={iconnew} ismobile={ismobile}>
        </CardUpdateImage>
      </CardUpdateImageWrapper>
      <CardUpdateInfoWrapper ismobile={ismobile} size={size}>
        <CardUpdateInfo title={title} description={description} commentcount={commentcount} timefromnowtext={timefromnowtext} ismobile={ismobile}>
        </CardUpdateInfo>
      </CardUpdateInfoWrapper>

    </CardUpdateContainer>
  )
}

